import PropTypes from 'prop-types';
import { useEffect, useState, useContext} from 'react';
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

import { DonneesUtilisateurContext } from '../contexte.js';

import { apiSoumissionFormulaireDemande } from '../Datas/datasAutre.js'

import ButtonDeBase from './ButtonsCustom.jsx';
import LoadingSpinner from "./LoadingSpinner";
import { MessageErreurApi } from './Messages.jsx';

/**
 * Component for message carif
 * Si l'étape fait partie de la formation , on affiche les commantaires carifs de la formation sur chacune des étapes de la dite formation
 * de la meme façon pour l'action et pour la session :
 * Si l'étape fait partie de l'action , on affiche les commantaires carifs de l'action sur chacune des étapes de la dite action
 
* L'api utilisée est la meme que dans FormDemandeChangementSession
 * @component
 * @return { HTMLElement }
*/
function FormRepDemandeCorrectionCarif(props) {
    const navigate = useNavigate(); // utile pour changer de page

    //console.log(`props : ${props}`)
    const { utilisateurOrg } = useContext(DonneesUtilisateurContext);
    const { utilisateurNomOrg } = useContext(DonneesUtilisateurContext);
    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);

    const [showModal, setShowModal] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(true);
    const [messageProbleme, setMessageProbleme] = useState();
    const [aCacherCarTraite, setaCacherCarTraite] = useState(false);

    const handleCloseModal = () => {
        setShowModal(false);
    }
    const handleClickEnvoiInfos = async () => {          
        var msgPourCarif = document.getElementById("message-pour-carif").value;
        //console.log(msgPourCarif);
        //console.log(props.formationNumber);

        var datasToPost= { // on créé ici comme pas d'info a donner tout de suite....
            "commentaire": msgPourCarif,
            "formationNumber": props.formationNumber,
            "actionNumber": props.actionNumber,
            "sessionNumber": props.sessionNumber,
            "DemandeurOrgId" : utilisateurOrg,
            "DemandeurNomOrganisme" : utilisateurNomOrg
        }
        setDataLoaded(false);
        var dataR = await apiSoumissionFormulaireDemande(utilisateurBearer, datasToPost, "envoi-formulaire-correction/correction-suite-demande-carif");
        if(global.config.modeDebugApp == 1) console.log('retour apiSoumissionFormulaireDemande avec "envoi-formulaire-correction/correction-suite-demande-carif" : ', dataR);
  
        switch (dataR.statusCode) {
            case 200: 
                 setDataLoaded(true);
                 var choixNavig = confirm("Souhaitez-vous maintenant être redirigée sur la liste des formations en attente de correction ?");
                 if(choixNavig) navigate(`/formation/en-attente`);
                 else {
                    handleCloseModal();
                    setaCacherCarTraite(true);
                 }
                break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
                break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }

    }

    if(props.infosGenFormation !== undefined && aCacherCarTraite == false) {
        //var numEtape = parseInt(props.nomEtape.substring(5)); // verifié ok si 1b => 1
        var strPourTitre = "";
        var strPourInfo = "";
        //console.log("props.sessionNumber", props.sessionNumber)
        if(props.infosGenFormation.form_commentaire_carif != "" && props.infosGenFormation.form_commentaire_carif != null && isNaN(props.actionNumber)) { //numEtape < 5 au lieu de props.actionNumber == null 
            strPourTitre = "formation";
            strPourInfo = "Le carif vous indique l'information suivante pour cette " + strPourTitre + 
            ' : <br /><b>"' + props.infosGenFormation.form_commentaire_carif + 
            '"</b><br/><br/>Cliquez sur le bouton ci-dessous une fois les modifications effectuées.';
        }
        if(props.infosGenFormation.act_commentaire_carif != "" && props.infosGenFormation.act_commentaire_carif != null && isNaN(props.sessionNumber)) {
            strPourTitre = "action";
            strPourInfo = "Le carif vous indique l'information suivante pour cette " + strPourTitre + 
            ' : <br /><b>"' + props.infosGenFormation.act_commentaire_carif + 
            '"</b><br/><br/>Cliquez sur le bouton ci-dessous une fois les modifications effectuées.';
        }
        if(props.infosGenFormation.sess_commentaire_carif != "" && props.infosGenFormation.sess_commentaire_carif != null  && !isNaN(props.sessionNumber)) {
            strPourTitre = "session";
            strPourInfo = "Le carif vous indique l'information suivante pour cette " + strPourTitre + 
            ' : <br /><b>"' + props.infosGenFormation.sess_commentaire_carif + 
            '"</b><br/><br/>Cliquez sur le bouton ci-dessous une fois les modifications effectuées.';
        }
        if(strPourTitre != "") {
            return (
            <>
            <div className="message-carif">
                <div dangerouslySetInnerHTML={{ __html: strPourInfo }}></div>
                <ButtonDeBase typeSubmit={false} lib="Indiquer au carif que le nécessaire a été fait" onClick={() =>setShowModal(true)} />
            </div>
            <Modal size="lg" show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} centered aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    <>Suite à votre action</>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LoadingSpinner dataLoaded={dataLoaded}/>
                    <MessageErreurApi message={messageProbleme} />
                    Merci pour votre action.<br/>
                    Le carif va être notifié des corrections que vous avez apportées.<br />
                    Si vous le souhaitez, vous pouvez adresser un message au carif tout en confirmant la procédure :<br />
                    <textarea style={{marginTop: "10px", width: "770px", height: "100px"}} id="message-pour-carif"></textarea>
                <ButtonDeBase typeSubmit={false} lib="Je confirme 👍" onClick={() =>handleClickEnvoiInfos()} />
                </Modal.Body>
            </Modal>
            </>
            )
        }
    }
}

FormRepDemandeCorrectionCarif.propTypes = {
    infosGenFormation: PropTypes.object.isRequired,
    nomEtape: PropTypes.string.isRequired,
    formationNumber: PropTypes.number.isRequired,
    actionNumber: PropTypes.number.isRequired,
    sessionNumber: PropTypes.number.isRequired,
}

export default FormRepDemandeCorrectionCarif;