import PropTypes from 'prop-types';
import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import { Container } from "react-bootstrap";
import {NavLink } from "react-router-dom";

import { DonneesUtilisateurContext } from '../../contexte.js';

import { apiCycleAjout, apiCycleSuppr } from '../../Datas/dataSessionsCycle.js'
import { apiSessAppelGetInfosDiv } from '../../Datas/dataSessions.js'

import LiensPrecedentSuivant from '../../Components/LiensPrecedentSuivant.jsx';
import LoadingSpinner from "../../Components/LoadingSpinner";
import MessageInfoBox, {MessageErreurApi, MessageInfoNoDroitErreurApi} from '../../Components/Messages.jsx';
import ButtonDeBase, { ButtonSuppr } from '../../Components/ButtonsCustom.jsx';
import FormRHF from "../../Components/Form/Form";
import FormControl from "../../Components/Form/FormControl";
import FormSelect from "../../Components/Form/FormSelect";
import FormCheck from "../../Components/Form/FormCheck";
import Table from '../../Components/Table.jsx';

/**
 * Return Formulaire étape 14 (cycle)
 *
 * @component
 * @summary page étape 14
 * @param {  }
 * @return { HTMLElement }
*/
function FormEtape14(props) {
    let numEtape = 14;

    const formationNumber = props.formationNumber;
    const actionNumber = props.actionNumber;
    const sessionNumber = props.sessionNumber;

    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const [dataApi, setDataApi] = useState();
    const [aumoinsUneModif, setAumoinsUneModif] = useState(false); // pour avertir l'utilisateur en cas de sortie sans  enregistrement

    const [dataLoaded, setDataLoaded] = useState(true);
    const [messageProbleme, setMessageProbleme] = useState();

    //const [adresseSelectionne, setAdresseSelectionne] = useState(false);

     const columns = useMemo(
      () => [
        {
          id:'listesCycles',
          hideHeader : false,
          trieDefautRp : 'LIB_LHEO_TYPE_CYCLE',
          columns: [
            { 
              Header: 'Type de cycle ', 
                accessor: 'LIB_LHEO_TYPE_CYCLE',
              },  
              { 
              Header: 'Type de contrat d’alternance', 
                accessor: 'LIB_LHEO_TYPE_ALTERNANCE',
              },    
              { 
              Header: 'Années du cycle', 
                accessor: 'ANNEES_CYCLE',
                id: "ANNEES_CYCLE_center",
              },
              {
                Header: 'Effectif minimal', 
                accessor: 'EFFCTIF_MINIMAL',
                id: "EFFCTIF_MINIMAL_center",
              },
              {
                Header: 'Capacité d’accueil simultanée', 
                accessor: 'CAPACITE_SIMULTANEE',
                id: "CAPACITE_SIMULTANEE_center",
              },
              {
                Header: 'Capacité de formation cumulée', 
                accessor: 'CAPACITE_CUMULEE',
                id: "CAPACITE_CUMULEE_center",
              },
              {
                Header: 'Temps en entreprise', 
                accessor: 'UNITE_ENTREPRISE',
                id: "UNITE_ENTREPRISE_center",
              },
              {
                Header: 'Temps en centre', 
                accessor: 'UNITE_CENTRE',
                id: "UNITE_CENTRE_center",
              },
          ]
        },
      ],
      []
    )
    const handleClickSupprCycle  = async (codeAsupprimer) => {
      var confirmation = confirm("Etes vous sûr de vouloir supprimer ce cycle ?");
      if(confirmation == true) {
        var dataR = await apiCycleSuppr(utilisateurBearer, sessionNumber, codeAsupprimer);
        if(global.config.modeDebugApp == 1) console.log('retour apiCycleSuppr : ', dataR);
        switch (dataR.statusCode) {
            case 204:
                //alert("Le cycle a bien été supprimé");
                recupererListeCompleteCycles();
              break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
      }
    }

    async function recupererListeCompleteCycles() {
      const dataR = await apiSessAppelGetInfosDiv(utilisateurBearer, "/"+sessionNumber + "/cycles");
      if(global.config.modeDebugApp == 1) console.log('retour apiSessAppelGetInfosDiv dans recupererListeCompleteCycles : ', dataR);
      switch (dataR.statusCode) {
          case 200:
              if(props.infosProprietes["ACTIONS_SESSIONS_PERIODE_CYCLE|other_dataEditable"].editable  == false) {
                //modification du contenu renvoyé par l'api (ajout d'une information)
                let nouveauTabRep = [];
                dataR.dataApi.forEach((el) => {
                    // on va ajouter le libellé des unités : 
                    let ligne = { ...el, 'noEditablePLease': 1, 'UNITE_ENTREPRISE': el.UNITE_ENTREPRISE + ' ' + el.LIB_LHEO_UNITE_RYTHME, 'UNITE_CENTRE': el.UNITE_CENTRE + ' ' + el.LIB_LHEO_UNITE_RYTHME}
                    nouveauTabRep.push(ligne);           
                });
                if(global.config.modeDebugApp == 1) console.log("dataApi modifié:", nouveauTabRep);
                setDataApi(nouveauTabRep);
              }
              else setDataApi(dataR.dataApi);
              //console.log("dataApi", dataR.dataApi)

              var objaAmodif = JSON.parse(JSON.stringify(props.infosGenFormation)); // on clone (duplique) l'objet pour que tout fonctionne bien
              objaAmodif.nbCycles = dataR.dataApi.length;
              //objaAmodif["etape14"] = "ok";
              props.SetInfosGenFormation(objaAmodif);

              return true;
            break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
            break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        return false;
      }
    }
    
   //pour effectuer des actions en fonction de la saisie de l'utilisateur
    function gestionEvenements(value, name){
      setAumoinsUneModif(true);
      if(global.config.modeDebugApp == 1) console.log (`modif de ${name} : ${value[name]}`);
    }

    const onSubmit = async (values) => {
      for(let i in values) if(values[i] === "") values[i] = null; // sinon génère une erreur : on met à null tout les champs vide (ract-hook-form semble le faire pour nous dans le cas ou on lui passe les valeurs par défaut en entré)
      if(global.config.modeDebugApp == 1) console.log("Values:::", values);
      if(global.config.modeDebugApp == 1) console.log("Values:::", JSON.stringify(values));

      var dataR = await apiCycleAjout(utilisateurBearer, values, "/"+sessionNumber + "/cycles/") // on envoie sans faire JSON.stringify
      if(global.config.modeDebugApp == 1) console.log('retour apiCycleAjout : ', dataR);

      switch (dataR.statusCode) {
          case 200: 
               alert("L'enregistrement s'est bien effectué.");
               await recupererListeCompleteCycles();
               setAumoinsUneModif(false);
              break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
    };
  
    const onError = (error) => {
      console.log("ERROR:::", error);
    };
    /// eslint-disable-next-line no-unused-vars


    /*const [dataLHEO_MODALITE_RECRUTEMENT, SetDataLHEO_MODALITE_RECRUTEMENT] = useState(); // pour recup données du select
    const [dataLHEO_PERIMETRE_RECRUTEMENT, SetDataLHEO_PERIMETRE_RECRUTEMENT] = useState(); // pour recup données du select
    const [adressesPourChoix, SetAdressesPourChoix] = useState(); // pour recup données du select*/
    async function chargementsConsecutifs() {
      setDataLoaded(false);
      let retP = await recupererListeCompleteCycles();
      /*if(retP == true) {
        var pb = false;
        var ret = await appelRecupInfoGet(utilisateurBearer, "LHEO_MODALITE_RECRUTEMENT", setMessageProbleme);
        if(ret != null) SetDataLHEO_MODALITE_RECRUTEMENT(ret); else pb = true;

        var ret = await appelRecupInfoGet(utilisateurBearer, "LHEO_PERIMETRE_RECRUTEMENT", setMessageProbleme);
        if(ret != null) SetDataLHEO_PERIMETRE_RECRUTEMENT(ret); else pb = true;

        var ret = await appelRecupInfoGet(utilisateurBearer, "lieux/lieux-formateurs-action/" + actionNumber, setMessageProbleme);// recuperation des adresses pour liste select
        if(ret != null) SetAdressesPourChoix(ret); else pb = true;

        if(pb == true ) setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }*/
      setDataLoaded(true);
    }

    useEffect(() => {
        if(global.config.modeDebugApp == 1) console.log('useffect FormEtape14');
        document.title = `Cycle(s) de la session ${sessionNumber}`;
          setMessageProbleme(null);
          chargementsConsecutifs();
    }, []);

    useEffect(() => {
      setAumoinsUneModif(false);
    }, [dataApi]);
    
   /*const handleChoixAdresse = async (codeAasso) => {
      setAdresseSelectionne(codeAasso);
    }*/

    return (
      <>
        <LoadingSpinner dataLoaded={dataLoaded}/>
        <MessageErreurApi message={messageProbleme} />


        {dataLoaded && dataApi && dataApi != null && props.infosProprietes != null && messageProbleme == null &&
        <Container className="my-0">
            <section className="section-generale">
                <div className="titre">Cycles déclarés</div><br />

                <MessageInfoBox  nomClasse="message-info2"  message={"🕓 Vous pourrez <b>prochainement</b> venir modifier ou rajouter les effectifs et les durées."} />

                {dataApi && dataApi.length > 0 && 
                  <>
                    <Table columns={columns} data={dataApi} noPagination={true} noFilter={true} />
                  </>
                }
                {dataApi && dataApi.length == 0 && <div>Aucun cycle n'a encore été déclaré.</div>}

                <br /><br />
                <MessageInfoBox message={"  ℹ️ Information<br><br>"+
                "L’effectif est le nombre d’apprenants accueillis en formation.<br>"+
                "<u>Effectif minimal</u> : l’effectif minimal est le nombre d’apprenants requis pour que la session de formation débute.<br><br>"+

                "<u>Capacité simultanée</u> : la capacité d’accueil simultanée est le nombre maximal d’apprenants accueillis sur la session de manière simultanée. Exemple: sur un plateau technique de couture, 12 machines à coudre peuvent déterminer un accueil simultané de 12 apprenants.<br><br>"+
                "<u>Capacité cumulée</u> : la capacité cumulée est le nombre total d’apprenants qui pourront être formés sur cette session. Cette notion est distincte de la capacité simultanée lorsque des entrées en formation sont personnalisables."} />
            </section>
            <br />

        </Container>
        }

        <LiensPrecedentSuivant formationNumber={formationNumber} actionNumber={actionNumber} sessionNumber={sessionNumber} numEtape={numEtape} infosGenFormation={props.infosGenFormation} notifBeCareful={aumoinsUneModif} />
      </>
    )

}

FormEtape14.propTypes = {
  formationNumber: PropTypes.number.isRequired,
  actionNumber: PropTypes.number.isRequired,
  sessionNumber: PropTypes.number.isRequired,
  SetInfosGenFormation : PropTypes.func,
  infosGenFormation: PropTypes.object,
}

export default FormEtape14;