import { useEffect, useState, useContext } from 'react';

import { apiPostFormulaire1  } from '../Datas/datasAutre.js'
import { DonneesUtilisateurContext } from '../contexte.js';

import MessageInfoBox, {MessageErreurApi} from '../Components/Messages.jsx';
import { useForm, Controller  } from "react-hook-form";

import MyEditor2  from '../Components/MyEditor2.jsx';
/**
 * Return template with Sign in form
 *
 * @component
 * @summary imported in Route
 * @param {  }
 * @return { HTMLElement }
*/


function Formulaire1() {
    
    /**
     * @summary fonction permettant de valider la saisie du formulaire
     * 
     * @return { Boolean }
     */
    function validate() {
        return true;
    };
    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const [messageProbleme, setMessageProbleme] = useState();

    // lors de la soumission du formulaire  
    const onSubmit = async (data) => {
       //event.preventDefault() 
        if (validate()) {            
            console.log(data)
/*
            var dataR = await apiPostFormulaire1(utilisateurBearer, data); // objRetour = {statusCode, retour
            console.log(dataR)
            //if (dataR.statusCode === 200) 
            //else if (dataR.statusCode === 401) 
            switch (dataR.statusCode) {
                case 200:
                    setMessageProbleme("Les données ont bien été soumises");
                  break;
                case 401:
                    setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
                  break;
                default:
                    setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
            }*/
        }
    }
    const { register, handleSubmit, control, watch, formState: { errors } } = useForm();

    useEffect(() => {
       document.title = "Formulaire 1"
    }, []);

    return (
        <main className="main bg-dark">
  
            <section className="sign-in-content">
                <i className="fa fa-user-circle sign-in-icon"></i>
                <h1>Sign In</h1>
                {/*messageProbleme && <span>{messageProbleme}</span>*/}
                <MessageErreurApi message={messageProbleme}  />

                <form onSubmit={handleSubmit(onSubmit)}> 
                    <div className="input-wrapper">
                        <label htmlFor="email">Username (Email)</label>
                        <input defaultValue="champ 1" {...register("champ1", { required: false })} /> 
                    </div>
                    <div className="input-wrapper">
                        <label htmlFor="password">Password</label>
                        <input defaultValue="champ 2" {...register("champ2", { required: true })} /> 
                        {errors.champ2 && <span>This field is required</span>}
                    </div>

                    <div className="input-wrapper">
                       {/* <MyEditor2 />*/}
                    </div>
                    <Controller
        control={control}
        name="MyEditor2"
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <MyEditor2
            onChange={onChange}
            onBlur={onBlur}
            selected={value}
          />
        )}
      />
                    <input type="submit" />
                </form>
            </section>
        </main>
    )
}

export default Formulaire1;