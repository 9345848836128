
import { BrowserRouter, Routes, Route } from "react-router-dom"; // in react-router-dom v6, "Switch" is replaced by routes "Routes"

import SignIn from "./Pages/SignIn.jsx";
import ReinitialisationMotPasse from "./Pages/ReinitialisationMotPasse.jsx";

import Profil from "./Pages/Profil.jsx";
import Formulaire1 from "./Pages/Formulaire1.jsx";

import Accueil from "./Pages/Accueil.jsx";
import Cgv from "./Pages/Cgv.jsx";
import Faq from "./Pages/Faq.jsx";

import Organisme from "./Pages/MonOrganisme.jsx";
import LieuxEtContacts from "./Pages/LieuxEtContacts/LieuxEtContacts.jsx";
import PcfEditionCoord from "./Pages/LieuxEtContacts/PcfEditionCoord.jsx"; // idem CreationProfessionnalisant
import PcfEditionPerson from "./Pages/LieuxEtContacts/PcfEditionPerson.jsx";

import FormationGen from "./Pages/FormationGen.jsx";
import FormationIncompletes from "./Pages/FormationIncompletes.jsx";
import Formation from "./Pages/Formation.jsx";

import CreationProfessionnalisant from "./Pages/CreationProfessionnalisant.jsx";

import AdmChangeOrg from "./AdmPages/AdmChangeOrg.jsx";

import AdmQuiforme from "./AdmPages/Quiforme/AdmQuiforme.jsx";
import AdmQuiformeDiffsList from "./AdmPages/Quiforme/AdmQuiformeDiffsList.jsx";
import AdmQuiformeIntrouvables from "./AdmPages/Quiforme/AdmQuiformeIntrouvables.jsx";

import AdmEditCompanie from "./AdmPages/AdmEditCompanie.jsx";

import AdmMoteur from "./AdmPages/Moteur/AdmMoteur.jsx";
import AdmMoteurSynsCertifs from "./AdmPages/Moteur/AdmMoteurSynsCertifs.jsx";
import AdmMoteurSynsProfs from "./AdmPages/Moteur/AdmMoteurSynsProfs.jsx";
import AdmEditDipSyn from "./AdmPages/Moteur/AdmEditDipSyn.jsx";

import AdmMoteurSynsTypesDipl from "./AdmPages/Moteur/AdmMoteurSynsTypesDipl.jsx";
import AdmEditTypDipSyn from "./AdmPages/Moteur/AdmEditTypDipSyn.jsx";

import NotFound from './Pages/NotFound.jsx';

import './SCSS/App.scss';
import UtilisateurRole from './ThemedComponent.jsx';

import Header from './Components/Header.jsx';
import Footer from './Components/Footer.jsx';

function App() {
    return (
        <div className="App">
        <UtilisateurRole />
        <BrowserRouter> 
            <Header />

            <section className="contenu-principal">

                <Routes>
                    <Route exact path="/" element={<SignIn asAnOrg={false}  />}></Route>
                    <Route  path="/pro-bo-paca" element={<SignIn asAnOrg={true} />}></Route>

                    <Route path="/reinitialisation-de-votre-mot-de-passe-brof/:tempKeyNewPwd" element={<ReinitialisationMotPasse />}></Route>

                    <Route path="/profil" element={<Profil />}></Route>
                    <Route path="/formulaire" element={<Formulaire1 />}></Route>

                    <Route path="/accueil" element={<Accueil />}></Route>

                    <Route path="/organisme" element={<Organisme />}></Route>
                    <Route path="/organisme/:idCompanie" element={<AdmEditCompanie origEditOrg="editOrg" />}></Route>

                    <Route path="/lieux-et-contacts" element={<LieuxEtContacts />}></Route>
                    <Route path="/lieux-et-contacts/creation-pcf" element={<PcfEditionCoord />}></Route>
                    <Route path="/lieux-et-contacts/creation-personne" element={<PcfEditionPerson />}></Route>
                    <Route path="/lieux-et-contacts/edition-coord/:IdDeCoord" element={<PcfEditionCoord />}></Route>
                    <Route path="/lieux-et-contacts/edition-contact/:IdPerson" element={<PcfEditionPerson />}></Route>

                    <Route path="/formation/creation-professionnalisant/:formationNumber" element={<CreationProfessionnalisant />}></Route>

                    <Route path="/formation/en-attente" element={<FormationIncompletes typeDePb="attenteCorrection" />}></Route>
                    <Route path="/formation/incomplete" element={<FormationIncompletes typeDePb="incompletes" />}></Route>
                    <Route path="/formation" element={<FormationGen />}></Route>
                    <Route path="/formation/:nomEtape/:formationNumber" element={<Formation />}></Route>
{/* http://localhost:3000/formation/etape5/101439/action-1276383 ne fonctionne plus avec action-:actionNumber, voir productSlug*/}

                    <Route path="/formation/:nomEtape/:formationNumber/:actionPart" element={<Formation />}></Route>
                    <Route path="/formation/:nomEtape/:formationNumber/:actionPart/:sessionpart" element={<Formation />}></Route>

                    <Route path="/faq" element={<Faq />}></Route>
                    <Route path="/cgv" element={<Cgv />}></Route>

                    <Route path="/admin/change-org" element={<AdmChangeOrg />}></Route>

                    <Route path="/admin/quiforme" element={<AdmQuiforme />}></Route>
                    <Route path="/admin/quiforme/diff-quiforme" element={<AdmQuiformeDiffsList />}></Route>
                    <Route path="/admin/quiforme/diff-quiforme/edit/:idCompanie" element={<AdmEditCompanie origEditOrg="editQuiforme" />}></Route>
                    <Route path="/admin/quiforme/introuvables-quiforme" element={<AdmQuiformeIntrouvables />}></Route>
                    <Route path="/admin/quiforme/introuvables-quiforme/edit/:idCompanie" element={<AdmEditCompanie origEditOrg="editQuiforme2" />}></Route>

                    <Route path="/admin/moteur" element={<AdmMoteur />}></Route>
                    <Route path="/admin/moteur/synonymes-certifications" element={<AdmMoteurSynsCertifs />}></Route>
                    <Route path="/admin/moteur/synonymes-professionnalisants" element={<AdmMoteurSynsProfs />}></Route>
                    <Route path="/admin/moteur/synonymes-certifications/:idCertif" element={<AdmEditDipSyn />}></Route>
                    
                    <Route path="/admin/moteur/synonymes-types-diplome" element={<AdmMoteurSynsTypesDipl />}></Route>
                    <Route path="/admin/moteur/synonymes-types-certification/:idTypeCertif" element={<AdmEditTypDipSyn />}></Route>
                    
                    <Route path="*" element={<NotFound />}></Route>
                </Routes> 

            </section>
            <Footer />
        </BrowserRouter>
        </div>
    );
}
export default App;