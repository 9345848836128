var objRetourInvalide = {"statusCode": 400, "dataApi": [] }

export async function  apiActAjout(utilisateurBearer, formationNumber, stdOUprofessionnalisation='std') { 
    var datasToPost= { // on créé ici comme pas d'info a donner tout de suite....
        "Etat_LHEO_Valide": 0
    }
    if (utilisateurBearer == "" || formationNumber == "" || (stdOUprofessionnalisation != 'std' && stdOUprofessionnalisation != 'professionnalisation')) return objRetourInvalide;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(datasToPost)
    };

    var urlApi = global.config.apiActionBase.url + "/" + formationNumber + "/" + stdOUprofessionnalisation;
    if(global.config.modeDebugApi == 1) console.log(`appel POST ${urlApi} dans apiActAjout`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200) return objRetour;
    
        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiActAjout', error_1);
        return objRetour;
    }
}

export async function apiActModif(utilisateurBearer, datasToPost, actionNumber) {
    if (utilisateurBearer == "") return objRetourInvalide;

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(datasToPost)
    };

    var urlApi = global.config.apiActionBase.url + "/" + actionNumber;
    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi}`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        return objRetour;
    } catch (error_1) {
        console.log('error apiActModif', error_1);
        return objRetour;
    }
}

export async function apiActAppelGetInfosDiv(utilisateurBearer, routeSuppl="") {
    if (utilisateurBearer == "") return objRetourInvalide;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiActionBase.url + routeSuppl;
    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi}`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200) return objRetour;

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiActAppelGetInfosDiv', error_1);
        return objRetour;
    }
}


export async function apiActSuppr(utilisateurBearer, actionNumber) {
    if (utilisateurBearer == "" || actionNumber == "") return objRetourInvalide;
    var objRetour = {statusCode : '', dataApi : ''};

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiActionBase.url  + "/" +  actionNumber;
    if(global.config.modeDebugApi == 1) console.log('appel ' + urlApi);

    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized, 204 NoContent si succès de la suppresion
        return objRetour;
    } catch (error_1) {
        console.log('error apiActSuppr', error_1);
        return objRetour;
    }
}

export async function apiActCopy(utilisateurBearer, actionNumber) {
    if (utilisateurBearer == "" || actionNumber == "") return objRetourInvalide;
    var objRetour = {statusCode : '', dataApi : ''};

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiActionBase.url  + "/" +  actionNumber + "/docopy";
    if(global.config.modeDebugApi == 1) console.log('appel ' + urlApi);

    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized, 204 NoContent si succès de la suppresion
        if (response.status != 200) return objRetour;
    
        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiActCopy', error_1);
        return objRetour;
    }
}

// pourrait encore etre amélioré pour etre utilisé de façon générique, cf apiPutFormObjs
export async function apiPutActObjs(utilisateurBearer, what, actionNumber, datasToPost) { // ex apiProfPutProfNsf
    if (utilisateurBearer == "" || (what != "public" && what != "modal-peda" && what != "langue")) return objRetourInvalide;
    /*/ adaptation déja faite en amon avec un .forEach((el)
    for (var occ in datasToPost) { // autre manièere de faire :   for (var i = 0; i < datasToPost.length; i++) {
        datasToPost[occ].ID_ACTIONS_SESSIONS = actionNumber
        datasToPost[occ].ID_LHEO_PUBLIC_VISE = datasToPost[occ].code;
    } */

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json' 
        },
        body: JSON.stringify(datasToPost)
    };

    var urlApi = global.config.apiActionBase.url + '/' + actionNumber + '/' + what + '/'; // = /public/ ou /modal-peda/
    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi}`);
    
    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200 && response.status != 400) return objRetour;  // 400 : un message personnalisé est retourné dans ce cas, ex : "La fiche certif info est déjà associée"
    
        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiPutActObjs', error_1);
        return objRetour;
    }
}

//method= PUT ou DELETE, permet également de définir un principale (POST dans ce cas)
export async function apiActAssocDissoFormateur(utilisateurBearer, actionNumber, codeOrganisme, method='PUT', routeSuppl="") {
    if (utilisateurBearer == "" || codeOrganisme == "" || method == "") return objRetourInvalide;

    const requestOptions = {
        method: method,
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiActionBase.url  + "/" +  actionNumber  + "/formateurs/" + codeOrganisme + routeSuppl;
    if(global.config.modeDebugApi == 1) console.log('appel ' + urlApi + ' avec methode ' + method);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200 && response.status != 400) return objRetour;  // 400 : un message personnalisé est retourné dans ce cas, ex : "La fiche certif info est déjà associée"

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiActAssocDissoFormateur', error_1);
        return objRetour;
    }
}



//method= PUT ou DELETE, permet également de définir un principale (POST dans ce cas)
export async function apiLieuAssocDisso(utilisateurBearer, idFormateur, codeOrganisme, method='PUT', routeSuppl="") {
    if (utilisateurBearer == "" || codeOrganisme == "" || method == "") return objRetourInvalide;

    const requestOptions = {
        method: method,
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiFormateurBase.url  + "/" +  idFormateur  + "/lieux/" + codeOrganisme + routeSuppl;
    if(global.config.modeDebugApi == 1) console.log('appel ' + urlApi + ' avec methode ' + method);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200 && response.status != 400) return objRetour;  // 400 : un message personnalisé est retourné dans ce cas, ex : "La fiche certif info est déjà associée"

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiLieuAssocDisso', error_1);
        return objRetour;
    }
}

export async function apiFormateurGetInfosDiv(utilisateurBearer, routeSuppl="") {
    if (utilisateurBearer == "") return objRetourInvalide;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiFormateurBase.url + routeSuppl;
    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi}`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200) return objRetour;

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiActAppelGetInfosDiv', error_1);
        return objRetour;
    }
}




//method= PUT ou DELETE, permet également de définir un principale (POST dans ce cas)
export async function apiCertifAssocDisso(utilisateurBearer, idFormateur, codeCertif, method='PUT', routeSuppl="") {
    if (utilisateurBearer == "" || codeCertif == "" || method == "") return objRetourInvalide;

    const requestOptions = {
        method: method,
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiFormateurBase.url  + "/" +  idFormateur  + "/certifications/" + codeCertif + routeSuppl;
    if(global.config.modeDebugApi == 1) console.log('appel ' + urlApi + ' avec methode ' + method);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200 && response.status != 400) return objRetour;  // 400 : un message personnalisé est retourné dans ce cas, ex : "La fiche certif info est déjà associée"

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiCertifAssocDisso', error_1);
        return objRetour;
    }
}
