import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

/**
 * Return détail Prof
 * @component
 * @summary pour affichage détail professionnalisant, utilise les composants Modal et Button de bootstrap
 * @summary  https://react-bootstrap.github.io/components/modal/
 * @return { HTMLElement }
*/



function DetailProf(props) {  
    const maDate = new Date(props.dataDetailProf.professionnalisantDetail.DATECREATE) // conversion de la date datetime en date francaise https://www.commentcoder.com/formater-date-javascript/
    return (
        <Modal size="lg" show={props.showModal} onHide={props.handleCloseModal} aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Détail du non certifiant {props.dataDetailProf.professionnalisantDetail.LIB_PROFESSIONNALISANTS}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <section className="detail-bloc-fiche"><br />
                    {/*afficheElement("Date de création", maDate.toLocaleDateString("fr"))*/}

                    {props.dataDetailProf.listeFormacodes && props.dataDetailProf.listeFormacodes.length > 0 &&
                    <>
                        <div className="titres-liste">Formacodes :</div>
                        <ul>
                            {props.dataDetailProf.listeFormacodes.map(item => (
                                <li key={item.code}>
                                    {item.FORMACODE_PRINCIPAL == 1 ? <b>{item.code} {item.libelle}</b> : <>{item.code} {item.libelle}</>}
                                </li>
                            ))}
                        </ul>
                    </>
                    }

                    {props.dataDetailProf.listeNfss && props.dataDetailProf.listeNfss.length > 0 &&
                    <>
                        <div className="titres-liste">Nsf (Nomenclature des Spécialités de Formation) :</div>
                        <ul>
                            {props.dataDetailProf.listeNfss.map(item => (
                                <li key={item.code}>
                                    {item.code} {item.libelle}
                                </li>
                            ))}
                        </ul>
                    </>
                    }

                    {props.dataDetailProf.listeRomes && props.dataDetailProf.listeRomes.length > 0 &&
                    <>
                        <div className="titres-liste">Romes :</div>
                        <ul>
                            {props.dataDetailProf.listeRomes.map(item => (
                                <li key={item.code}>
                                    {item.code} {item.libelle}
                                </li>
                            ))}
                        </ul>
                    </>
                    }
                </section>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleCloseModal}>Fermer</Button>
            </Modal.Footer>
        </Modal>
    )
}

DetailProf.propTypes = {
    dataDetailProf: PropTypes.object.isRequired,
    showModal: PropTypes.any,
    handleCloseModal: PropTypes.func,

}

export default DetailProf;