import { useState } from 'react';
import { Container,  Form,  Button} from "react-bootstrap";
import { useForm } from "react-hook-form";

import '../config.js';
import { apiCheckSiret, apiProcedureMdpEnvoieMail } from '../Datas/dataLogin.js'

import MessageInfoBox, {MessageErreurApi} from './Messages.jsx';
import ButtonDeBase from './ButtonsCustom.jsx';
import LoadingSpinner from "../Components/LoadingSpinner";

/**
 * Return Procedure mdp
 *
 * @component
 * @summary Return Procedure mdp
 * @param {  }
 * @return { HTMLElement }
 * 
*/
function ProcedureMdp(props) {
    const [dataApi, setDataApi] = useState();
    const [messageProbleme, setMessageProbleme] = useState();
    const [siretSaisie, SetSiretSaisie] = useState();
    const [dataLoaded, setDataLoaded] = useState(true);

    const onSubmit = async (values) => {
      if(global.config.modeDebugApp == 1) console.log("Values:::", values);
      if(global.config.modeDebugApp == 1) console.log("Values:::", JSON.stringify(values));
      setMessageProbleme();
      setDataLoaded(false);

      SetSiretSaisie(values.SIRET);
      var dataR = await apiCheckSiret(values.SIRET)
      if(global.config.modeDebugApp == 1) console.log('retour apiCheckSiret : ', dataR);

      switch (dataR.statusCode) {
          case 200: 
                setDataApi(dataR.dataApi);
              break;
          case 401:
                setDataApi(dataR);
              break;
          default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
      setDataLoaded(true);
    };
  
    const onError = (error) => {
      console.log("ERROR:::", error);
    };

    const {
        register, handleSubmit, getValues, watch, formState: { errors }, control } = useForm({ // control pour controler lors de la saisie
        mode: "onTouched", // Validation will trigger on the first blur event. After that, it will trigger on every change event.
        //reValidateMode: "onSubmit", le fait de commenter permet de revalider lors de la saisie val possible : onChange (defaut) | onBlur | onSubmit
    });
    const handleClickEnvoiMailProcedure = async () => { 
      setDataLoaded(false);

      var dataR = await apiProcedureMdpEnvoieMail(dataApi.SIRET);
      switch (dataR.statusCode) {
          case 200:
              alert("La procédure a bien été envoyée.");
              props.handleCloseModal();
            break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
      setDataLoaded(true);
    }
    
    const lienMail = `mailto:${global.config.personnelPaca.mail}`;

    return (     
      <main>
        <Container className="my-0">
          <MessageErreurApi message={messageProbleme} />
          <LoadingSpinner dataLoaded={dataLoaded} message="Veuillez patienter..." />
          {dataApi &&
            <>
            {dataApi.COURRIEL_retenu && dataApi.COURRIEL_retenu.length > 7 ?
              <>
              {/* plus affiché par sécurité
              Votre organisme se nomme "<b>{dataApi.LIB_COMPANIES}</b>" et l'identifiant associé est "<b>{dataApi.LOGIN}</b>".<br /><br /> */}
              Un courriel indiquant la procédure de réinitialisation sera envoyé sur l'adresse mail <b>{dataApi.COURRIEL_retenu}</b> de <b>{dataApi.FIRST_NAME} {dataApi.LAST_NAME}</b> qui a été désigné comme responsable de votre organisme.<br /><br />
              Pour que cette procédure fonctionne, vous devrez vous rapprocher d'une personne ayant accès à cette adresse.<br />
              <ButtonDeBase typeSubmit={false} lib="Envoyer 👍" onClick={() =>handleClickEnvoiMailProcedure()}  />
              </>
            :
              <>Nous n'avons malheureusement pas trouvé d'adresse mail associée au SIRET {siretSaisie}.<br /><br />
              Si vous avez récemment changé de SIRET, veuillez recommencer la procédure avec l'ancien SIRET .<br />
              Si vous avez complètement changé de siret, veuillez faire une <b>demande de code d'accès</b> depuis la page de connexion.<br /><br />
              Vous pouvez contacter le carif via ce courriel : <a href={lienMail}>{global.config.personnelPaca.mail}</a><br /><br />
              </>
            }
            </>
          }
          {(!dataApi || !dataApi.COURRIEL_retenu || dataApi.COURRIEL_retenu.length < 7) &&
          <>
            {!dataApi && <>Veuillez tout d'abord renseigner le SIRET de votre établissement.<br />
            En cas de problème, vous pouvez contacter le carif via ce courriel : <a href={lienMail}>{global.config.personnelPaca.mail}</a><br /><br /></>
            }

            <Form onSubmit={handleSubmit(onSubmit, onError)}>
              <Form.Group className="mb-3" controlId="SIRET"> {/*controlId permet de mettre un for  dans le label et un id identique dnas le controle*/ }
                <Form.Label>Siret de votre organisme (14 chiffres) :</Form.Label>
                <Form.Control 
                  type="text" 
                  placeholder="12345678912314"
                  {...register("SIRET", { required: true,  maxLength: 14 ,  minLength: 14})}
                />

                {errors.SIRET && (
                  <Form.Text className="text-danger">
                    {errors?.SIRET?.type === "required" && <p>Cette information est requise</p>}
                    {errors?.SIRET?.type === "maxLength" && (
                      <p>Ce champs ne doit pas contenir plus de 14 caractères</p>
                    )}
                    {errors?.SIRET?.type === "minLength" && (
                      <p>Ce champs ne doit pas contenir moins de 14 caractères</p>
                    )}
                  </Form.Text>
                )}
              </Form.Group>
              <ButtonDeBase typeSubmit={true} lib="Envoyer" />
            </Form>
          </>
          }
        </Container>
      </main>
    )

}


export default ProcedureMdp;