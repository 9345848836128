var objRetourInvalide = {"statusCode": 400, "dataApi": [] }

export async function  apiAppellationDiplomeAjout(utilisateurBearer, datasToPost, idCertif) {
    if (utilisateurBearer == "" || idCertif == "") return objRetourInvalide;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(datasToPost)
    };

    var urlApi = global.config.apiBase.url  + "/certifs/" + idCertif + "/autresAppellation/";
    if(global.config.modeDebugApi == 1) console.log(`appel POST ${urlApi} dans apiAppellationDiplomeAjout`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200 && response.status != 400) return objRetour;  // 400 : un message personnalisé est retourné dans ce cas, ex : "La fiche certif info est déjà associée"

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiAppellationDiplomeAjout', error_1);
        return objRetour;
    }
}
export async function apiAppellationDiplomeModif(utilisateurBearer, datasToPost, idDetail) {
    if (utilisateurBearer == "" || idDetail == "") return objRetourInvalide;

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(datasToPost)
    };

    var urlApi = global.config.apiBase.url  + "/certifs/autresAppellation/" +  idDetail;
    if(global.config.modeDebugApi == 1) console.log(`appel PUT ${urlApi} dans apiAppellationDiplomeModif`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 400) return objRetour;  // 400 : un message personnalisé est retourné dans ce cas, ex : "La fiche certif info est déjà associée"

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiAppellationDiplomeModif', error_1);
        return objRetour;
    }
}


export async function apiAppellationDiplomeSuppr(utilisateurBearer, idCertif, idDetail) {
    if (utilisateurBearer == "" || idCertif == ""|| idDetail == "") return objRetourInvalide;
    var objRetour = {statusCode : '', dataApi : ''};

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiBase.url  + "/certifs/autresAppellation/" +  idDetail;
    if(global.config.modeDebugApi == 1) console.log('appel ' + urlApi);

    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized, 204 NoContent si succès de la suppresion
        return objRetour;
    } catch (error_1) {
        console.log('error apiAppellationDiplomeSuppr', error_1);
        return objRetour;
    }
}


export async function apiAppellationDiplomeGet(utilisateurBearer, idCertif, idDetail) {
    if (utilisateurBearer == "" || idCertif == ""|| idDetail == "") return objRetourInvalide;
    var objRetour = {statusCode : '', dataApi : ''};

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiBase.url  + "/certifs/autresAppellation/" +  idDetail;
    if(global.config.modeDebugApi == 1) console.log('appel ' + urlApi);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200) return objRetour;

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiRecrutementSGet', error_1);
        return objRetour;
    }
}