export const items = [
    {
        categoryName: "", // possible de mettre un entete
        links: [{
            name: "Mes formations",
            route: "formation"
        },
        {
            name: "En attente de correction",
            route: "formation/en-attente"
        }, {
            name: "Incomplètes et non diffusées",
            route: "formation/incomplete"
        }]
    }
]

export const itemsProFctOrg = [
    {
        categoryName: "",
        links: [
            {
            name: "Gérer un OF ou accéder à ses fiches",
            route: "admin/change-org"
        },
        {
            name: "Fiche organisme",
            route: "organisme"
        },
        {
            name: "Lieux et contacts",
            route: "lieux-et-contacts"
        }, {
            name: "Formations",
            route: "formation"
        }]
    }
]

export const itemsProFQuiforme = [
    {
        categoryName: "",
        links: [{
            name: "Gestion des différences",
            route: "admin/quiforme/diff-quiforme"
        },
        {
            name: "Of actifs introuvables",
            route: "admin/quiforme/introuvables-quiforme"
        }]
    }
]
export const itemsProAdminMoteur = [
    {
        categoryName: "",
        links: [
            {
            name: "Synonymes des certifications",
            route: "admin/moteur/synonymes-certifications"
        },
        {
            name: "Synonymes des professionnalisants",
            route: "admin/moteur/synonymes-professionnalisants"
        },
        {
            name: "Synonymes des types de diplome",
            route: "admin/moteur/synonymes-types-diplome"
        }]
    }
]