import { NavLink } from "react-router-dom";
import {Link, useNavigate } from "react-router-dom";
/**
* Component for footer
*
* @component
* @return { HTMLElement }
*/
function Footer() {
    return (
        <footer className="footer">
          <nav className="main-nav">
                <div>
                    <NavLink to="/cgv">Conditions générales d'utilisation</NavLink>
                    <span className="pipe">|</span>
                    <a href="https://www.cariforef-provencealpescotedazur.fr/Page/mentions-legales">Mentions Légales</a>
                </div>
            </nav>
        </footer>
    )
}

export default Footer;