import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import { useNavigate, NavLink } from "react-router-dom";
import Form from 'react-bootstrap/Form'; // pour Form.Check
import Modal from 'react-bootstrap/Modal';

import { verifMaintenance } from '../Datas/datasAutre.js'
import { apiFormAppelList, apiFormAppelRecherche, apiFormAjout, apiFormSuppr } from '../Datas/dataFormations.js'
import { DonneesUtilisateurContext } from '../contexte.js';
import '../config.js';

import MessageInfoBox, {MessageErreurApi} from '../Components/Messages.jsx';
import LoadingSpinner from "../Components/LoadingSpinner";
import ButtonDeBase, {ButtonVoirFiche2, ButtonSuppr} from "../Components/ButtonsCustom.jsx";
import CsvDownloader from "../Components/CsvDownloader.jsx";

import Table, {SelectColumnFilter} from '../Components/Table.jsx';
import { retourneCauseInterdictionForm, recupererVolumes } from '../fonctionsDiv.js';

/**
 * Return Page principale de gestion des formations
 *
 * @component
 * @summary pour affichage recherche formations
 * @param {  }
 * @return { HTMLElement }
*/
function FormationGen() {
    const navigate = useNavigate(); // utile pour changer de page

    const {setBearer, utilisateurRouteLogin, utilisateurBearer, utilisateurIdMesureOf, utilisateurOrg, setNbFormTot, setNbFormAttente, setNbFormIncomplete, setNbActAttente, setNbActIncomplete, setNbSessAttente, setNbSessIncomplete } = useContext(DonneesUtilisateurContext);
    //var jj = localStorage.getItem('dataApiLS') !== undefined ? JSON.parse(localStorage.getItem('dataApiLS')) : ""; pour setDataApi
    const [dataApi, setDataApi] = useState();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [messageProbleme, setMessageProbleme] = useState();
    const [indicationSimple, setIndicationSimple] = useState();
    const [messageBase, setMessageBase] = useState('');
    const [choixCreaForm, setChoixCreaForm] = useState();
    const [enMaintenance, setEnMaintenance] = useState(false);
    const [msgMaintenance , setMsgMaintenance] = useState(false);
    const [btCreerActif, setBtCreerActif] = useState(true);

    const handleCloseModalIndicAvantCrea = () => {setShowModalIndicAvantCrea(false); setChoixCreaForm("")};
    const [showModalIndicAvantCrea, setShowModalIndicAvantCrea] = useState(false);
    

    //pour react-table https://stackoverflow.com/questions/56922381/how-to-include-url-in-react-table-cell
    const columns = useMemo(
        () => [
          {
            id:'listesFormations',
            hideHeader : false,
            trieDefautRp : 'LIB_ACTIONS_FORMATION',
            columns: [
                {  
                Header: 'Libellé de la formation', 
                accessor: 'LIB_ACTIONS_FORMATION', 
                id: "LIB_ACTIONS_FORMATION", // utile pour trie par défaut
                //Cell: ({ row }) => <a href={row.original.ID_ACTIONS_FORMATION}>{row.original.LIB_ACTIONS_FORMATION}</a>,
                Cell: ({ row }) => (
                    //<>{row.original.LIB_ACTIONS_FORMATION}</>
                    //<Link title={`Accéder à la fiche (créée le : ${row.original.DATE_CREATION_fr}, modifiée le : ${row.original.DATE_MODIF_fr != null ? row.original.DATE_MODIF_fr : ''}`} to={`./etape1/${row.original.ID_ACTIONS_FORMATION}`}>
                    //    {row.original.LIB_ACTIONS_FORMATION}
                    //</Link>
                    //autre facon  pour que le ctrl clique pour ouverture nouvelle tab / onglet ne fonctionne pas
                    //<a title="Accéder à la fiche" className="lib" onClick={() => navigate(`./etape1/${row.original.ID_ACTIONS_FORMATION}`)}>
                    //    {row.original.LIB_ACTIONS_FORMATION}
                    //    <ButtonVoirFiche />
                    //</a>
                    <>
                    {row.original.LIB_ACTIONS_FORMATION}
                    {row.original.LIB_complement != '' && <span style={{color: "red"}}>{row.original.LIB_complement}</span>}
                    </>
                ),
                },
                { 
                Header: 'Ville(s)', 
                accessor: 'villes', 
                disableSortBy: true,
                },
                /*{ 
                Header: 'CP', 
                accessor: 'cps', 
                disableSortBy: true,
                },*/
                { 
                Header: 'Mesure', 
                accessor: 'mesures_libs', 
                disableSortBy: true,
                },
                { 
                Header: 'Financeur', 
                accessor: 'financeurs_libs', 
                disableSortBy: true,
                Cell: ({ row }) => (  
                    <>
                    {
                     row.original.isDispositifNouveauMarcheFiliereProgammeSPRFPA == 1 ? 
                        <>
                        {row.original.financeurs_libs}
                        <br />
                        <span style={{color: "red"}}>Programme 24-25</span>
                        </>
                        :
                        <>{row.original.financeurs_libs}</>
                    }
                    </>
                    ),
                },
                { 
                Header: () => <>Numéro(s)<br />de session</>,
                accessor: 'idSessions', 
                disableSortBy: true,
                // troncature des id pour éviter un débordement... (nb, autre facon de faire le match popur compter le nb de fois ou le car est présent : match(new RegExp(",","gi")) )
                // si plus de 4 virgules (donc plus de 5 id) on ne retient que le premier
                // version précédente plus compliquéz et avec pb (manquait 1 au compteur du fait que sel les 1 étaient comptées)
                //row.original.idSessions && row.original.idSessions.match(/,/g) != null && row.original.idSessions.match(/,/g).length > 4 ?
                //row.original.idSessions.substring(0, row.original.idSessions.indexOf(",", 1)) + "... \r\n(" + row.original.idSessions.match(/,/g).length + " sessions)" 
                Cell: ({ row }) => (  
                    <>                    
                    {
                    row.original.idSessions && row.original.nbSessions > 4 ?
                    row.original.idSessions.substring(0, row.original.idSessions.indexOf(",", 1)) + "... \r\n(" + row.original.nbSessions + " sessions)" 
                    : 
                        row.original.idSessions}
                    </>
                    ),
                },
                {
                Header: () => <>Date(s)<br />début</>,
                accessor: 'debuts',
                disableSortBy: true,
                // modification des des dates pour éviter un débordement...
                //si plus de 5 dates (lg 44) :  point suspension entre la plus ancienne et la plus recente            
                Cell: ({ row }) => (  
                    <>
                    {
                    (!row.original.debuts || row.original.debuts.length == 0) && row.original.isDispositifNouveauMarcheFiliereProgammeSPRFPA == 1 ? 
                            <span style={{color: "red"}}>Session en cours de saisie par le carif</span>
                        :                        
                            row.original.debuts && row.original.debuts.length > 44 ? 
                                row.original.debuts.substring(0,8) + "..." + row.original.debuts.substring(row.original.debuts.length-8) : 
                                row.original.debuts

                    }
                    </>
                    ),
                },
                {  
                Header: () => <>Code<br />Rncp</>,
                accessor: 'codesRncps', 
                disableSortBy: true,
                },
                {  
                Header: () => <>Code<br />RS</>,
                accessor: 'codesRs', 
                disableSortBy: true,
                },
                {  
                Header: () => <>Echéance<br />Rncp/Rs</>, 
                accessor: 'DATES_FIN_ENREGISTREMENT_rncp_rs_fr', 
                disableSortBy: true,
                },
                {  
                Header: () => <>Etat<br />Certif info</>,
                id: "etatCertifInfo_center",
                accessor: 'etatCertifInfo', 
                disableSortBy: true,
                },
                {   
                Header: () => <>Contrat<br />Pro</>,
                id: "accessContratPro_center", 
                Cell: ({ row }) => (  
                    <>
                        {/* remplacement de 1/0 par oui/non, une cellule peut contenir les deux à la fois */
                        row.original.accessContratPro != null && row.original.accessContratPro.replace('0', 'Oui').replace('1', 'Non')
                        }
                    </>
                    ),
                },
                {
                Header: () => <></>,
                    accessor: 'actions', 
                    id: "actions_center", // le fait de mettre "center" dans l'id permet de center le contenu de la colone
                    disableSortBy: true,
                    Cell: ({ row }) => (  
                    <>
                        {// cette condition ne sert normalement a rien, sécu déjà prévue par l'api
                        row.original.ID_COMPANIES == utilisateurOrg &&
                        <>
                        <ButtonVoirFiche2 className="fas fa-pen-to-square" 
                                        lien={`./etape1/${row.original.ID_ACTIONS_FORMATION}`} 
                                        title={`Accéder à la fiche ${row.original.ID_ACTIONS_FORMATION} (créée le : ${row.original.DATE_CREATION_fr} ${row.original.DATE_MODIF_fr != null ? `, modifiée le : ${row.original.DATE_MODIF_fr})` : ')'}`} />
                        {/*<Link title="Accéder à la fiche" to={`./etape1/${row.original.ID_ACTIONS_FORMATION}`}> 
                            <ButtonVoirFiche className="fas fa-pen-to-square" />
                        </Link>*/}
                        {/* si au moins une action est conventionnée ou qu'elle a une session périmée de moins d'un certain nombre de jour ou pas terminée, on ne permet pas la suppression */}
                        { (row.original.cause_intervention_interdite == null || row.original.cause_intervention_interdite == "") ?
                            <NavLink title={`Supprimer ${row.original.ID_ACTIONS_FORMATION}`} to="" onClick={() => handleClickSuppFormation(row.original.ID_ACTIONS_FORMATION, row.original.nbSessions)}>
                              <ButtonSuppr />
                            </NavLink>
                            :
                            <NavLink title={retourneCauseInterdictionForm(row.original.cause_intervention_interdite)}
                                    to="" 
                                    onClick={() => hdleClickNoPossible(row.original.cause_intervention_interdite)}>
                              <ButtonSuppr disabled="disabled" />
                            </NavLink>
                        }
                        </>
                        }
                    </>
                    ),
                },
            ]
          },
        ],
        []
    )

    function  hdleClickNoPossible (cause_intervention_interdite) {
        alert(retourneCauseInterdictionForm(cause_intervention_interdite, 1));
    }

    const [champRechFormEnCoursAVenir, setChampRechFormEnCoursAVenir] = useState(localStorage.getItem('champRechFormEnCoursAVenir') ? localStorage.getItem('champRechFormEnCoursAVenir') : 'false');
    const [champPrst4Seul, setChampPrst4Seul] = useState(localStorage.getItem('champPrst4Seul') ? localStorage.getItem('champPrst4Seul') : 'false');
    const [champRechFormIntit, setChampRechFormIntit] = useState(localStorage.getItem('champRechFormIntit') ? localStorage.getItem('champRechFormIntit') : "");
    const [champRechFormLieu, setChampRechFormLieu] = useState(localStorage.getItem('champRechFormLieu') ? localStorage.getItem('champRechFormLieu') : "");
    const [champRechFormTypeForm, setChampRechFormTypeForm] = useState(localStorage.getItem('champRechFormTypeForm') ? localStorage.getItem('champRechFormTypeForm') : '-');
    const [champRechFormContrat, setChampRechFormContrat] =   useState(localStorage.getItem('champRechFormContrat')  ? localStorage.getItem('champRechFormContrat')  : '-');
    const [champRechFormMesure, setChampRechFormMesure] =     useState(localStorage.getItem('champRechFormMesure') ?        localStorage.getItem('champRechFormMesure')   : '-');
    const [champRechFormNumSess, setChampRechFormNumSess] = useState(localStorage.getItem('champRechFormNumSess') ? localStorage.getItem('champRechFormNumSess') : "");
    const [rechercheEffectuee, setRechercheEffectuee] = useState(localStorage.getItem('rechercheEffectuee') ? localStorage.getItem('rechercheEffectuee') : false);

    const handleClickSuppFormation  = async (codeAction, nbSessions) => {
        var texte = nbSessions == 0 ? "Etes vous sûr de vouloir supprimer cette formation ?" : "Cette formation contient " + nbSessions + " session(s), êtes vous sûr de vouloir la supprimer ?";
        var confirmation = confirm(texte);
        if(confirmation == true) {
          setDataLoaded(false);
          var dataR = await apiFormSuppr(utilisateurBearer, codeAction);
          if(global.config.modeDebugApp == 1) console.log('retour apiFormSuppr : ', dataR);
          switch (dataR.statusCode) {
              case 204:
                alert("La formation a bien été supprimée");
                await recupererListeCompleteForm();
                break;
              case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
                break;
              default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
          }
          setDataLoaded(true);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        effectueRecherche();
    }
    const handleClickReinit = async (e) => {
        e.preventDefault();
        setChampRechFormEnCoursAVenir("false"); localStorage.setItem('champRechFormEnCoursAVenir',"false");
        setChampPrst4Seul("false"); localStorage.setItem('champPrst4Seul',"false");
        setChampRechFormIntit(""); localStorage.setItem('champRechFormIntit',"");
        setChampRechFormLieu(""); localStorage.setItem('champRechFormLieu',"");

        setChampRechFormTypeForm("-"); localStorage.setItem('champRechFormTypeForm',"-");
        setChampRechFormContrat("-"); localStorage.setItem('champRechFormContrat',"-");
        setChampRechFormMesure("-"); localStorage.setItem('champRechFormMesure',"-");

        setChampRechFormNumSess(""); localStorage.setItem('champRechFormNumSess',"");
        setRechercheEffectuee("false"); localStorage.setItem('rechercheEffectuee',"false");
        setDataLoaded(false);
        await recupererListeCompleteForm();
        setDataLoaded(true);
    }
    
    const handleClickCreateFormation = async (certifOuPro='certif') => { // certifOuPro contiendra 'certif' ou 'pro'
        setBtCreerActif(false);
        setDataLoaded(false);
        var dataR = await apiFormAjout(utilisateurBearer, certifOuPro);
        switch (dataR.statusCode) {
            case 200:
                //let nomeEtape = certifOuPro == 'certif' ? 'etape1' : 'etape1b';
                let nomeEtape = 'etape1';
                navigate(`/formation/` + nomeEtape  + '/' + dataR.dataApi);
              break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
              break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
        setDataLoaded(true);
    }
    //const handleClickCreateFormationCi  = () => { handleClickCreateFormation("certif")}
    //const handleClickCreateFormationPro = () => { handleClickCreateFormation("pro")}

    //var AuMoinsUneFormInvalide = false;
    async function recupererListeCompleteForm() {  // on fait une fonction car useEffect ne tolere pas d'await direct
        setMessageProbleme();
        setIndicationSimple(""); 

        var dataR = await apiFormAppelList(utilisateurBearer);
        if(global.config.modeDebugApp == 1) console.log(`retour apiFormAppelList statusCode : ${dataR.statusCode},  dataR : `, dataR)

        switch (dataR.statusCode) {
           case 200:
                let presenceInvalide = false;
                let presenceRefusee = false;
                //boucle sur le contenu renvoyé par l'api pour savoir s'il y a des incomplètes ou des refusées
                dataR.dataApi.forEach((el) => {
                    if(el.Etat_LHEO_Valide == 0) presenceInvalide = true;
                    if(el.Etat_Validation_CO == 30) presenceRefusee = true; // 30 = refusé
                });
                let messageBase = "Recherchez vos formations existantes pour les consulter ou les modifier.";
                if(presenceInvalide == true || presenceRefusee == true ) messageBase += "<br>Les formations qui ne sont pas valides sont repérées sur fond orange."
                setMessageBase(messageBase);
                setDataApi(dataR.dataApi);
                setRechercheEffectuee("false");
                localStorage.setItem('rechercheEffectuee', "false");
                // finalement inutile, on utlise dataR.dataApi.nbFoRejetLheo ci dessous
                https://www.digitalocean.com/community/tutorials/js-array-search-methods-fr
                //on verifie si au moins une formation est invalide pour afficher l'indication de la coloration de la cellule en conséquence
                //const EtatsLheo = dataR.dataApi.map(el => el.Etat_LHEO_Valide);
                //const EtatsValidationCO = dataR.dataApi.map(el => el.Etat_Validation_CO);
                //AuMoinsUneFormInvalide = (EtatsLheo.includes(0) || EtatsValidationCO.includes(0)) ? true : false; // includesreturns true
                //console.log(`AuMoinsUneFormInvalide : `, AuMoinsUneFormInvalide);

                return true;
           case 401:
               setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
             break;
            case 400:
                var dataObjetVide = new Object();
                setDataApi(dataObjetVide);
                setIndicationSimple(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "Trop de resultat"
                return true;
           default:
               setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
       }
       return false;
   }
   
   async function effectueRecherche() {
        if(champRechFormNumSess != undefined && champRechFormNumSess != "undefined" && isNaN(champRechFormNumSess)) {
            alert("Le numéro de session doit être un numérique." + champRechFormNumSess);
            var ob = document.getElementById("champRechFormNumSess");
            ob.focus();
            return false;
        }

        let chaineRecherche = "?";
        if (champRechFormIntit && champRechFormIntit.length > 0) chaineRecherche += "intitule-recherche=" + champRechFormIntit + '&';
        else setChampRechFormIntit("");
        
        if (champRechFormLieu && champRechFormLieu.length > 0)  {   
            if(champRechFormLieu.length == 2 && !isNaN(champRechFormLieu)) chaineRecherche += "departement=" + champRechFormLieu + '&';
            else chaineRecherche += "ville=" + champRechFormLieu + '&';
        }
        else setChampRechFormLieu("");

        if (champRechFormTypeForm && champRechFormTypeForm != '-')  chaineRecherche += "typeform=" + champRechFormTypeForm + '&';
        if (champRechFormContrat && champRechFormContrat != '-')  chaineRecherche += "contrat=" + champRechFormContrat + '&';
        if (champRechFormMesure && champRechFormMesure != '-')  chaineRecherche += "mesure=" + champRechFormMesure + '&';

        if (champRechFormNumSess  && champRechFormNumSess.length > 0)  chaineRecherche += "id-session=" + champRechFormNumSess + '&';
        else setChampRechFormNumSess("");

        if (champRechFormEnCoursAVenir && champRechFormEnCoursAVenir == "true")  chaineRecherche += "en-cours-a-venir-seul=" + champRechFormEnCoursAVenir + '&';
        if (champPrst4Seul && champPrst4Seul == "true")  chaineRecherche += "prst4-seul=" + champPrst4Seul + '&';

        localStorage.setItem('champRechFormEnCoursAVenir', champRechFormEnCoursAVenir);
        localStorage.setItem('champPrst4Seul', champPrst4Seul);
        localStorage.setItem('champRechFormIntit', champRechFormIntit); 
        localStorage.setItem('champRechFormLieu', champRechFormLieu); 
        localStorage.setItem('champRechFormTypeForm', champRechFormTypeForm);
        localStorage.setItem('champRechFormContrat', champRechFormContrat);
        localStorage.setItem('champRechFormMesure', champRechFormMesure);
        localStorage.setItem('champRechFormNumSess', champRechFormNumSess)

        setDataLoaded(false);
        if (chaineRecherche.length == 1) {
            //setMessageProbleme('Veuillez saisir au moins un critère'); 
            await recupererListeCompleteForm();
            setDataLoaded(true);
            return true; 
        }
        else {  
            setMessageProbleme("");
            setIndicationSimple("");

            const dataR = await apiFormAppelRecherche(utilisateurBearer, chaineRecherche);
            if(global.config.modeDebugApp == 1) console.log('retour apiFormAppelRecherche : ', dataR);
            setDataLoaded(true);

            switch (dataR.statusCode) {
            case 200:
                let presenceInvalide = false;
                let presenceRefusee = false;
                //boucle sur le contenu renvoyé par l'api pour savoir s'il y a des incomplètes ou des refusées
                dataR.dataApi.forEach((el) => {
                    if(el.Etat_LHEO_Valide == 0) presenceInvalide = true;
                    if(el.Etat_Validation_CO == 30) presenceRefusee = true; // 30 = refusé
                });
                let messageBase = "Recherchez vos formations existantes pour les consulter ou les modifier.";
                if(presenceInvalide == true || presenceRefusee == true ) messageBase += "<br>Les formations qui ne sont pas valides sont repérées sur fond orange."
                setMessageBase(messageBase);
                setDataApi(dataR.dataApi);
                setRechercheEffectuee("true");
                localStorage.setItem('rechercheEffectuee', "true");
                return true;
                case 400:
                    var dataObjetVide = new Object();
                    setDataApi(dataObjetVide);
                    setIndicationSimple(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "Trop de resultat"
                    return true;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
                break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
            }
            return false;
        }
        
    }

    async function chargementsConsecutifs() {
        setMessageProbleme("");
        setIndicationSimple("");
        setDataLoaded(false);

        var retVerif = await verifMaintenance(setEnMaintenance, setMsgMaintenance);
        if(retVerif == true) { 
            var ret = true;
            if(rechercheEffectuee == "true") ret = await effectueRecherche();
            else ret = await recupererListeCompleteForm();

            // récupére nbFoTot, nbFoRejetLheo, nbFoRejetCarif, fait aussi dans Signin 
            if(ret == true) await recupererVolumes(utilisateurBearer, setNbFormTot, setNbFormAttente, setNbFormIncomplete, setNbActAttente, setNbActIncomplete, setNbSessAttente, setNbSessIncomplete, setMessageProbleme);
        }
        setDataLoaded(true);
    }

    useEffect(() => {
        if(choixCreaForm != undefined && choixCreaForm != "") {
            setShowModalIndicAvantCrea(true);
            //console.log(choixCreaForm);
        }
    }, [choixCreaForm]);

    useEffect(() => {
        if(global.config.modeDebugApp == 1) console.log('useffect formation, rechercheEffectuee :' + rechercheEffectuee)
        document.title = "Mes formations"
        if (utilisateurBearer === '000000')  navigate(utilisateurRouteLogin); // si le status n'est pas bon on redirige sur l'acceuil
        chargementsConsecutifs();
    }, []);
   var isRechercherDesactive = (!champRechFormIntit  && champRechFormEnCoursAVenir == "false" && champPrst4Seul == "false" && 
                                !champRechFormLieu && champRechFormTypeForm == "-" && champRechFormContrat == "-" && 
                                champRechFormMesure == "-" && !champRechFormNumSess) || !dataLoaded;

   return (     
        <main className="sous-bloc">
            
            {process.env.REACT_APP_MY_ENV != 'production' && <MessageErreurApi message={global.config.msgPrTechnique.msgSrvTest} />}            
            <MessageErreurApi message={messageProbleme}  />
            <LoadingSpinner dataLoaded={dataLoaded} />
            {enMaintenance == true && <MessageErreurApi message={msgMaintenance} />}


            { dataLoaded > 0 &&enMaintenance == false &&
            <>
                <MessageInfoBox message={messageBase} />
                <form onSubmit={handleSubmit}>
                    <div className="partie-form">
                        <input className="form-control" placeholder='Intitulé de la formation' type="text" id="champRechFormIntit" value={champRechFormIntit == null ? '' : champRechFormIntit} onChange={(e) => {setChampRechFormIntit(e.target.value)}} />
                        <input className="form-control" placeholder='Ville ou département' type="text" id="champRechFormLieu" value={champRechFormLieu == null ? '' : champRechFormLieu} onChange={(e) => {setChampRechFormLieu(e.target.value)}} />

                        <select className="form-control custom-select" id="champRechFormTypeForm" value={champRechFormTypeForm == null ? '-' : champRechFormTypeForm} onChange={(e) => {setChampRechFormTypeForm(e.target.value)}}>
                            <option value="-">Tout type de formation</option>
                            <option value="certif">Formations certifiantes</option>
                            <option value="pro">Formations non certifiantes</option>
                        </select>
                    </div>

                    <div className="partie-form">
                        <select className="form-control custom-select" id="champRechFormMesure" value={champRechFormMesure == null ? '-' : champRechFormMesure} onChange={(e) => {setChampRechFormMesure(e.target.value)}}>
                            <option value="-">Toutes mes formations</option>                            
                            <option value="appr">Contrats d'apprentissage</option>
                            <option value="ctpro">Contrats de professionnalisation</option>
                            <option value="agefiph">Agefiph</option>
                            <option value="nonconv">Non conventionné</option>
                            <option value="pe">Financeur Pôle emploi</option>
                            <option value="inactivRsRncp">Formations que je ne peux plus proposer (inactives au rncp ou au Répertoire spécifique)</option>
                        </select>

                        <input className="form-control" placeholder='Numéro de session' type="text" id="champRechFormNumSess" value={champRechFormNumSess == null ? '' : champRechFormNumSess} onChange={(e) => {setChampRechFormNumSess(e.target.value)}} />
                    </div>

                    <div className="partie-form partie-form-right">
                    <Form.Check type="switch" id="champRechFormEnCoursAVenir" label="Voir uniquement les formations en cours et à venir" 
                                checked={(champRechFormEnCoursAVenir === "true")} 
                                onChange={() => champRechFormEnCoursAVenir === "true" ? setChampRechFormEnCoursAVenir("false") : setChampRechFormEnCoursAVenir("true")} />
                    </div>

                    {utilisateurIdMesureOf > 0 && utilisateurIdMesureOf >=380 && utilisateurIdMesureOf <= 382 &&
                        <div className="partie-form partie-form-right">
                            <Form.Check type="switch" id="champPrst4Seul" label="Voir uniquement les formations PRST4" className="classLabelRed"
                                        checked={(champPrst4Seul === "true")} 
                                        onChange={() => champPrst4Seul === "true" ? setChampPrst4Seul("false") : setChampPrst4Seul("true")} />
                        </div>
                    }
                    
                    <div className="partie-form-bt">
                        <CsvDownloader  urlApi="/CsvSessions" boutonLib="Télécharger toutes vos formations CSV" boutonTitle="Télécharger vos formations en CSV"
                                        nomFicCible="vos formations extranet-formation.cariforef.fr.csv" />
                        <div className="partie-form-bt-info">Réinitialiser la recherche vous permet de visualiser la totalité de vos formations</div>
                        <a href="" onClick={handleClickReinit} rel="noopener">Réinitialiser</a>

                        <ButtonDeBase typeSubmit={true} lib="Rechercher" disabled={isRechercherDesactive} />
                    </div>
                </form>
                { dataApi &&
                <>
                    {dataApi.length > 0 && <Table columns={columns} data={dataApi} noPagination={null} noDisplayNbResult={null} noFilter={null} paginationOntop={true} />}
                    {dataApi.length == 0 && indicationSimple.length == 0 &&  <div>Aucune formation n'a été trouvée.<br /><br /></div>}
                    {indicationSimple.length > 0 &&  <MessageInfoBox message={indicationSimple} />}
                </>
                }
                <MessageInfoBox message="Si la formation n'existe pas encore, vous pouvez la créer : "  />

                <div className="partie-bouton-crea">
                    <ButtonDeBase onClick={() =>setChoixCreaForm("certif")} lib="Créer une formation certifiante" />
                    <ButtonDeBase onClick={() =>setChoixCreaForm("pro")} lib="Créer une formation non certifiante" />
                </div>
                <div style={{fontSize: "1.2em"}}>
                       <h4>ℹ️ Rappel :</h4>
                        Une formation <b>certifiante</b> conduit à une certification inscrite au <b>Répertoire Spécifique</b> ou au <b>Rncp</b> (dont VAE , Bilan de compétences, Permis et CACES).<br />
                        Une formation <b>non certifiante</b> est validée par une attestation et n'est inscrite ni au <b>Répertoire Spécifique</b> ni au <b>Rncp</b>.<br /><br />
                </div>

                {showModalIndicAvantCrea &&
                <Modal size="lg" show={showModalIndicAvantCrea} onHide={handleCloseModalIndicAvantCrea} backdrop="static" keyboard={false} centered aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                         ℹ️ Information
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{fontSize: "1.2em"}}>
                        Notez bien que vous ne pouvez créer que des actions accessibles par la voie de la <b>formation continue</b>.<br />
                        Si vous souhaitez référencer une offre en apprentissage, veuillez contacter <a href="mailto:{global.config.personnelPaca.mail}">{global.config.personnelPaca.mail}</a>
                    </div>
                    <div style={{fontSize: "1.2em"}}>
                        <br /><b>Rappel :</b><br />
                        Une formation <b>certifiante</b> conduit à une certification inscrite au <b>Répertoire Spécifique</b> ou au <b>Rncp</b> (dont VAE , Bilan de compétences, Permis et CACES).<br />
                        Une formation <b>non certifiante</b> est validée par une attestation et n'est inscrite ni au <b>Répertoire Spécifique</b> ni au <b>Rncp</b>.<br /><br />
                    </div>
                    <div className="partie-bouton-crea">
                        <ButtonDeBase onClick={() =>handleClickCreateFormation(choixCreaForm)} lib="👌 Ok, allons y " disabled={btCreerActif == true ? false : true} />
                        <ButtonDeBase onClick={() =>handleCloseModalIndicAvantCrea()} lib="Annuler la création" className="fas fa-ban sd" />
                    </div>
                </Modal.Body>
                </Modal>
                }
            </> } 
        </main>
    )
}


export default FormationGen;