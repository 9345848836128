import PropTypes from 'prop-types';
import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import { NavLink } from "react-router-dom";

import { DonneesUtilisateurContext } from '../../contexte.js';

import {apiActAppelGetInfosDiv, apiFormateurGetInfosDiv, apiLieuAssocDisso, apiCertifAssocDisso, apiActModif } from '../../Datas/dataActions.js'
import { apiRecupereInfoGet } from '../../Datas/datasAutre.js'

import LiensPrecedentSuivant from '../../Components/LiensPrecedentSuivant.jsx';
import LoadingSpinner from "../../Components/LoadingSpinner";
import MessageInfoBox, { MessageErreurApi, MessageInfoNoDroitErreurApi } from '../../Components/Messages.jsx';
import Table from '../../Components/Table.jsx'
import { ButtonVoirFiche, ButtonSuppr } from '../../Components/ButtonsCustom.jsx';
import { appelGetSetInfosObjetsFormActSess } from '../../fonctionsDiv.js';


/**
 * Return Formulaire étape 7 (association du ou des lieux et des certifications)
 *
 * @component
 * @summary page étape 7
 * @param {  }
 * @return { HTMLElement }
*/
function FormEtape7(props) {
    let numEtape = 7;

    const formationNumber = props.formationNumber;
    const actionNumber = props.actionNumber;

    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const [idFormateur, setIdFormateur] = useState();
    const [nomFormateur, setNomFormateur] = useState();
    const [dataApiFormateurs, setDataApiFormateurs] = useState();
    const [dataApiLieux, setDataApiLieux] = useState();
    const [dataLoaded, setDataLoaded] = useState(true);
    const [messageProbleme, setMessageProbleme] = useState();

    const [certificationsDeLaFormation, SetcertificationsDeLaFormation] = useState();
    const [dataCertifsApi, SetDataCertifsApi] = useState();
    
    //const [ID_PCF_INFORMATION, setID_PCF_INFORMATION] = useState(); (remis au niveau de l'action, comme il y a dejà les dates d'info...)

    const columnsFormateurs = useMemo(
        () => [
          {
            id:'listesFormateurs',
            hideHeader : false,
            trieDefautRp : 'LIB_COMPANIES',
            columns: [
                { 
                  Header: 'Nom', 
                  accessor: 'LIB_COMPANIES',
                  id: "LIB_COMPANIES",
                  Cell: ({ row }) => (
                      <>
                          <a title="Gérer" onClick={() => handleSelectFormateur(row.original.ID_ACTIONS_SESSIONS_FORMATEUR, row.original.LIB_COMPANIES)}>
                          {row.original.LIB_COMPANIES}
                          </a>
                      </>
                  ),
                },
                { 
                  Header: 'Siret', 
                  accessor: 'SIRET',
                },
                { 
                  Header: 'Certification(s)', 
                  Cell: ({ row }) => (  
                    <>
                      { /* pour retour à la ligne dans react table  https://stackoverflow.com/questions/69616909/react-how-to-break-a-line-in-a-cell-of-a-material-ui-table
                      bien laisser  key={idx} pour ne pas avoir des warning dans la console */}
                      {row.original.lib_concatene_certifs != null && 
                        row.original.lib_concatene_certifs.split("|").map((curs, idx) => (
                          <span key={idx}>{curs}<br /></span>
                        ))}
                    </>
                    ),
                },
                { 
                  Header: 'Lieu(x) de formation', 
                  Cell: ({ row }) => (  
                    <>
                      {row.original.lib_concatene_lieux != null && 
                        row.original.lib_concatene_lieux.split("|").map((curs, idx) => (
                          <span key={idx}>{curs}<br /></span>
                        ))}
                    </>
                    ),
                },
                {
                  Header: () => <>Principal</>,
                    accessor: 'PRINCIPAL', 
                    id: "PRINCIPAL_center",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                        <>
                          {row.original.PRINCIPAL == true ? 
                              <span className="enrobage-boutton"><span className="fas fa-star"></span></span>
                            :
                              <span className="enrobage-boutton"><span className="far fa-star"></span></span>
                          }
                        </>
                    ),
                },
                {
                  Header: () => <></>,
                    accessor: 'actions', 
                    id: "asso_center",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                        <>
                            <a title="Gérer" onClick={() => handleSelectFormateur(row.original.ID_ACTIONS_SESSIONS_FORMATEUR, row.original.LIB_COMPANIES)}>
                              <ButtonVoirFiche className="fas fa-pen-to-square" />
                            </a>
                        </>
                    ),
                },
            ]
          },
        ],
        []
    )

    const columnsLieux = useMemo(
        () => [
          {
            id:'listesLieux',
            hideHeader : false,
            trieDefautRp : 'LIB_GEO_VILLES',
            columns: [
                { 
                  Header: 'Dénomination', 
                  accessor: 'Denomination',
                },
                /*{ plus souhaité par Lidya le 141123 (embrouille avec le siret du lieu)
                  Header: 'Siret', 
                  accessor: 'SIRET',
                },*/
                { 
                  Header: 'Adresse', 
                  accessor: 'ADDRESS1',
                },
                { 
                  Header: 'Ville', 
                  accessor: 'LIB_GEO_VILLES',
                },
                { 
                  Header: 'CP', 
                  accessor: 'CODE_POSTAL',
                },
                {
                  Header: () => <>Principal</>,
                    accessor: 'PRINCIPAL', 
                    id: "PRINCIPAL_center",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                        <>
                          {row.original.PRINCIPAL == true ? 
                            <span className="enrobage-boutton"><span className="fas fa-star"></span></span>
                            :
                            <a title= "Définir comme principal"
                            onClick={() => handleClickSetPrincipalLieu(row.original.ID_ACTIONS_SESSIONS_FORMATEUR, row.original.ID_PCF)}>
                              <span className="enrobage-boutton"><span className="far fa-star"></span></span>
                            </a>
                          }
                        </>
                    ),
                },
                {
                  Header: () => <></>,
                    accessor: 'actions', 
                    id: "asso_center",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                      <>
                      {/* si il y a déjà une session, on ne permet pas le changement de formateur  */}
                      { (props.infosGenFormation["act_deja_exportee"] == false && props.infosGenFormation["nbSessions"] == 0 &&
                         props.infosProprietes["ACTIONS_SESSIONS_FORMATEUR_LIEU_FORMATION|other_dataEditable"].editable == 1) ?
                          <a title="Dissocier" onClick={() => handleClickDissoLieu(row.original.ID_ACTIONS_SESSIONS_FORMATEUR, row.original.ID_PCF)}>
                            <ButtonSuppr />
                          </a>
                          :
                            (props.infosGenFormation["act_deja_exportee"] == true) ?
                            <a title="Suppression impossible, cliquez pour en savoir plus" 
                              onClick={() => hdleClickSuppLieuNoPossible("Dissociation impossible, cette action a déjà été envoyée dans l'éco système, cette information ne peut par conséquent plus être modifiée.")}>
                              <ButtonSuppr disabled="disabled"  />
                            </a>
                            :
                            <a title="Suppression impossible, cliquez pour en savoir plus" 
                              onClick={() => hdleClickSuppLieuNoPossible("Dissociation impossible car cette action a au moins une session.\r\nVous pouvez encore intervenir sur les lieux si vous supprimez auparavant les sessions que vous venez de créer.")}>
                            <ButtonSuppr disabled="disabled"  />
                            </a>
                      }
                      </>
                    ),
                },
            ]
          },
        ],
        []
    )
 
 
    function  hdleClickSuppCertifNoPossible () {
      alert("Impossible de supprimer cette certification car cette action a au moins une session.");
    }
    function  hdleClickSuppLieuNoPossible (msg) {
      alert(msg);
    }

    // pour enregistrer l'adresse d'information
    /* (remis au niveau de l'action, comme il y a dejà les dates d'info...)
    const handleClickAssoAdresseInfo = async (codePcf) => {
          setID_PCF_INFORMATION(codePcf);
          //console.log(codePcf);
          setMessageProbleme();
          var datasToPut= { // on créé 
            "ID_ACTIONS_SESSIONS": actionNumber,
            "ID_PCF_INFORMATION": codePcf,
        }
          var dataR = await apiActModif(utilisateurBearer, datasToPut, actionNumber)
          switch (dataR.statusCode) {
              case 200:
                  alert("L'adresse d'information a bien été enregistrée.");
                break;
              case 401:
                  setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
                break;
                case 400:
                  setMessageProbleme(dataR.dataApiLieux); // un message personnalisé est retourné dans ce cas, ex : "L'organisme est déjà associé"
                break;
              default:
                  setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
          }
    }*/

    const handleClickAssoLieu = async (codeFormateur, codePcf) => {
          setDataLoaded(false);
          setMessageProbleme();
          var dataR = await apiLieuAssocDisso(utilisateurBearer, codeFormateur, codePcf, 'PUT');

          switch (dataR.statusCode) {
              case 200:
                  var pb = false;
                  var ret = await recupFormateurs(); // renseigne dataApiFormateurs
                  if(ret == null) pb = true;

                  if(pb == false) {
                    var retLieu = await recuperLieuxPossibles(codeFormateur); // ces 2 lignes sont pour le choix du lieu une fois le formateur choisi
                    if(retLieu != null) SetLieuxPossibles(retLieu); else pb = true;
                  }
                  if(pb == false) await recupFormateurLieux(codeFormateur); // reload la liste

                  if(pb == false) alert("L'association a bien été effectuée.");
                  else setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
                break;
              case 401:
                  setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
                break;
                case 400:
                  setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "L'organisme est déjà associé"
                break;
              default:
                  setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
          }
          setDataLoaded(true);
    }
    
    // (row.original.ID_ACTIONS_SESSIONS_FORMATEUR, row.original.ID_PCF)}>
    const handleClickDissoLieu  = async (codeFormateur, codePcf) => {
        setDataLoaded(false);
        setMessageProbleme();
        var dataR = await apiLieuAssocDisso(utilisateurBearer, codeFormateur, codePcf, 'DELETE');

        switch (dataR.statusCode) {
            case 200:
              var pb = false;
              var ret = await recupFormateurs(); // renseigne dataApiFormateurs
              if(ret == null) pb = true;

              if(pb == false) {
                var retLieu = await recuperLieuxPossibles(codeFormateur); // ces 2 lignes sont pour le choix du lieu une fois le formateur choisi
                if(retLieu != null) SetLieuxPossibles(retLieu); else pb = true;
              }
              if(pb == false) await recupFormateurLieux(codeFormateur); // reload la liste

              if(pb == false) {
                if(dataR.dataApi != "") alert(dataR.dataApi); // bien laisser : info texte de l'api
                else alert("La dissociation a bien été effectuée");
              }
              else setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut

              break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
        setDataLoaded(true);
    }
    const handleClickSetPrincipalLieu  = async (codeFormateur, codePcf) => {
      if(props.infosGenFormation["act_deja_exportee"] == true || props.infosGenFormation["nbSessions"] > 0) {
        alert("Vous ne pouvez pas modifier cette partie car cette action a au moins une session.");
        return;
      }
      var dataR = await apiLieuAssocDisso(utilisateurBearer, codeFormateur, codePcf, 'POST', '/setprincipal');
      if(global.config.modeDebugApp == 1) console.log('retour apiLieuAssocDisso : ', dataR);
      switch (dataR.statusCode) {
          case 200:
            await recupFormateurLieux(codeFormateur); // reload la liste
            alert("Modification effectuée.");
            break;
            case 400:
              setMessageProbleme(dataR.dataApi);
              break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
            break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
    }
    async function recupFormateurs() {
      const dataR = await apiActAppelGetInfosDiv(utilisateurBearer, "/" + actionNumber + "/formateurs");
      if(global.config.modeDebugApp == 1) console.log('retour apiActAppelGetInfosDiv dans recupFormateurs: ', dataR);

      switch (dataR.statusCode) {
          case 200:
              setDataApiFormateurs(dataR.dataApi);
              return dataR.dataApi;
            break;
            case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
            break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
    }
    async function recupFormateurLieux(codeFormateur) {
        const dataR = await apiFormateurGetInfosDiv(utilisateurBearer, "/" + codeFormateur + "/lieux");
        if(global.config.modeDebugApp == 1) console.log('retour apiFormateurGetInfosDiv dans recupFormateurLieux : ', dataR);

        switch (dataR.statusCode) {
            case 200:
                setDataApiLieux(dataR.dataApi);

                //pas suffisant car on doit recalculer la validité de l'action aussi : 
                //var objaAmodif = JSON.parse(JSON.stringify(props.infosGenFormation)); // on clone (duplique) l'objet pour que tout fonctionne bien
                //objaAmodif.nbLieux = dataR.dataApi.length;
                //objaAmodif.etape7 = dataR.dataApi.length > 0 ? "ok" : "nok";
                //props.SetInfosGenFormation(objaAmodif);
                // pour que le systeme calcule la validité Lhéo....
                var retAppel = await appelGetSetInfosObjetsFormActSess(utilisateurBearer, formationNumber, actionNumber, null, props.SetInfosGenFormation, setMessageProbleme);
              break;
              case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
    }

    // pour recup données pour sélection du lieux
    async function recuperLieuxPossibles(IdFormateurChoisi) {
        const dataR = await apiRecupereInfoGet(utilisateurBearer, "lieux/lieux-du-formateur/" + IdFormateurChoisi + '/' + actionNumber);
        if(global.config.modeDebugApp == 1) console.log(`retour apiRecupereInfoGet avec "lieux/lieux-du-formateur/` + IdFormateurChoisi + '/' + actionNumber + ':' , dataR);
        switch (dataR.statusCode) {
            case 200:            
                return dataR.dataApi;
                break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
                return null;
        }
    }
   
    /**** partie certif */
    const columnsCertifs = useMemo(
      () => [
        {
          id:'listesDiplomes',
          hideHeader : false,
          trieDefautRp : 'intitule_diplome',
          columns: [
              { 
                Header: 'Code', 
                accessor: 'code_diplome',
              },
              {  
                Header: 'Intitulé du diplôme', 
                accessor: 'intitule_diplome', 
              },
              { 
                Header: 'Code Rncp', 
                accessor: 'code_rncp',
              },
              { 
                Header: 'Etat', 
                id: "etat_lib_center",
                accessor: 'etat_lib',
              },
              {
                Header: () => <></>,
                  accessor: 'actions', 
                  id: "asso_center",
                  disableSortBy: true,
                  Cell: ({ row }) => (
                      <> 
                        { /*  on ne peut pas dissocier la certif si elle est la seule au niveau de la....
                        //on peut ainsi controller ce qu'on affiche en fonction de ce qu'il y a dans les donéées transmises...
                        // ne marche pas dès qu'on reload: dataCertifsApi && dataCertifsApi.length > 1 && */}

                        {/* si il y a déjà une session, on ne permet pas le changement */}
                        {props.infosGenFormation["nbSessions"] == 0 ?
                          <a title="Dissocier" onClick={() => handleClickDissoCerif(row.original.ID_ACTIONS_SESSIONS_FORMATEUR, row.original.code_diplome)}>
                            <ButtonSuppr />
                          </a>
                            :
                            <a title="Suppression impossible, au moins une session existe" onClick={() => hdleClickSuppCertifNoPossible()}>
                              <ButtonSuppr disabled="disabled"  />
                            </a>
                        }
                      </>
                  ),
              },
          ]
        },
      ],
      []
    )
    const columnsCertifSeule = useMemo(
      () => [
        {
          id:'listesDiplomes',
          hideHeader : false,
          trieDefautRp : 'intitule_diplome',
          columns: [
              { 
                Header: 'Code', 
                accessor: 'code_diplome',
                disableSortBy: true,
              },
              {  
                Header: 'libellé', 
                accessor: 'intitule_diplome', 
                disableSortBy: true,
              },
              { 
                Header: 'Code Rncp', 
                accessor: 'code_rncp',
                disableSortBy: true,
              },
              { 
                Header: 'Etat', 
                accessor: 'etat_lib',
                id: "etat_lib_center",
                disableSortBy: true,
              },
          ]
        },
      ],
      []
    )
    const handleClickDissoCerif  = async (codeFormateur, codePcf) => {
      setDataLoaded(false);
      setMessageProbleme();
      var dataR = await apiCertifAssocDisso(utilisateurBearer, codeFormateur, codePcf, 'DELETE');

      switch (dataR.statusCode) {
          case 200:
              var ret = await recupFormateurs(); // renseigne dataApiFormateurs

              await recupFormateurCertifs(codeFormateur); // reload la liste
              if(dataR.dataApi != "") alert(dataR.dataApi);
              else {
                await recupCertifsPossibles(codeFormateur);
                alert("La dissociation a bien été effectuée");
              }
            break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
            break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
      setDataLoaded(true);
    }
    const handleClickAssoCertif = async (codeFormateur, codePcf) => {
      setDataLoaded(false);
      setMessageProbleme();
      var dataR = await apiCertifAssocDisso(utilisateurBearer, codeFormateur, codePcf, 'PUT');

      switch (dataR.statusCode) {
          case 200:
              var ret = await recupFormateurs(); // renseigne dataApiFormateurs
              await recupFormateurCertifs(codeFormateur); // reload la liste
              await recupCertifsPossibles(codeFormateur); 
              alert("L'association a bien été effectuée.");
            break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
            break;
          case 400:
              setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "L'organisme est déjà associé"
            break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
      setDataLoaded(true);
    }
    async function recupFormateurCertifs(codeFormateur) {
      const dataR = await apiFormateurGetInfosDiv(utilisateurBearer, "/" + codeFormateur + "/certifications");
      if(global.config.modeDebugApp == 1) console.log('retour apiFormateurGetInfosDiv dans recupFormateurCertifs : ', dataR);

      switch (dataR.statusCode) {
          case 200:
              SetDataCertifsApi(dataR.dataApi);
            break;
            case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
            break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
    }
    //
    async function recupCertifsPossibles(codeFormateur) {
      const dataR = await apiFormateurGetInfosDiv(utilisateurBearer, "/" +  codeFormateur + "/certifications-possibles");
      if(global.config.modeDebugApp == 1) console.log('retour apiFormateurGetInfosDiv /certifications-possibles depuis recupCertifsPossibles : ', dataR);

      switch (dataR.statusCode) {
          case 200:
              SetcertificationsDeLaFormation(dataR.dataApi);
            break;
            case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
            break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
  }
  /**** fin partie certif */
/* (remis au niveau de l'action, comme il y a dejà les dates d'info...)
  async function recupDonneesAction() {
    const dataR = await apiActAppelGetInfosDiv(utilisateurBearer, "/"+actionNumber);
    if(global.config.modeDebugApp == 1) console.log('retour apiActAppelGetInfosDiv dans recupDonneesAction : ', dataR);

    switch (dataR.statusCode) {
      case 200:
          setID_PCF_INFORMATION(dataR.dataApi.actionDataPrincipales.ID_PCF_INFORMATION);
          return(1);
        case 401:
          setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
          break;
      default:
        setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
    }
    return(null);
  }
*/
  const [lieuxPossibles, SetLieuxPossibles] = useState(null);
  async function chargementsConsecutifs() {
    var pb = false;
    setDataLoaded(false);

    var ret = await recupFormateurs(); // renseigne dataApiFormateurs
    if(ret == null) pb = true;

    /*var ret2 = await recupDonneesAction(); // pour récuperer ID_PCF_INFORMATION (remis au niveau de l'action, comme il y a dejà les dates d'info...)
    if(ret2 == null) pb = true;*/

    if (ret.length == 1) { // cas simple ou un seul formateur
      if(props.infosGenFormation.certifOuPro == "certif") await recupCertifsPossibles(ret[0].ID_ACTIONS_SESSIONS_FORMATEUR); // renseigne certificationsDeLaFormation, que pour le certifiant, pas pour le professionnalisant

      if(props.infosProprietes["ACTIONS_SESSIONS_FORMATEUR_LIEU_FORMATION|other_dataEditable"].editable == 1) {
        var retLieu = await recuperLieuxPossibles(ret[0].ID_ACTIONS_SESSIONS_FORMATEUR); // ces 2 lignes sont pour le choix du lieu une fois le formateur choisi
        if(retLieu != null) SetLieuxPossibles(retLieu); else pb = true;
      }

      await recupFormateurLieux(ret[0].ID_ACTIONS_SESSIONS_FORMATEUR); // renseigne dataApi
      if(props.infosGenFormation.certifOuPro == "certif") await recupFormateurCertifs(ret[0].ID_ACTIONS_SESSIONS_FORMATEUR); // que pour le certifiant, pas pour le professionnalisant
      setIdFormateur(ret[0].ID_ACTIONS_SESSIONS_FORMATEUR);
    }

    if(pb == true ) setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
    setDataLoaded(true);
  }

    useEffect(() => {
      if(global.config.modeDebugApp == 1)  console.log('useffect FormEtape7, props.infosGenFormation : ', props.infosGenFormation)
      document.title = `Lieux et certif de l'action ${actionNumber}`;
      chargementsConsecutifs();
    }, []);

    async function handleSelectFormateur(IdFormateurChoisi, NomFormateurChoisi) {
      var pb = false;
      setDataLoaded(false);
      setIdFormateur(IdFormateurChoisi);
      setNomFormateur(NomFormateurChoisi); 

      await recupFormateurLieux(IdFormateurChoisi); 
      await recupFormateurCertifs(IdFormateurChoisi);
      if(props.infosProprietes["ACTIONS_SESSIONS_FORMATEUR_LIEU_FORMATION|other_dataEditable"].editable == 1) {
      var ret = await recuperLieuxPossibles(IdFormateurChoisi); // ces 2 lignes sont pour le choix du lieu une fois le formateur choisi
      if(ret != null) SetLieuxPossibles(ret); else pb = true;
      }

      setDataLoaded(true);
    }

    var messageInfoCiAsso = "Le lieu principal est marqué par une étoile <span class='picto-legende'><span class='fas fa-star'></span></span>.";
    if(props.infosGenFormation["nbSessions"] == 0) messageInfoCiAsso += "<br />Vous pouvez en désigner un autre en cliquant sur <span class='picto-legende2'><span class='far fa-star'></span></span>.";

    return (
        <>
          <LoadingSpinner dataLoaded={dataLoaded} />
          <MessageErreurApi message={messageProbleme} />
          
          {props.infosGenFormation["nbSessions"] > 0 ?
             <MessageInfoBox message="Important :<br />
             <ul>
              <li>Si vous enregistrez une formation avec plusieurs lieux de formation, vous indiquez que la <u>même</u> formation se déroulera et se partagera sur les différents lieux sélectionnés.</li>
              <li>Si vous proposez une formation qui se déroulera <u>entièrement</u> sur chaque lieu, il faudra enregistrer plusieurs actions à la formation en sélectionnant un seul lieu.</li>
             </ul>Vous ne pouvez pas modifier cette partie car cette action a au moins une session." /> 
             :
             <MessageInfoBox message="Important :<br />
             <ul>
              <li>Si vous enregistrez une formation avec plusieurs lieux de formation, vous indiquez que la <u>même</u> formation se déroulera et se partagera sur les différents lieux sélectionnés.</li>
              <li>Si vous proposez une formation qui se déroulera <u>entièrement</u> sur chaque lieu, il faudra enregistrer plusieurs actions à la formation en sélectionnant un seul lieu.</li>
             </ul>" />
          }
          {dataApiFormateurs && dataApiFormateurs.length > 1 &&
          <>
          <MessageInfoBox message={`${dataApiFormateurs.length} organismes formateurs ont été déclarés à l'étape précédente. Vous pouvez gérer ici les lieux et les certifications pour chacun d’eux.`}/>
          {/*
          <select className="form-control custom-select" id="listeOfFormateurs" 
                  title="Organismes" 
                  onChange={(e) => {handleSelectFormateur(e.target.value)}}>
              <option value="" key="-">Sélectionner l'un des {dataApiFormateurs.length} formateurs que vous avez déclarés afin d'intervenir sur ses lieux de formation et/où ses certifications</option>
              {dataApiFormateurs.map((item) => {
                  return (
                      <option value={item.ID_ACTIONS_SESSIONS_FORMATEUR} key={item.ID_ACTIONS_SESSIONS_FORMATEUR}>
                          {item.LIB_COMPANIES}
                      </option>
                  );
                  })}
          </select>*/}
          <Table columns={columnsFormateurs} data={dataApiFormateurs} noPagination={true} noDisplayNbResult={true} noFilter={true} />
          </>
          }


          {/* la section ci dessous s'affiche si il n'y a qu'un formateur ou qu'un formateur est sélectionné parmis la liste ci dessus */}
          <div style={{paddingTop:"15px", paddingLeft: "30px"}}>

          {nomFormateur != null && <span style={{fontSize:"15px", fontWeight: "bold", color: "#8BADDC"}}>Gestion des lieux et certification de l'organisme formateur "{nomFormateur}" :</span>}

          {dataLoaded && idFormateur && dataApiLieux &&
          <section className="lieux-associes">
            { props.infosProprietes["ACTIONS_SESSIONS_FORMATEUR_LIEU_FORMATION|other_dataEditable"].editable == 0 &&
              <MessageInfoNoDroitErreurApi />
            }

            { props.infosProprietes["ACTIONS_SESSIONS_FORMATEUR_LIEU_FORMATION|other_dataEditable"].editable == 1 
            && props.infosGenFormation["nbSessions"] == 0 &&
            <>
              {lieuxPossibles && lieuxPossibles.length > 0 &&
              <select className="form-control custom-select" id="listeOfLieux" 
                      title="Lieux de formations possibles" 
                      onChange={(e) => {handleClickAssoLieu(idFormateur, e.target.value);}}>
                  <option value="" key="-">Sélectionner un lieu de formation à ajouter pour cet organisme formateur</option>
                  {lieuxPossibles && lieuxPossibles.map((item) => {
                      return (
                          <option value={item.ID_PCF} key={item.ID_PCF}>
                              {item.lib_concatene}
                          </option>
                      );
                      })}
              </select>
              }
              Vous ne trouvez pas le lieu de formation adéquat 👀 ? 
              Vous pouvez ajouter un lieu à votre organisme en <NavLink className="main-nav-item" style={{textDecoration: "underline"}} 
              to={"/lieux-et-contacts/creation-pcf?gobackto=/formation/etape7/" + formationNumber + "/action-" + actionNumber}>cliquant ici</NavLink><br /><br />
            </>
            }

            <div className="titre">{dataApiLieux.length > 0 ? dataApiLieux.length > 1 ? 
                                      `${dataApiLieux.length} lieux de formation sont actuellement associés : ` 
                                      : "Un lieu de formation est actuellement associé : " 
                                      : "Aucun lieu de formation n'est actuellement associé."}
            </div>
            {dataApiLieux.length > 1 && <MessageInfoBox message={messageInfoCiAsso} nomClasse="message-info2" />}
            {dataApiLieux.length > 0 && <Table columns={columnsLieux} data={dataApiLieux} noPagination={true} noDisplayNbResult={true} noFilter={true} />}


            
            { /* (remis au niveau de l'action, comme il y a dejà les dates d'info...)dataApi.length > 0 &&
            <>
                <br /><br /><div className="titre">Adresse du lieu d'information :</div>
                <select className="form-control custom-select" id="ID_PCF_INFORMATION" 
                        title="AdressesInfosPossibles" 
                        value={ID_PCF_INFORMATION == null ? '' : ID_PCF_INFORMATION}
                        onChange={(e) => {handleClickAssoAdresseInfo(e.target.value);}}
                        disabled={props.infosProprietes["ACTIONS_SESSIONS_FORMATEUR_LIEU_FORMATION|other_dataEditable"].editable == 0 ? true : false}>
                    <option value="0" key="0">Aucune adresse d'information</option>
                    {lieuxPossibles && lieuxPossibles.map((item) => {
                        return (
                            <option value={item.ID_PCF} key={item.ID_PCF}>
                                {item.lib_concatene}
                            </option>
                        );
                        })}
                </select>
              </>
            */}
          </section>
          }

          { /* il est normal qu'on ne propose pas la select box ci dessous s'il n'y a qu'une certif associée à la formation */}
          {dataLoaded && idFormateur && dataCertifsApi &&
            <section className="certifications-associees">
              {/* si il y a déjà une session, on ne permet pas le changement de certifications */}
              {props.infosGenFormation["nbSessions"] > 0 &&
              <>
                  { certificationsDeLaFormation != null && certificationsDeLaFormation.length > 0 &&
                  <>
                    <select className="form-control custom-select" id="listeOfCertifs" 
                            title="Organismes" 
                            onChange={(e) => {handleClickAssoCertif(idFormateur, e.target.value);}}>
                        <option value="" key="-">Sélectionner une certification à ajouter pour cet organisme formateur</option>
                        {certificationsDeLaFormation && certificationsDeLaFormation.map((item) => {
                            return (
                                <option value={item.code_diplome} key={item.code_diplome}>
                                    {item.intitule_diplome}
                                </option>
                            );
                            })}
                    </select>
                    <br />
                  </>
                  }
                </>
              }

              <div className="titre">{ dataCertifsApi.length > 1 ? `${dataCertifsApi.length} Certifications sont préparées  par l'organisme formateur : ` : `Une certification est préparée par l'organisme formateur : `}</div>
              {dataCertifsApi && dataCertifsApi.length > 0 && <Table columns={ dataCertifsApi.length > 1 ? columnsCertifs : columnsCertifSeule} data={dataCertifsApi} noPagination={true} noDisplayNbResult={true} noFilter={true} />}
              {dataCertifsApi && dataCertifsApi.length == 0 && <div>Aucune certification n'est associée.</div>}
            </section>
          }
          </div>
          <br />
          <LiensPrecedentSuivant formationNumber={formationNumber} actionNumber={actionNumber} numEtape={numEtape} infosGenFormation={props.infosGenFormation} notifBeCareful={false} />
        </>
    )

}

FormEtape7.propTypes = {
  formationNumber: PropTypes.number.isRequired,
  actionNumber: PropTypes.number.isRequired,
  SetInfosGenFormation : PropTypes.func,
  infosGenFormation: PropTypes.object,
}

export default FormEtape7;