import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx

import { apiProfAppelBase } from '../Datas/dataDiplomes.js'
import { DonneesUtilisateurContext } from '../contexte.js';

import {MessageErreurApi} from './Messages.jsx';
import LoadingSpinner from "./LoadingSpinner.js";
import ButtonDeBase, {ButtonVoirFiche, ButtonVoirFiche2 } from './ButtonsCustom.jsx';
import Table from './Table.jsx';


/**
 * Return une section permettant de rechercher des professionnalisants
 *
 * @component
 * @summary page de rechercher pro des professionnalisants
 * @param {  }
 * @return { HTMLElement }
*/
function AdmRechercheProf(props) {
    const navigate = useNavigate(); // utile pour changer de page
    const { setBearer, utilisateurRouteLogin, utilisateurBearer, utilisateurRole} = useContext(DonneesUtilisateurContext);
    const [messageProbleme, setMessageProbleme] = useState();

    const [dataApiCiPro, setdataApiCiPro] = useState();
    const [dataLoadedRechCiPro, setDataLoadedRechCiPro] = useState(true);
    const [champRechProProfLib, setchampRechProProfLib] = useState(""); // useState(localStorage.getItem('champRechProProfLib'))
    const [chpRechProCiAvecSyn, setChpRechProCiAvecSyn] = useState(localStorage.getItem('chpRechProCiAvecSyn') ? localStorage.getItem('chpRechProCiAvecSyn') : 'true');

    const columnsCi = useMemo(
        () => [
          {
            id:'listesProfessionnalisant',
            hideHeader : false,
            trieDefautRp : 'LIB_PROFESSIONNALISANTS',
            columns: [
                {  
                Header: 'libellé', 
                accessor: 'LIB_PROFESSIONNALISANTS', 
                Cell: ({ row }) => (
                    <>
                    <a title="Voir la fiche" className="lib" onClick={() => props.handleClickViewCerifBootStrap(row.original.ID_PROFESSIONNALISANTS)}>
                        {row.original.LIB_PROFESSIONNALISANTS}&nbsp;
                        <ButtonVoirFiche />
                    </a>
                    {row.original.LIB_complement != '' && <span style={{color: "red"}}>{row.original.LIB_complement}</span>}
                    </>
                ),
                },
                { 
                Header: 'Formacodes', 
                accessor: 'codesFormacodes',
                disableSortBy: true,
                },
                { 
                Header: 'Nsf', 
                accessor: 'codesNsfss',
                disableSortBy: true,
                },

                { 
                Header: 'Romes', 
                accessor: 'codesRomes',
                disableSortBy: true,
                },

                {
                    Header: 'Appellations suppl.', 
                    disableSortBy: true, /*obligé comme cette colonne content desinfos complexes*/
                    Cell: ({ row }) => (  
                      <>
                        {row.original.listeDiplomesAppellationsPourMoteur != null && 
                          row.original.listeDiplomesAppellationsPourMoteur.map((ap, index) => (
                            <span key={ap.diplomes_pour_moteurs_id}>{ap.appellation_diplome} ({ap.source})<br /></span>
                          ))}
                      </>
                      ),
                },

                {
                    Header: () => <>Actions</>,
                    accessor: 'actions', 
                    id: "actions_center",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                        <>
                        {global.config.codesEtatsCiEnCours.includes(row.original.etat_code) ? 
                         <>
                            <a title="Voir la fiche" className="lib" onClick={() => props.handleClickViewCerifBootStrap(row.original.code_diplome)}>
                                <ButtonVoirFiche />
                            </a>
                            <ButtonVoirFiche2 className="fas fa-pen-to-square" 
                            lien={`/admin/moteur/synonymes-certifications/${row.original.code_diplome}`} 
                            title={`Gérer les synonymes de la fiche ${row.original.code_diplome}`} />
                        </>
                        :
                        <a title="Ce diplome n'est plus actif, cliquez pour savoir s'il est remplacé" onClick={function() {
                            let suite_remplacement = "";
                            if(row.original.listeDiplomesRemplacants && row.original.listeDiplomesRemplacants.length > 0) {
                                row.original.listeDiplomesRemplacants.forEach((remplacants) => {
                                    suite_remplacement += ', ' + remplacants.code;
                                })
                                if(row.original.listeDiplomesRemplacants.length == 1) alert("Ce diplôme n'est plus actif et ne peut pas être utilisé, il est remplacé par le " + suite_remplacement.substring(2));
                                else alert("Ce diplôme n'est plus actif et ne peut pas être utilisé, il est remplacé par les diplômes : " + suite_remplacement.substring(2));
                            }
                            else alert("Ce diplôme n'est plus actif et ne peut pas être utilisé");
                            }}>
                            <ButtonVoirFiche disabled="disabled" />
                        </a>
                        }
                        </>
                    ),
                },
            ]
          },
        ],
        []
    )


    async function effectueRechercheCi() {
        let chaineRecherche = "?";
        if (champRechProProfLib) chaineRecherche += "intitule-recherche=" + champRechProProfLib + '&';        
        if (chpRechProCiAvecSyn && chpRechProCiAvecSyn == "true")  chaineRecherche += "avec-appellation=1&";

        if (chaineRecherche.length == 1) {setMessageProbleme('Veuillez saisir au moins un critère'); return false; }
        else {    
            setDataLoadedRechCiPro(false);
            setMessageProbleme("");
            const dataR = await apiProfAppelBase(utilisateurBearer, 'Search' + chaineRecherche);
            if(global.config.modeDebugApp == 1) console.log('retour apiProfAppelBase : ', dataR);
            setDataLoadedRechCiPro(true);

            switch (dataR.statusCode) {
            case 200:
                //setMessageProbleme("Les données ont bien été recuperées");
                //modification du contenu renvoyé par l'api
                let nouveauTabRep = [];
                dataR.dataApi.forEach((el) => {
                    // on va couper les intitulés trop long
                    let libCourt = (el.LIB_PROFESSIONNALISANTS.length > global.config.lgMaxChaineTab)  ? el.LIB_PROFESSIONNALISANTS.substring(0, global.config.lgMaxChaineTab-3) + '...' :  el.LIB_PROFESSIONNALISANTS;
                    let ligne = { ...el, 'LIB_PROFESSIONNALISANTS': libCourt, 'libLong': el.LIB_PROFESSIONNALISANTS}
                    nouveauTabRep.push(ligne);           
                });
                setdataApiCiPro(nouveauTabRep); // dataR.dataApi
                setDataLoadedRechCiPro(true);
                break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
                break;
            case 400:
                // on test si c'est une chaine string qui est retourné par l'api
                if(typeof dataR.dataApi === 'string' || dataR.dataApi instanceof String) setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "Il y a plus de XXX résultats ({XXXX), veuillez affiner votre recherche"
                else setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
                break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
            }
        }
    }
    const handleSubmitRechCi = async (e) => {
        e.preventDefault();
        effectueRechercheCi();
    }

 
   useEffect(() => {
        if(global.config.modeDebugApp == 1) console.log('useffect AdmRechercheProf')
        if (utilisateurBearer === '000000' || utilisateurRole != "AdminExtranet" )  navigate(utilisateurRouteLogin); // si pas identifié ou role NOK on redirige sur l'acceuil
        if(typeof dataR == 'undefined') setchampRechProProfLib(); // pour que la derniere saisie ne se réaffiche pas si on revient sur la page
   }, []);


    return (
        <section>
            <p className="titre-rech-certif">Rechercher un non certifiant à associer à votre formation</p>
            <MessageErreurApi message={messageProbleme} />

            <LoadingSpinner dataLoaded={dataLoadedRechCiPro}/>
            {dataLoadedRechCiPro && 
                <>
                    <form onSubmit={handleSubmitRechCi} className="form-rech-ci">
                        <div className="partie-form-rech-ci">
                            { /* value={champRechProProfLib != null && champRechProProfLib}*/}
                            <input className="form-control input-lib" placeholder='Extrait du libellé (4 lettres minimum)' type="text" id="champRechProProfLib" 
                            onChange={(e) => {setchampRechProProfLib(e.target.value); localStorage.setItem('champRechProProfLib', e.target.value)}}
                            value={champRechProProfLib  == null ? '' : champRechProProfLib}  />
                            <ButtonDeBase typeSubmit={true } lib="Rechercher" disabled={(!champRechProProfLib || champRechProProfLib.length < 4)} />
                        </div>
                    </form>
                    {dataApiCiPro && dataApiCiPro.length > 0 && 
                    <>
                    <p className="message-info">
                        {dataApiCiPro.length} 
                        {dataApiCiPro.length > 1 ? 
                            <> non certifiants trouvés - cliquez sur l'un des boutons <span className="fas fa-pen-to-square picto-legende"></span> de la liste pour gérer les synonymes d'un non certifian</>
                            : 
                            <> non certifiant trouvé - cliquez sur la <span className="fas fa-pen-to-square picto-legende"></span> pour gérer ses synonymes </>}
                    </p>    
                    <Table columns={columnsCi} data={dataApiCiPro} noDisplayNbResult={true} />
                    </>
                    }
                </>
            }
            {dataLoadedRechCiPro && dataApiCiPro && dataApiCiPro.length == 0 ? <div>Aucun non certifiant n'a été trouvé, veuillez modifier vos critères.</div> : null}
        </section>
    )

}


export default AdmRechercheProf;

AdmRechercheProf.propTypes = {
    handleClickAssoCerif: PropTypes.func,
    handleClickViewCerifBootStrap: PropTypes.func
}