import { useEffect, useState, useContext} from 'react';
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import ButtonDeBase from "../Components/ButtonsCustom.jsx";
import { DonneesUtilisateurContext } from '../contexte.js';

/**
 * Return Page accueil
 *
 * @component
 * @summary accueil
 * @param {  }
 * @return { HTMLElement }
*/
function Accueil() {
  const navigate = useNavigate(); // utile pour changer de page

  const {utilisateurRouteLogin, utilisateurBearer, utilisateurNbFormAttente, utilisateurNbFormIncomplete, utilisateurNbActAttente, utilisateurNbActIncomplete, utilisateurNbSessAttente, utilisateurNbSessIncomplete} = useContext(DonneesUtilisateurContext);

  const [nbFicheTotalIncompletes, setNbFicheTotalIncompletes] = useState();
  const [nbFicheTotalAttente, setNbFicheTotalAttente] = useState();

  const handleCloseModal = () => {setShowInfoFiche(false);};
  const [showInfoFiche, setShowInfoFiche] = useState(false);

  const handleClickGotoPage = async () => {
      navigate(`/formation/incomplete`);
  }

    useEffect(() => {
        if (utilisateurBearer === '000000') navigate(utilisateurRouteLogin); // si le status n'est pas bon on redirige sur l'acceuil

        var ficheEnAttente = (utilisateurNbFormAttente > 0 || utilisateurNbActAttente > 0 || utilisateurNbSessAttente > 0) ? true : false;
        var ficheIncomplete = (utilisateurNbFormIncomplete > 0 || utilisateurNbActIncomplete > 0 || utilisateurNbSessIncomplete > 0) ? true : false;
        setNbFicheTotalIncompletes(utilisateurNbFormIncomplete + utilisateurNbActIncomplete + utilisateurNbSessIncomplete);
        setNbFicheTotalAttente(utilisateurNbFormAttente + utilisateurNbActAttente + utilisateurNbSessAttente);

        if(ficheEnAttente || ficheIncomplete) setShowInfoFiche(true);

        document.title = "Accueil";
    }, []);

    var msgVolume = '';
    if(nbFicheTotalIncompletes == 1)  msgVolume += '1 fiche incomplète';
    else if(nbFicheTotalIncompletes > 1)  msgVolume += nbFicheTotalIncompletes + ' fiches incomplètes';
    if(nbFicheTotalAttente > 0  && nbFicheTotalIncompletes > 0) msgVolume += ' et ';
    if(nbFicheTotalAttente == 1)  msgVolume += '1 fiche en attente';
    else if(nbFicheTotalAttente > 1)  msgVolume += nbFicheTotalAttente + ' en attente';
    if(msgVolume != '') msgVolume = 'Vous avez ' + msgVolume + '.';
    
    var msgComplement = (nbFicheTotalIncompletes + nbFicheTotalAttente > 1 ) ? 'ces dernières puissent' : 'cette dernière puisse';
    return (
        <>
            <div style={{padding: "10px"}}>
                <h1 style={{lineHeight: "24px", color: "#999999", textAlign: "center", padding: "25px 0 35px"}}>Bienvenue dans votre espace réservé</h1>
                <p>Si vous êtes un organisme de formation déclaré en région Provence Alpes Côte d’Azur, auprès du Service Régional de Contrôle de la DREETS PACA, un espace réservé vous permet de mettre à jour les informations concernant votre organisme de formation et d’enregistrer l’ensemble des actions de formation que vous proposez.</p>

                <p>Vous pouvez mettre à jour les informations concernant votre organisme, vos formations et les actions et sessions qui s’y rapportent ou en créer de nouvelles.</p>

                <p>Certaines actions de formation collectives financées par la Région et/ou Pôle Emploi sont déjà pré-remplies par le Carif-Oref. Vous ne pourrez compléter ou mettre à jour que certaines informations.</p>

                <p>Nous insistons sur l'importance de procéder régulièrement à la mise à jour de vos données, car elles sont consultées par le grand public, les prescripteurs (Pôle Emploi, Missions locales...) et les professionnels en charge des salariés et des demandeurs d'emploi.</p>
                <div>
                <br />Cette base de données est exportée et diffusée quotidiennement à Offre Info pour alimenter :
                <div style={{padding: "10px"}}>
                    <span style={{fontWeight: "bold", fontSize: "1.1rem"}}>Les applicatifs des prescripteurs de formation :</span>
                    <ul>
                        <li>•	France Travail : AUDE Formation, KAIROS</li>
                        <li>•	Missions locales, Cap emploi, Conseils départementaux : OUIFORM</li>
                    </ul>

                    <span style={{fontWeight: "bold", fontSize: "1.1rem"}}>Les applicatifs :</span>
                    <ul>
                        <li>•	Education nationale : Affelnet (collégiens), Parcours Sup</li>
                        <li>•	Le référentiel des parcours de formation réalisés par les salariés et demandeurs d’emploi : AGORA.</li>
                    </ul>

                    <span style={{fontWeight: "bold", fontSize: "1.1rem"}}>Les sites régionaux et nationaux :</span>
                        <ul>
                            <li>•	Le portail du CARIF OREF Provence Alpes Côte d’Azur : <a href="https://www.cariforef-provencealpescotedazur.fr" target="_blank">www.cariforef-provencealpescotedazur.fr</a></li>
                            <li>•	Le portail Orientation de la région Sud :  <a href="https://www.orientation-regionsud.fr" target="_blank">www.orientation-regionsud.fr</a></li>
                            <li>•	Le site internet Fenum Grande Provence : <a href="https://grandeprovence.fenum.net" target="_blank">grandeprovence.fenum.net</a> (formations dans le domaine du numérique sur les territoires de Vaucluse et Arles)</li>
                            <li>•	DATA SUD : <a href="https://www.datasud.fr" target="_blank">www.datasud.fr</a></li>
                            <li>•	Le portail de l’Association du réseau des CARIF OREF : <a href="https://www.intercariforef.org/" target="_blank">www.intercariforef.org</a></li>
                            <li>•	Catalogue des offres de formations en apprentissage : <a href="https://catalogue-apprentissage.intercariforef.org/" target="_blank">catalogue-apprentissage.intercariforef.org</a></li>


                            <li>•	Le portail Alternance de la DGEFP : <a href="https://www.alternance.emploi.gouv.fr" target="_blank">www.alternance.emploi.gouv.fr</a></li>
                            <li>•	La bonne formation : <a href="https://labonneformation.pole-emploi.fr" target="_blank">labonneformation.pole-emploi.fr</a></li>
                            <li>•	La bonne alternance : <a href="https://labonnealternance.apprentissage.beta.gouv.fr" target="_blank">labonnealternance.apprentissage.beta.gouv.fr</a></li> 
                            <li>•	1 jeune 1 solution : <a href="https://www.1jeune1solution.gouv.fr" target="_blank">www.1jeune1solution.gouv.fr</a></li>
                            <li>•	Le portail de Centre Inffo : <a href="https://www.orientation-pour-tous.fr" target="_blank">www.orientation-pour-tous</a></li>
                    </ul>
                </div>
            </div>

            <p>Les informations contenues dans la Banque Régionale Offre de Formation relèvent de la responsabilité des organismes de formation qui les saisissent.</p>

            </div>


            {showInfoFiche &&
            <Modal size="lg" show={showInfoFiche} onHide={handleCloseModal} backdrop="static" keyboard={false} centered aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                ⚠️ Action requise ⚠️
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {msgVolume}
                    <br />
                Il est fortement recommandé de faire le nécessaire afin que <>{msgComplement}</> par exemple apparaitre sur le site.<br />
                Souhaitez vous vous rendre sur la liste de ces fiches dès à présent pour les traiter ?
                </div>
                <div className="partie-bouton-crea">
                    <ButtonDeBase typeSubmit={false} onClick={() =>handleClickGotoPage()} lib="👌 Ok, allons y " />
                    <ButtonDeBase typeSubmit={false} onClick={() =>handleCloseModal()} lib="Je verrai plus tard..." className="fas fa-ban sd" />
                </div>
            </Modal.Body>
            </Modal>
            }
        </>
    )

}

export default Accueil;