export async function apiQuiformeCompanieModif(utilisateurBearer, datasToPost, idCompanie) {
    if (utilisateurBearer == "") return objRetourInvalide;

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(datasToPost)
    };

    var urlApi = global.config.apiBase.url + "/organismes/" + idCompanie;
    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi}`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        return objRetour;
    } catch (error_1) {
        console.log('error apiActModif', error_1);
        return objRetour;
    }
}

//method= PUT ou DELETE, permet également de définir un principale (POST dans ce cas)
export async function apiQuiformeCompanieMasqueIntrouvable(utilisateurBearer, quiforme_SysId, method='PUT', routeSuppl="") {
    if (utilisateurBearer == "" || quiforme_SysId == "" || method == "") return objRetourInvalide;

    const requestOptions = {
        method: method,
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiBase.url + "/quiforme/introuvables/" + quiforme_SysId + routeSuppl;
    if(global.config.modeDebugApi == 1) console.log('appel ' + urlApi + ' avec methode ' + method);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200 && response.status != 400) return objRetour;  // 400 : un message personnalisé est retourné dans ce cas, ex : "La fiche certif info est déjà associée"

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiQuiformeCompanieMasqueIntrouvable', error_1);
        return objRetour;
    }
}