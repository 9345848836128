import '../config.js';
import { apiCertifAppelBase } from '../Datas/dataDiplomes.js'

var objRetourInvalide = {"statusCode": 400, "dataApi": [] }


export async function apiPostFormulaire1(utilisateurBearer, datasToPost) {
    if (utilisateurBearer == "") return objRetourInvalide;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json' /*,
            data: JSON.stringify(datasToPost) // {username, password}*/
        },
        body: JSON.stringify(datasToPost) // https://www.techomoro.com/submit-a-form-data-to-rest-api-in-a-react-app/
    };

    var urlApi = global.config.apiFormations.url + 'RecordEtat';
    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi}`);
    
    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200) return objRetour;
    
        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiPostFormulaire1', error_1);
        return objRetour;
    }
}


export async function apiGetSetInfosObjetsFormActSess(utilisateurBearer, formationNumber, actionNumber, sessionNumber) {
    if (utilisateurBearer == "" || formationNumber == "") return objRetourInvalide;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apigestioninfosobjetsformactsess.url  + "/" + formationNumber;
    if(!isNaN(actionNumber) && actionNumber != null) urlApi += "/" + actionNumber;
    if(!isNaN(sessionNumber) && sessionNumber != null) urlApi += "/" + sessionNumber;
    if(global.config.modeDebugApi == 1) console.log(`appel apiGetSetInfosObjetsFormActSess :  ${urlApi}`);
    
    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200 && response.status != 400) return objRetour;

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiGetSetInfosObjetsFormActSess', error_1);
        return objRetour;
    }
}

// récupère nbFoTot, nbFoRejetLheo, nbFoRejetCarif, nbActRejetLheo, nbActRejetCarif, nbSessRejetLheo, nbSessRejetCarif
export async function apiVolumesObjetsLheo(utilisateurBearer) {
    if (utilisateurBearer == "") return objRetourInvalide;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiVolumesObjetsLheo.url;
    if(global.config.modeDebugApi == 1) console.log(`appel apiVolumesObjetsLheo ${urlApi}`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200) return objRetour;

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiVolumesObjetsLheo', error_1);
        return objRetour;
    }
}


export async function apiGetProprietes(utilisateurBearer, formationNumber  = null, actionNumber = null, sessionNumber = null) {
    if (utilisateurBearer == "" || formationNumber == "") return objRetourInvalide;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiGetProprietes.url;
    if(formationNumber != null && !isNaN(formationNumber)) urlApi += "/" + formationNumber;
    if(actionNumber != null && !isNaN(actionNumber)) urlApi += "/" + actionNumber;
    if(sessionNumber != null && !isNaN(sessionNumber)) urlApi += "/" + sessionNumber;
    if(global.config.modeDebugApi == 1) console.log(`appel apiGetProprietes :  ${urlApi}`);
    
    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200) return objRetour;

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiGetProprietes', error_1);
        return objRetour;
    }
}

//idem apiGetProprietes mais sans bearer pour l'inscription
export async function apiGetProprietesFree() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiGetProprietesFree.url;

    if(global.config.modeDebugApi == 1) console.log(`appel apiGetProprietesFree :  ${urlApi}`);
    
    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200) return objRetour;

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiGetProprietesNoAuth', error_1);
        return objRetour;
    }
}

//  utlisé pour appeler divers api GET (fonction la plus commune, générique)
export async function apiRecupereInfoGet(utilisateurBearer, suiteUrl) {
    if (suiteUrl == "") return objRetourInvalide;
    var requestOptions =  null;
    if (utilisateurBearer == "" )  {
        requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        };
    } else {
        requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${utilisateurBearer}`,
                'Content-Type': 'application/json'
            },
        };
    }

    var urlApi = global.config.apiBase.url + "/" + suiteUrl;
    //if(global.config.modeDebugApi == 1) console.log(`appel apiRecupereInfoGet :  ${urlApi}`);
    
    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        //if (response.status != 200) return objRetour; // remplacé par ligne ci dessous le 2011 pour avoir message perso dans listes des organismes pro
        if (response.status != 200 && response.status != 400) return objRetour;  // 400 : un message personnalisé est retourné dans ce cas, ex : "La fiche certif info est déjà associée"

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiRecupereInfoGet', error_1);
        return objRetour;
    }
}

// pourrait faire l'objet d'une fonction commune, générique pour le POST
export async function apiSoumissionFormulaireDemande(utilisateurBearer, datasToPost, routeSuppl) { 
    var requestOptions =  null;
    if (utilisateurBearer == "" )  {
        requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(datasToPost)
        };
    } else {
        requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${utilisateurBearer}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(datasToPost)
        };
    }


    var urlApi = global.config.apiBase.url + "/" + routeSuppl;
    if(global.config.modeDebugApi == 1) console.log(`appel POST ${urlApi} dans apiSoumissionFormulaireDemande`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status;
        if (response.status != 200) return objRetour;
    
        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiSoumissionFormulaireDemande', error_1);
        return objRetour;
    }
}


export const checkLapsTraitementNocturne = (setEnMaintenance, setMsgMaintenance) => { //une fct aurait tout aussi bien marché
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const isCrenauInterditBetween22And4 = currentHour >= 22 || currentHour < 7;

    if(isCrenauInterditBetween22And4 == 1) {
        setEnMaintenance(true);
        setMsgMaintenance("Le site est en maintenance jusqu'à 07 heure du matin.<br /><br />Si ce message persiste, veuillez contacter le support à cette adresse :<br />eo-technique@cariforef.fr");
    }
    else {
        setEnMaintenance(false);
        setMsgMaintenance("");
    }
    if(global.config.modeDebugApp == 1)  console.log('check noct : ', isCrenauInterditBetween22And4)
    return isCrenauInterditBetween22And4;
};

// pour vérifier si le créenau horaire est OK ET si le seveur de la base de données est opérationnel (select InMaintenanceE0 from ApplicationParam)
//si ce n'est pas le cas, la fct utilise les Set pour agir en conséquence
export async function verifMaintenance(setEnMaintenance, setMsgMaintenance ) {
    var isCrenauInterdit = checkLapsTraitementNocturne(setEnMaintenance, setMsgMaintenance)
    if(isCrenauInterdit) return false;

    var requestOptions =  null;
        requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        };

    var urlApi = global.config.apiBase.url + "/verifState";
  
  try {
        const response = await fetch(urlApi, requestOptions);    
        var dataApi = await response.json();

        if(global.config.modeDebugApp == 1) console.log(`('###################### retour verifMaintenance avec "VerifStat/"` , dataApi);
        switch (response.status) {
            case 200:  
                setEnMaintenance(false);     
                setMsgMaintenance("");               
                return true;
            default:
                setEnMaintenance(true);

                if(dataApi && dataApi.length > 0) setMsgMaintenance(dataApi);
                else setMsgMaintenance(global.config.msgPrTechnique.msgMaintenance);
                return false;
        }
    } catch (error_1) {
        setMsgMaintenance(global.config.msgPrTechnique.msgMaintenance);
        return false;
    }
}

export async function recupereNiveauxCI(utilisateurBearer) {
    const dataR = await apiCertifAppelBase(utilisateurBearer, 'niveaux');
    if(global.config.modeDebugApp == 1)  console.log('retour apiCertifAppelBase : ', dataR);
    switch (dataR.statusCode) {
        case 200:            
            return dataR.dataApi;
        default:
            if(global.config.modeDebugApp == 1) console.log("return null");
            return null;
    }
}