import PropTypes from 'prop-types'; // permet de déclarer le type des props qui est attendu lorsque vous les récupérez dans vos composants, et de déclencher un warning si ça ne correspond pas
// a voir plsu tard: https://react-bootstrap.github.io/components/alerts/
/**
 * Return un compoant affichant un message d'info
 *
 * @component
 * @summary importé dans les pages
 * @param {  }
 * @return { HTMLElement }
*/
function MessageInfoBox(props) {
    var nomClasse = (props.nomClasse == undefined || props.nomClasse == "") ? "message-info" : props.nomClasse;
    if(props.message !== undefined && props.message != null  && props.message != '') {
        //console.log(`props : ${props}`)
        return (        
            <div className={nomClasse} dangerouslySetInnerHTML={{  __html: props.message }}></div>
        )
    }
    //return (<></>)
}

MessageInfoBox.propTypes = {
    message: PropTypes.string,
    nomClasse: PropTypes.string
}

export default MessageInfoBox;

/**
 * Return un compoant affichant un message d'erreur
 *
 * @component
 * @summary importé dans les pages utilisant les api
 * @param {  }
 * @return { HTMLElement }
*/
export function MessageErreurApi(props) {
    //if(props.message !== undefined && props.message != null && props.message != '') {
        //return <div className="message-erreur-api">{props.message}</div>
        return (<MessageInfoBox nomClasse="message-erreur-api" message={props.message} />)
    //}
}

MessageErreurApi.propTypes = {
    message: PropTypes.string
}


/**
 * Return un compoant affichant un message d'info spécifique sur les droits
 *
 * @component
 * @summary importé dans les pages 
 * @param {  }
 * @return { HTMLElement }
*/
export function MessageInfoNoDroitErreurApi() {
    return (<MessageInfoBox message="Vous n'avez pas les droits pour intervenir sur ces informations." />)
}

MessageInfoNoDroitErreurApi.propTypes = {
    message: PropTypes.string
}


/**
 * Return un compoant affichant un message d'info sur les asterisques
 *
 * @component
 * @summary importé dans les pages 
 * @param {  }
 * @return { HTMLElement }
*/
export function MessageInfoChampsObligatoires() {
    return (<MessageInfoBox message="Les champs signalés d'un astérisque rouge <span style='font-size:15px; color: #d00;'>*</span> sont obligatoires." />)
}

MessageInfoChampsObligatoires.propTypes = {
    message: PropTypes.string
}
