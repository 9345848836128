import PropTypes from 'prop-types';
import "../SCSS/Spinner.scss";

//https://contactmentor.com/how-to-add-loading-spinner-react-js/

/**
 * Affiche un load spiner lorsque !props.dataLoaded
 *
 * @component
 * @summary importé dans les pages
 * @param {boolean} props.dataLoaded
 * @return { HTMLElement }
 * @example props.dataLoaded = false
*/
function LoadingSpinner(props) {
  var className = props.className != null ? props.className : "spinner-container";

  return (
    <>
    {!props.dataLoaded && 
    <div className={className}>
      
      {props.message !== undefined && props.message != null  && props.message != '' &&
          <div className="msg">{props.message}</div>
      }
      <div className="loader-spinner-container">
        <div className="loading-spinner"></div>
      </div>

    </div>}
    </>
  );
}

LoadingSpinner.propTypes = {
  dataLoaded: PropTypes.bool.isRequired,
  message: PropTypes.string,
  className : PropTypes.string,
}

export default LoadingSpinner;