var objRetourInvalide = {"statusCode": 400, "dataApi": [] }

//inspi apiPutActObjs en mieux, fait pour etre utilisé de façon générique : pourra remplacer apiFormAssocDissoProf et apiFormAssocDissoCertif a terme
export async function apiPutFormObjs(utilisateurBearer, what, formationNumber,  method='PUT', idObjet='', datasToPost=[]) {
    if (utilisateurBearer == "" ||  method == ""
        || (what != "formes-prst4" && what != "publics-prst4" && what != "secteurs-prst4" && what != "thematiques-prst4")
        || (idObjet == '' && datasToPost.length === 0)
    ) return objRetourInvalide;

    const requestOptions = {
        method: method,
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(datasToPost)
    };

    var urlApi = '';    
    if(datasToPost.length > 0) urlApi = global.config.apiFormationsBase.url  + '/' + formationNumber  + '/' + what + '/';
    else urlApi = global.config.apiFormationsBase.url  + '/' + formationNumber  + '/' + what + '/' + idObjet; // if(idObjet != '') 

    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi} avec méthode ' + ${method}`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200 && response.status != 400) return objRetour;

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiPutFormObjs', error_1);
        return objRetour;
    }
}

export async function apiFormAppelList(utilisateurBearer, routeSuppl="") {
    if (utilisateurBearer == "") return objRetourInvalide;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiFormationsBase.url + routeSuppl;
    if(global.config.modeDebugApi == 1) console.log(`appel apiFormAppelList ${urlApi}`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200 && response.status != 400) return objRetour;  // 400 : un message personnalisé est retourné dans ce cas, ex : "La fiche certif info est déjà associée"

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiFormAppelList', error_1);
        return objRetour;
    }
}

export async function apiFormAppelRecherche(utilisateurBearer, champRech) {
    if (utilisateurBearer == "" || champRech == "") return objRetourInvalide;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiFormationsRecherche.url + champRech; //+"?intitule-recherche=" + champRechFormIntit;
    if(global.config.modeDebugApi == 1) console.log(`appel apiFormAppelRecherche ${urlApi}`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200 && response.status != 400) return objRetour;  // 400 : un message personnalisé est retourné dans ce cas, ex : "La fiche certif info est déjà associée"

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiFormAppelRecherche', error_1);
        return objRetour;
    }
}

export async function  apiFormAjout(utilisateurBearer, typeFormCertifOupro='certif') { // certifou pro
    if (utilisateurBearer == "" || (typeFormCertifOupro != 'certif' && typeFormCertifOupro != 'pro')) return objRetourInvalide;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiFormationsBase.url + "/" + typeFormCertifOupro;
    if(global.config.modeDebugApi == 1) console.log(`appel POST ${urlApi}`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200) return objRetour;
    
        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiFormAjout', error_1);
        return objRetour;
    }
}

export async function apiFormModif(utilisateurBearer, datasToPost, formationNumber) {
    if (utilisateurBearer == "") return objRetourInvalide;

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
            //data: JSON.stringify(datasToPost)
        },
        body: JSON.stringify(datasToPost)
    };

    var urlApi = global.config.apiFormationsBase.url + "/" + formationNumber;
    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi}`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        //if (response.status != 200) return objRetour;    
        //objRetour.dataApi = await response.json(); si aucune retour de l'api ca génère un warning
        return objRetour;
    } catch (error_1) {
        console.log('error apiFormModif', error_1);
        return objRetour;
    }
}

//utiliser pour récupérer données de base sur la formation,
// ou ces liaisons avec par exemple  routeSuppl = "/certifications"
export async function apiFormGetInfosDiv(utilisateurBearer, formationNumber, routeSuppl="") {
    if (utilisateurBearer == "" || formationNumber == "") return objRetourInvalide;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiFormationsBase.url  + "/" + formationNumber + routeSuppl;
    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi}`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200) return objRetour;

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiFormGetInfosDiv', error_1);
        return objRetour;
    }
}

export async function apiFormSuppr(utilisateurBearer, formationNumber) {
    if (utilisateurBearer == "" || formationNumber == "") return objRetourInvalide;
    var objRetour = {statusCode : '', dataApi : ''};

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiFormationsBase.url  + "/" +  formationNumber;
    if(global.config.modeDebugApi == 1) console.log('appel ' + urlApi);

    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized, 204 NoContent si succès de la suppresion
        return objRetour;
    } catch (error_1) {
        console.log('error apiFormSuppr', error_1);
        return objRetour;
    }
}

//method= PUT ou DELETE, permet également de définir un principale (POST dans ce cas)
export async function apiFormAssocDissoCertif(utilisateurBearer, formationNumber, codeCI, method='PUT', routeSuppl="") {
    if (utilisateurBearer == "" || codeCI == "" || method == "") return objRetourInvalide;

    const requestOptions = {
        method: method,
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiFormationsBase.url  + "/" + formationNumber  + "/certifications/" + codeCI + routeSuppl;
    if(global.config.modeDebugApi == 1) console.log('appel ' + urlApi + ' avec methode ' + method);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200 && response.status != 400) return objRetour;  // 400 : un message personnalisé est retourné dans ce cas, ex : "La fiche certif info est déjà associée"

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiFormAssocDissoCertif', error_1);
        return objRetour;
    }
}

//method= PUT ou DELETE
export async function apiFormAssocDissoProf(utilisateurBearer, formationNumber, codeAassocier, method='PUT') {
    if (utilisateurBearer == "" || codeAassocier == "" || method == "") return objRetourInvalide;

    const requestOptions = {
        method: method,
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiFormationsBase.url  + "/" + formationNumber  + "/professionnalisants/" + codeAassocier;
    if(global.config.modeDebugApi == 1) console.log('appel ' + urlApi + 'e ' + method);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200 && response.status != 400) return objRetour;

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiFormAssocDissoProf', error_1);
        return objRetour;
    }
}


//method= PUT ou DELETE, permet également de définir un principale (POST dans ce cas)
//codeTypeContact n'est utile qu'enc as d'association
export async function apiFormAssocDissoContact(utilisateurBearer, formationNumber, codeContact, method='PUT', routeSuppl="", codeTypeContact) {
    if (utilisateurBearer == "" || codeContact == "" || method == "") return objRetourInvalide;

    const requestOptions = {
        method: method,
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiFormationsBase.url  + "/" + formationNumber  + "/contacts/" + codeContact + routeSuppl;
    if(codeTypeContact != undefined)  urlApi += "/" + codeTypeContact;
    if(global.config.modeDebugApi == 1) console.log('appel ' + urlApi + ' avec methode ' + method);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200 && response.status != 400) return objRetour;  // 400 : un message personnalisé est retourné dans ce cas, ex : "La fiche certif info est déjà associée"

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiFormAssocDissoContact', error_1);
        return objRetour;
    }
}