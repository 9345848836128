var objRetourInvalide = {"statusCode": 400, "dataApi": [] }

export async function apiCoordModifOuCrea(utilisateurBearer, datasToPost, identifiant=null) {
    if (utilisateurBearer == "") return objRetourInvalide;
    var methode = identifiant == null  ? 'POST' : 'PUT';

    const requestOptions = {
        method: methode,
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(datasToPost)
    };

    var urlApi = global.config.apiBase.url + "/coordonnees/";
    if(identifiant != null) var urlApi = urlApi + identifiant;
    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi} en methode ${methode}`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200) return objRetour;

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiCoordModifOuCrea', error_1);
        return objRetour;
    }
}

export async function apiAddrdModifOuCrea(utilisateurBearer, datasToPost, identifiant=null) {
    if (utilisateurBearer == "") return objRetourInvalide;
    var methode = identifiant == null  ? 'POST' : 'PUT';

    const requestOptions = {
        method: methode,
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(datasToPost)
    };

    var urlApi = global.config.apiBase.url + "/adresses/";
    if(identifiant != null) var urlApi = urlApi + identifiant;
    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi} en methode ${methode}`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200 && response.status != 400) return objRetour;

        if(methode == 'POST') objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiAddrdModifOuCrea', error_1);
        return objRetour;
    }
}


export async function apiPersonModifOuCrea(utilisateurBearer, datasToPost, identifiant=null) {
    if (utilisateurBearer == "") return objRetourInvalide;
    var methode = identifiant == null  ? 'POST' : 'PUT';

    const requestOptions = {
        method: methode,
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(datasToPost)
    };

    var urlApi = global.config.apiBase.url + "/persons/";
    if(identifiant != null) var urlApi = urlApi + identifiant;
    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi} en methode ${methode}`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200 && response.status != 400) return objRetour;

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiPersonModifOuCrea', error_1);
        return objRetour;
    }
}

export async function apiCoordSuppr(utilisateurBearer, codeCoordASuppr) {
    if (utilisateurBearer == "" || codeCoordASuppr == "") return objRetourInvalide;

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiBase.url + "/lieux/" + codeCoordASuppr+ "/delcoord";
    if(global.config.modeDebugApi == 1) console.log('appel ' + urlApi);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200) return objRetour;

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiCoordSuppr', error_1);
        return objRetour;
    }
}

export async function apiPcfSuppr(utilisateurBearer, code, type) {
    if (utilisateurBearer == "" || code == "") return objRetourInvalide;

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiBase.url + "/lieux/" + code+ "/" + type;
    if(global.config.modeDebugApi == 1) console.log('appel ' + urlApi);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200) return objRetour;

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiPcfSuppr', error_1);
        return objRetour;
    }
}

export async function apiPcfModifOuCrea(utilisateurBearer, datasToPost, identifiant=null) {
    if (utilisateurBearer == "") return objRetourInvalide;
    var methode = identifiant == null  ? 'POST' : 'PUT';

    const requestOptions = {
        method: methode,
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(datasToPost)
    };

    var urlApi = global.config.apiBase.url + "/lieux/";
    if(identifiant != null) var urlApi = urlApi + identifiant;
    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi} en methode ${methode}`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200) return objRetour;

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiPcfModifOuCrea', error_1);
        return objRetour;
    }
}

export async function apiPcAjouts(utilisateurBearer, tabIdsCoordsAenregistrer, identifiantPerson) {
    if (utilisateurBearer == "") return objRetourInvalide;

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(tabIdsCoordsAenregistrer)
    };

    var urlApi = global.config.apiBase.url + "/lieux/add-pcfs-person/" + identifiantPerson;
    if(global.config.modeDebugApi == 1) console.log(`appel PUT ${urlApi}`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200) return objRetour;

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiPcAjouts', error_1);
        return objRetour;
    }
}