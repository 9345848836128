import PropTypes from 'prop-types';
import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import { Container } from "react-bootstrap";
import {NavLink } from "react-router-dom";

import { DonneesUtilisateurContext } from '../../contexte.js';

import {  apiRecrutementGet, apiRecrutementModif, apiRecrutementAjout, apiRecrutementSuppr } from '../../Datas/dataSessionsRecrutement.js'
import { apiSessAppelGetInfosDiv } from '../../Datas/dataSessions.js'

import LiensPrecedentSuivant from '../../Components/LiensPrecedentSuivant.jsx';
import LoadingSpinner from "../../Components/LoadingSpinner";
import MessageInfoBox, {MessageErreurApi, MessageInfoNoDroitErreurApi, MessageInfoChampsObligatoires} from '../../Components/Messages.jsx';
import ButtonDeBase, {ButtonVoirFiche, ButtonSuppr } from '../../Components/ButtonsCustom.jsx';
import FormRHF from "../../Components/Form/Form";
import FormControl from "../../Components/Form/FormControl";
import FormSelect from "../../Components/Form/FormSelect"
import FormHidden from "../../Components/Form/FormHidden";;
import Table from '../../Components/Table.jsx';
import { dateDuJourYYYYmmdd, dateModifSmallDatTimeToAmericAvecSep,
        verifOrdreDateDebutFin, 
        verifOrdreHeureDebutFin, verifConformiteHeureHHhMM, 
        appelRecupInfoGet, validateDate, confirmationSiCLickSUrLien } from '../../fonctionsDiv.js';

/**
 * Return Formulaire étape 13 (recrutement)
 *
 * @component
 * @summary page étape 13
 * @param {  }
 * @return { HTMLElement }
*/
function FormEtape13(props) {
    let numEtape = 13;

    const formationNumber = props.formationNumber;
    const actionNumber = props.actionNumber;
    const sessionNumber = props.sessionNumber;

    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const [dataApi, setDataApi] = useState();
    const [dataApiDetailSess, setDataApiDetailSess] = useState();
    const [aumoinsUneModif, setAumoinsUneModif] = useState(false); // pour avertir l'utilisateur en cas de sortie sans  enregistrement

    const [dataLoaded, setDataLoaded] = useState(true);
    const [messageProbleme, setMessageProbleme] = useState();
    const [libBtAnnuler, setLibBtAnnuler] = useState("Annuler l'ajout");
    const [msgInfoF, setMsgInfoF] = useState();
    const [showMessageRDVi, setShowMessageRDVi] = useState(false);
    
    //const [adresseSelectionne, setAdresseSelectionne] = useState(false);

     const columns = useMemo(
      () => [
        {
          id:'listesRecrutements',
          hideHeader : false,
          trieDefautRp : 'LIB_LHEO_MODALITE_RECRUTEMENT',
          columns: [
            { 
              Header: 'Modalité de recrutement', 
                accessor: 'LIB_LHEO_MODALITE_RECRUTEMENT',
              },  
              { 
              Header: 'Début', 
                accessor: 'DEBUT',
              },    
              { 
              Header: 'Fin', 
                accessor: 'FIN',
              },
              { 
                Header: 'Hr début', 
                  accessor: 'HEURE_DEBUT',
                },    
                { 
                Header: 'Hr fin', 
                  accessor: 'HEURE_FIN',
                },
              {
                Header: 'Places', 
                accessor: 'NB_PLACES',
                id: "NB_PLACES_center",
              },
              {
                Header: () => <>A distance</>,
                  accessor: 'A_DISTANCE', 
                  id: "A_DISTANCE_center",
                  Cell: ({ row }) => (
                    <>{row.original.A_DISTANCE == true ? `Oui`: `Non`}</>
                  ),
              },
              {
                Header: 'Adresse', 
                accessor: 'adresse_concat',
              },
              {
                Header: 'Commentaire', 
                accessor: 'COMMENTAIRE',
              },
              {
                Header: () => <></>,
                  accessor: 'actions', 
                  id: "actions_center",
                  disableSortBy: true,
                  Cell: ({ row }) => (
                    <>
                      <NavLink title={`Voir la fiche`} to="" onClick={() => recupererInfosPrincipales(row.original.ID_ACTIONS_SESSIONS_PERIODE_RECRUTEMENT)}>
                            <ButtonVoirFiche className="fas fa-pen-to-square" />
                      </NavLink>

                      { (row.original.noEditablePLease != 1) ?
                        <NavLink title={`Supprimer ${row.original.ID_ACTIONS_SESSIONS_PERIODE_RECRUTEMENT}`} to="" onClick={() => handleClickSupprRecrutement(row.original.ID_ACTIONS_SESSIONS_PERIODE_RECRUTEMENT)}>
                          <ButtonSuppr />
                        </NavLink>
                      :
                        <ButtonSuppr disabled="disabled" />
                      }
                    </>
                  ),
              },
          ]
        },
      ],
      []
    )
    const handleClickSupprRecrutement  = async (codeAsupprimer) => {
      var confirmation = confirm("Etes vous sûr de vouloir supprimer ce recrutement ?");
      if(confirmation == true) {
        var dataR = await apiRecrutementSuppr(utilisateurBearer, sessionNumber, codeAsupprimer);
        if(global.config.modeDebugApp == 1) console.log('retour apiRecrutementSuppr : ', dataR);
        switch (dataR.statusCode) {
            case 204:
                //alert("Le recrutement a bien été supprimé");
                recupererListeCompleteRecrutements();
              break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
      }
    }

    async function recupererListeCompleteRecrutements() {
      const dataR = await apiSessAppelGetInfosDiv(utilisateurBearer, "/"+sessionNumber + "/recrutements"); // ?type-modalite=9  pour n'avoir que les infos collectives
      if(global.config.modeDebugApp == 1) console.log('retour apiSessAppelGetInfosDiv dans recupererListeCompleteRecrutements : ', dataR);
      switch (dataR.statusCode) {
          case 200:
              const tabDates = ["DEBUT", "FIN"]; // declaration tableau dates a modifier
              for (let i = 0; i<tabDates.length; i++) {
                if(dataR.dataApi[tabDates[i]] != null) dataR.dataApi[tabDates[i]] = dateModifSmallDatTimeToAmericAvecSep(dataR.dataApi[tabDates[i]]);
              }
              if(props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|other_dataEditable"].editable  == false) {
                //modification du contenu renvoyé par l'api (ajout d'une information)
                let nouveauTabRep = [];
                dataR.dataApi.forEach((el) => {
                    // on va ajouter une notion de non modifiable
                    let ligne = { ...el, 'noEditablePLease': 1}
                    nouveauTabRep.push(ligne);           
                });
                //console.log("dataApi modifié:", nouveauTabRep);
                setDataApi(nouveauTabRep);
              }
              else setDataApi(dataR.dataApi);
              //console.log("dataApi", dataR.dataApi)

              var objaAmodif = JSON.parse(JSON.stringify(props.infosGenFormation)); // on clone (duplique) l'objet pour que tout fonctionne bien
              objaAmodif.nbRecrutements = dataR.dataApi.length;
              //objaAmodif["etape13"] = "ok";
              props.SetInfosGenFormation(objaAmodif);

              return true;
            break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
            break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        return false;
      }
    }


    const [adresseVisible, setAdresseVisible] = useState(false);
    const [dateFinVisible, setDateFinVisible] = useState(false);
    const [heuresVisible, setHeuresVisible] = useState(false);

    //pour effectuer des actions en fonction de la saisie de l'utilisateur
    function gestionEvenements(value, name){
      var ob = document.getElementById(name);
      setMessageProbleme(null); // modif 10723 pour que l'éventuelle message précédent ne s'affiche plus su l'utilsateur a modifié sa saisie
      setAumoinsUneModif(true); document.getElementById("hidden_marque_une_modif").value = 1;
      if((name == "HEURE_DEBUT" || name == "HEURE_FIN") ) {        
        if(value[name].length < 6) ob.classList.remove("alert-input");
        if(value[name].length > 4) {
          let ret = verifConformiteHeureHHhMM(value[name]); //verifie si le format et ok, les 2d et 3eme paramètres permettent de préciser des butées
          if(ret == false) { 
            ob.classList.add("alert-input");
          }
        }
      }

      if(name == "NB_PLACES") {    
         // inscription sur candidature ISC (7)
        if(value["ID_LHEO_MODALITE_RECRUTEMENT"] == 7 && (value[name] < 1  || value[name] > 999)) ob.classList.add("alert-input");
        // RDVi (8) ou une information collective (9) : si le recrutement n'est pas a distance, l'adresse est obligatoire
        else if((value["ID_LHEO_MODALITE_RECRUTEMENT"] == 8 || value["ID_LHEO_MODALITE_RECRUTEMENT"] == 9) && (value[name] < 1  || value[name] > 299)) ob.classList.add("alert-input");
        else ob.classList.remove("alert-input");
      }

      // si c'est un RDVi (8) ou une information collective (9) : si le recrutement n'est pas a distance, l'adresse est obligatoire
      if(name == "ID_PCF_ADRESSE" && (value["ID_LHEO_MODALITE_RECRUTEMENT"] == 8 || value["ID_LHEO_MODALITE_RECRUTEMENT"] == 9)) {
        if(value[name] != null && value['A_DISTANCE'] != 1) {
          ob.classList.remove("alert-input");
        } else ob.classList.add("alert-input");// normalement inutile puisque ce champ sera masqué si a distance
      }

      if(name == "ID_LHEO_MODALITE_RECRUTEMENT") {  
        setShowMessageRDVi(false); 
        setDateFinVisible(true);  setHeuresVisible(true); setAdresseVisible(false);//interessant de le mettre ici car permet de bien recharger les donnéees en fonction des obligations
        props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|DEBUT"].lib_form = 'Début de la période';

          // propriétés obligatoires uniquement pour inscription sur candidature ISC (7), RDVi (8) ou une information collective (9)
          if(value[name] == 7 || value[name] == 8 || value[name] == 9) {
            //idem dans ajoutRecrutement  
            if(value[name] == 8 || value[name] == 9) { // si c'est un RDVi (8) ou une information collective (9) alors inutile d'afficher la date de fin (toujours sur un jour)
              setDateFinVisible(false);
              props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|DEBUT"].lib_form = 'Date de l\'évènement';
            }
            // else {
            //  setDateFinVisible(true);
            //  props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|DEBUT"].lib_form = 'Début de la période';
            //}
            if(value[name] == 7) { // si c'est une inscription sur candidature ISC (7) alors il ne faut pas afficher les heures ni la proposition d'adresse
              setHeuresVisible(false);
              setAdresseVisible(false);
            }
            else {
              if(value["A_DISTANCE"] == 1) setAdresseVisible(false);
              else setAdresseVisible(true);
            }

            if(global.config.modeDebugApp == 1)  console.log (`NB_PLACES DEBUT et FIN placés required`);
            props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|NB_PLACES"].required = 1;
            props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|DEBUT"].required = 1;
            props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|FIN"].required = 1;

            if(value[name] == 8 || value[name] == 9) {
              if(global.config.modeDebugApp == 1)  console.log (`HEURE_DEBUT et HEURE_FIN placés required`);
              props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|HEURE_DEBUT"].required = 1;
              props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|HEURE_FIN"].required = 1;              
            }
          }
          else {
            if(global.config.modeDebugApp == 1)  console.log (`champs obligatoires desactivés`);
            props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|NB_PLACES"].required = false;
            props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|DEBUT"].required = false;
            props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|FIN"].required = false;
            props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|HEURE_DEBUT"].required = false;
            props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|HEURE_FIN"].required = false;   
          }
          
          if(value[name] == 8) setShowMessageRDVi(true);

      }

      if(name == "A_DISTANCE") {
        if(value["ID_LHEO_MODALITE_RECRUTEMENT"] == 7) setAdresseVisible(false); // si c'est une inscription sur candidature ISC (7) alors il ne faut pas afficher la proposition d'adresse
        else {
          if(value[name] == 1) {
            setAdresseVisible(false); //var obAddr = document.getElementById("ID_PCF_ADRESSE");... obAddr.style.display = "none"; // marche pas bien comme l'input est dans un div qui n'a pas d'id...
          } else setAdresseVisible(true);
        }
      }

      if(global.config.modeDebugApp == 1) console.log (`modif de ${name} : ${value[name]}`);
    }


    const tabDatesAgerer = ["DEBUT", "FIN"]; // declaration tableau dates a modifier 
    async function recupererInfosPrincipales(recrutementNumber) {
      setShowForm(false); //important sinon bug quand on passe directement d'un recrutement à l'autre

      const dataR = await apiRecrutementGet(utilisateurBearer, sessionNumber, recrutementNumber);
      if(global.config.modeDebugApp == 1) console.log('retour apiRecrutementGet dans recupererInfosPrincipales : ', dataR);
      switch (dataR.statusCode) {
        case 200:
          // formatage des dates comme attendu par l'api
          for (let i = 0; i<tabDatesAgerer.length; i++) {
            if(dataR.dataApi[tabDatesAgerer[i]] != null) dataR.dataApi[tabDatesAgerer[i]] = dateModifSmallDatTimeToAmericAvecSep(dataR.dataApi[tabDatesAgerer[i]]);
          }

          // propriétés obligatoires uniquement pour inscription sur candidature ISC (7), RDVi (8) ou une information collective (9)
          if(dataR.dataApi['ID_LHEO_MODALITE_RECRUTEMENT'] == 7 || dataR.dataApi['ID_LHEO_MODALITE_RECRUTEMENT'] == 8 || dataR.dataApi['ID_LHEO_MODALITE_RECRUTEMENT'] == 9) {
            props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|NB_PLACES"].required = 1;
            props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|DEBUT"].required = 1;
            props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|FIN"].required = 1;
          }

          // si c'est un RDVi (8) ou une information collective (9) alors inutile d'afficher la date de fin (toujours sur un jour)
          // sont obligatoire en plus : les heures
          if(dataR.dataApi['ID_LHEO_MODALITE_RECRUTEMENT'] == 8 || dataR.dataApi['ID_LHEO_MODALITE_RECRUTEMENT'] == 9) {
              setDateFinVisible(false);
              props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|DEBUT"].lib_form = 'Date de l\'évènement';

              props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|HEURE_DEBUT"].required = 1;
              props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|HEURE_FIN"].required = 1;
              if(dataR.dataApi['ID_LHEO_MODALITE_RECRUTEMENT'] == 8) setShowMessageRDVi(true);
          } else {
            setDateFinVisible(true);
            props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|DEBUT"].lib_form = 'Début de la période';
          }
          if(dataR.dataApi['ID_LHEO_MODALITE_RECRUTEMENT'] == 7) {
            setHeuresVisible(false);
          } else {
            setHeuresVisible(true);
          }
          props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|ID_LHEO_MODALITE_RECRUTEMENT"].editable = 0; // comme le veut l'api, on ne peut changer de type de modalité de recrutement en modif

    
          if(dataR.dataApi['A_DISTANCE'] == 1) {
              setAdresseVisible(false);
          } else setAdresseVisible(true);

          // gestion des champs bouléens oui/non qui doivent avoir true ou false (alimentés par optsOuiNon)
          dataR.dataApi['A_DISTANCE'] = (dataR.dataApi['A_DISTANCE'] == true) ? "1" : "0"; 

          setDataApiDetailSess(dataR.dataApi);
          //console.log("dataApi", dataR.dataApi)
          setLibBtAnnuler("Annuler la modification");
          setShowForm(true); 
          return true;
        case 401:
          setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
          break;
        default:
          setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
      return false;
    }

    const onSubmit = async (values) => {
      //var ob = document.getElementById("ID_PCF_ADRESSE"); ok marche

      /* plus utile, forcage a l'enregistrement
      if(values.ID_PCF_ADRESSE != "" && values.A_DISTANCE == true){
        alert("Vous ne pouvez pas à la fois indiquer que l'évenement se déroule à distance et indiquer une adresse.")
        return false;
      }*/
      var dateMin = '1990-01-01';
      var dateMax = '2079-06-06';
      var testdateOk = 1;
      
      var heureButeMin = ''; // geston des heures
      var heureButeMax = '';

      tabDatesAgerer.forEach((el) => {
        if(document.getElementById(el) != null && values[el] != ""  && values[el] != null ) {          
          if(global.config.modeDebugApp == 1) console.log("date qui va être vérifiée : ", values[el]);
          if(testdateOk == 1) {
            if(values[el] >  dateMax) { // la comparaison est ok même avec les tirets
              alert("La date ne doit pas être supérieure au 06/06/2079");
              testdateOk = 0;
            }
            else if(testdateOk == 1 && values[el] < dateMin) {
              alert("La date est trop ancienne, elle doit être supérieure au 01/01/1990");
              testdateOk = 0;
            }
            else if(testdateOk == 1 && validateDate(values[el]) == false) {
              alert("La date est incorrecte");
              testdateOk = 0;
            }

            if(testdateOk == 0){
              document.getElementById(el).focus();
              return false;
            }
          }
        }
      });
      if(testdateOk == 0) return false;

      // gestion des champs bouléens oui/non qui doivent avoir true ou false (alimentés par optsOuiNon)
      values.A_DISTANCE = (values.A_DISTANCE == "1") ? true : false; 

      if(values.A_DISTANCE == true && values.ID_PCF_ADRESSE != null) {
         values.ID_PCF_ADRESSE = null
      }

      // inscription sur candidature ISC (7)
      if(values.ID_LHEO_MODALITE_RECRUTEMENT == 7) {
        if(values["NB_PLACES"] < 1 || values["NB_PLACES"] > 999) { 
          var ob = document.getElementById("NB_PLACES");
          ob.classList.add("alert-input");
          alert ("Vous devez saisir un nombre de place entre 1 et 999");
          return false;
        }
      }
      // RDVi (8) ou une information collective (9)
      else if(values.ID_LHEO_MODALITE_RECRUTEMENT == 8 || values.ID_LHEO_MODALITE_RECRUTEMENT == 9) {
        if(values["NB_PLACES"] < 1 || values["NB_PLACES"] > 299) { 
          var ob = document.getElementById("NB_PLACES");
          ob.classList.add("alert-input");
          alert ("Vous devez saisir un nombre de place entre 1 et 299");
          return false;
        }
      }
      // si c'est un RDVi (8) ou une information collective (9) 
      // alors date de fin = date de début (toujours sur un jour)
      // la date doit être supérieure ou égale à la date du jour
      // le nombre de place  doit ete compris entre 1 et 299
      // il y a des tranches horaire 7h 20h à respecter
      if(values.ID_LHEO_MODALITE_RECRUTEMENT == 8 || values.ID_LHEO_MODALITE_RECRUTEMENT == 9) {
        values["FIN"] = values["DEBUT"];

        // la date doit être supérieure ou égale à la date du jour
        if(values["DEBUT"] != null) { // format dans : DEBUT 2023-06-22
          // la date doit être supérieure ou égale à la date du jour
          if(values["DEBUT"] < dateDuJourYYYYmmdd()) { 
            alert("La date doit être supérieure ou égale à la date du jour.");
            var ob = document.getElementById("DEBUT");
            ob.focus();
            return false;
          }
        }
        heureButeMin = 7; // tranches horaire 7h 20h à respecter
        heureButeMax = 20;

        // si c'est un RDVi (8) ou une information collective (9) : si le recrutement n'est pas a distance, l'adresse est obligatoire
        if(values.A_DISTANCE == false && (values.ID_PCF_ADRESSE == null || values.ID_PCF_ADRESSE == '')) {
          var ob = document.getElementById("ID_PCF_ADRESSE");
          ob.classList.add("alert-input");
          alert ("Vous devez préciser une adresse");
          return false;
        }
      } //if(values.ID_LHEO_MODALITE_RECRUTEMENT == 8 || values.ID_LHEO_MODALITE_RECRUTEMENT == 9)

      // inscription sur candidature ISC (7), RDVi (8) ou une information collective (9)
      // La date doit être antérieure à la date de fin de la session
      if(values.ID_LHEO_MODALITE_RECRUTEMENT == 7 || values.ID_LHEO_MODALITE_RECRUTEMENT == 8 || values.ID_LHEO_MODALITE_RECRUTEMENT == 9) {
        // la date doit être antérieure à la date de FIN, format dans : DEBUT 2023-06-22
        if(values["DEBUT"] != null && 
        props.infosGenFormation.sess_FIN_ymd != null && props.infosGenFormation.sess_FIN_fr != null) { 
          if(values["DEBUT"] >= props.infosGenFormation.sess_FIN_ymd) { 
            if(global.config.modeDebugApp == 1) console.log("DEBUT ", values["DEBUT"]);
            if(global.config.modeDebugApp == 1) console.log("sess_FIN_fr ", values["props.infosGenFormation.sess_FIN_fr"]);
            alert("La date de début doit être antérieure à la date de fin de la session qui est le " + props.infosGenFormation.sess_FIN_fr);
            var ob = document.getElementById("DEBUT");
            ob.focus();
            return false;
          }
        }
        if(values["FIN"] != null && 
        props.infosGenFormation.sess_FIN_ymd != null && props.infosGenFormation.sess_FIN_fr != null) { 
          if(values["FIN"] >= props.infosGenFormation.sess_FIN_ymd) { 
            if(global.config.modeDebugApp == 1) console.log("FIN ", values["FIN"]);
            if(global.config.modeDebugApp == 1) console.log("sess_FIN_fr ", values["props.infosGenFormation.sess_FIN_fr"]);
            alert("La date de fin doit être antérieure à la date de fin de la session qui est le " + props.infosGenFormation.sess_FIN_fr);
            var ob = document.getElementById("FIN");
            ob.focus();
            return false;
          }
        }
      }

      if(values.DEBUT != null && values.FIN != null) { // attention à placer après values["FIN"] = values["DEBUT"];
        var ret = verifOrdreDateDebutFin(values["DEBUT"], values["FIN"], "La date de fin doit être supérieure à celle début");
        if(ret == false) { 
          var ob = document.getElementById("DEBUT");
          ob.classList.add("alert-input");
          return false;
        }

        // si sur une journée on vérifie la cohérence des heures
        //(si c'est un RDVi (8) ou une information collective (9) l'heure de début doit être inferieure à l'heure de fin (la date étant sur une journée))
        if(values.DEBUT == values.FIN) { 
          var ret = verifOrdreHeureDebutFin(values["HEURE_DEBUT"], values["HEURE_FIN"]);
          if(ret == false) { 
            var ob = document.getElementById("HEURE_DEBUT");
            ob.classList.add("alert-input");
            return false;
          }
        }
      }

      var deuxHrPresentes = false;
      if(values.HEURE_DEBUT != null && values.HEURE_DEBUT.length > 0) {       
        let ret = verifConformiteHeureHHhMM(values.HEURE_DEBUT, heureButeMin, heureButeMax); //verifie si le format est ok, les 2d et 3eme paramètres permettent de préciser des butées
        if(ret == false) { 
          var ob = document.getElementById("HEURE_DEBUT");
          ob.classList.add("alert-input");
          return false;
        }
        deuxHrPresentes = true;
      }
      if(values.HEURE_FIN != null && values.HEURE_FIN.length > 0) {        
        let ret = verifConformiteHeureHHhMM(values.HEURE_FIN, heureButeMin, heureButeMax);
        if(ret == false) { 
          var ob = document.getElementById("HEURE_FIN");
          ob.classList.add("alert-input");
          return false;
        }
        deuxHrPresentes = true;
      }

      // si c'est un RDVi (8) ou une information collective (9) 
      // les heures sont obligatoires
      if(values.ID_LHEO_MODALITE_RECRUTEMENT == 8 || values.ID_LHEO_MODALITE_RECRUTEMENT == 9) {
        if(deuxHrPresentes == false) {
          var ob = document.getElementById("HEURE_DEBUT");
          ob.classList.add("alert-input");
          alert ("Vous devez saisir des heures");
          return false;
        }
      }

      if(values.URLWEB != null && values.URLWEB.length > 4 && values.URLWEB.substring(0,8) != 'https://') {
        var ret = confirm(`La plupart des urls commencent maintenant par https://.\r\nVous confirmez que votre site n'est pas accessible avec une telle url ?`);
        if (ret == false) {
          alert(`Parfait, veuillez donc modifier l'url\r\n${values.URLWEB}`);
          var ob = document.getElementById("URLWEB");
          ob.focus();
          return false;
        }
      }

      
      for(let i in values) if(values[i] === "") values[i] = null; // sinon génère une erreur : on met à null tout les champs vide (ract-hook-form semble le faire pour nous dans le cas ou on lui passe les valeurs par défaut en entré)

      if(global.config.modeDebugApp == 1) console.log("Values:::", values);
      if(global.config.modeDebugApp == 1) console.log("Values:::", JSON.stringify(values));
      
      setAumoinsUneModif(false); // pour éviter double enregistrement au double clique
      document.getElementById("hidden_marque_une_modif").value = 0;
      
      // création ou modification
      if(dataApiDetailSess == null || dataApiDetailSess.ID_ACTIONS_SESSIONS_PERIODE_RECRUTEMENT == null) { //2d tst important comme c'est l'api qui pré rempli l'objet
        var dataR = await apiRecrutementAjout(utilisateurBearer, values, sessionNumber) // on envoie sans faire JSON.stringify
        if(global.config.modeDebugApp == 1) console.log('retour apiRecrutementAjout : ', dataR);
      }
      else {
        var dataR = await apiRecrutementModif(utilisateurBearer, values, sessionNumber, dataApiDetailSess.ID_ACTIONS_SESSIONS_PERIODE_RECRUTEMENT)
        if(global.config.modeDebugApp == 1) console.log('retour apiRecrutementModif : ', dataR);
      }

      switch (dataR.statusCode) {
          case 200: 
               alert("L'enregistrement s'est bien effectué.");
               await recupererListeCompleteRecrutements();
               annulerChangements();
              break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
          case 400:
                setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas
              break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
    };
  
    function annulerChangements () { //si clique sur bouton annuler ou après enregistrement
      setMessageProbleme(null);
      setAumoinsUneModif(false);
      setShowForm(false);
      setDataApiDetailSess(null);
      setDateFinVisible(true);
      setHeuresVisible(true);
      props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|DEBUT"].lib_form = 'Début de la période';
      props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|ID_LHEO_MODALITE_RECRUTEMENT"].editable = 1; 
      props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|HEURE_DEBUT"].required = 0;
      props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|HEURE_FIN"].required = 0;
      props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|NB_PLACES"].required = 0;
      props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|DEBUT"].required = 0;
      props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|FIN"].required = 0;

      setLibBtAnnuler("Annuler l'ajout");
      setShowMessageRDVi(false);
    }
    
    const onError = (error) => {
      console.log("ERROR:::", error);
    };
    /// eslint-disable-next-line no-unused-vars


    const [dataLHEO_MODALITE_RECRUTEMENT, SetDataLHEO_MODALITE_RECRUTEMENT] = useState(); // pour recup données du select
    const [dataLHEO_PERIMETRE_RECRUTEMENT, SetDataLHEO_PERIMETRE_RECRUTEMENT] = useState(); // pour recup données du select
    const [adressesPourChoix, SetAdressesPourChoix] = useState(); // pour recup données du select
    async function chargementsConsecutifs() {
      setDataLoaded(false);
      let retP = await recupererListeCompleteRecrutements();

      // si on ne voulait pas charger les données pour les select :if(retP == true && props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|other_dataEditable"].editable != 0) 
      if(retP == true) {
        var pb = false;
        var ret = await appelRecupInfoGet(utilisateurBearer, "LHEO_MODALITE_RECRUTEMENT", setMessageProbleme);
        if(ret != null) SetDataLHEO_MODALITE_RECRUTEMENT(ret); else pb = true;

        var ret = await appelRecupInfoGet(utilisateurBearer, "LHEO_PERIMETRE_RECRUTEMENT", setMessageProbleme);
        if(ret != null) SetDataLHEO_PERIMETRE_RECRUTEMENT(ret); else pb = true;

        var ret = await appelRecupInfoGet(utilisateurBearer, "lieux/lieux-formateurs-action/" + actionNumber, setMessageProbleme);// recuperation des adresses pour liste select
        if(ret != null) SetAdressesPourChoix(ret); else pb = true;

        if(pb == true ) setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
      setDataLoaded(true);
    }

    useEffect(() => {
        if(global.config.modeDebugApp == 1) console.log('useffect FormEtape13');
        document.title = `Recrutement(s) de la session ${sessionNumber}`;
        setMessageProbleme(null);
        chargementsConsecutifs();

        var links = document.querySelectorAll('a');
         
        // Parcours des liens pour ajouter gestionnaire d'événement au clic
        for (var i = 0; i < links.length; i++) {
          links[i].addEventListener('click', confirmationSiCLickSUrLien);
        }
        // Nettoyage de l'écouteur d'événement lorsque le composant est démonté
        return () => {      
          for (var i = 0; i < links.length; i++) {
            links[i].removeEventListener('click', confirmationSiCLickSUrLien);
          }
        };
    }, []);

    useEffect(() => {
      setAumoinsUneModif(false);
      //setMsgInfoF(props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|other_recrutement_info_1"].aide); // pour information dynamique
    }, [dataApi]);
    
   /*const handleChoixAdresse = async (codeAasso) => {
      setAdresseSelectionne(codeAasso);
    }*/

    /*function ajoutRecrutement(Idrecrutement){
      var dataApi = new Object();
      dataApi['ID_LHEO_MODALITE_RECRUTEMENT'] = Idrecrutement;

      //idem dans gestionEvenements
      if(Idrecrutement == 8 || Idrecrutement == 9) { // si c'est un RDVi (8) ou une information collective (9) alors inutile d'afficher la date de fin (toujours sur un jour)
        setDateFinVisible(false);
        props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|DEBUT"].lib_form = 'Date de l\'évènement';
        if(Idrecrutement == 8) setShowMessageRDVi(true);
      } else {
        setDateFinVisible(true);
        props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|DEBUT"].lib_form = 'Début de la période';
      }

      setDataApiDetailSess(dataApi);
      setShowForm(true);
    }*/

    const [showForm, setShowForm] = useState(false);
    return (
      <>
        <LoadingSpinner dataLoaded={dataLoaded}/>
        <MessageErreurApi message={messageProbleme} />
        { props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|other_dataEditable"].editable == 1 ?
          <MessageInfoBox message="Vous pouvez déclarer ici les recrutements, notamment les informations collectives, inscriptions sur candidature et entretiens ou rendez-vous individuels.<br />Le renseignement de cette étape est facultatif." />
          :
            props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|other_dataEditable"].aide != "" ?
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <MessageInfoBox message={props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|other_dataEditable"].aide} />
            </div>
          :
            <MessageInfoNoDroitErreurApi />
        }
        
        {/* messageProbleme == null &&*/}
          {/*<>
            Vous pouvez ajouter :
            <div className="partie-bouton-crea">
              <ButtonDeBase typeSubmit={true} lib="Une information collective" onClick={() => ajoutRecrutement(9)} />
              <ButtonDeBase typeSubmit={true} lib="Une inscription sur candidature" onClick={() => ajoutRecrutement(7)} />
              <ButtonDeBase typeSubmit={true} lib="Un rendez-vous individuel" onClick={() => ajoutRecrutement(8)} />
            </div>
        </>*/}
        {dataLoaded && dataApi && dataApi != null && props.infosProprietes != null &&
        <Container className="my-0">
              <>
              {!showForm ? 
                props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|other_dataEditable"].editable == 1 &&
                <ButtonDeBase typeSubmit={true} lib="Ajouter un recrutement" onClick={() => setShowForm(true)} />
              :
              <ButtonDeBase typeSubmit={true} lib={libBtAnnuler} className={"fas fa-arrow-rotate-left"} onClick={() =>annulerChangements(false)} />
              /*"fas fa-arrow-rotate-left fa-circle-chevron-left*/
              }
              {showForm && 
                <div>
                  <FormRHF onSubmit={onSubmit} onError={onError} dataApi={dataApiDetailSess} gestionEvenements={gestionEvenements} >    
                    { props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|other_dataEditable"].editable == 1 &&     
                      <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div style={{marginTop:"10px", marginBottom:"20px", minWidth: "700px"}}>
                          <MessageInfoChampsObligatoires />
                        </div>
                        <div>
                          <ButtonDeBase typeSubmit={true} lib="Enregistrer" disabled={aumoinsUneModif == true ? false : true} />
                        </div>
                      </div>
                    }

                    <FormSelect id="ID_LHEO_MODALITE_RECRUTEMENT" infosProp={props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|ID_LHEO_MODALITE_RECRUTEMENT"]} options={dataLHEO_MODALITE_RECRUTEMENT} />
                    <FormSelect id="ID_LHEO_PERIMETRE_RECRUTEMENT" infosProp={props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|ID_LHEO_PERIMETRE_RECRUTEMENT"]} options={dataLHEO_PERIMETRE_RECRUTEMENT} />

                    <FormControl id="INFOS_PERIMETRE_RECRUTEMENT" infosProp={props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|INFOS_PERIMETRE_RECRUTEMENT"]} />

                    {/*<FormCheck id="A_DISTANCE" infosProp={props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|A_DISTANCE"]} typeCheckbox="checkbox" /> */}
                    <FormSelect id="A_DISTANCE" infosProp={props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|A_DISTANCE"]} options={global.config.optsOuiNon} />

                    {/*<div>
                    <select className="form-control custom-select" id="ID_PCF_ADRESSE" 
                            title="Adresse" 
                            onChange={(e) => {handleChoixAdresse(e.target.value);}}>
                        <option value="" key="-">Sélectionner une adresse parmi celle du formateur à associer à cette session</option>
                        {adressesPourChoix && adressesPourChoix.map((item) => {
                            return (
                              <option value={item.ID_PCF} key={item.ID_PCF}>
                              {item.lib}
                              </option>
                            );
                            })}
                    </select>
                    </div>*/}
                    {adresseVisible  && <FormSelect id="ID_PCF_ADRESSE" infosProp={props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|ID_PCF_ADRESSE"]} options={adressesPourChoix} />}


                    <FormControl id="URLWEB" infosProp={props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|URLWEB"]} />
                    {showMessageRDVi &&
                      <MessageInfoBox message="Pour les RDVI, le nombre de places correspond au nombre de personnes pouvant être reçu simultanément.<br />Si vous indiquez 5, cela signifie que 5 personnes peuvent recevoir 5 candidats chacune." />
                    }
                    <FormControl id="NB_PLACES" infosProp={props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|NB_PLACES"]} />
                    <FormControl id="DEBUT" infosProp={props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|DEBUT"]} style=" inline-input" />
                    {dateFinVisible  && <FormControl id="FIN" infosProp={props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|FIN"]} style=" inline-input" />}
                    <br />
                    {heuresVisible  && <FormControl id="HEURE_DEBUT" infosProp={props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|HEURE_DEBUT"]} style=" inline-input" />}
                    {heuresVisible  && <FormControl id="HEURE_FIN" infosProp={props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|HEURE_FIN"]} style=" inline-input" />}
                    
                    <FormControl id="COMMENTAIRE" infosProp={props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|COMMENTAIRE"]} />
                    {/*<MessageInfoBox message={msgInfoF} nomClasse="message-info2" />*/}{/*pour information dynamique */} 

                    { props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|other_dataEditable"].editable == 1 && 
                    <div className="partie-bouton-droite">
                      <ButtonDeBase typeSubmit={true} lib="Enregistrer" disabled={aumoinsUneModif == true ? false : true} />
                    </div>
                    }

                    <FormHidden id="hidden_marque_une_modif" />
                  </FormRHF>
                </div>
              }
              </>
            {/*/***************************** fin controle editable */}

            <section className="section-generale">
                <div className="titre">Recrutements déclarés</div><br />
                {dataApi && dataApi.length > 0 && 
                  <>
                    { props.infosGenFormation.sess_recrutement_ferme == 1 ?
                    <MessageInfoBox message="Important :<br />L'état de recrutement est déclaré comme 'fermé' dans la rubrique 'Informations principales'  de la session.<br />Notez bien qu'en conséquence, pôle emploi ne tiendra plus compte des inscriptions sur candidature, RDV individuels ou informations collectives déclarés"/> :
                    <MessageInfoBox message="Important :<br />Si vous souhaitez que pôle emploi ne tienne plus compte des inscriptions sur candidature, RDV individuels et informations collectives déclarés, vous pouvez indiquer que l'état de recrutement est 'fermé' dans la rubrique 'Informations principales' de la session."/>
                    }
                    {/* props.infosProprietes["ACTIONS_SESSIONS_PERIODE_RECRUTEMENT|other_dataEditable"].editable != 0 &&
                      <MessageInfoBox message="Vous pouvez modifier ou suppprimer des recrutements" />
                    */}
                    <Table columns={columns} data={dataApi} noPagination={true} noDisplayNbResult={true} noFilter={true} />
                  </>
                }
                {dataApi && dataApi.length == 0 && <div>Aucun recrutement n'a encore été déclaré.</div>}
            </section>
            <br />
            
        </Container>
        }

        <LiensPrecedentSuivant formationNumber={formationNumber} actionNumber={actionNumber} sessionNumber={sessionNumber} numEtape={numEtape} infosGenFormation={props.infosGenFormation} notifBeCareful={aumoinsUneModif} />
      </>
    )

}

FormEtape13.propTypes = {
  formationNumber: PropTypes.number.isRequired,
  actionNumber: PropTypes.number.isRequired,
  sessionNumber: PropTypes.number.isRequired,
  SetInfosGenFormation : PropTypes.func,
  infosGenFormation: PropTypes.object,
}

export default FormEtape13;