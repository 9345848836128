import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { useState } from 'react';

import {afficheElement} from '../fonctionsDiv.js';

/**
 * Return détail Certif
 * @component
 * @summary pour affichage détail Certif, utilise les composants Modal et Button de bootstrap
 * @summary  https://react-bootstrap.github.io/components/modal/
 * @return { HTMLElement }
*/

function DetailCertif(props) {
    const [showModal, setShowModal] = useState(true); // 2 déclarations pour la modal bootstrap
    const handleCloseModal = () => setShowModal(false);

    return (
        <Modal size="lg" show={showModal} onHide={handleCloseModal} aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Détail de la certification {props.dataDetailCertif.intitule_diplome}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <section className="detail-bloc-fiche"><br />
                    {/*<div className="titre">Détail de la certification "{props.dataDetailCertif.intitule_diplome}"</div>*/}

                    {afficheElement('Code "diplôme"', props.dataDetailCertif.code_diplome)}
                    {afficheElement("Etat", props.dataDetailCertif.etat_lib)}

                    {props.isPro == 1 && props.dataDetailCertif.listeDiplomesAppellationsPourMoteur && props.dataDetailCertif.listeDiplomesAppellationsPourMoteur.length > 0 &&
                    <>
                        <br />
                        <div className="titres-liste-big" style={{color: "red"}}>Appellations supplémentaires pour les moteurs :</div>
                        <ul>
                            {props.dataDetailCertif.listeDiplomesAppellationsPourMoteur.map(item => (
                                <li key={item.code}>
                                    {item.diplomes_pour_moteurs_id} {item.appellation_diplome} (source :  {item.source})
                                </li>
                            ))}
                        </ul>
                    </>
                    }

                    {props.dataDetailCertif.listeDiplomesRemplacants && props.dataDetailCertif.listeDiplomesRemplacants.length > 0 &&
                    <>
                        <br />
                        <div className="titres-liste-big">Diplome(s) remplaçant(s) :</div>
                        <ul>
                            {props.dataDetailCertif.listeDiplomesRemplacants.map(item => (
                                <li key={item.code}>
                                    {item.code} {item.libelle}
                                </li>
                            ))}
                        </ul>
                    </>
                    }

                    {afficheElement("Type ", props.dataDetailCertif.intitule_type_dip)}
                    {afficheElement("Niveau", props.dataDetailCertif.nom_niveau)}
                    {afficheElement("Niveau européen ", props.dataDetailCertif.nom_niveau_europeen)}

                    {afficheElement('Code RNCP', props.dataDetailCertif.code_rncp)}

                    {props.dataDetailCertif.listeRepertoiresSpecifiques && props.dataDetailCertif.listeRepertoiresSpecifiques.length > 0 &&
                    <>
                        <div className="titres-liste">Répertoire spécifique (RS) :</div>
                        <ul>
                            {props.dataDetailCertif.listeRepertoiresSpecifiques.map(item => (
                                <li key={item.code}>
                                    {item.code} {item.libelle}
                                </li>
                            ))}
                        </ul>
                    </>
                    }
                    {props.dataDetailCertif.listeRomes && props.dataDetailCertif.listeRomes.length > 0 &&
                    <>
                        <div className="titres-liste">Romes :</div>
                        <ul>
                            {props.dataDetailCertif.listeRomes.map(item => (
                                <li key={item.code}>
                                    {item.code} {item.libelle}
                                </li>
                            ))}
                        </ul>
                    </>
                    }

                    {afficheElement("Chapô", props.dataDetailCertif.texte_chapo, true)}
                    {afficheElement("Objectif", props.dataDetailCertif.texte_objectif, true)}
                    {afficheElement("Admission", props.dataDetailCertif.texte_admission, true)}
                    {afficheElement("Débouchés", props.dataDetailCertif.texte_debouches, true)}
                    {afficheElement("Poursuite", props.dataDetailCertif.texte_poursuite, true)}
                    {afficheElement("Programme", props.dataDetailCertif.texte_programme, true)}
                    {afficheElement("Source", props.dataDetailCertif.texte_source)}
                    {afficheElement("Valideur(s)", props.dataDetailCertif.valideur)}

                    {afficheElement("Nsf", props.dataDetailCertif.libelle_nsf)}


                    {props.dataDetailCertif.listeFormacodes && props.dataDetailCertif.listeFormacodes.length > 0 &&
                    <div style={{paddingTop: "10px"}}>
                        <div className="titres-liste">Formacodes :</div>
                        <ul>
                            {props.dataDetailCertif.listeFormacodes.map(item => (
                                <li key={item.code}>
                                    {item.code} {item.libelle}
                                </li>
                            ))}
                        </ul>
                    </div>
                    }
                </section>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                Fermer
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

DetailCertif.propTypes = {
    dataDetailCertif: PropTypes.object.isRequired,
    isPro: PropTypes.bool
}

export default DetailCertif;