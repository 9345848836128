var objRetourInvalide = {"statusCode": 400, "dataApi": [] }

export async function  apiEquipementAjout(utilisateurBearer, datasToPost, routeSuppl="") {
    if (utilisateurBearer == "" || routeSuppl == "") return objRetourInvalide;

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(datasToPost)
    };

    var urlApi = global.config.apiActionBase.url  + routeSuppl;
    if(global.config.modeDebugApi == 1) console.log(`appel POST ${urlApi} dans apiEquipementAjout`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200 && response.status != 400) return objRetour;  // 400 : un message personnalisé est retourné dans ce cas, ex : "Cet équipement existe déjà et n'a donc pas été ajouté"
    
        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiEquipementAjout', error_1);
        return objRetour;
    }
}

export async function apiEquipementAppelGetInfosDiv(utilisateurBearer, routeSuppl="") {
    if (utilisateurBearer == "") return objRetourInvalide;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiActionBase.url + routeSuppl;
    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi}`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200) return objRetour;

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiEquipementAppelGetInfosDiv', error_1);
        return objRetour;
    }
}


export async function apiEquipementSuppr(utilisateurBearer, actionNumber, equipementNumber) {
    if (utilisateurBearer == "" || actionNumber == ""|| equipementNumber == "") return objRetourInvalide;
    var objRetour = {statusCode : '', dataApi : ''};

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiActionBase.url  + "/" +  actionNumber  + "/equipements/" +  equipementNumber;
    if(global.config.modeDebugApi == 1) console.log('appel ' + urlApi);

    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized, 204 NoContent si succès de la suppresion
        return objRetour;
    } catch (error_1) {
        console.log('error apiEquipementSuppr', error_1);
        return objRetour;
    }
}