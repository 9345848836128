import { useEffect, useState, useContext} from 'react';
import { useNavigate} from "react-router-dom";
import { DonneesUtilisateurContext } from '../../contexte.js';

import ListeLieux from './ListeLieux.jsx';
import ListeContacts from './ListeContacts.jsx';

/**
 * Return Page des lieux et des contacts
 *
 * @component
 * @summary page lieux et contacts
 * @param {  }
 * @return { HTMLElement }
*/
function LieuxEtContacts() {
   const [lieuxCharges, setLieuxCharges] = useState(false)
   const {utilisateurRouteLogin, utilisateurBearer } = useContext(DonneesUtilisateurContext);
   const navigate = useNavigate(); // utile pour changer de page

   useEffect(() => {
      if (utilisateurBearer === '000000') navigate(utilisateurRouteLogin); // si le status n'est pas bon on redirige sur l'acceuil
      document.title = "Mes lieux et contacts";
    }, []);
  
    return (
        <>
          <ListeLieux lieuxCharges={lieuxCharges} setLieuxCharges={setLieuxCharges} />
          {lieuxCharges == true && <ListeContacts />}
        </>
    )

}

export default LieuxEtContacts;