export const ROUTE_PROFILE = { name: "p", link: "/profil" };
export const ROUTE_TESTFORM = { name: "test form", link: "/formulaire" };
export const ROUTE_TYPDIP = { name: "td", link: "/typesdiplomes" };

export const ROUTE_ACCUEIL = { name: "Accueil", link: "/accueil" };
export const ROUTE_ORGANISME = { name: "Mon organisme", nameForPro: "Fiche organisme", link: "/organisme" };
export const ROUTE_LIEUX_ET_CONTACTS = { name: "Mes lieux et contacts", nameForPro: "Lieux et contacts", link: "/lieux-et-contacts" };
export const ROUTE_FORMATION = { name: "Mes formations", nameForPro: "Formations", link: "/formation" };

export const ROUTE_FAQ = { name: "Faq", link: "/faq" };
//export const ROUTE_LOGOUT = { name: "Se déconnecter", link: "/" , linkAdmin: "/pro-bo-paca" };


//export const ROUTE_ADMIN_CHANGE_ORG = { name: "Changer d'organisme", link: "/admin/change-org" };

export const ROUTE_ADMIN_MOTEUR = { name: "Admin moteur", link: "/admin/moteur" };