import PropTypes from 'prop-types';
import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import { useNavigate } from "react-router-dom";

import { DonneesUtilisateurContext } from '../../contexte.js';

import { hdleClickNoPossible } from '../../fonctionsDiv.js';
import { apiFormGetInfosDiv, apiFormAssocDissoProf } from '../../Datas/dataFormations.js';
import { apiProfAppelBase } from '../../Datas/dataDiplomes.js';

import LiensPrecedentSuivant from '../../Components/LiensPrecedentSuivant.jsx';
import LoadingSpinner from "../../Components/LoadingSpinner";
import ButtonDeBase, { ButtonVoirFiche, ButtonSuppr } from '../../Components/ButtonsCustom.jsx';
import RechercheProf from '../../Components/RechercheProf.jsx';
import MessageInfoBox, {MessageErreurApi} from '../../Components/Messages.jsx';
import DetailProf from '../../Components/DetailProf.jsx';
import Table from '../../Components/Table.jsx';

/**
 * Return Formulaire étape 1b
 *
 * @component
 * @summary page étape 1
 * @param {  }
 * @return { HTMLElement }
*/
function FormEtape1b(props) {
    let numEtape = 1;

    const formationNumber = props.formationNumber;

    const navigate = useNavigate(); 
    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const [dataApi, setDataApi] = useState();
    const [dataLoaded, setDataLoaded] = useState(true);
    const [messageProbleme, setMessageProbleme] = useState();

    const [msgInfoF, setMsgInfoF] = useState();

    const [dataLoadedDetailCi, setDataLoadedDetailCi] = useState(false);
    const [dataDetailProf, SetDataDetailProf] = useState();
    const [nbProfAssociesLoc, SetNbProfAssociesLoc] = useState(0);

    const [nbProfFounded, setNbProfFounded] = useState("");
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);

    //pour react-table https://stackoverflow.com/questions/56922381/how-to-include-url-in-react-table-cell
    const columns = useMemo(
        () => [
          {
            id:'listesProfessionnalisantAssocies',
            hideHeader : false,
            trieDefautRp : 'LIB_PROFESSIONNALISANTS',
            columns: [
                { 
                  Header: 'libellé', 
                  accessor: 'LIB_PROFESSIONNALISANTS', 
                  Cell: ({ row }) => (
                      <>
                      <a title="Voir la fiche" className="lib" onClick={() => handleClickViewCerifBootStrap(row.original.ID_PROFESSIONNALISANTS)}>
                          {row.original.LIB_PROFESSIONNALISANTS}&nbsp;
                          <ButtonVoirFiche />
                      </a>
                      {row.original.LIB_complement != '' && <span style={{color: "red"}}>{row.original.LIB_complement}</span>}
                      </>
                  ),
                },
                {  
                  Header: 'Formacodes', 
                  accessor: 'codesFormacodes',
                  disableSortBy: true,
                  },
                  { 
                  Header: 'Nsf', 
                  accessor: 'codesNsfss',
                  disableSortBy: true,
                  },
  
                  { 
                  Header: 'Romes', 
                  accessor: 'codesRomes',
                  disableSortBy: true,
                  },
                {
                  Header: () => <></>,
                    accessor: 'actions', 
                    id: "asso_center",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                        <>
                          <a title="Voir la fiche" onClick={() => handleClickViewCerifBootStrap(row.original.ID_PROFESSIONNALISANTS)}>
                            <ButtonVoirFiche />
                          </a>


                          {props.infosGenFormation["nbActions"] == 0 &&
                            props.infosProprietes["ACTIONS_PROFESSIONNALISANTS|other_dataEditable"].editable == 1 ?
                            <a title="Dissocier" onClick={() => handleClickDissoCerif(row.original.ID_PROFESSIONNALISANTS)}>
                              <ButtonSuppr />
                            </a>
                            :
                            <a title="Suppression impossible, cliquez pour en savoir plus" 
                              onClick={() => hdleClickNoPossible()}>
                            <ButtonSuppr disabled="disabled"  />
                            </a>
                          }
                        </>
                    ),
                },
            ]
          },
        ],
        []
    )

    const handleClickAssoCerif = async (codeDiplomeAasso) => {
        setDataLoaded(false);
        setMessageProbleme();
        var dataR = await apiFormAssocDissoProf(utilisateurBearer, formationNumber, codeDiplomeAasso, 'PUT');
        //if(global.config.modeDebugApp == 1) console.log('retour apiFormAssocDissoProf : ', dataR);
        switch (dataR.statusCode) {
            case 200:
                recupEtAfficheAssoFormCertif(); // reload la liste
                alert("L'association a bien été effectuée");
              break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
              case 400:
                setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "La fiche certif info est déjà associée"
              break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
        setDataLoaded(true);
    }

    const handleClickDissoCerif  = async (codeDiplomeAasso) => {
        setDataLoaded(false);
        var dataR = await apiFormAssocDissoProf(utilisateurBearer, formationNumber, codeDiplomeAasso, 'DELETE');
        //if(global.config.modeDebugApp == 1) console.log('retour apiFormAssocDissoProf : ', dataR);

        switch (dataR.statusCode) {
            case 200:
                recupEtAfficheAssoFormCertif(); // reload la liste
                alert("La dissociation a bien été effectuée");
              break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
        setDataLoaded(true);
    }

    const handleClickViewCerifBootStrap  = async (codeDiplomeAasso) => {
      setDataLoadedDetailCi(false);
      var dataR = await apiProfAppelBase(utilisateurBearer, codeDiplomeAasso);
      if(global.config.modeDebugApp == 1) console.log('retour apiProfAppelBase : ', dataR);
      setDataLoadedDetailCi(true);
      switch (dataR.statusCode) {
          case 200:
              SetDataDetailProf(dataR.dataApi);
            break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
            break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }

      setShowModal(true);      
    }

    const [notifSuivant, setNotifSuivant] = useState();
    async function recupEtAfficheAssoFormCertif() {
        setDataLoaded(false);
        const dataR = await apiFormGetInfosDiv(utilisateurBearer, formationNumber, "/professionnalisants");
        if(global.config.modeDebugApp == 1) console.log('retour apiFormGetInfosDiv : ', dataR);

        switch (dataR.statusCode) {
            case 200:
                setDataApi(dataR.dataApi);
                var nbCertifEncorePossible = global.config.nbProfMaxParFormation - dataR.dataApi.length;

                var objaAmodif = JSON.parse(JSON.stringify(props.infosGenFormation)); // on clone (duplique) l'objet pour que tout fonctionne bien
                objaAmodif["nbProfessionnalisants"] = dataR.dataApi.length;
                objaAmodif["etape1"] = dataR.dataApi.length > 0 ? "ok" : "nok";
                dataR.dataApi.length > 0 ? setNotifSuivant("J'ai terminé cette étape et<br />je passe à l'étape suivante") : setNotifSuivant();
                props.SetInfosGenFormation(objaAmodif);

                SetNbProfAssociesLoc(dataR.dataApi.length);

                if(props.infosGenFormation["nbActions"] == 0) {
                  var messageInfoBase = `Vous débutez la déclaration d'une formation non certifiante.<br />Le menu de gauche représente les diverses étapes et vous permettra ensuite de naviguer entre elles.<br /> 
                  Le renseignement correct d'une étape vous permet de passer à la suivante, jusqu’à la déclaration des sessions.`;
                  /*
                  if (nbCertifEncorePossible == 1) messageInfoBase +=  `<br /><br />Vous devez associer ${nbCertifEncorePossible} non certifiant.`;
                  else if (nbCertifEncorePossible == 0) {
                    messageInfoBase += `<br /><br />Un seul non certifiant peut être associé à une formation, vous pouvez encore changer le non certifiant actuellement associé.`; 
                    //messageInfoBase += `<br /><br />Si vous devez en plus associer un certifiant au non certifiant sélectionné , cliquez sur le bouton situé en bas de page.`;
                  }*/
                  if (nbCertifEncorePossible == 1) messageInfoBase += `<br /><br />Veuillez choisir un non certifiant déjà présent dans notre base de données.<br />Une fois la description de votre formation finalisée, ce choix ne pourra pas être modifié.<br />Si aucun non certifiant ne convient, vous aurez la possibilité d'en créer un après avoir effectué une première recherche.`
                  else if (nbCertifEncorePossible == 0) messageInfoBase += `<br /><br />Vous pouvez encore changer le non certifiant actuellement associé.`;
                  setMsgInfoF(messageInfoBase); 
                }
                else setMsgInfoF(`Vous ne pouvez pas modifier le non certifiant associé à une formation où des actions sont déjà déclarées.`); 
              break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
        setDataLoaded(true);
   }

   useEffect(() => {
      if(global.config.modeDebugApp == 1) console.log('useffect FormEtape1b');
      document.title = `Edition de la formation ${formationNumber}`;
      recupEtAfficheAssoFormCertif();
   }, []);

   const handleClickCreateProfessionalisant = () => {
    navigate(`/formation/creation-professionnalisant/${formationNumber}`);
   }

    return (
        <>
        <LoadingSpinner dataLoaded={dataLoaded}/>
        <MessageInfoBox message={msgInfoF}  />
        <MessageErreurApi message={messageProbleme} />

        {dataLoaded && dataApi &&
        <>
          {props.infosGenFormation["nbActions"] == 0 &&
            <>
            {(nbProfAssociesLoc == 0) &&
              <RechercheProf handleClickAssoCerif={handleClickAssoCerif} handleClickViewCerifBootStrap={handleClickViewCerifBootStrap} setNbProfFounded={setNbProfFounded} />
            }
            </>
          }

          {nbProfFounded !== "" &&  // attention bien faire !==, avec != '', 0 est évalué comme vode
          <div className="centrage-deux-elements">
            <div className="align-div-right" >Vous ne trouvez pas le non certifiant adéquat 👀 ? vous pouvez le créer<br />Notez bien que notre équipe le validera dans un délai maximum de 5 jours<br />Votre formation <b>ne sera pas visible dans l'écosystème durant cette période</b></div> 
            <div className="align-div-left" >
              <ButtonDeBase onClick={handleClickCreateProfessionalisant} lib="Créer un non certifiant" />
              </div>
          </div>
          }

          <section className="certifications-associees">
              {dataApi.length > 0 &&
                <>
                  <div className="titre">
                    Non certifiant associé à votre formation - cliquez sur la <span className="fas fa-magnifying-glass picto-legende"></span> associée pour visualiser son détail (romes, nsf...)
                  </div>
                  <Table columns={columns} data={dataApi} noPagination={true} noDisplayNbResult={true} noFilter={true} />
                </>
              }
          </section>

          {dataLoadedDetailCi && dataDetailProf != null ? 
          <>
          <DetailProf dataDetailProf={dataDetailProf}  showModal={showModal} handleCloseModal={handleCloseModal}/>
          </> : null}

          <LiensPrecedentSuivant  formationNumber={formationNumber} numEtape={numEtape} infosGenFormation={props.infosGenFormation} notifBeCareful={false} 
                                  notifSuivant={notifSuivant}/>
        </>
        }
       </>
    )

}

FormEtape1b.propTypes = {
  formationNumber: PropTypes.number.isRequired,
  SetInfosGenFormation : PropTypes.func,
  infosGenFormation: PropTypes.object,
}

export default FormEtape1b;