import { useEffect, useState, useContext } from 'react';
import { useForm  } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";

import { apiProfPostNouvelleProf, apiProfPutProfFormacode } from '../Datas/dataDiplomes.js';
import { apiFormAssocDissoProf } from '../Datas/dataFormations.js';

import { DonneesUtilisateurContext } from '../contexte.js';

import LoadingSpinner from "../Components/LoadingSpinner";
import MessageInfoBox, {MessageErreurApi} from '../Components/Messages.jsx';
import ButtonDeBase from '../Components/ButtonsCustom.jsx';
import GestionFormacodes from '../Components/GestionFormacodes.jsx';


/**
 * Return template 
 *
 * @component
 * @summary imported in Route
 * @param {  }
 * @return { HTMLElement }
*/
function CreationProfessionnalisant() {
    var { formationNumber } = useParams();

    const { setBearer, utilisateurRouteLogin, utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const navigate = useNavigate(); // utile pour changer de page
    const [messageProbleme, setMessageProbleme] = useState();
    const [dataLoaded, setDataLoaded] = useState(true);

	//pour mémoriser les codes sélectionnés, objFormacodes est un TABLEAU d'OBJET, chaque objet ayant le code et le libelle comme propriété 
    const [objFormacodes, SetObjFormacodes] = useState([]); //useState(localStorage.getItem('objFormacodes') ? JSON.parse(localStorage.getItem('objFormacodes')) : []);
    const [formacodePrincipal, SetFormacodePrincipal] = useState(null); //useState(localStorage.getItem('formacodePrincipal') ? JSON.parse(localStorage.getItem('formacodePrincipal')) : null);

	useEffect(() => {
        localStorage.setItem('objFormacodes', JSON.stringify(objFormacodes));
	}, [objFormacodes])
	useEffect(() => {
        localStorage.setItem('formacodePrincipal', JSON.stringify(formacodePrincipal));
	}, [formacodePrincipal])

    /**
     * @summary fonction permettant de valider la saisie du formulaire
     * 
     * @return { Boolean }
     */
     function validate(data) {
        if (typeof data["LIB_PROFESSIONNALISANTS"] !== "undefined") {
            if (data["LIB_PROFESSIONNALISANTS"].length < 4) {
                document.getElementById("LIB_PROFESSIONNALISANTS").focus();
                alert ("Veuiller renseigner un intitulé avec plus de 3 lettres");
                return false;
            }
            if (!data["LIB_PROFESSIONNALISANTS"].match(/^[^&_$*£`\#]+$/)) {
                document.getElementById("LIB_PROFESSIONNALISANTS").focus();
                alert ("Les caractères ^ & _ $ * £ ` \ # sont interdits, la première lettre doit être en majuscule.");
                return false;
            }
        }
        if(objFormacodes.length == 0) {
            alert ("vous devez saisir au moins un Formacode");
            return false;
        }
        if(formacodePrincipal == null) {
            alert ("Veuillez définir un formacode principal en cliquant sur l'un de ceux que vous avez sélectionné.");
            return false;
        }
        return true;
    };

    // lors de la soumission du formulaire  
    const onSubmit = async (data) => {
        if (validate(data)) {            
            //console.log(data)
            setMessageProbleme();
            setDataLoaded(false);

            var dataR = await apiProfPostNouvelleProf(utilisateurBearer, data);
            if(global.config.modeDebugApp == 1) console.log('retour apiProfPostNouvelleProf : ', dataR);

            var codeDiplomeAasso = "";
            switch (dataR.statusCode) {
                case 200: 
                    codeDiplomeAasso = dataR.dataApi;
                    break;
                case 401:
                    setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
                    break;
                case 400:
                    setMessageProbleme(dataR.dataApi);
                    break;
                default:
                    setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
            }

            if (!Number.isInteger(codeDiplomeAasso)) {
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
                return false;
            }

            // on associe les codes formacodes
            var dataR = await apiProfPutProfFormacode(utilisateurBearer, codeDiplomeAasso, objFormacodes, formacodePrincipal);
            if(global.config.modeDebugApp == 1) console.log('retour apiProfPutProfFormacode : ', dataR);
            
            switch (dataR.statusCode) {
                case 200:
                    if (dataR.dataApi  < 1) {
                        setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
                        return false;
                    }
                  break;
                case 401:
                    setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
                    return false;
                case 400:
                    setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné
                    return false;
                default:
                    setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
                    return false;
            }
            // fin associe formacodes

            // on associe directement la fiche à la formation
            var dataR = await apiFormAssocDissoProf(utilisateurBearer, formationNumber, codeDiplomeAasso, 'PUT');
            if(global.config.modeDebugApp == 1) console.log('retour apiFormAssocDissoProf : ', dataR);
            setDataLoaded(true);
            switch (dataR.statusCode) {
                case 200:
                    if (dataR.dataApi < 1) {
                        setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
                        return false;
                    }
                    alert("Le non certifiant a bien été enregistré, il est maintenant automatiquement associé à la formation en cours");
                    //navigate(`/formation/etape1b/${formationNumber}`);
                    navigate(`/formation/etape1/${formationNumber}`);
                  break;
                case 401:
                    setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
                    return false;
                case 400:
                    setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "La fiche certif info est déjà associée"
                    return false;
                default:
                    setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
                    return false;
            }
        }
    }
    const { register, handleSubmit, control, watch, formState: { errors } } = useForm();


    useEffect(() => {
       if (utilisateurBearer === '000000' || formationNumber.length == 0) navigate(utilisateurRouteLogin); // si le status n'est pas bon on redirige sur l'acceuil
       document.title = "Ajout d'un non certifiant"
    }, []);

    return (  
            <section className="sous-bloc2">
                <LoadingSpinner dataLoaded={dataLoaded}/>
                <MessageErreurApi message={messageProbleme}  />

                <form onSubmit={handleSubmit(onSubmit)}> 
                    <i className="fa fa-plus"></i>
                    <h1>Ajout d'un non certifiant</h1>
                    <div className="partie-bouton-droite">
                        <ButtonDeBase lib="Enregistrer ce non certifiant" typeSubmit={true} disabled={dataLoaded == true ? false : true} />
                    </div>

                    <div className="input-wrapper">
                        <br />
                        {/*<label htmlFor="LIB_PROFESSIONNALISANTS">Libellé</label>&nbsp; */}
                        <MessageInfoBox message="Saisir l'intitulé du non certifiant" />
                        {/* pour le patern, on met plus simple que le patherne vérifier lors du submit (on aurait pu mettre le meme mais sans le \ => [^&_$*£`#] ) */}
                        <input id="LIB_PROFESSIONNALISANTS" defaultValue="" {...register("LIB_PROFESSIONNALISANTS", { required: true,  maxLength: 255 })} className="form-control " 
                               placeholder="Saisir l'intitulé du non certifiant" />
                {errors.LIB_PROFESSIONNALISANTS && (
                  <Form.Text className="text-danger">
                    {errors?.LIB_PROFESSIONNALISANTS?.type === "required" && <p>Cette information est requise</p>}
                    {errors?.LIB_PROFESSIONNALISANTS?.type === "maxLength" && (
                      <p>Ce champs ne doit pas contenir plus de 255 caractères</p>
                    )}
                  </Form.Text>
                )}
                        <br />
                    </div>
                </form>
              
                <GestionFormacodes tabObjCible={objFormacodes} SetTabObjCible={SetObjFormacodes} formacodePrincipal={formacodePrincipal} SetFormacodePrincipal={SetFormacodePrincipal}/>


                {/*  garde accordéons pour souvenir ...
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Formacodes associés à ce non certifiant ({objFormacodes.length > 0 ? <> {objFormacodes.length} actuellement</> : <>Aucun actuellement</>})</Accordion.Header>
                        <Accordion.Body>
                        <GestionFormacodes tabObjCible={objFormacodes} SetTabObjCible={SetObjFormacodes} formacodePrincipal={formacodePrincipal} SetFormacodePrincipal={SetFormacodePrincipal}/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                */}
            </section>
    )
}
  
export default CreationProfessionnalisant;

/*
CreationProfessionnalisant.propTypes = {
    formationNumber: PropTypes.number.isRequired
}
*/