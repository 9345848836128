
import React from 'react';
import PropTypes from 'prop-types';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce,     usePagination, useSortBy} from 'react-table'
import styled from 'styled-components'
import {convertSmallDateTimeToLocalDateStr} from '../fonctionsDiv.js';

// Our table component
function Table({ columns, data, noPagination=false, noDisplayNbResult=false, noFilter=false, paginationOntop=false}) {
      const filterTypes = React.useMemo(
        () => ({
          // Add a new fuzzyTextFilterFn filter type.
          fuzzyText: fuzzyTextFilterFn,
          // Or, override the default text filter to use
          // "startWith"
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
      )
    
      const defaultColumn = React.useMemo(
        () => ({
          // Let's set up our default Filter UI
          Filter: DefaultColumnFilter,
        }),
        []
      )
      var nbPagePArDefaut = noPagination === true ? 1000 : global.config.tableauResultatNbResultParPage; // si pas de pagination ont fixe une limite haute car ca n'affiche pas tout sinon

      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
    
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page
    
        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
      } = useTable(
        {
          columns,
          data,
          defaultColumn, // Be sure to pass the defaultColumn option
          filterTypes,
          initialState: { 
            pageIndex: 0,
            pageSize: nbPagePArDefaut, // nombre de page par défaut
            hiddenColumns: ['theId', 'code_arborescence'], //  permet de ne pas afficher les colonnes qui ont cet accessor
            sortBy: [
              {
                  id: columns[0].trieDefautRp,
                  desc: false
              }
          ]
          },
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        usePagination
      )
    

    const blocPourPagination =<div className="react-table-pagination">

    {rows.length} résultats{' '}
      <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
        {'<<'}
      </button>{' '}
      <button onClick={() => previousPage()} disabled={!canPreviousPage}>
        {'<'}
      </button>{' '}
      <button onClick={() => nextPage()} disabled={!canNextPage}>
        {'>'}
      </button>{' '}
      <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        {'>>'}
      </button>{' '}
      <span className="infoPagination">
        Page{' '}
        <strong>
          {pageIndex + 1}  / {pageOptions.length}
        </strong>{' '}
      </span>
      { /* changement de page pagination désactivé car pouvait saisir des pages négtives ou au dela du max....
      <span>
        Aller à la page :{' '}
        <input
          type="number"
          defaultValue={pageIndex + 1}
          onChange={e => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0
            gotoPage(page)
          }}
          style={{ width: '40px' }}
        />
      </span>{' '}
        */}
      Lignes par page :{' '}
      <select
        value={pageSize}
        onChange={e => {
          setPageSize(Number(e.target.value))
        }}
      >
        {[10, 15, 20, 30, 40, 50, 100].map(pageSize => (
          <option key={pageSize} value={pageSize}>
            {pageSize}
          </option>
        ))}
      </select>

    </div>

      return (
        <>
          { /* pour débugage, voir les variables :}
          <pre>
            <code>
              {JSON.stringify(
                {
                  pageIndex,
                  pageSize,
                  pageCount,
                  canNextPage,
                  canPreviousPage,
                },
                null,
                2
              )}
            </code>
              </pre> */ }
    { /* attention : modif faite pour ne pas afficher la premiere colonne header : https://kiranvj.com/blog/blog/react-table-7-x-hide-column-header-row/ : ajout id+hideHeader dans form-rech-ci 
    
     version des tries avec des emojis :
                          <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                         {!column.isSorted && !column.isSorted && !column.disableSortBy
                            ? '▶️'
                          : ''}
                      </span>
    
                      th :
                      fait pour éviter les retours à la ligne du aux tries : <div style={{display: "flex"}}>...</div>
                      ai aussi ajouté {...column.getHeaderProps({style: { minWidth: column.minWidth }})} après coup, permet de  mettre un minWidth: 100, dans "columns: ["", 
                      moins bien car il faudrait le faire sur chaque colonne
                        */
    }


    {paginationOntop === false ? 
      <>
      {noDisplayNbResult != true && <>{rows.length} résultats</>}
      </> 
    : 
      blocPourPagination}
    <Styles>
      
          <div style={{display: "flex", justifyContent:  "center" }}>
          <table className="react-table" {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column =>{
              return column.hideHeader === false ? null : (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} 
                    /* suffit de mettre columns: [{ minWidth: 100} dans le useMemo pour que ca marche */
                    /* la div ci dessous avait display: "flex" a l'origine */
                    {...column.getHeaderProps({style: { minWidth: column.minWidth }})} 
                      >
                      <div style={{textAlign: "center"}}>
                      {column.render('Header')}
                      &nbsp;<span> <i className=
                        {!column.disableSortBy ?
                            column.isSorted ? 
                              column.isSortedDesc ? 'fas fa-sort-down'
                                                    : 'fas fa-sort-up'
                              : 'fas fa-sort'
                         : ''}></i>
                      </span>
                      </div>
                    </th>
             );
            })}
                </tr>
              ))}
              {noFilter !== true && 
              <tr>
                <th
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: 'left',
                  }} 
                >
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                </th>
              </tr>
              }
            </thead>
            <tbody {...getTableBodyProps()}>
              {/*// for pagination :  Instead of using 'rows', we'll use page, */}
              {page.map((row, i) => {
                prepareRow(row)
    
                return (
    
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
///////////// ici pour colorer une cellule en fonction de sa valeur https://codesandbox.io/s/cool-frog-u5y8z?file=/src/App.js:1369-1822
//POUR COLORER LA CELLULE SI l'ACTION EST INVALIDE (couleur différente, mise en évidence)
let style = {};
if (cell.column.id == "actions_center") {
  //console.log(cell.row.cells[5].value)
  if (cell.row.original.Etat_LHEO_Valide == 0) { //  || cell.row.original.Etat_Validation_CO == 0
    style = { background: "#ffad71" }; // coloration en orange
  }
}
else if (cell.column.id === "etatCertifInfo_center") {
  if (cell.value == "inactif") {
    style = { background: "red" }; // coloration en rouge
  }
}
else if (cell.column.id === "etat_lib_center") {
  if (cell.value == "Archivées" || cell.value == "Erronées" || cell.value == "Formation non certifiante supprimée") {
    style = { background: "red" }; // coloration en rouge
  }
}

if (cell.row.original.est_secondaire == 1) { //  
  style = { color: "grey" }; // coloration en gris de la ligne entière, utile pour la liste des formacodes, quand le lib du formacode ne contient pas la recherche, ex avec "banque"

}
// pour Quiforme
if (cell.column.id == "qf_LIB_COMPANIES" || cell.column.id == "db_LIB_COMPANIES") {
  // .normalize("NFD").replace(/[\u0300-\u036f]/g, "") permet de gérer supprimer les accents https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
  // plus value de toLocaleLowerCasepar rapport à toLowerCase pas prouvé... toLowerCase converti déjà bien Â, Ê, Î, Ô, Û, Ä, Ë, Ï, Ö, Ü, À, Æ, Ç, É, È, Œ, œ, Ù
  if (cell.row.original.qf_LIB_COMPANIES == null || 
    cell.row.original.qf_LIB_COMPANIES.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '’').toLocaleLowerCase('fr-FR') !== 
    cell.row.original.db_LIB_COMPANIES.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '’').toLocaleLowerCase('fr-FR')) { 
    style = { background: "#8BBF91"}; 
  }
}
if (cell.column.id == "qf_Numero_UAI" || cell.column.id == "db_Numero_UAI") {
  if (cell.row.original.qf_Numero_UAI != cell.row.original.db_Numero_UAI && (cell.row.original.qf_Numero_UAI || cell.row.original.db_Numero_UAI)) { 
    style = { background: "#86E390" };
  }
}
if (cell.column.id == "qf_NUM_DE" || cell.column.id == "db_NUM_DE") {
  if (cell.row.original.qf_NUM_DE != cell.row.original.db_NUM_DE && (cell.row.original.qf_NUM_DE || cell.row.original.db_NUM_DE)) { 
    style = { background: "#A8D8AD" };
  }
}

if (cell.column.id == "qf_Qualiopi_Form_FormationContinue" || cell.column.id == "db_Qualiopi_Form_FormationContinue") {
  if (cell.row.original.qf_Qualiopi_Form_FormationContinue != cell.row.original.db_Qualiopi_Form_FormationContinue ) { 
    style = { background: "#ffad71" }; 
  }
}
if (cell.column.id == "qf_Qualiopi_Form_BilanCompetence" || cell.column.id == "db_Qualiopi_Form_BilanCompetence") {
  if (cell.row.original.qf_Qualiopi_Form_BilanCompetence != cell.row.original.db_Qualiopi_Form_BilanCompetence ) { 
    style = { background: "#ffbd71" };
  }
}
if (cell.column.id == "qf_Qualiopi_Form_VAE" || cell.column.id == "db_Qualiopi_Form_VAE") {
  if (cell.row.original.qf_Qualiopi_Form_VAE != cell.row.original.db_Qualiopi_Form_VAE ) { 
    style = { background: "#ffcf84" };
  }
}
if (cell.column.id == "qf_Qualiopi_Form_Apprentissage" || cell.column.id == "db_Qualiopi_Form_Apprentissage") {
  if (cell.row.original.qf_Qualiopi_Form_Apprentissage != cell.row.original.db_Qualiopi_Form_Apprentissage ) { 
    style = { background: "#ffdf84" }; 
  }
}


if (cell.column.id == "qf_ADDRESS1" || cell.column.id == "db_ADDRESS1") { //test null ci-dessous pour eviter erreur du replace dans cas ou valeur null
  if (cell.row.original.qf_ADDRESS1 == null && cell.row.original.db_ADDRESS1 != null ||
      cell.row.original.db_ADDRESS1 == null && cell.row.original.qf_ADDRESS1 != null||
      cell.row.original.qf_ADDRESS1.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '’').toLocaleLowerCase('fr-FR') !== 
      cell.row.original.db_ADDRESS1.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '’').toLocaleLowerCase('fr-FR')) { 

    style = { background: "#74A3ED" }; 
  }
}
if (cell.column.id == "qf_CODEPOSTAL" || cell.column.id == "db_CODE_POSTAL") {
  if (cell.row.original.qf_CODEPOSTAL != cell.row.original.db_CODE_POSTAL ) { 
    style = { background: "#95B7ED" };
  }
}
if (cell.column.id == "qf_CODE_INSEE" || cell.column.id == "db_CODE_INSEE") {
  if (cell.row.original.qf_CODE_INSEE != cell.row.original.db_CODE_INSEE && (cell.row.original.qf_CODE_INSEE)) { // excpetion on ne colorie pas puisque depuis la v2 on a plus de code insee
    style = { background: "#b7d0f7" };
  }
}

/*else if (cell.column.id == "actions_depreciees_center") {
   if(!global.config.codesEtatsCiEnCours.includes(cell.row.original.etat_code)) {
    style = { background: "#e8e5e5" };
  }
} marche mais décommenté car pas top*/
/////////////

                      //!!!! si le tab de données contient "noEditablePLease" = 1 alors on affiche pas le contenu prévu sur les champs souhaités
                      // marche mais inutile, autant le faire dans l'objet qui va etre fourni.....
                      //if (cell.getCellProps().key.indexOf('suppr_conditionnel') >= 0 && cell.row.original.noEditablePLease == 1)  
                      //  return <td {...cell.getCellProps({ style })}></td>

                      // !!!!to convert date in fr format
                      //else 
                      if(cell.getCellProps().key.indexOf('DATES_FIN_ENREGISTREMENTrech_rncp_rs_formated') >= 0 ||
                        (cell.getCellProps().key.indexOf('date_') >= 0 && !(cell.getCellProps().key.indexOf('date_traitementFr') > 0)) ||
                         cell.getCellProps().key.indexOf('EndDate') >= 0 ||
                         cell.getCellProps().key.indexOf('StartDate') >= 0 ||
                         (cell.getCellProps().key.indexOf('DEBUT') >=  0&& !(cell.getCellProps().key.indexOf('HEURE') > 0)) ||
                         (cell.getCellProps().key.indexOf('FIN') >= 0 && !(cell.getCellProps().key.indexOf('FINAN') > 0)
                                                                      && !(cell.getCellProps().key.indexOf('DATES') > 0)
                                                                      && !(cell.getCellProps().key.indexOf('HEURE') > 0))) { // DATE_DEBUT DATE_FIN pour sessions mais DEBUT FIN  pour recrutement et date_creation + date_modification pour liste session d'une action
                        //+ éviter HEURE_DEBUT et HEURE_FIN et 
                        //console.log(cell.getCellProps().key + ':: ' + cell.value)
                        let ll =  convertSmallDateTimeToLocalDateStr(cell.value)
                        return <td {...cell.getCellProps({ style })}>{ll}</td>
                      }
                      // pour centrer le contenu d'une colonne
                      else if(cell.getCellProps().key.indexOf('center') >= 0)   // !!!!si le terme center apparait dans l'id...
                        return <td {...cell.getCellProps({ style })} className="center-col">{cell.render('Cell')}</td>

                      else if(cell.getCellProps().key.indexOf('secondaires') >= 0)  // !!!!si le terme secondaires apparait dans l'id...
                        return <td {...cell.getCellProps({ style })} className="infos-secondaires">{cell.render('Cell')}</td>

                      else  
                        return <td {...cell.getCellProps({ style })}>{cell.render('Cell')}</td>
                    })}
                  </tr>
    
                )
              })}
            </tbody>
          </table>
          </div>

          </Styles>

          {noPagination === true ? 
            <>
            {noDisplayNbResult != true && <>{rows.length} résultats</>}
            </> 
          : 
            blocPourPagination}
    
          { /*}
          <div>
            <pre>
              <code>{JSON.stringify(state.filters, null, 2)}</code>
            </pre>
              </div>*/}


          {/*<div>affichage de {page.length} des {rows.length} résultats</div>*/}

        </>
      )
    }

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  noPagination: PropTypes.bool,
  noDisplayNbResult: PropTypes.bool,
  noFilter: PropTypes.bool,
}
export default Table;

// syle de base : https://codesandbox.io/s/github/tannerlinsley/react-table/tree/v7/examples/basic
// si on voulait colorer la première col :  td:nth-child(1) { color: green !important; }
const Styles = styled.div`
  padding: 1rem;

  table {
    border-collapse: separate; // obligé pour pouvoir réussir les arrondis comme ci dessous
    border-spacing: 0;
    //border: 1px solid black;
     //width: 100%;
     min-width: 940px; //980px nok depasse dans l'accordéon a cause d'un padding left dans accordion-body...

    th,
    td {
      margin: 0;
      padding: 0.5rem 0.2rem; // avant il y avait 0.5 partout mais pas les padding-left: 0.5rem; padding-right: 0.5rem; sur les child.. 0 permet de ne pas avoir d'espace quand on veut colorrer les fons des cellules
    }
  }

  // class ci dessous inspirée du jsfiddle de https://stackoverflow.com/questions/10666573/tables-border-radius-does-not-function-as-expected :
  td {
    border: solid 1px grey;
    border-style: none none solid none;
    border-right-style: dotted;
    /*border-right-color: fcfcfc;*/
    padding: 10px;
  }

  th {
    border: solid 1px grey;
    border-style: solid none none none;
  }
  tr td:first-child, tr th:first-child {padding-left: 0.5rem;  border-top-left-radius: 10px; border-bottom-left-radius: 10px;}
  tr td:last-child{padding-right: 0.5rem; border-style: none solid solid none; border-top-right-radius: 10px; border-bottom-right-radius: 10px;}
  tr th:last-child { border-style: solid solid none none; border-top-right-radius: 10px; border-bottom-right-radius: 10px;}

  tr:first-child td, tr:first-child th { border-top-style: solid; }
  tr td:first-child, tr th:first-child { border-left-style: solid; }
  // pour colorier une ligne sur 2
  tr:nth-child(even)  {
    background-color: #f9f9f9;
  }  
  tr:nth-child(even) td:nth-child(even) {
    background-color: #fafafa;
  }
  tr:nth-child(odd) td:nth-child(even) {
    background-color: #fefefe;
  }
 /* issu de cet exemple : https://openclassrooms.com/forum/sujet/colorer-une-cellule-sur-deux-en-css
  td:nth-child(odd){
    background-color: #c0c0c0;
  }
  tr:nth-child(even) td:nth-child(even) {
    background-color: red;
  }
  tr:nth-child(odd) td:nth-child(even) {
    background-color: orange;
  }*/
  tr:hover {
    background-color:#eff1fc;
    opacity: 0.9;
  }
  /* 2 ci dessous obligés comme on fait tr:nth-child(even) td:nth-child(even) et tr:nth-child(odd) td:nth-child(even)*/
  tr:nth-child(even):hover td:nth-child(even) {
    background-color:#e1e4f7;
    opacity: 0.9;
  }
  tr:nth-child(odd):hover td:nth-child(even) {
    background-color:#e1e4f7;
    opacity: 0.9;
  }
`

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <span>
      Rechercher un ou des termes parmi la liste :{' '}
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        style={{
          fontSize: '1.1rem',
          border: '1px lightgrey  solid',
        }}
      />
    </span>
  )
}
// auparavant était ci dessus en dessus de style : placeholder={`filtre sur les ${count} lignes...`}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}


// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set()
      preFilteredRows.forEach(row => {
        options.add(row.values[id])
      })
      return [...options.values()]
    }, [id, preFilteredRows])
  
    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    )
  }