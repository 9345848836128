import { useEffect, useState, useContext,  } from 'react';

import { DonneesUtilisateurContext } from '../contexte.js';

import MessageInfoBox, {MessageErreurApi} from './Messages.jsx';
import ButtonDeBase from './ButtonsCustom.jsx';
import FormRHF from "./Form/Form";
import FormControl from "./Form/FormControl";
import FormSelect from "./Form/FormSelect";
import { apiGetProprietesFree, apiSoumissionFormulaireDemande } from '../Datas/datasAutre.js'
import LoadingSpinner from "./LoadingSpinner";
import { dateDuJourYYYYmmdd, dateModifCharFrToAmericSansSep, appelRecupInfoGet } from '../fonctionsDiv.js';

import '../config.js';

/**
 * Return Formulaire pour Demande de formateur manquant
 *
 * @component
 * @summary page d'inscription
 * @param {  }
 * @return { HTMLElement }
*/
function FormDemandeAjoutAntenneFormateur(props) {
    const [dataLoaded, setDataLoaded] = useState(true);
    const [infosProprietes, SetInfosProprietes] = useState(undefined); // contient les infos sur les champ (taille max, caractre obligatoire, éditable...)

    const [messageProbleme, setMessageProbleme] = useState();
    const [aumoinsUneModif, setAumoinsUneModif] = useState(false); // pour avertir l'utilisateur en cas de sortie sans  enregistrement
    const { utilisateurNomOrg } = useContext(DonneesUtilisateurContext);
    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);

    //pour effectuer des actions en fonction de la saisie de l'utilisateur
    function gestionEvenements(value, name){
      setAumoinsUneModif(true);
      if(global.config.modeDebugApp == 1) console.log (`modif de ${name} : ${value[name]}`);
    }

    const onSubmit = async (values) => {
      for(let i in values) if(values[i] === "") values[i] = null; // sinon génère une erreur : on met à null tout les champs vide (ract-hook-form semble le faire pour nous dans le cas ou on lui passe les valeurs par défaut en entré)

      if(values["x_DATE_DECLARATION"] != null && dateModifCharFrToAmericSansSep(values["x_DATE_DECLARATION"]) > dateDuJourYYYYmmdd()) {
        alert("La date de déclaration ne peut être postérieure à la date du jour.");
        var ob = document.getElementById("x_DATE_DECLARATION");
        ob.focus();
        return false;
      }
      if(values["x_RESPONSABLE_EMAIL"] != values["x_RESPONSABLE_EMAIL_CONFIRMATION"]) {
        alert("L\'e-mail de confirmation du responsable ne correpsond pas à l'e-mail saisi, veuillez vérifier votre saisie.");
        var ob = document.getElementById("x_RESPONSABLE_EMAIL");
        ob.focus();
        return false;
      }

      values.DemandeurNomOrganisme = utilisateurNomOrg; //en plus des données du formulaire, on ajoute à l'objet le nom de 'lorganisme demandeur

      if(global.config.modeDebugApp == 1) console.log("Values:::", values);
      if(global.config.modeDebugApp == 1) console.log("Values:::", JSON.stringify(values));

      var dataR = await apiSoumissionFormulaireDemande(utilisateurBearer, values, "envoi-formulaire/demande-ajout-organisme");
      if(global.config.modeDebugApp == 1) console.log('retour apiSoumissionFormulaireDemande : ', dataR);

      switch (dataR.statusCode) {
          case 200: 
               alert("Votre demande a bien été transmise, nous revenons vers vous dans les plus brefs délais.\r\n\r\nVous pouvez laisser cette action en l'état en attendant (vous la retrouverez depuis le menu 'Mes formations=> Incomplètes et non diffusées')");
               props.handleCloseModal();
              break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
      //props.handleCloseModal();
    };

    
    const onError = (error) => {
      console.log("ERROR:::", error);
    };

    async function recupererProprietesFree() {
        const dataR = await apiGetProprietesFree();
        if(global.config.modeDebugApp == 1) console.log('retour apiGetProprietesFree : ', dataR);
        switch (dataR.statusCode) {
          case 200:
            // on duplique l'objet  x_RESPONSABLE_EMAIL pour pouvoir demander une confirmation
            var objPourConfirmation =  JSON.parse(JSON.stringify(dataR.dataApi["FomulaireInscription|x_RESPONSABLE_EMAIL"]));
            objPourConfirmation.lib_form = objPourConfirmation.lib_form + " (confirmation)"; // on clone (duplique) l'objet pour que  out fonctionne bien);
            objPourConfirmation.placeholder = objPourConfirmation.placeholder + " (confirmation)";
            dataR.dataApi["FomulaireInscription|x_RESPONSABLE_EMAIL_CONFIRMATION"] = objPourConfirmation
            
            SetInfosProprietes(dataR.dataApi);
             break;
          default:
            setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
    }

    const [dataLEGALSTATUS, SetDataLEGALSTATUS] = useState(); // pour recup données du select
    async function chargementsConsecutifs() {
      setDataLoaded(false);
      await recupererProprietesFree();
      var pb = false;
      var ret = await appelRecupInfoGet("", "LEGALSTATUS", setMessageProbleme); 
      if(ret != null) SetDataLEGALSTATUS(ret); else pb = true;
      if(pb == true ) setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut

      setDataLoaded(true);
    }

    useEffect(() => {  
        setMessageProbleme(null);
        chargementsConsecutifs();
    }, []);

    const InputWithDiv = (props) => {
      return (<div className="alignement-horizontal-champs-form">
        <FormControl {...props} noCount="1" />
      </div>)
    };


    return (  
        <>
        <LoadingSpinner dataLoaded={dataLoaded}/>
        <MessageErreurApi message={messageProbleme} />

        {dataLoaded && infosProprietes &&
            <FormRHF onSubmit={onSubmit} onError={onError} gestionEvenements={gestionEvenements} >

              <div className="titre-inscription">Identité de l'antenne</div>
              <FormControl id="x_LIB_COMPANIES" infosProp={infosProprietes["FomulaireInscription|x_LIB_COMPANIES"]} nolib="1" />
              <InputWithDiv id="x_SHORT_LIB" infosProp={infosProprietes["FomulaireInscription|x_SHORT_LIB"]} nolib="1" />
              <InputWithDiv id="x_NUM_DE" infosProp={infosProprietes["FomulaireInscription|x_NUM_DE"]} nolib="1" />

              <FormSelect id="x_STATUT" infosProp={infosProprietes["FomulaireInscription|x_STATUT"]} options={dataLEGALSTATUS} nolib="1" />

              <InputWithDiv id="x_SIRET" infosProp={infosProprietes["FomulaireInscription|x_SIRET"]} nolib="1" />




              <div className="titre-inscription-separation">Coordonnées de l'antenne</div>

              <InputWithDiv id="x_COMPANIES_EMAIL" infosProp={infosProprietes["FomulaireInscription|x_COMPANIES_EMAIL"]} nolib="1" />
              <InputWithDiv id="x_COMPANIES_PHONE_PORTABLE" infosProp={infosProprietes["FomulaireInscription|x_COMPANIES_PHONE_PORTABLE"]} nolib="1" />
              <InputWithDiv id="x_COMPANIES_PHONE_NUMBER_FIXE" infosProp={infosProprietes["FomulaireInscription|x_COMPANIES_PHONE_NUMBER_FIXE"]} nolib="1" />

              <FormControl id="x_URL" infosProp={infosProprietes["FomulaireInscription|x_URL"]} nolib="1" />

              <FormControl id="x_ADDRESS1" infosProp={infosProprietes["FomulaireInscription|x_ADDRESS1"]} nolib="1" />
              <FormControl id="x_ADDRESS2" infosProp={infosProprietes["FomulaireInscription|x_ADDRESS2"]} nolib="1" />

              <InputWithDiv id="x_CODE_POSTAL" infosProp={infosProprietes["FomulaireInscription|x_CODE_POSTAL"]} nolib="1" />
              <InputWithDiv id="x_LIB_VILLE" infosProp={infosProprietes["FomulaireInscription|x_LIB_VILLE"]} nolib="1" /><br />




              <div className="titre-inscription-separation">Responsable de l'antenne</div>

              <InputWithDiv id="x_RESPONSABLE_EMAIL" infosProp={infosProprietes["FomulaireInscription|x_RESPONSABLE_EMAIL"]} nolib="1" />
              <InputWithDiv id="x_RESPONSABLE_EMAIL_CONFIRMATION" infosProp={infosProprietes["FomulaireInscription|x_RESPONSABLE_EMAIL_CONFIRMATION"]} nolib="1" /><br />

              <InputWithDiv id="x_RESPONSABLE_FIRST_NAME" infosProp={infosProprietes["FomulaireInscription|x_RESPONSABLE_FIRST_NAME"]} nolib="1" />
              <InputWithDiv id="x_RESPONSABLE_LAST_NAME" infosProp={infosProprietes["FomulaireInscription|x_RESPONSABLE_LAST_NAME"]} nolib="1" /><br />

              <InputWithDiv id="x_RESPONSABLE_TEL_PORTABLE" infosProp={infosProprietes["FomulaireInscription|x_RESPONSABLE_TEL_PORTABLE"]} nolib="1" />
              <InputWithDiv id="x_RESPONSABLE_TEL_FIXE" infosProp={infosProprietes["FomulaireInscription|x_RESPONSABLE_TEL_FIXE"]} nolib="1" />




              <div className="partie-bouton-droite">
                <ButtonDeBase typeSubmit={true} lib="Envoyer" disabled={aumoinsUneModif == true ? false : true} nolib="1" />
              </div>

              <span className="text-obligatoire">Les champs signalés d'un astérisque rouge * sont obligatoires.</span>
          </FormRHF>
        }
        </>
    )

}


export default FormDemandeAjoutAntenneFormateur;