import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import { useNavigate } from "react-router-dom";

import { DonneesUtilisateurContext } from '../../contexte.js';
import { apiProfAppelBase } from '../../Datas/dataDiplomes.js'
import MessageInfoBox, {MessageErreurApi} from '../../Components/Messages.jsx';
import AdmRechercheProf from '../../Components/AdmRechercheProf.jsx'
import DetailProf from '../../Components/DetailProf.jsx'


function AdmMoteurSynsProfs() {
    const navigate = useNavigate(); // utile pour changer de page

    const { setBearer, utilisateurRouteLogin, utilisateurBearer, utilisateurRole } = useContext(DonneesUtilisateurContext);
    const [messageProbleme, setMessageProbleme] = useState();
    const [dataLoadedDetailCi, setDataLoadedDetailCi] = useState(false);
    const [dataDetailProf, SetDataDetailProf] = useState();
    const [messageBase, setMessageBase] = useState('');

    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);

    const handleClickViewCerifBootStrap  = async (codeDiplomeSelected) => {
        setDataLoadedDetailCi(false);
        var dataR = await apiProfAppelBase(utilisateurBearer, codeDiplomeSelected, codeDiplomeSelected);
        if(global.config.modeDebugApp == 1) console.log('retour apiProfAppelBase : ', dataR);
        switch (dataR.statusCode) {
            case 200:
                SetDataDetailProf(dataR.dataApi);
              break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
              break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
        setDataLoadedDetailCi(true);   
    }

   useEffect(() => {
    if (utilisateurBearer === '000000' || utilisateurRole != "AdminExtranet" )  navigate(utilisateurRouteLogin); // si pas identifié ou role NOK on redirige sur l'acceuil
    document.title = `Gestion des synonymes de professionnalisant`;
    setMessageBase("Gestion des synonymes de professionnalisant : recherchez le professionnalisant sur lequel vous souhaitez agir.");
   }, []);

   return (     
        <main className="sous-bloc">
            <MessageInfoBox message={messageBase} />
            <MessageErreurApi message={messageProbleme} />
            <AdmRechercheProf handleClickViewCerifBootStrap={handleClickViewCerifBootStrap} />

            {dataLoadedDetailCi && dataDetailProf != null &&
            
            <><b>ok</b>
                <DetailProf dataDetailProf={dataDetailProf}  showModal={showModal} handleCloseModal={handleCloseModal}/>
                </>
            }
        </main>
    )
}

export default AdmMoteurSynsProfs;