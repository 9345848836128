import PropTypes from 'prop-types';
import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx

import { apiProfAppelBase } from '../Datas/dataDiplomes.js'
import { DonneesUtilisateurContext } from '../contexte.js';

import {MessageErreurApi} from '../Components/Messages.jsx';
import LoadingSpinner from "../Components/LoadingSpinner";
import ButtonDeBase, {ButtonPourList, ButtonVoirFiche } from '../Components/ButtonsCustom.jsx';
import Table from '../Components/Table.jsx';


/**
 * Return une section permettant de rechercher des professionnalisants
 *
 * @component
 * @summary page de rechercher des professionnalisants
 * @param {  }
 * @return { HTMLElement }
*/
function RechercheProf(props) {
    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const [messageProbleme, setMessageProbleme] = useState();

    const [dataApiCi, setDataApiCi] = useState();
    const [dataLoadedRechCi, setDataLoadedRechCi] = useState(true);
    const [champRechProfLib, setChampRechProfLib] = useState(""); // useState(localStorage.getItem('champRechProfLib'))
  
    const columnsCi = useMemo(
        () => [
          {
            id:'listesProfessionnalisant',
            hideHeader : false,
            trieDefautRp : 'LIB_PROFESSIONNALISANTS',
            columns: [
                {  
                Header: 'libellé', 
                accessor: 'LIB_PROFESSIONNALISANTS', 
                Cell: ({ row }) => (
                    <>
                    <a title="Voir la fiche" className="lib" onClick={() => props.handleClickViewCerifBootStrap(row.original.ID_PROFESSIONNALISANTS)}>
                        {row.original.LIB_PROFESSIONNALISANTS}&nbsp;
                        <ButtonVoirFiche />
                    </a>
                    {row.original.LIB_complement != '' && <span style={{color: "red"}}>{row.original.LIB_complement}</span>}
                    </>
                ),
                },
                { 
                Header: 'Formacodes', 
                accessor: 'codesFormacodes',
                disableSortBy: true,
                },
                { 
                Header: 'Nsf', 
                accessor: 'codesNsfss',
                disableSortBy: true,
                },

                { 
                Header: 'Romes', 
                accessor: 'codesRomes',
                disableSortBy: true,
                },
                {  
                    Header: () => <>Associer</>,
                    accessor: 'actions', 
                    id: "asso_center",
                    disableSortBy: true,
                    Cell: ({ row }) => (       
                            <a title="Associer" onClick={() => props.handleClickAssoCerif(row.original.ID_PROFESSIONNALISANTS)}>
                                <ButtonPourList />
                            </a>
                    ),
                },
            ]
          },
        ],
        []
    )


    async function effectueRechercheCi() {
        let chaineRecherche = "?";
        if (champRechProfLib) chaineRecherche += "intitule-recherche=" + champRechProfLib + '&';        

        if (chaineRecherche.length == 1) {setMessageProbleme('Veuillez saisir au moins un critère'); return false; }
        else {    
            setDataLoadedRechCi(false);
            setMessageProbleme("");
            const dataR = await apiProfAppelBase(utilisateurBearer, 'Search' + chaineRecherche);
            if(global.config.modeDebugApp == 1) console.log('retour apiProfAppelBase : ', dataR);

            switch (dataR.statusCode) {
            case 200:
                //setMessageProbleme("Les données ont bien été recuperées");
                //modification du contenu renvoyé par l'api
                let nouveauTabRep = [];
                dataR.dataApi.forEach((el) => {
                    // on va couper les intitulés trop long
                    let libCourt = (el.LIB_PROFESSIONNALISANTS.length > global.config.lgMaxChaineTab)  ? el.LIB_PROFESSIONNALISANTS.substring(0, global.config.lgMaxChaineTab-3) + '...' :  el.LIB_PROFESSIONNALISANTS;
                    let ligne = { ...el, 'LIB_PROFESSIONNALISANTS': libCourt, 'libLong': el.LIB_PROFESSIONNALISANTS}
                    nouveauTabRep.push(ligne);           
                });
                setDataApiCi(nouveauTabRep); // dataR.dataApi
                setDataLoadedRechCi(true);
                props.setNbProfFounded(dataR.dataApi.length);
                break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
                break;
            case 400:
                // on test si c'est une chaine string qui est retourné par l'api
                if(typeof dataR.dataApi === 'string' || dataR.dataApi instanceof String) setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "Il y a plus de XXX résultats ({XXXX), veuillez affiner votre recherche"
                else setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
                break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
            }
        }
    }
    const handleSubmitRechCi = async (e) => {
        e.preventDefault();
        effectueRechercheCi();
    }

 
   useEffect(() => {
        if(global.config.modeDebugApp == 1) console.log('useffect RechercheProf')

        if(typeof dataR == 'undefined') setChampRechProfLib(); // pour que la derniere saisie ne se réaffiche pas si on revient sur la page
   }, []);


    return (
        <section>
            <p className="titre-rech-certif">Rechercher un non certifiant à associer à votre formation</p>
            <MessageErreurApi message={messageProbleme} />

            <LoadingSpinner dataLoaded={dataLoadedRechCi}/>
            {dataLoadedRechCi && 
                <>
                    <form onSubmit={handleSubmitRechCi} className="form-rech-ci">
                        <div className="partie-form-rech-ci">
                            { /* value={champRechProfLib != null && champRechProfLib}*/}
                            <input className="form-control input-lib" placeholder='Extrait du libellé (4 lettres minimum)' type="text" id="champRechProfLib" 
                            onChange={(e) => {setChampRechProfLib(e.target.value); localStorage.setItem('champRechProfLib', e.target.value)}}
                            value={champRechProfLib  == null ? '' : champRechProfLib}  />
                            <ButtonDeBase typeSubmit={true } lib="Rechercher" disabled={(!champRechProfLib || champRechProfLib.length < 4)} />
                        </div>
                    </form>
                    {dataApiCi && dataApiCi.length > 0 && 
                    <>
                    <p className="message-info">
                        {dataApiCi.length} 
                        {dataApiCi.length > 1 ? 
                            <> non certifiants trouvés - cliquez sur l'un des boutons <span className="button-list-ajout-in-lib"><span className="fas fa-plus"></span></span> de la liste pour en associer un à votre formation</>
                            : 
                            <> non certifiant trouvé - cliquez sur le bouton <span className="button-list-ajout-in-lib"><span className="fas fa-plus"></span></span> dans la liste pour l'associer à votre formation</>}
                    </p>    
                    <Table columns={columnsCi} data={dataApiCi} noDisplayNbResult={true} />
                    </>
                    }
                </>
            }
            {dataLoadedRechCi && dataApiCi && dataApiCi.length == 0 ? <div>Aucun non certifiant n'a été trouvé, veuillez modifier vos critères.</div> : null}
        </section>
    )

}


export default RechercheProf;

RechercheProf.propTypes = {
    handleClickAssoCerif: PropTypes.func,
    handleClickViewCerifBootStrap: PropTypes.func,
    setNbProfFounded: PropTypes.func
}