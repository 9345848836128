import { useEffect } from 'react';
import { NavLink } from "react-router-dom";
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useContext } from 'react';
import { DonneesUtilisateurContext } from '../contexte.js';

/**
 * Component for left navigation
 *
 * @component
 * @return { HTMLElement }
*/

{/*Certification ou Professionalisant
Informations Principales
Contacts
Actions
informations principales
Formateurs
Lieux + "et certifications" dasn le cas d'une formation avec code certif info
Equipements
Sessions
Informations principales
Contact
Certif. préparée
Recrutements
Cycle (pour initial et apprentissage)
https://github.com/atoulmet/atoulmet-OC-React-Beginners/blob/P2C3-Solution-1/src/datas/plantList.js
https://github.com/atoulmet/atoulmet-OC-React-Beginners/blob/P2C3-Solution-1/src/components/ShoppingList.js*/}



function LeftMenu(props) {

    const { utilisateurIdMesureOf} = useContext(DonneesUtilisateurContext);

    // à noter que ces props sont mises à jour à chaque fois qu'il y a une évolution
    var nbCertif = props.infosGenFormation.nbCertifications;
    var nbProf = props.infosGenFormation.nbProfessionnalisants;
    var nbContactsFormation = props.infosGenFormation.nbContactsFormation;
    var nbAct = props.infosGenFormation.nbActions;
    var nbFormateurs = props.infosGenFormation.nbFormateurs;
    var nbLieux = props.infosGenFormation.nbLieux;
    var nbCertifsFormateurs = props.infosGenFormation.nbCertifsFormateurs;
    var nbEquipement = props.infosGenFormation.nbEquipement;
    var nbSessions = props.infosGenFormation.nbSessions;
    var nbContactsSession = props.infosGenFormation.nbContactsSession;
    var nbRecrutements = props.infosGenFormation.nbRecrutements;
    var nbCycles = props.infosGenFormation.nbCycles;

    //var numEtape = parseInt(props.nomEtape.substring(5));

    //la partie Action n'est activée que si il y a lieu (sans doute aurait on pu utiliser numEtape)
    var partieActionActivee  = (!(props.actionNumber > 0) || nbAct == 0) ? false : true;
    var partieSessionActivee = (!(props.sessionNumber > 0) || nbSessions == 0) ? false : true;

    var pluraliteCertif = nbCertif > 0 ? nbCertif > 1  ? `s (${nbCertif})` : ` (${nbCertif})` : ' (0)';
    var pluraliteProf = nbProf == 1 ? ' (1)' : ' (0)';
    var pluraliteContactForm = nbContactsFormation > 0 ? nbContactsFormation > 1  ? `s de la formation (${nbContactsFormation})` : ` de la formation (${nbContactsFormation})` : ' de la formation (0)';
    var pluraliteAction     =  nbAct > 1  ? `s (${nbAct})` : ` (${nbAct})`;

    var pluraliteFormateur  = partieActionActivee == true ? nbFormateurs > 1  ? `s (${nbFormateurs})` : ` (${nbFormateurs})` : 's';
    var pluraliteLieu       = partieActionActivee == true ? nbLieux > 1  ? `x (${nbLieux})` : ` (${nbLieux})` : 'x';
    //var pluraliteCertifFormateurs       = partieActionActivee == true ? nbCertifsFormateurs > 1  ? `s (${nbCertifsFormateurs})` : ` (${nbCertifsFormateurs})` : 's';
    var pluraliteEquipement = partieActionActivee == true ? nbEquipement > 1  ? `s (${nbEquipement})` : ` (${nbEquipement})` : 's';
    var pluraliteSession     = partieActionActivee ==  true ? nbSessions > 1  ? `s (${nbSessions})` : ` (${nbSessions})` : 's';

    var pluraliteContactSess = partieSessionActivee ==  true ? nbContactsSession > 1  ? `s de la session (${nbContactsSession})` : ` de la session (${nbContactsSession})` : 's de la session';
    var pluraliteRecrutement = partieSessionActivee ==  true ? nbRecrutements > 1  ? `s (${nbRecrutements})` :` (${nbRecrutements})` : 's';
    var pluraliteCycle = partieSessionActivee ==  true ? nbCycles > 1  ? `s (${nbCycles})` :` (${nbCycles})` : 's';

    useEffect(() => {
        //if(global.config.modeDebugApp == 1)  console.log('useffect LeftMenu, props.infosGenFormation : ', props.infosGenFormation)
    }, [props.infosGenFormation]);

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          <div>Veuillez vous rendre sur les étapes grisées <span className="fas fa-circle-check nogood"></span> pour compléter la formation.</div>
        </Tooltip>
    );

    return (<aside className="left-menu">
                <div className="in">
                    {props.infosGenFormation.estValideLheoFormation == 'ok' ? 
                    <>
                        <span className="etat-form-ok">Etat de la formation : complète</span> 
                        <span className="fas fas fa-circle-check" style={{fontSize: "1.6em", marginLeft: "10px"}}></span>
                    </>
                    : 
                    <>
                        <span className="etat-form-nok">Etat de la formation : incomplète</span>
                        <OverlayTrigger  placement="right" overlay={renderTooltip} > 
                            <button className="simply" type='button'><span className="fas fa-circle-question"></span></button>
                        </OverlayTrigger>
                    </>
                    }
                    <br /><br />
                    Formation 
                    <ul>
                        {/*<li>                            
                            {props.infosGenFormation.etape1 == 'ok' ? <span className="fas fa-circle-check"></span> : <span className="fas fa-circle-check nogood"></span>}&nbsp;
                            <NavLink className={(navData) => (navData.isActive ? "left-menu-nav-itemOn" : 'left-menu-nav-item')} to={"/formation/etape1/" + props.formationNumber}>
                            <span className="fas fa-location-arrow"></span>Certification ()</NavLink>
                        </li>
                        
                        <li>
                            {props.infosGenFormation.etape2 == 'ok' ? <span className="fas fa-circle-check"></span> : <span className="fas fa-circle-check nogood"></span>}
                            <NavLink className={(navData) => (navData.isActive ? "left-menu-nav-itemOn" : 'left-menu-nav-item')} to={"/formation/etape2/" + props.formationNumber}>
                            <span className="fas fa-location-arrow"></span>Informations Principales</NavLink>
                        </li>
                        AfficheLienEtape (props, numeEtape, intituleEtape, tabluation, lienActif = true)
                        */ }

                        {props.infosGenFormation.certifOuPro == 'certif' ? 
                            AfficheLienEtape(props, 1, `Certification${pluraliteCertif} `, 0)
                            :
                            AfficheLienEtape(props, 1, `Professionalisant${pluraliteProf}`, 0)
                        }
                        {AfficheLienEtape(props, 2, "Informations Principales", 0, props.infosGenFormation.etape1 == 'ok')}
                        {AfficheLienEtape(props, 3, `Contact${pluraliteContactForm}`, 0, props.infosGenFormation.etape1 == 'ok' && 
                                                                                      props.infosGenFormation.etape2 == 'ok')}

                        {/* comme dans LiensPrecedentSuivant, on doit gérer la possibilité d'aller à l'étape suivante :
                        pas possibilité de créer d'action temps que les étapes de la formation ne sont pas toutes ok ! */}
                        {AfficheLienEtape(props, 4, `Action${pluraliteAction}`, 0, props.infosGenFormation.etape1 == 'ok' && 
                                                                                   props.infosGenFormation.etape2 == 'ok' && 
                                                                                   props.infosGenFormation.etape3 == 'ok')}
                    </ul>
                        {props.actionNumber > 0 && 
                            <div className="position_arborescence-3-5">Action {props.actionNumber}</div>
                        }
                        {!isNaN(props.actionNumber) && 
                            <div className="position_arborescence-3-5b">
                            {/*Crée le {props.infosGenFormation.act_DATE_CREATION_fr}<br />
                        Modifiée le {props.infosGenFormation.act_DATE_MODIF_fr}<br /> 
                            mesure : {props.infosGenFormation.act_mesure_lib}<br />*/}   
                            {props.infosGenFormation.act_financeur_lib != null && <>Financeur : {props.infosGenFormation.act_financeur_lib}<br /></>}
                            {props.infosGenFormation.isContratProfessionnalisation == true && <>Ct de professionnalisation</>}
                            </div>
                        }
                    <ul>
                        {AfficheLienEtape(props, 5, `Informations principales`, 2, partieActionActivee)}
                        {AfficheLienEtape(props, 6, `Formateur${pluraliteFormateur}`, 2, partieActionActivee && props.infosGenFormation.etape5 == 'ok')}

                        {props.infosGenFormation.certifOuPro == 'certif' ?
                            <>{AfficheLienEtape(props, 7, `Lieu${pluraliteLieu} et certifications`, 4, partieActionActivee 
                            && props.infosGenFormation.etape5 == 'ok'
                            && props.infosGenFormation.etape6 == 'ok')}</> /*${pluraliteCertifFormateurs} possible mais prévoir raffraichissement lorsqu'une certif est retirée par l'of */
                            :
                            <>{AfficheLienEtape(props, 7, `Lieu${pluraliteLieu}`, 4, partieActionActivee && props.infosGenFormation.etape5 == 'ok'
                                                                                                         && props.infosGenFormation.etape6 == 'ok')}</>
                        }

                        {AfficheLienEtape(props, 8, `Equipement${pluraliteEquipement}`, 2, partieActionActivee && props.infosGenFormation.etape5 == 'ok'
                                                                                                               && props.infosGenFormation.etape6 == 'ok' 
                                                                                                               && props.infosGenFormation.etape7 == 'ok')}

                        {/* comme dans LiensPrecedentSuivant, on doit gérer la possibilité d'aller à l'étape suivante :
                        pas possibilité de créer de session temps que les étapes obligatoires de l'action ne sont pas toutes ok ! */}
                        {AfficheLienEtape(props, 9, `Session${pluraliteSession}`, 2, partieActionActivee &&
                                                                                   props.infosGenFormation.etape5 == 'ok' && 
                                                                                   props.infosGenFormation.etape6 == 'ok' && 
                                                                                   props.infosGenFormation.etape7 == 'ok' && 
                                                                                   props.infosGenFormation.etape8 == 'ok')}


                        {props.sessionNumber > 0 && <li><span className="position_arborescence-5-5">Session {props.sessionNumber}</span></li>}

                        {AfficheLienEtape(props, 10, `Informations principales`, 4, partieSessionActivee)}

                         {/*on n'affiche pas cette étape si c'est une formation associée à un seul code certif info et qu'elle n'a pas de bloc)*/}
                        {props.infosGenFormation.certifOuPro == 'certif' && 
                        (props.infosGenFormation.nbCertifications > 1 || props.infosGenFormation.nbCertifAyantDesBlocs > 0) &&
                        <>{AfficheLienEtape(props, 11, `Certifications et blocs`, 4, partieSessionActivee && props.infosGenFormation.etape10 == 'ok')}</>
                        }

                        {AfficheLienEtape(props, 12, `Contact${pluraliteContactSess}`, 4, partieSessionActivee && props.infosGenFormation.etape10 == 'ok')}
                        {AfficheLienEtape(props, 13, `Recrutement${pluraliteRecrutement}`, 4, partieSessionActivee && props.infosGenFormation.etape10 == 'ok')}
                        {/* les cycles ne doivent s'afficher que s'il sagit d'apprentissage 22222222*/}
                        {props.infosGenFormation.act_is_apprentissage == true && AfficheLienEtape(props, 14, `Cycle${pluraliteCycle}`, 4, partieSessionActivee)}
                    </ul>
                    {global.config.modeDebugAppDisplayInfosSupp == 1 &&
                    <div className='debug'>
                        <b>mode modeDebugApp :</b><br />
                        <>utilisateurIdMesureOf : {utilisateurIdMesureOf}
                        <br /></>

                        <>Etat Formation {props.formationNumber} :  
                        {props.infosGenFormation.estValideLheoFormation == 'ok' ? 
                            <span className="fas fa-circle-check"></span> 
                            : 
                            <span className="fas fa-circle-check nogood"></span>}
                        <br /></>

                        {props.actionNumber > 0 && 
                            <>Etat Action {props.actionNumber} : 
                            {props.infosGenFormation.estValideLheoAction == 'ok' ? 
                                <span className="fas fa-circle-check"></span> 
                                : 
                                <span className="fas fa-circle-check nogood"></span>}
                            <br /></>
                        }
                        {props.sessionNumber > 0 && 
                            <>Etat Session {props.sessionNumber} : 
                            {props.infosGenFormation.estValideLheoSession == 'ok' ? 
                                <span className="fas fa-circle-check"></span> 
                                : 
                                <span className="fas fa-circle-check nogood"></span>}
                            <br /></>
                        }

                        <br />
                        {!isNaN(props.formationNumber) && 
                            <>Formation {props.formationNumber}<br />
                            Crée le {props.infosGenFormation.form_DATE_CREATION_fr}<br />
                            Modifiée le {props.infosGenFormation.form_DATE_MODIF_fr}<br />
                            form_deja_exportee : {props.infosGenFormation.form_deja_exportee == true ? <>Oui</> : <>Non</>}<br />
                            certifOuPro (ci ou professionnalisant) :<br />{props.infosGenFormation.certifOuPro} <br /> 
                            aUneCertifInactive : {props.infosGenFormation.aUneCertifInactive == true ? <>Oui</> : <>Non</>}<br /> 
                            nbCertifAyantDesBlocs : {props.infosGenFormation.nbCertifAyantDesBlocs} <br />
                            ID_REF_EXTERNE : {props.infosGenFormation.form_ID_REF_EXTERNE} <br />
                            ID_EXTERNE : {props.infosGenFormation.form_ID_EXTERNE} <br />
                            <br />
                            </>
                        }
                        {!isNaN(props.actionNumber) && 
                            <>Action {props.actionNumber}<br />
                            Crée le {props.infosGenFormation.act_DATE_CREATION_fr}<br />
                            Modifiée le {props.infosGenFormation.act_DATE_MODIF_fr}<br />
                            act_mesure_lib : {props.infosGenFormation.act_mesure_lib}<br />
                            {/*ajout_sess_interdit : {props.infosGenFormation.ajout_sess_interdit}<br />*/}
                            act_is_apprentissage : {props.infosGenFormation.act_is_apprentissage == true ? <>Oui</> : <>Non</>}<br />
                            act_financeur_lib : {props.infosGenFormation.act_financeur_lib}<br />
                            eo_accessibilite : {props.infosGenFormation.eo_accessibilite}<br />
                            act_deja_exportee : {props.infosGenFormation.act_deja_exportee == true ? <>Oui</> : <>Non</>}<br />
                            isContratProfessionnalisation : {props.infosGenFormation.isContratProfessionnalisation == true ? <>Oui</> : <>Non</>}<br />
                            ID_REF_EXTERNE : {props.infosGenFormation.act_ID_REF_EXTERNE} <br />
                            ID_EXTERNE : {props.infosGenFormation.act_ID_EXTERNE} <br />
                            <br />
                            </>
                        }
                        {!isNaN(props.sessionNumber) && 
                            <>
                            sess_deja_exportee : {props.infosGenFormation.sess_deja_exportee == true ? <>Oui</> : <>Non</>}<br />
                            sess_DEBUT_ymd : {props.infosGenFormation.sess_DEBUT_ymd}<br />
                            sess_FIN_ymd : {props.infosGenFormation.sess_FIN_ymd}<br /><br />
                            ID_REF_EXTERNE : {props.infosGenFormation.form_ID_REF_EXTERNE} <br />
                            ID_EXTERNE : {props.infosGenFormation.sess_ID_EXTERNE} <br />
                            </>
                        }
                        {/*
                            <>Session {props.sessionNumber}<br />
                            Crée le {props.infosGenFormation.sess_DATE_CREATION_fr}<br />
                            Modifiée le {props.infosGenFormation.sess_DATE_MODIF_fr}<br /><br />
                            </>
                       */}

                        nbActions : {props.infosGenFormation.nbActions} <br />                        
                        nbCertifications : {props.infosGenFormation.nbCertifications} <br />


                        {/*props.infosGenFormation.etape4*/}
                    </div>

                    }
                </div>
            </aside>);
}

/*
fonctionnait bien, mais pas arrivé à mettre un style grisé sur le libellé lorsque  lienActif !== true, a agrder pour voir suit de param dans style
        <NavLink style={{paddingLeft: tabluation + 'em', pointerEvents: lienActif == true ? '' : 'none'}} 
                 className={(navData) => (navData.isActive ? "left-menu-nav-itemOn" : 'left-menu-nav-item')} 
                 to={"/formation/" + props.formationNumber + "/" + nometape} >
        <span className="fas fa-location-arrow"></span>{intituleEtape}</NavLink>

        comme on le voit pour accéder a une variable de l'objet infosGenFormation , il suffit d'utiliser les crochets []

        lienActif sert a rendre un lien cliquable

*/
function AfficheLienEtape (props, numeEtape, intituleEtape, tabluation, lienActif = true) {

    var nometape = "etape" + numeEtape;
    var to = "/formation/" + nometape + "/" + props.formationNumber ;
    if(numeEtape > 4 && numeEtape <= 9) to = "/formation/" + nometape + "/" + props.formationNumber + "/action-" + props.actionNumber;
    else if(numeEtape > 9) to = "/formation/" + nometape + "/" + props.formationNumber + "/action-" + props.actionNumber + "/session-" + props.sessionNumber;

    return <li>
            {/*picto servant à vérifier la validité d'une étape, on l'affiche noogood si il n'est pas encore navigable */}
            {props.infosGenFormation[nometape] == 'ok' && lienActif == true ? 
                <span className="fas fa-circle-check"></span> 
                : 
                <span className="fas fa-circle-check nogood"></span>}&nbsp;

            {lienActif == true ?  
                <>
                <NavLink style={{paddingLeft: tabluation + 'em'}} 
                        className={(navData) => (navData.isActive ? "left-menu-nav-itemOn" : 'left-menu-nav-item')} 
                        to={to} >
                    <span className="fas fa-location-arrow"></span>{intituleEtape}</NavLink>
                </>
                :
                <span style={{paddingLeft: tabluation + 'em'}}  className="nogood">{intituleEtape}</span>
            }
        </li>
}

LeftMenu.propTypes = {
    formationNumber: PropTypes.number.isRequired,
    actionNumber: PropTypes.number.isRequired,
    sessionNumber: PropTypes.number.isRequired,

    infosGenFormation: PropTypes.object.isRequired,
}
/* necessaire de commenter suite maj dependance
LeftMenu.defaultProps = {
    infosGenFormation: {
        "ID_ACTIONS_FORMATION": 1,

        "LIB_ACTIONS_FORMATION": "titre...",
        "certifOuPro": "pro",
        //"isCHangeCertifOuProPossible": true,

        "nbCertifications": 0,
        "nbProfessionnalisants": 0,
        "nbActions": 0,
        "nbFormateurs": 0,
        "nbSessions": 0,
        "nbCertifAyantDesBlocs": 0,
        "etape1": "ok",
        "etape2": "ok",
        "etape3": "ok",
        "etape4": "ok",
        "etape5": "nok",
        "etape6": "nok",
        "etape7": "nok",
        "etape8": "nok",
        "etape9": "nok",
        "etape10": "nok",
        "etape11": "nok",
        "etape12": "nok",
        "etape13": "nok",
        "etape14": "nok",
        "estValideLheoFormation": "nok",
        "estValideLheoAction": "nok",
        "estValideLheoSession": "nok",
    }
 }
*/
export default LeftMenu