import { useEffect, useState, useContext,  } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { DonneesUtilisateurContext } from '../contexte.js';
import { appelGetSetInfosObjetsFormActSess, recupererProprietes } from '../fonctionsDiv.js';

import {MessageErreurApi} from '../Components/Messages.jsx';
import LoadingSpinner from "../Components/LoadingSpinner";
import LeftMenu from '../Components/LeftMenu.jsx';
import FilAriane from '../Components/FilAriane.jsx';
import FormRepDemandeCorrectionCarif from '../Components/FormRepDemandeCorrectionCarif.jsx';


import FormEtape1 from './FormEtapes/FormEtape1.jsx';
import FormEtape1b from './FormEtapes/FormEtape1b.jsx';
import FormEtape3 from './FormEtapes/FormEtape3.jsx';
import FormEtape2 from './FormEtapes/FormEtape2.jsx';
import FormEtape4 from './FormEtapes/FormEtape4.jsx';
import FormEtape5 from './FormEtapes/FormEtape5.jsx';
import FormEtape6 from './FormEtapes/FormEtape6.jsx';
import FormEtape7 from './FormEtapes/FormEtape7.jsx';
import FormEtape8 from './FormEtapes/FormEtape8.jsx';

import FormEtape9 from './FormEtapes/FormEtape9.jsx';

import FormEtape10  from './FormEtapes/FormEtape10.jsx';
import FormEtape11  from './FormEtapes/FormEtape11.jsx';
import FormEtape12  from './FormEtapes/FormEtape12.jsx';
import FormEtape13  from './FormEtapes/FormEtape13.jsx';
import FormEtape14  from './FormEtapes/FormEtape14.jsx';

import FormEtape2_test from "./FormEtapes/FormEtape2_test.jsx";
/**
 * Return Etape formation
 *
 * @component
 * @summary pour affichage des etapes de la formation
 * @param {  }
 * @return { HTMLElement }
*/
function Formation() {
    var { formationNumber, actionPart, sessionpart, nomEtape } = useParams()
    formationNumber = parseInt(formationNumber);

    var actionNumber = parseInt(); // permet d'avoir NaN au lieu de undefined... pour éviter d'avoir Failed prop type: The prop `actionNumber` is marked as required in `FilAriane`, but its value is `undefined`.
    if(actionPart != null) {
      var [partA1, partA2] = actionPart.split("-"); 
      var actionNumber = parseInt(partA2);/* avant : actionNumber = parseInt(actionNumber);*/
    }

    var sessionNumber = parseInt();
    if(sessionpart != null) {
      var [partA1, partA2] = sessionpart.split("-"); 
      var sessionNumber = parseInt(partA2); /* avant : sessionNumber = parseInt(sessionNumber); */
    }
    //console.log("actionNumber : ", actionNumber);
    //console.log("sessionNumber : ", sessionNumber);

    const navigate = useNavigate(); // utile pour changer de page
    const { utilisateurRouteLogin, utilisateurBearer } = useContext(DonneesUtilisateurContext);

    const [dataLoaded, setDataLoaded] = useState(false);
    const [messageProbleme, setMessageProbleme] = useState();

    const [infosGenFormation, SetInfosGenFormation] = useState(undefined); // infosGenFormation est passé à leftMenu et à LiensPrecedentSuivant
    const [infosProprietes, SetInfosProprietes] = useState(undefined); // contient les infos sur les champ (taille max, caractre obligatoire, éditable...)


    async function chargementsConsecutifs() { 
      setDataLoaded(false);
      await appelGetSetInfosObjetsFormActSess(utilisateurBearer, formationNumber, actionNumber, sessionNumber, SetInfosGenFormation, setMessageProbleme);
      
      //await verifMaintenance(); // commenté pour éviter requete de plus
      setDataLoaded(true);
    }

    useEffect(() => {
      if(global.config.modeDebugApp == 1) console.log('useffect Formation')
      document.title = "Edition d'une formation";
      if (utilisateurBearer === '000000' || formationNumber.length == 0) navigate(utilisateurRouteLogin); // si le status n'est pas bon on redirige sur l'acceuil

      chargementsConsecutifs();
    }, []);

    async function chargementsMajActOuSess() { 
      //setDataLoaded(false); surtout pas comme il est utilisé dans le retourn pour l'affichage, ca recharge 2 fois l'tetape
      if(global.config.modeDebugApp == 1)  console.log("**********recupererProprietes (apiGetProprietes) depuis Formation "); 
      await recupererProprietes(utilisateurBearer, formationNumber, actionNumber, sessionNumber, SetInfosProprietes, setMessageProbleme); // pas besoin de tester le retour, il a déjà setMessageProbleme
      //setDataLoaded(true);
    }

    // dès qu'il y a changement de l'action ou de la session consultée il faut metttre à jour l'info de infosProprietes
    // à noter que ce useEffect s'execute aussi au chargement de la page
    useEffect(() => { 
      chargementsMajActOuSess();
    }, [actionNumber, sessionNumber]);

    var composantEtapeFor= '';
    
    if(infosGenFormation !== undefined && infosProprietes !== undefined) {
      // le formulaire d'édition de formation est décomposé en plus d'une dizaine d'étapes, il y a un composant par étape
      if(nomEtape == 'etape1') { // ex typeTocreate == "certif" typeTocreate == "pro", était dans les useParams
        if(infosGenFormation.certifOuPro ==  "pro") {
              composantEtapeFor = <FormEtape1b formationNumber={formationNumber} 
                                  SetInfosGenFormation={SetInfosGenFormation} infosGenFormation={infosGenFormation} infosProprietes={infosProprietes} />;
            }
        else {
          composantEtapeFor = <FormEtape1 formationNumber={formationNumber} 
                              SetInfosGenFormation={SetInfosGenFormation} infosGenFormation={infosGenFormation} infosProprietes={infosProprietes} />;
        }
      }

      //else if(nomEtape == 'etape1b') {
      //  composantEtapeFor = <FormEtape1b formationNumber={formationNumber} 
      //                      SetInfosGenFormation={SetInfosGenFormation} infosGenFormation={infosGenFormation} infosProprietes={infosProprietes} />;
      //}
      else if(nomEtape == 'etape2')  {
        composantEtapeFor = <FormEtape2 formationNumber={formationNumber} 
                            SetInfosGenFormation={SetInfosGenFormation} infosGenFormation={infosGenFormation} infosProprietes={infosProprietes} />;
      }
      else if(nomEtape == 'etape3') {
        composantEtapeFor = <FormEtape3 formationNumber={formationNumber} 
                            SetInfosGenFormation={SetInfosGenFormation} infosGenFormation={infosGenFormation} infosProprietes={infosProprietes} />;
      }
      else if(nomEtape == 'etape4') {
        composantEtapeFor = <FormEtape4 formationNumber={formationNumber} 
                            SetInfosGenFormation={SetInfosGenFormation} infosGenFormation={infosGenFormation} infosProprietes={infosProprietes} />;
      }
      else if(nomEtape == 'etape5') {
        composantEtapeFor = <FormEtape5 formationNumber={formationNumber} actionNumber={actionNumber} 
                            SetInfosGenFormation={SetInfosGenFormation} infosGenFormation={infosGenFormation} infosProprietes={infosProprietes} />;
      }
      else if(nomEtape == 'etape6') {
        composantEtapeFor = <FormEtape6 formationNumber={formationNumber} actionNumber={actionNumber} 
                            SetInfosGenFormation={SetInfosGenFormation} infosGenFormation={infosGenFormation} infosProprietes={infosProprietes} />;
      }
      else if(nomEtape == 'etape7') {
        composantEtapeFor = <FormEtape7 formationNumber={formationNumber} actionNumber={actionNumber} 
                            SetInfosGenFormation={SetInfosGenFormation} infosGenFormation={infosGenFormation} infosProprietes={infosProprietes} />;
      }
      else if(nomEtape == 'etape8') {
        composantEtapeFor = <FormEtape8 formationNumber={formationNumber} actionNumber={actionNumber} 
                            SetInfosGenFormation={SetInfosGenFormation} infosGenFormation={infosGenFormation} infosProprietes={infosProprietes} />;
      }
      else if(nomEtape == 'etape9') {
        composantEtapeFor =  <FormEtape9 formationNumber={formationNumber} actionNumber={actionNumber} 
                             SetInfosGenFormation={SetInfosGenFormation} infosGenFormation={infosGenFormation} infosProprietes={infosProprietes} />;
      }
      else if(nomEtape == 'etape10') {
        composantEtapeFor = <FormEtape10 formationNumber={formationNumber} actionNumber={actionNumber} sessionNumber={sessionNumber} 
                            SetInfosGenFormation={SetInfosGenFormation} infosGenFormation={infosGenFormation} infosProprietes={infosProprietes} />;
      }
      else if(nomEtape == 'etape11') {
        composantEtapeFor = <FormEtape11 formationNumber={formationNumber} actionNumber={actionNumber} sessionNumber={sessionNumber} 
                            SetInfosGenFormation={SetInfosGenFormation} infosGenFormation={infosGenFormation} infosProprietes={infosProprietes} />;
      }
      else if(nomEtape == 'etape12')  {
        composantEtapeFor = <FormEtape12 formationNumber={formationNumber} actionNumber={actionNumber} sessionNumber={sessionNumber} 
                            SetInfosGenFormation={SetInfosGenFormation} infosGenFormation={infosGenFormation} infosProprietes={infosProprietes} />;
      }
      else if(nomEtape == 'etape13')  {
        composantEtapeFor = <FormEtape13 formationNumber={formationNumber} actionNumber={actionNumber} sessionNumber={sessionNumber} 
                            SetInfosGenFormation={SetInfosGenFormation} infosGenFormation={infosGenFormation} infosProprietes={infosProprietes} />;
      }
      else if(nomEtape == 'etape14')  {
        composantEtapeFor = <FormEtape14 formationNumber={formationNumber} actionNumber={actionNumber} sessionNumber={sessionNumber} 
                            SetInfosGenFormation={SetInfosGenFormation} infosGenFormation={infosGenFormation} infosProprietes={infosProprietes} />;
      }
      else if(nomEtape == 'FormEtape2_test')  composantEtapeFor = <FormEtape2_test formationNumber={formationNumber} SetInfosGenFormation={SetInfosGenFormation} infosGenFormation={infosGenFormation} infosProprietes={infosProprietes} />;
    }

    // si on teste pas infosGenFormation, LeftMenu et LiensPrecedentSuivant disent que ce n'est pas un objet (on aurait pu aussi désactiver le PropTypes)
    return (
        <main className="sous-bloc">
          {process.env.REACT_APP_MY_ENV != 'production' && <MessageErreurApi message={global.config.msgPrTechnique.msgSrvTest} />}
          <MessageErreurApi message={messageProbleme} />
          <LoadingSpinner dataLoaded={dataLoaded}/>

          {(!messageProbleme && dataLoaded && infosGenFormation) && 
            <>
            <FilAriane infosGenFormation={infosGenFormation}
                       formationNumber={formationNumber} actionNumber={actionNumber} sessionNumber={sessionNumber} nomEtape={nomEtape} />

            <FormRepDemandeCorrectionCarif infosGenFormation={infosGenFormation}
                          formationNumber={formationNumber} actionNumber={actionNumber} sessionNumber={sessionNumber} nomEtape={nomEtape} />

            <div className="edit-form">
                <LeftMenu formationNumber={formationNumber} 
                          actionNumber={actionNumber}
                          sessionNumber={sessionNumber} 
                          infosGenFormation={infosGenFormation}
                          nomEtape={nomEtape} />
                <div className="corps-form">

                {composantEtapeFor}

                {/*<LiensPrecedentSuivant  formationNumber={formationNumber} nomEtape={nomEtape} infosGenFormation={infosGenFormation} /> desactivé pour pouvoir gérer l'inactivité depuis chaque form*/}
                </div>
            </div>
            
            </>
          }

        </main>
    )

}


export default Formation;