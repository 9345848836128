import PropTypes from 'prop-types';
import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";

import { DonneesUtilisateurContext } from '../../contexte.js';
import { appelGetSetInfosObjetsFormActSess, retourneCauseInterdictionSess } from '../../fonctionsDiv.js';

import { apiActAppelGetInfosDiv } from '../../Datas/dataActions.js'
import { apiSessAjout, apiSessSuppr } from '../../Datas/dataSessions.js'

import LiensPrecedentSuivant from '../../Components/LiensPrecedentSuivant.jsx';
import LoadingSpinner from "../../Components/LoadingSpinner";
import MessageInfoBox, {MessageErreurApi} from '../../Components/Messages.jsx';
import Table from '../../Components/Table.jsx'
import ButtonDeBase, {ButtonVoirFiche2, ButtonSuppr } from '../../Components/ButtonsCustom.jsx';


/**
 * Return Formulaire étape 9 (session liste ou page permettant d'en ajouter une)
 *
 * @component
 * @summary page étape *
 * @param {  }
 * @return { HTMLElement }
*/
function FormEtape9(props) {
    let numEtape = 4;

    const formationNumber = props.formationNumber;
    const actionNumber = props.actionNumber;

    const navigate = useNavigate(); // utile pour changer de page
    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const [dataApi, setDataApi] = useState();
    const [dataLoaded, setDataLoaded] = useState(true);
    const [messageProbleme, setMessageProbleme] = useState();
    const [messageBase, setMessageBase] = useState('');
    const [btCreerActif, setBtCreerActif] = useState(true);
    
    const columns = useMemo(
        () => [
          {
            id:'listesSessions',
            hideHeader : false,
            trieDefautRp : 'ID_ACTIONS_SESSION_PERIODE',
            columns: [
                { 
                  Header: 'N° de session', 
                  accessor: 'ID_ACTIONS_SESSION_PERIODE', 
                },
                { 
                  Header: 'Diplome(s) préparé(s)', 
                  accessor: 'intitules_diplome',
                  disableSortBy: true,
                },
                { 
                  Header: 'Validation des blocs', 
                  accessor: 'libs_types_blocs',
                  disableSortBy: true,
                },
                { 
                  Header: 'Début', 
                  accessor: 'DATE_DEBUT', // ne surtout pas donner de date directment en fr : le trie ne marche pas, c'est vérifié
                },
                { 
                  Header: 'Fin', 
                  accessor: 'DATE_FIN',
                },
                { 
                  Header: 'Date création', 
                  accessor: 'date_creation',
                },
                { 
                  Header: 'Date modification', 
                  accessor: 'date_modification',
                },
                {
                  Header: () => <div style={{ textAlign: "center", width: "100%" }}></div>,
                    accessor: 'actions', 
                    id: "actions_center", // le fait de mettre "center" dans l'id permet de center le contenu de la colone
                    disableSortBy: true,
                    Cell: ({ row }) => (
                      <>
                        <ButtonVoirFiche2 className="fas fa-pen-to-square" lien={`/formation/etape10/${formationNumber}/action-${row.original.ID_ACTIONS_SESSIONS}/session-${row.original.ID_ACTIONS_SESSION_PERIODE}`} />
                        {/*<NavLink title="Accéder à la fiche" to={`/formation/etape10/${formationNumber}/action-${row.original.ID_ACTIONS_SESSIONS}/session-${row.original.ID_ACTIONS_SESSION_PERIODE}`}>
                          <ButtonVoirFiche className="fas fa-pen-to-square" />
                        </NavLink> */}
                        {/* si la session est périmée de moins d'un certain nombre de jour ou qu'elle n'est pas terminée, on ne permet pas la suppression */}
                        { (row.original.cause_intervention_interdite == null || row.original.cause_intervention_interdite == "") ?
                            <NavLink title={`Supprimer ${row.original.ID_ACTIONS_FORMATION}/${row.original.ID_ACTIONS_SESSIONS}/${row.original.ID_ACTIONS_SESSION_PERIODE}`} to="" onClick={() => handleClickSupprSession(row.original.ID_ACTIONS_SESSION_PERIODE)}>
                              <ButtonSuppr />
                            </NavLink>
                            :
                            <NavLink title={retourneCauseInterdictionSess(row.original.cause_intervention_interdite)}
                                    to="" 
                                    onClick={() => hdleClickNoPossible(row.original.cause_intervention_interdite)}>
                              <ButtonSuppr disabled="disabled" />
                            </NavLink>
                        }
                    </>
                    ),
                },
            ]
          },
        ],
        []
    )

    function  hdleClickNoPossible (cause_intervention_interdite) {
      alert(retourneCauseInterdictionSess(cause_intervention_interdite, 1));
    }
    
    const handleClickSupprSession  = async (codeSession) => {
      var confirmation = confirm("Etes vous sûr de vouloir supprimer cette session ?");
      if(confirmation == true) {
        setDataLoaded(false);
        var dataR = await apiSessSuppr(utilisateurBearer, codeSession);
        if(global.config.modeDebugApp == 1) console.log('retour apiSessSuppr : ', dataR);
        switch (dataR.statusCode) {
            case 204:
              //alert("La session a bien été supprimée");
              await recupererListeCompleteSess();

              // on appelle cette m comme dans l'étape 5 et 10, cela permet d'afficher correctement les pictos de validité lhéo et le bon nombre de contact,recrutement et cycles
              var retAppel = await appelGetSetInfosObjetsFormActSess(utilisateurBearer, formationNumber, actionNumber, null, props.SetInfosGenFormation, setMessageProbleme);
              if(retAppel != true) setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
              break;
            case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
            default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
        setDataLoaded(true);
      }
    }
   async function recupererListeCompleteSess() {
        const dataR = await apiActAppelGetInfosDiv(utilisateurBearer, "/" + actionNumber + "/sessions");
        if(global.config.modeDebugApp == 1) console.log('retour apiActAppelGetInfosDiv dans recupererListeCompleteSess : ', dataR);
        setDataLoaded(false);
        switch (dataR.statusCode) {
            case 200:
                let presenceInvalide = false;
                //boucle sur le contenu renvoyé par l'api pour savoir s'il y a des incomplètes
                dataR.dataApi.forEach((el) => {
                    if(el.Etat_LHEO_Valide == 0) {
                      presenceInvalide = true;
                    }   
                });
                let messageBase = "Cette page liste les sessions de l'action en cours et vous permet d'en ajouter de nouvelles.<br />";
                if(dataR.dataApi.length > 0 && presenceInvalide == true ) messageBase += "Les sessions qui ne sont pas valides sont repérées sur fond orange.<br />";
                if(dataR.dataApi.length > 0) messageBase += 'Vous pouvez en ajouter une en cliquant sur le bouton "Créer une session".<br />';
                else messageBase += 'Vous pouvez en créer une en cliquant sur le bouton "Créer une session".<br />';
                setMessageBase(messageBase);
                setDataApi(dataR.dataApi);
                // pas très utile la premire fois mais lors de la suppression oui :
                var objaAmodif = JSON.parse(JSON.stringify(props.infosGenFormation)); // on clone (duplique) l'objet pour que tout fonctionne bien
                objaAmodif["nbSessions"] = dataR.dataApi.length;
                //objaAmodif["etape9"] = dataR.dataApi.length > 0 ? "ok" : "nok"; surtout pas, si une session est invalide alors l'étape 0 l'est
                props.SetInfosGenFormation(objaAmodif);
              break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
        setDataLoaded(true);
   }

  const handleClickCreateSession = async () => {
    setBtCreerActif(false);
    var dataR = await apiSessAjout(utilisateurBearer, actionNumber);
    switch (dataR.statusCode) {
        case 200:
            //var objaAmodif = JSON.parse(JSON.stringify(props.infosGenFormation)); // on clone (duplique) l'objet pour que tout fonctionne bien
            //objaAmodif["nbSessions"] = objaAmodif["nbSessions"]+1;
            //props.SetInfosGenFormation(objaAmodif);
            // pour que le systeme calcule la validité Lhéo après l'enregistrement....
            var sessionNumber = dataR.dataApi;

            // commenté le  1910 vue qu'on le fait déjà au chargement de étape 10
            //var retAppel = await appelGetSetInfosObjetsFormActSess(utilisateurBearer, formationNumber, actionNumber, sessionNumber, props.SetInfosGenFormation, setMessageProbleme);
            //if(retAppel == true) 
            navigate(`/formation/etape10/${formationNumber}/action-${actionNumber}/session-${sessionNumber}`);
          break;
        case 401:
            setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
          break;
        case 400:
            setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "Vous n'avez pas les droits pour ajouter une session à l'action XXX"
            return false;
        default:
            setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
  }
    
  }

  async function chargementsConsecutifs() {
    setDataLoaded(false);
    var pb = false;
    // on appelle cette methode au chargement comme dans l'étape 5 et 10, cela permet d'afficher correctement les pictos de validité lhéo et le bon nombre de contact,recrutement et cycles
    //var retAppel = await appelGetSetInfosObjetsFormActSess(utilisateurBearer, formationNumber, actionNumber, null, props.SetInfosGenFormation, setMessageProbleme);
    //if(retAppel != true) pb = true;
    if(pb == false) await recupererListeCompleteSess();
    setDataLoaded(true)
  }

  useEffect(() => {
    if(global.config.modeDebugApp == 1) console.log('useffect FormEtape9');
    document.title = `Session(s) de l'action ${actionNumber}`;
    chargementsConsecutifs();
  }, []);


    return (
      <>
        <LoadingSpinner dataLoaded={dataLoaded}/>
        <MessageErreurApi message={messageProbleme} />
        <MessageInfoBox message={messageBase} />

        <section className="certifications-associees">            
            {/*<div className="titre">Sessions de l'action</div> */}
            { dataLoaded > 0 && dataApi &&
            <>
            {dataApi.length > 0 ? <Table columns={columns} data={dataApi} noPagination={true} noDisplayNbResult={true} noFilter={true} /> : <div>Aucune session n'a été trouvée</div>}
            </>}
            
            <div className="partie-bouton-crea">
              {props.infosProprietes["ACTIONS_SESSIONS|other_possibiliteAjoutSess"].editable == 1 ?
                <ButtonDeBase onClick={handleClickCreateSession} lib="Créer une session" disabled={btCreerActif == true ? false : true} />
              :
              <div>
                Vous ne pouvez pas ajouter de session sur cette action
                {props.infosProprietes["ACTIONS_SESSIONS|other_possibiliteAjoutSess"].aide != "" && <b><br />Cause : {props.infosProprietes["ACTIONS_SESSIONS|other_possibiliteAjoutSess"].aide}</b>}
              </div>
              }
            </div>
        </section>

        <LiensPrecedentSuivant formationNumber={formationNumber} actionNumber={actionNumber} numEtape={numEtape} infosGenFormation={props.infosGenFormation} notifBeCareful={false} /> 
      </>
    )

}

FormEtape9.propTypes = {
  formationNumber: PropTypes.number.isRequired,
  actionNumber: PropTypes.number.isRequired,
  SetInfosGenFormation : PropTypes.func,
  infosGenFormation: PropTypes.object,
}

export default FormEtape9;