import { useContext, useEffect } from 'react';
import jwt_decode from "jwt-decode";
//import { useNavigate } from "react-router-dom";

import { DonneesUtilisateurContext } from './contexte.js';

/**
 * Return NavLink
 * @component
 * @summary Permet de charger une scss différente si c'est un admin qui est connecté
 * @return { HTMLElement }
*/
const ThemedComponent = () => {
  //const navigate = useNavigate(); // nok : Uncaught Error: useNavigate() may be used only in the context of a <Router> component.
  const { utilisateurRouteLogin, utilisateurBearer, setBearer, utilisateurRole } = useContext(DonneesUtilisateurContext);
  //const utilisateurRole = "AdminExtranet";
  //return utilisateurRole; a noter qu'un composant peut retourner une valeur ainsi, il faut alors utiliser la valeur ainsi : if(utilisateurRole()  == "AdminExtranet")


  // pour personnalisation du thème
  useEffect(() => {
    if(global.config.modeDebugApp == 1) console.log("keepit : ", utilisateurRole);
    if(utilisateurRole == "AdminExtranet") {
      //require('./SCSS/AppPro.scss'); ne fonctionne pas en prod : le scss est inclu quel que soit l'utilisateur..
      document.documentElement.style.setProperty('--user-theme-color', "#8aaadc5c");
    }
    else document.documentElement.style.setProperty('--user-theme-color', "#ececec");
  }, [utilisateurRole]); 
  
  // pour gestion expiration
  useEffect(() => {
    if(utilisateurBearer !== '000000') {
      var decodedBearer = jwt_decode(utilisateurBearer);

      if(global.config.modeDebugApp == 1) console.log("decodedBearer: ", decodedBearer);
      var exp = decodedBearer["exp"] * 1000 ;
      var timenow = Date.now()
      if(exp < timenow) {
        if(global.config.modeDebugApp == 1) console.log(`session epxirée !!! ${exp} < ${timenow}`);
        setBearer('000000');
        //navigate(`/`); // pro-bo-paca
      }
    }
  }, [utilisateurBearer]); 

  useEffect(() => {
    if(global.config.modeDebugApp == 1) console.log("utilisateurRouteLogin: ", utilisateurRouteLogin);
  }, []); 
  return (
    <></>
  );

};

export default ThemedComponent;