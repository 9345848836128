import PropTypes from 'prop-types';
import { useState, useContext} from 'react';
import Modal from 'react-bootstrap/Modal';

import { DonneesUtilisateurContext } from '../contexte.js';

import { apiSoumissionFormulaireDemande } from '../Datas/datasAutre.js'

import ButtonDeBase from './ButtonsCustom.jsx';
import LoadingSpinner from "./LoadingSpinner";
import { MessageErreurApi } from './Messages.jsx';

/**
 * Component for message carif
 * Affiche un mini formulaire permettant d'indiquer aux carif la modification d'informations concernant la structure
 * L'api utilisée est la meme que dans FormRepDemandeCorrectionCarif
 * @component
 * @return { HTMLElement }
*/
function FormDemandeChangementInfoStructure(props) {

    //console.log(`props : ${props}`)
    const { utilisateurOrg } = useContext(DonneesUtilisateurContext);
    const { utilisateurNomOrg } = useContext(DonneesUtilisateurContext);
    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);

    const [showModal, setShowModal] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(true);
    const [messageProbleme, setMessageProbleme] = useState();

    const handleCloseModal = () => {
        setShowModal(false);
    }
    const handleClickEnvoiInfos = async () => {          
        var msgPourCarif = document.getElementById("message-pour-carif").value;
        var demandeurMailPerso = document.getElementById("DemandeurMailPerso").value;
        if(msgPourCarif.length == 0) {alert("Veuillez saisir un message"); return false;}
        if(demandeurMailPerso.length == 0) {alert("Veuillez saisir un email"); return false;}
        //console.log(msgPourCarif);
        //console.log(props.formationNumber);

        var datasToPost= { // on créé ici comme pas d'info a donner tout de suite....
            "commentaire": msgPourCarif,
            "DemandeurOrgId" : utilisateurOrg,
            "DemandeurNomOrganisme" : utilisateurNomOrg,

            "DemandeurMailPerso" : demandeurMailPerso,

            "DemandeurNumDe" : props.dataApi[0].NUM_DE,
            "DemandeurSiret" : props.dataApi[0].SIRET_COMPANIES,
        }
        setDataLoaded(false);
        var dataR = await apiSoumissionFormulaireDemande(utilisateurBearer, datasToPost, "envoi-formulaire-correction/correction-demande-structure");
        if(global.config.modeDebugApp == 1) console.log('retour apiSoumissionFormulaireDemande avec "envoi-formulaire-correction/correction-demande-structure" : ', dataR);
  
        switch (dataR.statusCode) {
            case 200: 
                setDataLoaded(true);
                alert("L'envoi a bien été effectué, vous aurez bientôt des nouvelles.")
                handleCloseModal();
                break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
                break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }

    }

        var strPourInfo = `Vous souhaitez apporter une modification sur l'une des informations de cette page ?`;
        return (
        <>
        <div className="message-info2" style={{backgroundColor: "#fafafa", marginBottom: "10px"}}>
            Vous souhaitez apporter une modification sur l'une des informations de cette page ?
            <ButtonDeBase typeSubmit={false} lib="Demandez une modification" onClick={() =>setShowModal(true)} className2="myside2" />
        </div>
        <Modal size="lg" show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} centered aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
                <>J'envoie ma demande au Carif</>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadingSpinner dataLoaded={dataLoaded}/>
                <MessageErreurApi message={messageProbleme} />
                Le carif va recevoir les corrections que vous souhaitez apporter et vous répondra dans les plus brefs délais.<br />
                Veuillez préciser ci-dessous l'objet de votre demande ainsi que votre adresse mail si elle diffère de celle associée à votre compte :<br />

                <textarea style={{marginTop: "10px", width: "770px", height: "100px"}} placeholder='Merci de détailler votre demande le plus précisément possible' id="message-pour-carif"></textarea>
                <br /><br />
                <input className="form-control input-lib" placeholder='Votre e-mail pour que le carif vous réponde directement' type="email" id="DemandeurMailPerso" />

            <ButtonDeBase typeSubmit={false} lib="Je confirme 👍" onClick={() =>handleClickEnvoiInfos()} />
            </Modal.Body>
        </Modal>
        <br />
        </>
        )
}

FormDemandeChangementInfoStructure.propTypes = {
    dataApi: PropTypes.array.isRequired,
}

export default FormDemandeChangementInfoStructure;