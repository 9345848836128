import PropTypes from 'prop-types';
import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import { Container } from "react-bootstrap";
import {NavLink } from "react-router-dom";

import { DonneesUtilisateurContext } from '../../contexte.js';
import { apiEquipementAjout, apiEquipementAppelGetInfosDiv, apiEquipementSuppr } from '../../Datas/dataActionsEquipement.js'

import LiensPrecedentSuivant from '../../Components/LiensPrecedentSuivant.jsx';
import LoadingSpinner from "../../Components/LoadingSpinner";
import MessageInfoBox, { MessageErreurApi, MessageInfoNoDroitErreurApi } from '../../Components/Messages.jsx';
import ButtonDeBase, { ButtonSuppr } from '../../Components/ButtonsCustom.jsx';
import FormRHF from "../../Components/Form/Form";
import FormControl from "../../Components/Form/FormControl";
import FormSelect from "../../Components/Form/FormSelect";
import FormHidden from "../../Components/Form/FormHidden";
import Table from '../../Components/Table.jsx';
import { appelRecupInfoGet, confirmationSiCLickSUrLien } from '../../fonctionsDiv.js'

/**
 * Return Formulaire étape 8 (équipements)
 *
 * @component
 * @summary page étape 8
 * @param {  }
 * @return { HTMLElement }
*/
function FormEtape8(props) {
    let numEtape = 8;

    const formationNumber = props.formationNumber;
    const actionNumber = props.actionNumber;


    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const [dataApi, setDataApi] = useState();
    const [aumoinsUneModif, setAumoinsUneModif] = useState(false); // pour avertir l'utilisateur en cas de sortie sans  enregistrement

    const [dataLoaded, setDataLoaded] = useState(true);
    const [messageProbleme, setMessageProbleme] = useState();

     const columns = useMemo(
      () => [
        {
          id:'listesEquipements',
          hideHeader : false,
          trieDefautRp : 'LIB_LHEO_TYPE_EQUIPEMENT',
          columns: [
              { 
              Header: 'Code', 
                accessor: 'LIB_LHEO_TYPE_EQUIPEMENT',
              },    
              { 
              Header: 'Côut indicatif', 
                accessor: 'COUT_INDICATIF',
              },
              {
                Header: 'Commentaire', 
                accessor: 'COMMENTAIRE',
              },
              {
                Header: () => <></>,
                  accessor: 'actions', 
                  id: "actions_center",
                  disableSortBy: true,
                  Cell: ({ row }) => (
                    <>
                    { (row.original.noEditablePLease != 1) ?
                      <NavLink title={`Supprimer ${row.original.ID_ACTIONS_SESSIONS_EQUIPEMENTS}`} to="" onClick={() => handleClickSupprEquipement(row.original.ID_ACTIONS_SESSIONS_EQUIPEMENTS)}>
                        <ButtonSuppr />
                      </NavLink>
                    :
                      <ButtonSuppr disabled="disabled" />
                    }
                    </>
                  ),
              },
          ]
        },
      ],
      []
    )
    const handleClickSupprEquipement  = async (codeAsupprimer) => {
      var confirmation = confirm("Etes vous sûr de vouloir supprimer cet équipement ?");
      if(confirmation == true) {
        setDataLoaded(false);
        var dataR = await apiEquipementSuppr(utilisateurBearer, actionNumber, codeAsupprimer);
        if(global.config.modeDebugApp == 1) console.log('retour apiEquipementSuppr : ', dataR);
        switch (dataR.statusCode) {
            case 204:
                //alert("L'équipement a bien été supprimé");
                recupererListeCompleteEquipements();
              break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
        setDataLoaded(true);
      }
    }

    async function recupererListeCompleteEquipements() {
      const dataR = await apiEquipementAppelGetInfosDiv(utilisateurBearer, "/"+actionNumber + "/equipements");
      if(global.config.modeDebugApp == 1) console.log('retour apiEquipementAppelGetInfosDiv dans recupererListeCompleteEquipements : ', dataR);
      switch (dataR.statusCode) {
          case 200:
              if(props.infosProprietes["ACTIONS_SESSIONS_EQUIPEMENTS|other_dataEditable"].editable  == false) {
                //modification du contenu renvoyé par l'api (ajout d'une information)
                let nouveauTabRep = [];
                dataR.dataApi.forEach((el) => {
                    // on va ajouter une notion de non modifiable
                    let ligne = { ...el, 'noEditablePLease': 1}
                    nouveauTabRep.push(ligne);           
                });
                //console.log("dataApi modifié:", nouveauTabRep);
                setDataApi(nouveauTabRep);
              }
              else setDataApi(dataR.dataApi);
              //console.log("dataApi", dataR.dataApi)

              var objaAmodif = JSON.parse(JSON.stringify(props.infosGenFormation)); // on clone (duplique) l'objet pour que tout fonctionne bien
              objaAmodif.nbEquipement = dataR.dataApi.length;
              //objaAmodif["etape8"] = "ok";
              props.SetInfosGenFormation(objaAmodif);

              return true;
            break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
            break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        return false;
      }
    }
    
   //pour effectuer des actions en fonction de la saisie de l'utilisateur
    function gestionEvenements(value, name){
      setAumoinsUneModif(true); document.getElementById("hidden_marque_une_modif").value = 1;
      if(global.config.modeDebugApp == 1) console.log (`modif de ${name} : ${value[name]}`);
    }

    const onSubmit = async (values) => {
      for(let i in values) if(values[i] === "") values[i] = null; // sinon génère une erreur : on met à null tout les champs vide (ract-hook-form semblait le faire mais ai eu un problème...)

      if(global.config.modeDebugApp == 1) console.log("Values:::", values);
      if(global.config.modeDebugApp == 1) console.log("Values:::", JSON.stringify(values));

      setAumoinsUneModif(false);
      setDataLoaded(false); // utile pour pas que l'utilisateur soumette plusieurs fois
      var dataR = await apiEquipementAjout(utilisateurBearer, values, "/"+actionNumber + "/equipements/") // on envoie sans faire JSON.stringify
      if(global.config.modeDebugApp == 1) console.log('retour apiEquipementAjout : ', dataR);

      switch (dataR.statusCode) {
          case 200: 
               alert("L'enregistrement s'est bien effectué.");
               await recupererListeCompleteEquipements();
               setMessageProbleme(null);
              break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
            case 400:
                setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "Cet équipement existe déjà et n'a donc pas été ajouté"
              break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
      setAumoinsUneModif(true);
      setDataLoaded(true); // utile pour pas que l'utilisateur soumette plusieurs fois
    };
  
    const onError = (error) => {
      console.log("ERROR:::", error);
    };
    /// eslint-disable-next-line no-unused-vars


    const [dataLHEO_TYPE_EQUIPEMENT, SetDataLHEO_TYPE_EQUIPEMENT] = useState(); // pour recup données du select

    async function chargementsConsecutifs() {
      setDataLoaded(false);
      let retP = await recupererListeCompleteEquipements();
      if(retP == true) {
        var pb = false;
        var ret = await appelRecupInfoGet(utilisateurBearer, "LHEO_TYPE_EQUIPEMENT", setMessageProbleme);
        if(ret != null) SetDataLHEO_TYPE_EQUIPEMENT(ret); else pb = true;
        if(pb == true ) setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
      setDataLoaded(true);
    }

   useEffect(() => {
    	if(global.config.modeDebugApp == 1) console.log('useffect FormEtape8');
    	document.title = `Equipements de l'action ${actionNumber}`;
        setMessageProbleme(null);
        chargementsConsecutifs();

        var links = document.querySelectorAll('a');
         
        // Parcours des liens pour ajouter gestionnaire d'événement au clic
        for (var i = 0; i < links.length; i++) {
          links[i].addEventListener('click', confirmationSiCLickSUrLien);
        }
        // Nettoyage de l'écouteur d'événement lorsque le composant est démonté
        return () => {      
          for (var i = 0; i < links.length; i++) {
            links[i].removeEventListener('click', confirmationSiCLickSUrLien);
          }
        };
   }, []);
    useEffect(() => {
      setAumoinsUneModif(false);
    }, [dataApi]);
    
    // pour éviter erreur "register is not a function" de  react-hook-form quand le Controle n'est pas directement dans le Form
    //https://stackoverflow.com/questions/66215808/typeerror-register-is-not-a-function-using-react-hook-form-in-react
    // utilisation : <InputWithDiv id="date_information2" infosProp={props.infosProprietes["ACTIONS_SESSIONS|date_information2"]} />
    //desactivé sur les autres formulaires car un changement déclanchait gestionEvenements, et setAumoinsUneModif engendrait une perte de focus sur le champs en cours de saisi....
    // a la place : style=" inline-input"
    // on garde ce principe ici mais pas pour 
    const InputWithDiv = (props) => {
      return (<div className="alignement-horizontal-champs-form">
        <FormControl {...props} noCount="1" />
      </div>)
      };
      //<FormSelect id="ID_TYPE_EQUIPEMENT" infosProp={props.infosProprietes["ACTIONS_SESSIONS_EQUIPEMENTS|ID_TYPE_EQUIPEMENT"]} options={dataLHEO_TYPE_EQUIPEMENT} />
      const SelectWithDiv = (props) => {
        return (<div className="alignement-horizontal-champs-form">
          <FormSelect {...props} noCount="1" />
        </div>)
        };
    return (
      <>
        <LoadingSpinner dataLoaded={dataLoaded}/>
        <MessageInfoBox message="Équipements à la charge des stagiaires :<br />
❗ Le renseignement de cette étape est <u>FACULTATIF</u>.<br />
Certaines formations nécessitent des équipements obligatoires ou règlementaires de sécurité, tenue, outillage professionnel que l’apprenant doit acquérir.<br />
Par exemple: chaussures de sécurité, blouse, ordinateur, mallette de couteaux, mallette professionnelle pour la coiffure."  />
        <MessageErreurApi message={messageProbleme} />

        {dataLoaded && dataApi && dataApi != null && props.infosProprietes != null &&
        <Container className="my-0">
            { props.infosProprietes["ACTIONS_SESSIONS_EQUIPEMENTS|other_dataEditable"].editable == 0 ?
              <MessageInfoNoDroitErreurApi />
            :
              <FormRHF onSubmit={onSubmit} onError={onError} gestionEvenements={gestionEvenements} >
                <SelectWithDiv id="ID_TYPE_EQUIPEMENT" infosProp={props.infosProprietes["ACTIONS_SESSIONS_EQUIPEMENTS|ID_TYPE_EQUIPEMENT"]}  options={dataLHEO_TYPE_EQUIPEMENT} />
                <InputWithDiv id="COUT_INDICATIF" infosProp={props.infosProprietes["ACTIONS_SESSIONS_EQUIPEMENTS|COUT_INDICATIF"]} />
                <InputWithDiv id="COMMENTAIRE" infosProp={props.infosProprietes["ACTIONS_SESSIONS_EQUIPEMENTS|COMMENTAIRE"]} />
                <div className="partie-bouton-droite alignement-horizontal-champs-form">
                  <ButtonDeBase typeSubmit={true} lib="Enregistrer" disabled={aumoinsUneModif == true ? false : true} />
                </div>

                <FormHidden id="hidden_marque_une_modif" />
              </FormRHF>
            }

            <section className="section-generale">
                <div className="titre">Equipements actuellement déclarés</div><br />
                {dataApi && dataApi.length > 0 && props.infosProprietes["ACTIONS_SESSIONS_EQUIPEMENTS|other_dataEditable"].editable != 0 && <MessageInfoBox message="Vous pouvez suppprimer et/ou ajouter des équipements" />}
                {dataApi && dataApi.length > 0 && <Table columns={columns} data={dataApi} noPagination={true} noDisplayNbResult={true} noFilter={true} />}
                {dataApi && dataApi.length == 0 && <div>Aucun équipement n'a encore été déclaré (le renseignement des équipements est facultatif).</div>}
            </section>
            <br />
        </Container>
        }

        <LiensPrecedentSuivant formationNumber={formationNumber} actionNumber={actionNumber} numEtape={numEtape} infosGenFormation={props.infosGenFormation} notifBeCareful={aumoinsUneModif} />
      </>
    )

}

FormEtape8.propTypes = {
  formationNumber: PropTypes.number.isRequired,
  actionNumber: PropTypes.number.isRequired,
  SetInfosGenFormation : PropTypes.func,
  infosGenFormation: PropTypes.object,
}

export default FormEtape8;