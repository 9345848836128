import { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams, NavLink } from "react-router-dom";
import jwt_decode from "jwt-decode";

import { apiReinitMotDePasse } from '../Datas/dataLogin.js'

import { DonneesUtilisateurContext } from '../contexte.js';
import {MessageErreurApi} from '../Components/Messages.jsx';
import ButtonDeBase from '../Components/ButtonsCustom.jsx';
import LoadingSpinner from "../Components/LoadingSpinner";
import { recupererVolumes } from '../fonctionsDiv.js';



/**
 * Return template of réinitlisation page
 *
 * @component
 * @summary imported in Route
 * @param {  }
 * @return { HTMLElement }
*/
function ReinitialisationMotPasse() {
    const { tempKeyNewPwd } =  useParams();
    const {setBearer, setRole, setOrg, setNomOrg, setIdMesureOf,  setNbFormTot, setNbFormAttente, setNbFormIncomplete, setNbActAttente, setNbActIncomplete, setNbSessAttente, setNbSessIncomplete } = useContext(DonneesUtilisateurContext);
    const navigate = useNavigate(); // utile pour changer de page.
    
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState(); // 9696 = 7610DB9E380BA9775B3C215346184A87
    const [password2, setPassword2] = useState();

    let [errorMsg, seterrorMsg] = useState(''); // pour afficher un message derreur si champs nok
    const [dataLoaded, setDataLoaded] = useState(true);
    let [messageProbleme, setMessageProbleme] = useState('');

    /**
     * @summary fonction permettant de valider la saisie du formulaire
     * 
     * @return { Boolean }
     */
    function validate() {  
        let errorMessage = '';

        if (!password && !login) { errorMessage = 'Entrez l\'identifiant et le mot de passe' } //
        else if (!login) { errorMessage = 'Entrez l\'identifiant' }
        else if (password.length < 8) { errorMessage = 'Le mot de passe doit posséder au moins 8 caractères' }
        else if (!password) { errorMessage = 'Entrez le mot de passe' }
        else if (!password2) { errorMessage = 'Veuillez confirmer le mot de passe' }
        else if (password != password2) { errorMessage = 'Les mots de passe ne correspondent pas' }

        if (errorMessage) { seterrorMsg(errorMessage); return false; }

        seterrorMsg('');
        return true;
    };


    // lors de la soumission du formulaire  
    const handleSubmit = async (event) => {
        event.preventDefault() // On utilise la fonction preventDefault de notre objet event pour empêcher le comportement par défaut de cet élément lors du clic de la souris
        if (validate()) {   
            if (typeof(Storage) === "undefined") {
                let pb = global.config.msgPrTechnique.msgDefaut + '<br /><br />Détail de l\'erreur à transférer : "Storage non supporté"';
                setMessageProbleme(pb);
                return 0;
            }

            setDataLoaded(false);
            setMessageProbleme('');
            const bearer = await apiReinitMotDePasse(login.trim(), password.trim(), tempKeyNewPwd)
            setDataLoaded(true);
  
            if(typeof bearer == 'undefined') {
                setMessageProbleme(global.config.msgPrTechnique.msgBadIReinit); // Problème technique par défaut
                return 0;
            }

            var decodedBearer = jwt_decode(bearer);
            var role = decodedBearer["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
            var OrgId = decodedBearer["OrgId"];
            var NomOrg = decodedBearer["NomOrg"];
            var IdMesureOf = decodedBearer["IdMesureOf"];
            var UtilisateurId = decodedBearer["UtilisateurId"];

            if (typeof(role) === "undefined" ||  typeof(OrgId) === "undefined" || typeof(NomOrg) === "undefined" || typeof(IdMesureOf) === "undefined") {
                let pb = global.config.msgPrTechnique.msgDefaut + '<br /><br />Détail de l\'erreur à transférer : " une propriété est manquante"';
                setMessageProbleme(pb);
                return 0;
            }

            setRole(role);
            setOrg(OrgId);
            setNomOrg(NomOrg);
            setIdMesureOf(IdMesureOf);

            alert("Votre mot de passe a bien été modifié.\r\n Notez-le et n'oubliez pas de le communiquer aux éventuelles personnes qui se connectent avec ce compte.");
            setBearer(bearer)
            //console.log('Bearer enregistré dans le contexte : ', bearer);
                // récupére nbFoTot, nbFoRejetLheo, nbFoRejetCarif, fait aussi dans FormationGen :
            await recupererVolumes(bearer, setNbFormTot, setNbFormAttente, setNbFormIncomplete, setNbActAttente, setNbActIncomplete, setNbSessAttente, setNbSessIncomplete, setMessageProbleme);

            if(global.config.modeDebugApp == 1) console.log('OrgId issu du bearer enregistré dans le contexte: ', OrgId);
            //if (typeof(Storage) === "undefined") console.log("Storage non supporté!!!!")
            navigate(`/formation`); // /formation profil



        }
    }

    useEffect(() => {
        document.title = "Réinitialisation de votre mot de passe"
    }, []); // crochet vide : 1er rendu seulement

    return (
        <>
            <div className='logo-login'>
                <img src={`${process.env.PUBLIC_URL}/img/logo_CarifOref.png`} alt="Carif Oref Paca" className="feature-icon" />
            </div>

            <h1 className='sign-in-titre'>Extranet des organismes de formation</h1>

            <div className='bloc-centre'>
                <MessageErreurApi message={messageProbleme}  />
            </div>

            <section className="sign-in-content">
                <LoadingSpinner dataLoaded={dataLoaded}/>
                { dataLoaded == true && 
                <form onSubmit={handleSubmit}>
                    <div className='bloc-centre'>
                        <h2>Réinitialisation de votre mot de passe</h2>
                    </div>
                    <div className='bloc-centre'>
                        Si vous avez effectivement bien demandé à réinitialiser votre mot de passe,<br />veuillez renseigner les informations ci-dessous, puis <br /><b>cliquer sur "Validez la réinitialisation du mot de passe</b>".
                    </div>
                    <div>
                        <div>Votre identifiant qui vous a été rappelé dans le mail : </div>
                        <div><input className="form-control form-input-lg-short" placeholder='Identifiant' type="text" id="login" value={login} onChange={(event) => setLogin(event.target.value)} /></div>
                    </div>

                    <div>
                        <div>Votre nouveau mot de passe (8 caractères minimum) :</div>
                        <div><input className="form-control form-input-lg-short" placeholder='Nouveau mot de passe (8 caractères minimum)' type="password" id="password" onChange={(event) => setPassword(event.target.value)} /></div>
                    </div>
                    <div>
                        <div>Votre nouveau mot de passe (pour confirmation) :</div>
                        <div><input className="form-control form-input-lg-short" placeholder='Nouveau mot de passe (confirmation)' type="password" id="password2" onChange={(event) => setPassword2(event.target.value)} /></div>
                    </div>

                    <div className="form-error">
                        {errorMsg ? errorMsg : null}
                    </div>

                    <ButtonDeBase typeSubmit={true } lib="Valider la réinitialisation du mot de passe" disabled={(!login || !password  || !password2)} />
                </form>    
                }           
            </section>

            <div className='image-presentation'>
                <img src={`${process.env.PUBLIC_URL}/img/image_presentation.png`} alt="Carif Oref Paca"  className="feature-presentation" />
            </div>
        </>
    )
}

export default ReinitialisationMotPasse;