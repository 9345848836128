// https://stackoverflow.com/questions/34351804/how-to-declare-a-global-variable-in-react

module.exports = global.config = {
    admin: {
        mail : "eo-technique@cariforef.fr"
    },
    msgPrTechnique: { 
        msgSrvTest : "Attention : vous vous trouvez sur un serveur de test, les données saisies ou modifiées ne seront pas prises en compte.",
        msgBadIdentification : "Les identifiants saisis sont inconnus, veuillez réessayer.<br /> Si le problème persiste, veuillez contacter le support à cette adresse : eo-technique@cariforef.fr",
        msgBadIReinit : "La réinitialisation de votre mot de passe n'a pas fonctionné, veuillez réessayer.<br /> Si le problème persiste, veuillez contacter le support à cette adresse : eo-technique@cariforef.fr",
        msgMaintenance : "Le site est en maintenance, veuillez revenir ulterieurement.<br /> Si le problème persiste, veuillez contacter le support à cette adresse : eo-technique@cariforef.fr",
        msgUnauthorized : "Vous n'êtes plus identifié, veuillez vous reconnecter en cliquant <a href='/?logout=1'>ici</a>. Si le problème persiste, veuillez contacter le support à cette adresse : eo-technique@cariforef.fr",
        msgUnauthorizedAccessObject : "<br />Si vous pensez que c'est une erreur, veuillez contacter le support à cette adresse : eo-technique@cariforef.fr", // message par dessus des messages comme : Formation avec l'id XXXX introuvable.. Vous n'avez pas les droits pour accéder à la formation ayant l'id XXXX"
        msgDefaut : "Problème technique. Veuillez contacter le support à cette adresse : eo-technique@cariforef.fr"
    },
    personnelPaca: {
        mail : "eo-bo@cariforef.fr" // acces : import './config.js'; + global.config.personnelPaca.mail
    },
    apiBase : {
        url: process.env.REACT_APP_API_URL
    },
    apiAuth : {
        url: process.env.REACT_APP_API_URL + "/auth/"
    },
    apiFormationsBase : {
        url: process.env.REACT_APP_API_URL + "/formations"  //  accès : global.config.apiFormations.url
    },
    apiFormationsRecherche : {
        url: process.env.REACT_APP_API_URL + "/formations/Search"  
    },
    apiVolumesObjetsLheo : {
        url: process.env.REACT_APP_API_URL + "/volumes-objets-lheo"  
    },
    apigestioninfosobjetsformactsess : {
        url: process.env.REACT_APP_API_URL + "/gestion-infos-objets-form-act-sess" 
    },
    apiGetProprietes : {
        url: process.env.REACT_APP_API_URL + "/get-proprietes" 
    },
    apiGetProprietesFree : {
        url: process.env.REACT_APP_API_URL + "/get-proprietes-free" 
    },

    apiCertifications : {
        url: process.env.REACT_APP_API_URL + "/certifs/"
    },
    apiProfessionnalisants : {
        url: process.env.REACT_APP_API_URL + "/professionnalisants/"
    },

    apiActionBase : {
        url: process.env.REACT_APP_API_URL + "/actions"  //  accès : global.config.apiActionBase.url
    },
    apiFormateurBase : {
        url: process.env.REACT_APP_API_URL + "/formateurs"
    },
    apiSessionBase : {
        url: process.env.REACT_APP_API_URL + "/sessions"  //  accès : global.config.apiSessionBase.url
    },

    lgMaxChaineTab : 180, //pour troncature si besoin

    nbCertifMaxParFormation : 5,
    nbProfMaxParFormation : 1,
    
    nbFormesPrst4MaxParFormation : 7,
    nbPublicsPrst4MaxParFormation : 10,
    nbSecteursPrst4MaxParFormation : 10,
    nbThematiquesPrst4MaxParFormation : 10,

    //nbNsfMaxParProf : 1,
    //nbRomeMaxParProf : 3,
    nbFcodeMaxParProf : 5, 
    nbPublicViseMaxParAction : 5, 
    nbModalitesPedaMaxParAction : 5,
    nbLanguesMaxParAction : 5,

    tableauResultatNbResultParPage : 10, 

    codesEtatsCiEnCours : [0, 4, 11], //  accès : global.config.codesEtatsCiEnCours

    // tableaux d'objet au lieu d'utiliser l'api (cause pas de table)
    optsDictEtatRecrutement : [{ id: 1, lib: "ouvert" }, 
                               { id: 2, lib: "fermé" },
                               { id: 3, lib: "suspendu" }],
    optsDictModalitesEntreesSorties : [{ id: 0, lib: "entrées/sorties à dates fixes" },
                                       { id: 1, lib: "entrées/sorties permanentes" }],
   optsOuiNon : [{ id: 0, lib: "Non" },
                 { id: 1, lib: "Oui" }],

    optsVerifsQuiforme : [  //{ id: '-', lib: "sans modif" },
                            { id: 'o', lib: "Pris en compte (ne plus mettre en évidence la différence avant prochaine modif)" },
                            { id: 'n', lib: "Non Pris en compte (continuer à mettre en évidence la différence)" }],
    modeDebugApi : 0,
    modeDebugApp : 0,
    modeDebugAppDisplayInfosSupp : 0
};