function AdmQuiforme() {
    return (     
         <main style={{fontSize: "1.1em"}}>
            <br/><b>Les outils de cette section servent à améliorer la qualité des données relatives aux organismes grâce aux informations retournées par l'API Quiforme.</b><br/><br/>

            <b>Périmètre</b> :
            <p style={{paddingLeft: "1.2em"}}>Tout organisme de formation formateur ou responsable ayant au moins une session qui est présent dans l'export Lhéo destiné au RCO.</p>

            <b>Principe :</b><br/><br/>

            <b>"Gestion des différences"</b> :
            <p style={{paddingLeft: "1.2em"}}>Une première interface liste les différences entre les données BROF et quiforme pour les OF actifs :<br />
            Elle permet de mettre en évidence et de traiter 3 types (familles) de différences.<br />
            Pour chacune d'elle , il y a une notion d 'état : vue et corrigé / vue / à traiter.<br/><br/>

            Par défaut :  seuls les éléments qui n'ont pas encore été traités s'affichent.<br/>
            Un clic sur un élément affiche un formulaire permet de voir le détail des différences et d'agir en conséquence : <br/>
            à gauche, l'exhaustivité des informations brof qui ont une correspondance avec les données quiforme, à droite les données quiforme.<br/>

            Pour chaque famille où des différences ont été trouvées, vous devrez préciser si vous avez traité les données en conséquence ou si vous préférez continuer à avoir l'alerte sur la différence.<br/>
            Les éléments d'un of indiqués comme "traités" ne seront plus visibles dans la liste des différence temps qu'un nouveau changement n'est pas perçu.<br /><br />

            L'historique d'un of est enfin visible en bas de ce même formulaire<br /></p>

            <b>"Of actifs introuvable"</b> :
            <p style={{paddingLeft: "1.2em"}}>Une seconde interface liste les Of actifs de la bof dont le siret n'est pas trouvé dans quiforme :<br/>
            Vous pouvez masquer un organisme de cette liste en le passant en état "vu"</p>
         </main>
     )
 }
 export default AdmQuiforme;