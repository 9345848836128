import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import { useNavigate } from "react-router-dom";

import { DonneesUtilisateurContext } from '../../contexte.js';
import { apiCertifAppelBase } from '../../Datas/dataDiplomes.js'
import MessageInfoBox, {MessageErreurApi} from '../../Components/Messages.jsx';
import AdmRechercheCertif from '../../Components/AdmRechercheCertif.jsx'
import DetailCertif from '../../Components/DetailCertif.jsx'


function AdmMoteurSynsCertifs() {
    const navigate = useNavigate(); // utile pour changer de page

    const { setBearer, utilisateurRouteLogin, utilisateurBearer, utilisateurRole } = useContext(DonneesUtilisateurContext);
    const [messageProbleme, setMessageProbleme] = useState();
    const [dataLoadedDetailCi, setDataLoadedDetailCi] = useState(false);
    const [dataDetailCertif, SetDataDetailCertif] = useState();
    const [messageBase, setMessageBase] = useState('');

    const handleClickViewCerifBootStrap  = async (codeDiplomeSelected) => {
        setDataLoadedDetailCi(false);
        var dataR = await apiCertifAppelBase(utilisateurBearer, codeDiplomeSelected);
        if(global.config.modeDebugApp == 1) console.log('retour apiCertifAppelBase : ', dataR);
        switch (dataR.statusCode) {
            case 200:
                SetDataDetailCertif(dataR.dataApi);
              break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
              break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
        setDataLoadedDetailCi(true);   
    }

   useEffect(() => {
    if (utilisateurBearer === '000000' || utilisateurRole != "AdminExtranet" )  navigate(utilisateurRouteLogin); // si pas identifié ou role NOK on redirige sur l'acceuil
    document.title = `Gestion des synonymes de certification`;
    setMessageBase("Gestion des synonymes de certification : recherchez la certification sur laquelle vous souhaitez agir.");
   }, []);

   return (     
        <main className="sous-bloc">
            <MessageInfoBox message={messageBase} />
            <MessageErreurApi message={messageProbleme} />
            <AdmRechercheCertif handleClickViewCerifBootStrap={handleClickViewCerifBootStrap} />

            {dataLoadedDetailCi && dataDetailCertif != null &&
                <DetailCertif dataDetailCertif={dataDetailCertif} isPro={true} />
            }
        </main>
    )
}

export default AdmMoteurSynsCertifs;