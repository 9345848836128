import PropTypes from 'prop-types';
import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx

import { DonneesUtilisateurContext } from '../../contexte.js';

import { hdleClickNoPossible } from '../../fonctionsDiv.js';
import { apiFormGetInfosDiv, apiFormAssocDissoCertif } from '../../Datas/dataFormations.js'
import { apiCertifAppelBase } from '../../Datas/dataDiplomes.js'

import LiensPrecedentSuivant from '../../Components/LiensPrecedentSuivant.jsx';
import LoadingSpinner from "../../Components/LoadingSpinner";
import RechercheCertif from '../../Components/RechercheCertif.jsx'
import MessageInfoBox, {MessageErreurApi} from '../../Components/Messages.jsx';
import Table from '../../Components/Table.jsx'
import {ButtonVoirFiche, ButtonSuppr } from '../../Components/ButtonsCustom.jsx';
import DetailCertif from '../../Components/DetailCertif.jsx'

/**
 * Return Formulaire étape 1
 *
 * @component
 * @summary page étape 1
 * @param {  }
 * @return { HTMLElement }
*/
function FormEtape1(props) {
    let numEtape = 1;

    const formationNumber = props.formationNumber; //const { formationNumber } = useParams() // aurait pu faire ceci et ainsi ne pas passer la var en props

    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const [dataApi, setDataApi] = useState();
    const [dataLoaded, setDataLoaded] = useState(true);
    const [messageProbleme, setMessageProbleme] = useState();

    const [msgInfoF, setMsgInfoF] = useState();

    const [dataLoadedDetailCi, setDataLoadedDetailCi] = useState(false);
    const [dataDetailCertif, SetDataDetailCertif] = useState();

    //pour react-table https://stackoverflow.com/questions/56922381/how-to-include-url-in-react-table-cell
    const columns = useMemo(
        () => [
          {
            id:'listesDiplomes',
            hideHeader : false,
            trieDefautRp : 'intitule_diplome',
            columns: [
                { 
                  Header: () => <>Code Certif<br />info</>,
                  accessor: 'code_diplome',
                },
                {  
                  Header: 'Libellé', 
                  accessor: 'intitule_diplome', 
                  id: "intitule_diplome",
                  Cell: ({ row }) => (       
                    <a title="Voir la fiche" className="lib" onClick={() => handleClickViewCerifBootStrap(row.original.code_diplome)}>
                          {row.original.intitule_diplome}&nbsp;
                    </a>
                  ),
                },
                { 
                  Header: () => <>Code<br />Rncp</>,
                  accessor: 'code_rncp',
                },
                { 
                  Header: 'Nom du ou des certificateur(s)', 
                  accessor: 'certificateur_lib',
                  disableSortBy: true,
                },
                { 
                  Header: 'Etat', 
                  id: "etat_lib_center",
                  accessor: 'etat_lib',
                },
                {
                  Header: () => <>Principale</>,
                    accessor: 'PRINCIPAL', 
                    id: "PRINCIPAL_center",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                        <>
                          {row.original.PRINCIPAL == true ? 
                            <span className="enrobage-boutton"><span className="fas fa-star"></span></span>
                            :
                            <a title= {props.infosGenFormation["nbActions"] == 0 ? "Définir comme principale"  : ""}
                            onClick={props.infosGenFormation["nbActions"] == 0 ? () => handleClickSetPrincipal(row.original.code_diplome) : null}>
                              <span className="enrobage-boutton"><span className="far fa-star"></span></span>
                            </a>
                          }
                        </>
                    ),
                },
                {
                  Header: () => <></>,
                    accessor: 'actions', 
                    id: "asso_center",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                        <>
                          <a title="Voir la fiche" onClick={() => handleClickViewCerifBootStrap(row.original.code_diplome)}>
                            <ButtonVoirFiche />
                          </a>
                          {props.infosGenFormation["nbActions"] == 0 &&
                            props.infosProprietes["ACTIONS_CERTIFICATIONS|other_dataEditable"].editable == 1 ?
                            <a title="Dissocier" onClick={() => handleClickDissoCerif(row.original.code_diplome)}>
                              <ButtonSuppr />
                            </a>
                            :
                            <a title="Suppression impossible, cliquez pour en savoir plus" 
                              onClick={() => hdleClickNoPossible()}>
                            <ButtonSuppr disabled="disabled"  />
                            </a>
                          }
                        </>
                    ),
                },
            ]
          },
        ],
        []
    )

    const handleClickAssoCerif = async (codeDiplomeAasso, codeEtat) => {
        SetDataDetailCertif();
        let confirmed = true;
        //normalement plus utile , on ne permet pas l'asso avec une fiche plus active
        // includes permet de vérifier si une valeur est présente dans un tableau
        if(!global.config.codesEtatsCiEnCours.includes(codeEtat)) confirmed = confirm("Ce diplome n'est plus actif, confirmez vous son utilisation ?")
        
        if(confirmed) {
          setDataLoaded(false);
          setMessageProbleme();
          var dataR = await apiFormAssocDissoCertif(utilisateurBearer, formationNumber, codeDiplomeAasso, 'PUT');

          switch (dataR.statusCode) {
              case 200:
                  await recupCertifsDeLaformation(); // reload la liste
                  alert("L'association a bien été effectuée.");
                break;
              case 401:
                  setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
                break;
              case 400:
                  setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "La fiche certif info est déjà associée"
                break;
              default:
                  setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
          }
          setDataLoaded(true);
        }
    }

    const handleClickDissoCerif  = async (codeDiplomeAasso) => {
        SetDataDetailCertif();
        setDataLoaded(false);
        setMessageProbleme();
        var dataR = await apiFormAssocDissoCertif(utilisateurBearer, formationNumber, codeDiplomeAasso, 'DELETE');

        switch (dataR.statusCode) {
            case 200:
              await recupCertifsDeLaformation(); // reload la liste des certifs
                if(dataR.dataApi != "") alert(dataR.dataApi);
                else alert("La dissociation a bien été effectuée");
              break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
        setDataLoaded(true);
    }
    const handleClickSetPrincipal  = async (codeDiplomeAasso) => {
      SetDataDetailCertif();
      setDataLoadedDetailCi(false);
      var dataR = await apiFormAssocDissoCertif(utilisateurBearer, formationNumber, codeDiplomeAasso, 'POST', '/setprincipal');
      if(global.config.modeDebugApp == 1) console.log('retour apiCertifAppelBase : ', dataR);
      switch (dataR.statusCode) {
          case 200:
            await recupCertifsDeLaformation(); // reload la liste des certifs
            alert("Modification effectuée.");
            break;
            case 400:
              setMessageProbleme(dataR.dataApi);
              break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
            break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
      
      setDataLoadedDetailCi(true);
    }

    const handleClickViewCerifBootStrap  = async (codeDiplomeAasso) => {
      setDataLoadedDetailCi(false);
      var dataR = await apiCertifAppelBase(utilisateurBearer, codeDiplomeAasso);
      if(global.config.modeDebugApp == 1) console.log('retour apiCertifAppelBase : ', dataR);
      switch (dataR.statusCode) {
          case 200:
              SetDataDetailCertif(dataR.dataApi);
            break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
            break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
      setDataLoadedDetailCi(true);     
    }
    
    const [notifSuivant, setNotifSuivant] = useState();
    async function recupCertifsDeLaformation() {
        SetDataDetailCertif();
        const dataR = await apiFormGetInfosDiv(utilisateurBearer, formationNumber, "/certifications");
        if(global.config.modeDebugApp == 1) console.log('retour apiFormGetInfosDiv : ', dataR);

        switch (dataR.statusCode) {
          case 200:
              setDataApi(dataR.dataApi);
              var nbCertifEncorePossible = global.config.nbCertifMaxParFormation - dataR.dataApi.length;
              var objaAmodif = JSON.parse(JSON.stringify(props.infosGenFormation)); // on clone (duplique) l'objet pour que tout fonctionne bien
              objaAmodif.nbCertifications = dataR.dataApi.length;
              objaAmodif.etape1 = dataR.dataApi.length > 0 ? "ok" : "nok";
              dataR.dataApi.length > 0 ? setNotifSuivant("J'ai terminé ma sélection et<br />je passe à l'étape suivante") : setNotifSuivant();
              props.SetInfosGenFormation(objaAmodif);

              if(props.infosGenFormation["nbActions"] == 0) {
                var messageInfoBase = `Vous débutez la déclaration d'une formation certifiante.<br />Le menu de gauche représente les diverses étapes et vous permettra ensuite de naviguer entre elles.<br /> 
                Le renseignement correct d'une étape vous permet de passer à la suivante, jusqu’à la déclaration des sessions.<br /><br />
                Une formation certifiante doit être associée à au moins une certification et jusqu'à ${global.config.nbCertifMaxParFormation}.<br />
                Le fait d'associer plusieurs certifications produira bien une seule formation dite "multi-certifiante".`;

                if(dataR.dataApi.length > 0) {
                  //messageInfoBase += `<br /><br />Si vous voulez ajouter une information complémentaire à la certification`;
                  ////messageInfoBase += `<br /><br />Si vous devez en plus associer un non certifiant `;
                  ////messageInfoBase += dataR.dataApi.length > 1 ? `aux ${dataR.dataApi.length } certifications sélectionnées` : `à la certification sélectionnée` 
                  //messageInfoBase += `, cliquez sur le bouton situé en bas de page.`;
                  messageInfoBase += `<br /><br />Vous pouvez encore associer ${nbCertifEncorePossible} fiches certif info.`; 
                }
                setMsgInfoF(messageInfoBase); 
              }
              else setMsgInfoF(`Vous ne pouvez pas modifier les certifications associées à une formation où des actions sont déjà déclarées.`); 
            break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
            break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
   }

   async function chargementsConsecutifs() {
    setDataLoaded(false);
    await recupCertifsDeLaformation();
    setDataLoaded(true);
  }

   useEffect(() => {
    if(global.config.modeDebugApp == 1)  console.log('useffect FormEtape1, props.infosGenFormation : ', props.infosGenFormation)
    document.title = `Edition de la formation ${formationNumber}`;
    chargementsConsecutifs();
   }, []);
   
   var messageInfoCiAsso = "La certification principale est marquée par une étoile <span class='picto-legende'><span class='fas fa-star'></span></span>.";
   if(props.infosGenFormation["nbActions"] == 0) messageInfoCiAsso += "<br />Vous pouvez en désigner une autre en cliquant sur <span class='picto-legende2'><span class='far fa-star'></span></span>.";
    return (
        <>
          <LoadingSpinner dataLoaded={dataLoaded} />
          <MessageInfoBox message={msgInfoF} />
          <MessageErreurApi message={messageProbleme} />

          {dataLoaded && dataApi &&
          <>
            {props.infosGenFormation["nbActions"] == 0 && props.infosProprietes["ACTIONS_CERTIFICATIONS|other_dataEditable"].editable == 1 && 
              <RechercheCertif handleClickAssoCerif={handleClickAssoCerif} handleClickViewCerifBootStrap={handleClickViewCerifBootStrap} />
            }

            <section className="certifications-associees">
                {dataApi.length > 0 ?
                <>
                  <div className="titre">
                  {dataApi.length > 1 ? 
                    <>{dataApi.length} certifications sont actuellement associées à votre formation - cliquez sur la <span className="fas fa-magnifying-glass picto-legende"></span> pour visualiser le détail de l'une d'elles (romes, nsf...)</>
                    :
                    <>Une certification est actuellement associée à votre formation - cliquez sur la <span className="fas fa-magnifying-glass picto-legende"></span> pour la visualiser en détail (romes, nsf...)</>
                  } 
                  </div> 

                  {dataApi.length > 1 && <MessageInfoBox message={messageInfoCiAsso} nomClasse="message-info2" />}
                  <Table columns={columns} data={dataApi} noPagination={true} noDisplayNbResult={true} noFilter={true} />
                </>
                :
                <div className="titre">Aucune fiche certif info n'est associée à cette formation.</div>
                }
            </section>          

            {dataLoadedDetailCi && dataDetailCertif != null &&
            <>
            <DetailCertif dataDetailCertif={dataDetailCertif} isPro={false}/>
            </>}

            <LiensPrecedentSuivant  formationNumber={formationNumber} numEtape={numEtape} infosGenFormation={props.infosGenFormation} notifBeCareful={false}
                                    notifSuivant={notifSuivant} />
          </>
          }
        </>
    )

}

FormEtape1.propTypes = {
  formationNumber: PropTypes.number.isRequired,
  SetInfosGenFormation : PropTypes.func,
  infosGenFormation: PropTypes.object,
}

export default FormEtape1;