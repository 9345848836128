import PropTypes from 'prop-types';
import { useEffect, useState, useContext} from 'react';
import Modal from 'react-bootstrap/Modal';

import { DonneesUtilisateurContext } from '../contexte.js';

import { apiPutBlocs } from '../Datas/dataSessionsBloc.js'

import LoadingSpinner from "../Components/LoadingSpinner";
import MessageInfoBox, { MessageErreurApi } from '../Components/Messages.jsx';
import ButtonDeBase from '../Components/ButtonsCustom.jsx';
import { appelRecupInfoGet } from '../fonctionsDiv.js'

/**
 * Affichage et gestion des blocs d'une certif pour une ID_ACTIONS_SESSIONS_PERIODE_BLOCS donnée
 *
 * @component
 * @summary importé dans les composantd d'édition des sessions
 * @param {boolean} props.dataLoaded
 * @return { HTMLElement }
*/
function BlocsCompetences(props) {
    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);
    
    const handleCloseModal = () => {
        props.setChoixID_ACTIONS_SESSIONS_PERIODE_BLOCS(null);
        setShowModal(false);
    }
    const [showModal, setShowModal] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(true);
    const [messageProbleme, setMessageProbleme] = useState();
    const [aumoinsUneModif, setAumoinsUneModif] = useState(false);

    const [dataPeriodeBloc, SetDataPeriodeBloc] = useState(); 
    const [dataListBlocs, SetDataListBlocs] = useState(); 

    const [dataLHEO_TYPE_VALIDATION_BLOCS, SetDataLHEO_TYPE_VALIDATION_BLOCS] = useState(); // pour recup données du select
    const [typeValidationChoisi, SetTypeValidationChoisi] = useState();

    const handleClickEnvoiInfos = async () => { 
       var cpt = 0;
       var nbBlocTotal = checkedState.length;
       var tabBlocCoches = [];

        checkedState.forEach((el) => {
            if(el == true) { // tableau d'objet qui va etre envoyé, les indice de checkedState correspondent à dataListBlocs c'est pour ela qu'on peut faire ainsi
                var ID_ACTIONS_SESSIONS_PERIODE_BLOCS = props.ID_ACTIONS_SESSIONS_PERIODE_BLOCS;
                var CODE_BLOC = dataListBlocs[cpt].CODE;
                tabBlocCoches.push({ ID_ACTIONS_SESSIONS_PERIODE_BLOCS, CODE_BLOC}); 
            }
            cpt++;
        });

       var nbBlocCoches = tabBlocCoches.length;
       //console.log(`typeValidationChoisi :  ${typeValidationChoisi},  nbBlocCoches :  ${nbBlocCoches}, nbBlocTotal :  ${nbBlocTotal}`);

       // si validation partielle et que tout les blocs sont cochés, ce n'est pas possible
       if(typeValidationChoisi == 0 && nbBlocCoches == nbBlocTotal) {
            alert("Vous ne pouvez choisir une validation partielle et sélectionner tous les blocs.\r\nVeuillez désélectionner les blocs de compétence non préparés ou sélectionner une validation totale.");
            return false;
       }
       // si validation partielle et qu'aucun bloc n'est coché, ce n'est pas possible
       if(typeValidationChoisi == 0 && nbBlocCoches == 0) {
            alert("Vous avez indiqué une validation partielle.\r\nVous devez sélectionner au moins un bloc de compétence.");
            return false;
       }
       // on envoie les bloc cochés que si la validation est partielle (0)
       //l'api détecte alors qu'il n'y a pas de blocs, efface de fait les éventuelles blocs précédents et enregistre le type de validation comme totale
       if(typeValidationChoisi == 1) tabBlocCoches = [];
       
       var dataR = await apiPutBlocs(utilisateurBearer, props.ID_ACTIONS_SESSIONS_PERIODE_BLOCS, tabBlocCoches); // model apiProfPutProfFormacode
       if(global.config.modeDebugApp == 1) console.log('retour apiPutBlocs : ', dataR);
       
       switch (dataR.statusCode) {
           case 200:
               /* non ca peut arriver si validation totale if (dataR.dataApi  < 1) {
                   setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
                   return false;
               }*/

               setDataLoaded(true);
               props.setMajTab(true);;
               props.setChoixID_ACTIONS_SESSIONS_PERIODE_BLOCS(null);
               alert("Les modifications ont bien été enregistrées.");
               setShowModal(false);
             break;
           case 401:
               setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
             break;
           case 400:
               setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné
             break;
           default:
               setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
       }
    }

    async function handleChangeSelectTypeValidation (selectedOptions)  { // selectedOptions : 1= total, 0 = partielle
        SetTypeValidationChoisi(selectedOptions);
        setAumoinsUneModif(true);
        //console.log(`Options selected:`, selectedOptions);
    }

    async function chargementsConsecutifs() {
        setDataLoaded(false);
        var pb = false;

        var ret = await appelRecupInfoGet(utilisateurBearer, "LHEO_TYPE_VALIDATION_BLOCS", setMessageProbleme);
        if(ret != null) SetDataLHEO_TYPE_VALIDATION_BLOCS(ret); else pb = true;

        // recupere un objet avec les elements suivants : 
        //ID_ACTIONS_SESSIONS_PERIODE_BLOCS ID_ACTIONS_SESSION_PERIODE ID_LHEO_TYPE_VALIDATION_BLOCS LIB_LHEO_TYPE_VALIDATION_BLOCS codeRncp code_diplome intituleDiplome nbBlocs
        var retPeriodeBloc = await appelRecupInfoGet(utilisateurBearer, "sessions/ACTIONS-SESSIONS-PERIODE-BLOCS/" + props.ID_ACTIONS_SESSIONS_PERIODE_BLOCS, setMessageProbleme);
        if(retPeriodeBloc != null) {
            SetDataPeriodeBloc(retPeriodeBloc);
            SetTypeValidationChoisi(retPeriodeBloc.ID_LHEO_TYPE_VALIDATION_BLOCS); // sert pour savoir si affichage simple des blocs ou sélection
            //console.log(`typeValidationChoisi : `, retPeriodeBloc.ID_LHEO_TYPE_VALIDATION_BLOCS);
        }
        else pb = true;

        // si le type de validation est totale on va afficher les blocs possibles, sinon on va les afficher pour que l'utilisateur pointe ceux qui sont retenus
        // donc, on les récupère déjà ici
        //console.log("--------------------------------", retPeriodeBloc);
        if(pb == false && retPeriodeBloc.codeRncp > 0 && retPeriodeBloc.nbBlocs > 0) {

            var retListBlocs = await appelRecupInfoGet(utilisateurBearer, "certifs/blocs-competences/Search?code-rncp=" + retPeriodeBloc.codeRncp + 
                                                                          "&ID_ACTIONS_SESSIONS_PERIODE_BLOCS=" + props.ID_ACTIONS_SESSIONS_PERIODE_BLOCS, setMessageProbleme);
            if(retListBlocs != null) {
                SetDataListBlocs(retListBlocs); 
                // on initialise un tableau pour la gestion des checkbox, l'idée est d'avoir juste le meme nombre d'indice 
                var tab=[];
                var cpt=0;
                retListBlocs.forEach((el) => {
                    tab[cpt] = el.CODE_BLOC_ASSOCIE != null ? true : false;
                    cpt++;
                });
                setCheckedState(tab);
            } else pb = true;
        }
        if(pb == true ) setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      //}
      setDataLoaded(true);
    }

    useEffect(() => {
        if(props.ID_ACTIONS_SESSIONS_PERIODE_BLOCS > 0) setShowModal(true);
        setMessageProbleme(null);
        chargementsConsecutifs();
    }, []);


    // gestion de la selection des blocs
    // inspi https://codesandbox.io/s/wild-silence-b8k2j?file=/src/App.js:940-947 de https://www.freecodecamp.org/news/how-to-work-with-multiple-checkboxes-in-react/
    const [checkedState, setCheckedState] = useState();
    const handleChangeCheckBoxBloc = (position) => {
        setAumoinsUneModif(true);
        //console.log("position", position)
        const updatedCheckedState = checkedState.map((item, index) =>
          index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);
    };


  return (
    <>
        {showModal && dataPeriodeBloc &&
        <Modal size="lg" show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} centered aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
                <>Blocs de compétence de la certification <b>{dataPeriodeBloc.intituleDiplome}</b><br />
                  du formateur "<b>{props.LIB_COMPANIES}</b>" </>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadingSpinner dataLoaded={dataLoaded}/>
                <MessageErreurApi message={messageProbleme} />
                {global.config.modeDebugApp == 111111111 && <div className='debug'>Edition de l'ID_ACTIONS_SESSIONS_PERIODE_BLOCS <b>{props.ID_ACTIONS_SESSIONS_PERIODE_BLOCS}</b></div>}
                {(dataPeriodeBloc.nbBlocs > 0 && props.infosGenFormation["sess_deja_exportee"] == false) &&
                    <>
                        {dataPeriodeBloc.nbBlocs > 0 ?
                            <>Vous pouvez modifier le type de validation :<br />
                                {/*disabled={true}*/}
                                <select className="form-control custom-select" id="types-contact-list" 
                                    title="Types de validation" 
                                    value = {typeValidationChoisi} 
                                    onChange={(e) => {handleChangeSelectTypeValidation(e.target.value)}}>
                                <option value="" key="-">Sélectionner un type de validation</option>
                                {dataLHEO_TYPE_VALIDATION_BLOCS && dataLHEO_TYPE_VALIDATION_BLOCS.map((item) => {
                                    return (
                                        <option value={item.id} key={item.id}>
                                            {item.lib}
                                        </option>
                                    );
                                    })}
                                </select>
                            </>
                            :
                            <>Cette certification n'a pas de bloc de compétence associé, la validation sera obligatoirement {dataPeriodeBloc.LIB_LHEO_TYPE_VALIDATION_BLOCS}.</>
                        }
                    </>
                }
                {dataListBlocs && dataListBlocs.length > 0 && typeValidationChoisi != null  &&
                    typeValidationChoisi == 1 ?
                        <> {/*cas validation totale  */}
                            <br />
                            <MessageInfoBox message="Pour information, les blocs suivants sont préparés par cette certification :" />
                            <ul className="list-bloc">
                            {dataListBlocs.map((item) => {
                                    return (
                                        <li key={item.CODE}>
                                            {item.CODE}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {item.LIBELLE}
                                        </li>
                                    );
                                    })
                            }
                            </ul>
                        </>
                    :
                        <> {/*cas validation partielle  */}
                            <br />
                            {props.infosGenFormation["sess_deja_exportee"] == false ?
                            <>
                                <MessageInfoBox message="Les blocs suivants sont préparés par cette certification, choisissez ceux que cette session prépare :" />
                                <ul className="list-bloc">
                                {dataListBlocs && dataListBlocs.map((item, index) => {
                                        return (
                                            <li key={item.CODE}>
                                                <input type="checkbox"
                                                    id={`custom-checkbox-${index}`}
                                                    checked={checkedState[index]}
                                                    onChange={() => handleChangeCheckBoxBloc(index)}
                                                />&nbsp;&nbsp;&nbsp;
                                                <label htmlFor={`custom-checkbox-${index}`}>{item.CODE}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {item.LIBELLE}</label>
                                            </li>
                                        );
                                        })
                                }
                                </ul>
                            </>
                            :
                            <>
                                <MessageInfoBox message="Les blocs suivants sont préparés par cette certification :" />
                                <ul className="list-bloc">
                                {dataListBlocs && dataListBlocs.map((item, index) => {
                                        return (
                                            <li key={item.CODE}>
                                                {checkedState[index] ? <>✔️ </> : <>⛔ </>}
                                                <label htmlFor={`custom-checkbox-${index}`}>{item.CODE}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {item.LIBELLE}</label>
                                            </li>
                                        );
                                        })
                                }
                                </ul>
                            </>}
                        </>
                }

                {dataPeriodeBloc.nbBlocs > 0 && props.infosGenFormation["sess_deja_exportee"] == false &&
                    <ButtonDeBase typeSubmit={false} lib="Mettre à jour 👍" disabled={aumoinsUneModif == true ? false : true}  onClick={() =>handleClickEnvoiInfos()} />}
            </Modal.Body>
        </Modal>
    }
    </>
  );
}


BlocsCompetences.propTypes = {
    ID_ACTIONS_SESSIONS_PERIODE_BLOCS: PropTypes.number.isRequired,
    LIB_COMPANIES: PropTypes.string,
    setChoixID_ACTIONS_SESSIONS_PERIODE_BLOCS: PropTypes.func.isRequired,
    setMajTab: PropTypes.func.isRequired,
    infosGenFormation: PropTypes.object.isRequired,
}

export default BlocsCompetences;