import PropTypes from 'prop-types';
import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx

import { apiCertifAppelBase } from '../Datas/dataDiplomes.js'
import { DonneesUtilisateurContext } from '../contexte.js';

import MessageInfoBox, {MessageErreurApi} from './Messages.jsx';
import LoadingSpinner from "./LoadingSpinner";
import ButtonDeBase, {ButtonPourList} from './ButtonsCustom.jsx';
import Table from './Table.jsx';


/**
 * Return une section permettant de rechercher des formacodes (source : TechercheNsf)
 *
 * @component
 * @summary page de recherche des formacodes
 * @param {  }
 * @return { HTMLElement }
*/
function RechercheFormacodes({ tabObjCible, SetTabObjCible, formacodePrincipal, SetFormacodePrincipal }) {
    var nbSelectionMax = global.config.nbFcodeMaxParProf;
    
    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const [messageProbleme, setMessageProbleme] = useState();

    const [dataApiCi, setDataApiCi] = useState();
    const [dataLoaded, setDataLoaded] = useState(true);
    const [champRechFcodeLib, setChampRechFcodeLib] = useState(localStorage.getItem('champRechFcodeLib'));

    const [codeChoisi, setCodeChoisi] = useState()
    const [libChoisi, setLibChoisi] = useState()
    const [infodeBase, setInfoDeBase] = useState();

    //region pour gestion de la selection des codes
    function gestionSelection(row) {
        setCodeChoisi(row.original.code_formacode);  
        setLibChoisi(row.original.libelle_formacode);
    }

	function addToTabTheObj(code, libelle) {
        var objectLength = tabObjCible.length;
        if(objectLength >= nbSelectionMax) {
            alert(`Vous ne pouvez pas sélectionner plus de ${nbSelectionMax} formacodes.`);
            return;
        } 
		const currentElementAdded = tabObjCible.find((elementTab) => elementTab.code === code)
		if (currentElementAdded) alert("Ce code a déjà été selectionné");
		else SetTabObjCible([...tabObjCible, { code, libelle}])
	}

	function removeToTabTheObj(code) {
		const currentElementAdded = tabObjCible.find((elementTab) => elementTab.code === code)
		if (currentElementAdded) {
			const cartFilteredCurrent = tabObjCible.filter(
				(element) => element.code !== code
			)
			SetTabObjCible([...cartFilteredCurrent])
            setCodeChoisi();
	    }
        // si ce formacode est le principal on oubli pas de le supprimer
        if(code == formacodePrincipal) SetFormacodePrincipal(null);
    }
    //fin region pour gestion de la selection des codes

    const columnsCi = useMemo(
        () => [
          {
            id:'listeFormacodes',
            hideHeader : false,
            trieDefautRp : 'code_arborescence',
            columns: [
                {  
                    //show: false, inutile à priori 
                    Header: 'arborescence', 
                    accessor: 'code_arborescence', //  "hiddenColumns" dans Table.jsx permet de ne pas afficher les colonnes qui ont cet accessor
                    disableSortBy: true,
                },
                {  
                    Header: 'Code', 
                    accessor: 'code_formacode', 
                    disableSortBy: true,
                },
                {  
                Header: 'Libellé du formacode', 
                accessor: 'libelle_formacode', 
                //  {row.original.code_arborescence } {row.original.libelle_formacode }
                disableSortBy: true,
                    Cell: ({ row }) => (       
                            <span className={"position_arborescence-" + row.original.position_arborescence}>
                                {row.original.libelle_formacode }
                            </span>
                    ),
                },
                {  
                    Header: 'Domaine associé', 
                    accessor: 'libelle_domaine', 
                    disableSortBy: true,
                },
                {  
                    Header: () => <>Associer</>,
                    accessor: 'actions', 
                    id: "asso_center",
                    disableSortBy: true,
                    Cell: ({ row }) => (       
                            <>
                            <a title="Associer" onClick={function() {gestionSelection(row)}}>
                            <ButtonPourList />
                            </a></>
                    ),
                },
            ]
          },
        ],
        []
    )

    async function effectueRecherche() {
        let chaineRecherche = "?";       
        if (champRechFcodeLib && champRechFcodeLib.length > 0)  {
            if(!isNaN(champRechFcodeLib)) chaineRecherche += "code-formacode=" + champRechFcodeLib + '&';
            else chaineRecherche += "intitule-recherche=" + champRechFcodeLib + '&';
        }

        if (chaineRecherche.length == 1) {setMessageProbleme('Veuillez saisir au moins un critère'); return false; }
        else {    
            setDataLoaded(false);
            setMessageProbleme("");
            const dataR = await apiCertifAppelBase(utilisateurBearer, 'formacodes/Search' + chaineRecherche);
            if(global.config.modeDebugApp == 1) console.log('retour apiCertifAppelBase : ', dataR);

            switch (dataR.statusCode) {
            case 200:
                setDataApiCi(dataR.dataApi);
                //if(dataR.dataApi.length == 0) setChampRechFcodeLib();
                break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
                break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
            }
            setDataLoaded(true);
        }
    }
    const handleSubmitRech = async (e) => {
        e.preventDefault();
        effectueRecherche();
    }

   useEffect(() => {
        if(global.config.modeDebugApp == 1) console.log('useffect RechercheFormacodes')

        if(typeof dataR == 'undefined') setChampRechFcodeLib(); // pour que la derniere saisie ne se réaffiche pas si on revient sur la page
   }, []);

    useEffect(() => {
        if(global.config.modeDebugApp == 1) console.log('useffect codeChoisi')
        if(codeChoisi !== undefined && !isNaN(codeChoisi) && codeChoisi.toString().length == 5) addToTabTheObj(codeChoisi, libChoisi);
        setCodeChoisi(); 
    }, [codeChoisi])
    
    useEffect(() => {
        if(global.config.modeDebugApp == 1) console.log('useffect tabObjCible + formacodePrincipal')
        majSelection();
    }, [tabObjCible, formacodePrincipal]);

    function  majSelection() {
        var infode = `Enregistrez les Formacodes que vous voulez associer à ce non certifiant (de 1 à  ${nbSelectionMax}), puis désigner le prioritaire`; 
        if(tabObjCible.length > 1) {
            infode  += '<br />' + tabObjCible.length + ' y sont actuellement associés. ';
            if(formacodePrincipal == null ) {
                infode  +=  "<br />❗L'un d'eux doit être défini comme prioritaire. A cet effet, veuillez cliquer sur le prioritaire dans la partie 'Votre sélection'."
            }
            else {
                infode  += `<br />Le formacode ${formacodePrincipal} est le prioritaire, vous pouvez en désigner un autre en cliquant sur son libellé.`;
            }
        }
        else if(tabObjCible.length == 1 && formacodePrincipal == null) {
            infode  += `<br />❗Si le formacode que vous avez selectionné est le seul que vous souhaitez associer à la fiche, veuillez cliquer sur ce dernier (ci-dessous) pour le rendre prioritaire.` 
        }
        setInfoDeBase(infode);
    }
        return (
            <section>
                <MessageInfoBox message={infodeBase} />

                {tabObjCible.length > 0 && 
                <div className="conteneur-liste-selection">
                    Votre sélection :
                    {tabObjCible.map(({ code, libelle }, index) => (
                        <span className={formacodePrincipal == code ? 'item-principal enrobage-boutton' : 'item enrobage-boutton'}  key={`${code}`}>
                            <span onClick={() => SetFormacodePrincipal(code)} >
                                {code} {libelle}
                            </span>
                            <i onClick={() => removeToTabTheObj(code)} className="fas fa-trash poubelle"></i>
                        </span>
                    ))}
                    <a onClick={function() { setCodeChoisi(); SetTabObjCible([]); SetFormacodePrincipal(null);}}><ButtonPourList className="fas fa-trash-can" lib="Effacer Tout" /></a>
                </div> }

                
                <MessageErreurApi message={messageProbleme} />
                {dataLoaded &&
                    <>
                    <form onSubmit={handleSubmitRech} className="form-rech-ci">
                        <div className="partie-form-rech-ci">
                            <input className="form-control input-lib" placeholder='Extrait du code ou du libellé du formacode à rechercher' type="text" id="champRechFcodeLib" 
                                   value={champRechFcodeLib  == null ? '' : champRechFcodeLib} 
                                   onChange={(e) => {setChampRechFcodeLib(e.target.value); localStorage.setItem('champRechFcodeLib', e.target.value)}} />
                            <ButtonDeBase typeSubmit={true} lib="Rechercher" disabled={!champRechFcodeLib} />
                        </div>
                    </form>

                    <LoadingSpinner dataLoaded={dataLoaded}/>
                    { dataApiCi &&
                    <>
                        {dataApiCi.length > 0  && 
                            <>
                                <p className="titre-rep-certif">
                                    {dataApiCi.length} 
                                    {dataApiCi.length > 1 ? 
                                        <> formacodes trouvés - cliquez sur l'un des boutons <span className="button-list-ajout-in-lib"><span className="fas fa-plus"></span></span> de la liste pour associer un formacode à votre non certifiant</>
                                        : 
                                        <> formacode trouvé - cliquez sur le bouton <span className="button-list-ajout-in-lib"><span className="fas fa-plus"></span></span> dans la liste pour l'associer à votre non certifiant</>}
                                </p>  
                                <Table columns={columnsCi} data={dataApiCi} noPagination={true} noDisplayNbResult={true} />
                            </>
                        }
                        {dataApiCi.length == 0 && <div>Aucun non certifiante n'a été trouvé, veuillez modifier vos critères.</div>}
                    </>
                    }
                    </>
                }
            </section>
        )

}



export default RechercheFormacodes;

RechercheFormacodes.propTypes = {
    tabObjCible: PropTypes.array,
    SetTabObjCible: PropTypes.func,

    formacodePrincipal: PropTypes.number,
    SetFormacodePrincipal: PropTypes.func,
}