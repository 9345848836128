import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

import { apiRecupereInfoGet, verifMaintenance } from '../Datas/datasAutre.js'
import { apiProChangOrg } from '../Datas/dataLogin.js'

import { DonneesUtilisateurContext } from '../contexte.js';
import '../config.js';

import MessageInfoBox, {MessageErreurApi} from '../Components/Messages.jsx';
import LoadingSpinner from "../Components/LoadingSpinner.js";
import ButtonDeBase, {ButtonVoirFiche2, ButtonVoirFiche} from "../Components/ButtonsCustom.jsx";

import Table from '../Components/Table.jsx';
//import { retourneCauseInterdictionForm } from '../fonctionsDiv.js';

/**
 * Return Page pro pour changement d'organisme de formation
 *
 * @component
 * @summary pour affichage recherche organisme
 * @param {  }
 * @return { HTMLElement }
*/
function AdmChangeOrg() {
    const navigate = useNavigate(); // utile pour changer de page

    const {setBearer, setOrg, setNomOrg, setIdMesureOf,  utilisateurRouteLogin, utilisateurBearer, utilisateurRole } = useContext(DonneesUtilisateurContext);
    const [dataApi, setDataApi] = useState();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [messageProbleme, setMessageProbleme] = useState('');
    const [indicationSimple, setIndicationSimple] = useState('');
    const [messageBase, setMessageBase] = useState('');
    const [enMaintenance, setEnMaintenance] = useState(false);
    const [msgMaintenance , setMsgMaintenance] = useState(false);

    const columns = useMemo(
        () => [
          {
            id:'listesResultats',
            hideHeader : false,
            trieDefautRp : 'link',
            columns: [
                {  
                Header: 'Libellé de l\'organisme', 
                accessor: 'LIB_COMPANIES', 
                id: "link", // utile pour trie par défaut
                },
                { 
                Header: 'Sigle', 
                accessor: 'SHORT_LIB', 
                },
                { 
                Header: 'Num DE', 
                accessor: 'NUM_DE', 
                },
                { 
                Header: 'SIRET', 
                accessor: 'SIRET', 
                },
                { 
                Header: 'ID EXTERNE', 
                accessor: 'Numero_UAI', 
                },
                {
                Header: 'Ville', 
                accessor: 'LIB_GEO_VILLES', 
                },
                {
                Header: 'Cp', 
                accessor: 'CODE_POSTAL', 
                },
                {
                Header: () => <></>,
                    accessor: 'actions', 
                    id: "actions_center", // le fait de mettre "center" dans l'id permet de center le contenu de la colone
                    disableSortBy: true,
                    Cell: ({ row }) => (  
                    <>
                        <ButtonVoirFiche2 className="fas fa-pen-to-square" lien={`/organisme/${row.original.ID_COMPANIES}`} />

                        <a title={`Etre profilé comme cet organisme ${row.original.ID_COMPANIES} (créée le : ${row.original.DATECREATE} ${row.original.DATE_DECLARATION != null ? `, date déclaration : ${row.original.DATE_DECLARATION})` : ')'}`}
                           className="lib" onClick={() => handleClickChoixOrgLike(row.original.ID_COMPANIES)}>
                            <ButtonVoirFiche className="fas fa-user" />
                        </a>
                       
                    </>
                    ),
                },
            ]
          },
        ],
        []
    )

    const handleClickChoixOrgLike  = async (ID_COMPANIES) => {
        if (typeof(Storage) === "undefined") {
            let pb = global.config.msgPrTechnique.msgDefaut + '<br /><br />Détail de l\'erreur à transférer : "Storage non supporté"';
            setMessageProbleme(pb);
            return 0;
        }
        setMessageProbleme("");
        setIndicationSimple("");
        setDataLoaded(false);
        const response = await apiProChangOrg(utilisateurBearer, ID_COMPANIES);
        setDataLoaded(true);

        if(typeof response == 'undefined' || typeof response.status == 'undefined') { // si pas de retour
            setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
            return 0;
        }

        if (response.status == 200) { //===  400 si bad
            const bearer =  await response.text();

            var decodedBearer = jwt_decode(bearer);
            var role = decodedBearer["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
            var OrgId = decodedBearer["OrgId"];
            var NomOrg = decodedBearer["NomOrg"];
            var IdMesureOf = decodedBearer["IdMesureOf"];
            var UtilisateurId = decodedBearer["UtilisateurId"];

            if (typeof(role) === "undefined" ||  typeof(OrgId) === "undefined" || typeof(NomOrg) === "undefined" || typeof(IdMesureOf) === "undefined" || 
                typeof(UtilisateurId) === "undefined") {
                let pb = global.config.msgPrTechnique.msgDefaut + '<br /><br />Détail de l\'erreur à transférer : " une propriété est manquante"';
                setMessageProbleme(pb);
                return 0;
            }

            //(role)
            setOrg(OrgId)
            setNomOrg(NomOrg)
            setIdMesureOf(IdMesureOf)

            setBearer(bearer) 

            navigate(`/organisme`);
        }
        else if (response.status == 401) {  // Compte non valide ou En maintenance
            const dataApi = await response.text();
            if(dataApi == "Compte non valide") setMessageProbleme(global.config.msgPrTechnique.msgBadIdentification);
            else if(dataApi == "En maintenance") setMessageProbleme(global.config.msgPrTechnique.msgMaintenance);
            else setMessageProbleme(dataApi);
        }
        else setMessageProbleme(global.config.msgPrTechnique.msgBadIdentification);

        return false;
    }

    const [champRechOrgProLibOuSiret, setChampRechOrgProLibOuSiret] = useState(localStorage.getItem('champRechOrgProLibOuSiret') ? localStorage.getItem('champRechOrgProLibOuSiret') : "");
    const [champRechOrgProLieu, setChampRechOrgProLieu] = useState(localStorage.getItem('champRechOrgProLieu') ? localStorage.getItem('champRechOrgProLieu') : "");
    const [champRechOrgProNumForm, setChampRechOrgProNumForm] = useState(localStorage.getItem('champRechOrgProNumForm') ? localStorage.getItem('champRechOrgProNumForm') : "");
    const [champRechOrgProNumAct, setChampRechOrgProNumAct] = useState(localStorage.getItem('champRechOrgProNumAct') ? localStorage.getItem('champRechOrgProNumAct') : "");
    const [champRechOrgProNumSess, setChampRechOrgProNumSess] = useState(localStorage.getItem('champRechOrgProNumSess') ? localStorage.getItem('champRechOrgProNumSess') : "");

    const handleSubmit = async (e) => {
        e.preventDefault();
        effectueRecherche();
    }
    const handleClickReinit = async (e) => {
        e.preventDefault();
        setChampRechOrgProLibOuSiret(""); localStorage.setItem('champRechOrgProLibOuSiret',"");
        setChampRechOrgProLieu(""); localStorage.setItem('champRechOrgProLieu',"");

        setChampRechOrgProNumForm(""); localStorage.setItem('champRechOrgProNumForm',"");
        setChampRechOrgProNumAct(""); localStorage.setItem('champRechOrgProNumAct',"");
        setChampRechOrgProNumSess(""); localStorage.setItem('champRechOrgProNumSess',"");
    }
    
   //  /organismes/search?intitule-recherche=MonCERI&ville=nans&departement=83&id-formation=248502&id-action=1531682&id-session=493451
   async function effectueRecherche() {
        if(champRechOrgProNumForm != undefined && champRechOrgProNumForm != "undefined" && isNaN(champRechOrgProNumForm)) {
            alert("Le numéro de formation doit être un numérique." + champRechOrgProNumForm);
            document.getElementById("champRechOrgProNumForm").ob.focus();
            return false;
        }
        if(champRechOrgProNumAct != undefined && champRechOrgProNumAct != "undefined" && isNaN(champRechOrgProNumAct)) {
            alert("Le numéro d'action doit être un numérique." + champRechOrgProNumAct);
            document.getElementById("champRechOrgProNumAct").focus();
            return false;
        }
        if(champRechOrgProNumSess != undefined && champRechOrgProNumSess != "undefined" && isNaN(champRechOrgProNumSess)) {
            alert("Le numéro de session doit être un numérique." + champRechOrgProNumSess);
            document.getElementById("champRechOrgProNumSess").focus();
            return false;
        }
        let chaineRecherche = "?";
        if (champRechOrgProLibOuSiret && champRechOrgProLibOuSiret.length > 0) chaineRecherche += "intitule-recherche=" + champRechOrgProLibOuSiret + '&';
        else setChampRechOrgProLibOuSiret("");
        
        if (champRechOrgProLieu && champRechOrgProLieu.length > 0)  {   
            if(champRechOrgProLieu.length == 2 && !isNaN(champRechOrgProLieu)) chaineRecherche += "departement=" + champRechOrgProLieu + '&';
            else chaineRecherche += "ville=" + champRechOrgProLieu + '&';
        }
        else setChampRechOrgProLieu("");

        if (champRechOrgProNumForm && champRechOrgProNumForm.length > 0) chaineRecherche += "id-formation=" + champRechOrgProNumForm + '&';
        else setChampRechOrgProNumForm("");
        if (champRechOrgProNumAct && champRechOrgProNumAct.length > 0) chaineRecherche += "id-action=" + champRechOrgProNumAct + '&';
        else setChampRechOrgProNumAct("");
        if (champRechOrgProNumSess && champRechOrgProNumSess.length > 0) chaineRecherche += "id-session=" + champRechOrgProNumSess + '&';
        else setChampRechOrgProNumSess("");

        localStorage.setItem('champRechOrgProLibOuSiret', champRechOrgProLibOuSiret); 
        localStorage.setItem('champRechOrgProLieu', champRechOrgProLieu); 
        localStorage.setItem('champRechOrgProNumForm', champRechOrgProNumForm)
        localStorage.setItem('champRechOrgProNumAct', champRechOrgProNumAct)
        localStorage.setItem('champRechOrgProNumSess', champRechOrgProNumSess)
        
        if (chaineRecherche.length == 1) {
            setMessageProbleme('Veuillez saisir au moins un critère'); 
            return false; 
        }
        else {  
            setMessageProbleme("");
            setIndicationSimple("");
            setDataLoaded(false);

            const dataR = await apiRecupereInfoGet(utilisateurBearer, "organismes/search" + chaineRecherche);
            if(global.config.modeDebugApp == 1) console.log(`+++++++++++++++++++++retour apiRecupereInfoGet avec organismes/search` + chaineRecherche + ` : `, dataR);
            setDataLoaded(true);

            switch (dataR.statusCode) {
                case 200:
                    setDataApi(dataR.dataApi);
                  return true;
                case 400:
                    var dataObjetVide = new Object();
                    setDataApi(dataObjetVide);
                    setIndicationSimple(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "Trop de resultat"
                    return true;
                case 401:
                    setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
                  break;
                default:
                    setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
            }            
            return false;
        }
        
    }

    async function chargementsConsecutifs() {
        setDataLoaded(false);
        verifMaintenance(setEnMaintenance, setMsgMaintenance);
        setMessageBase("Recherchez un organisme pour consulter ou gérer ses données.");
        setDataLoaded(true);
    }

    useEffect(() => {
        if(global.config.modeDebugApp == 1) console.log('useffect 1st');
        document.title = "Changement d'organsime"
        if (utilisateurBearer === '000000' || utilisateurRole != "AdminExtranet" )  navigate(utilisateurRouteLogin); // si pas identifié ou role NOK on redirige sur l'acceuil
        chargementsConsecutifs();
    }, []);
    var isRechercherDesactive = (!champRechOrgProLibOuSiret && !champRechOrgProLieu && !champRechOrgProNumForm && !champRechOrgProNumAct && !champRechOrgProNumSess) || !dataLoaded;

   return (     
        <main className="sous-bloc">            
            {process.env.REACT_APP_MY_ENV != 'production' && <MessageErreurApi message={global.config.msgPrTechnique.msgSrvTest} />}            
            <MessageErreurApi message={messageProbleme}  />
            <LoadingSpinner dataLoaded={dataLoaded} />
            {enMaintenance == true && <MessageErreurApi message={msgMaintenance} />}

            { dataLoaded > 0 &&enMaintenance == false &&
            <>
                <MessageInfoBox message={messageBase} />
                <form onSubmit={handleSubmit}>
                    <div className="partie-form">
                        <input className="form-control" placeholder="Libellé / Siret / Id de l'organisme" type="text" id="champRechOrgProLibOuSiret" value={champRechOrgProLibOuSiret == null ? '' : champRechOrgProLibOuSiret} onChange={(e) => {setChampRechOrgProLibOuSiret(e.target.value)}} />
                        <input className="form-control" placeholder='Ville ou département' type="text" id="champRechOrgProLieu" value={champRechOrgProLieu == null ? '' : champRechOrgProLieu} onChange={(e) => {setChampRechOrgProLieu(e.target.value)}} />

                    </div>

                    <div className="partie-form">
                        <input className="form-control" placeholder='Numéro de formation' type="text" id="champRechOrgProNumForm" value={champRechOrgProNumForm == null ? '' : champRechOrgProNumForm} onChange={(e) => {setChampRechOrgProNumForm(e.target.value)}} />
                        <input className="form-control" placeholder='Numéro de action' type="text" id="champRechOrgProNumAct" value={champRechOrgProNumAct == null ? '' : champRechOrgProNumAct} onChange={(e) => {setChampRechOrgProNumAct(e.target.value)}} />
                        <input className="form-control" placeholder='Numéro de session' type="text" id="champRechOrgProNumSess" value={champRechOrgProNumSess == null ? '' : champRechOrgProNumSess} onChange={(e) => {setChampRechOrgProNumSess(e.target.value)}} />
                    </div>

                    <div className="partie-form-bt">
                        <a href="" onClick={handleClickReinit} rel="noopener">Réinitialiser</a>
                        <ButtonDeBase typeSubmit={true} lib="Rechercher" disabled={isRechercherDesactive} />
                    </div>
                </form>
                { dataApi &&
                <>
                    <MessageInfoBox message="Editez ou consultez  les données d'un organisme en cliquant sur le pictogramme <span class='enrobage-boutton'><span class='fas fa-pen-to-square picto-legende'></span></span> 
                    ou profilez-vous comme ce dernier en cliquant sur <span class='enrobage-boutton'><span class='fas fa-user picto-legende'></span></span>" nomClasse="message-info2" />
                    {dataApi.length > 0 && <Table columns={columns} data={dataApi} noPagination={null} noDisplayNbResult={null} noFilter={null} paginationOntop={true} />}
                    {dataApi.length == 0 && indicationSimple.length == 0 &&  <div>Aucun organisme n'a été trouvé.<br /><br /></div>}
                    {indicationSimple.length > 0 &&  <MessageInfoBox message={indicationSimple} />}
                </>
                }

            </> } 
        </main>
    )
}


export default AdmChangeOrg;