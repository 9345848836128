import React from "react";
import { useEffect} from 'react'; // useMemo pour Table.jsx
import { useForm, useWatch } from "react-hook-form";

// inspiration : 
//https://react-hook-form.com/advanced-usage#SmartFormComponent : "The Form component's responsibility is to inject all react-hook-form methods into the child component."
// et surtout le sandbox associé : https://codesandbox.io/s/react-hook-form-smart-form-component-forked-iq89z

export default function FormRHF({ defaultValues, children, onSubmit, onError, dataApi, gestionEvenements }) {
    // register pour notamment faire passé la donnée à afficher
    //control : utile pour compté le nombre de lettre, avec useWatch
    // getValues pour recuperer la valeur si souhait de l'utilser en texte plein
    const {register, setValue,handleSubmit, getValues, watch, formState: { errors }, control, reset } = useForm({ 
        mode: "onTouched", // Validation will trigger on the first blur event. After that, it will trigger on every change event.
        //reValidateMode: "onSubmit", le fait de commenter permet de revalider lors de la saisie val possible : onChange (defaut) | onBlur | onSubmit
        //defaultValues: initialValues
        // setValue ajouté 1223 pour pouvoir modifier dynamiquement le champ d'un formulaire (pour formulaire Organisme et Quiforme)
    });

    useEffect(() => { // reset sert a afficher les données correctement
      reset(dataApi);
    }, [dataApi]);

    useEffect(() => {
      const subscription = watch((value, { name, type }) => {
        // ajout de cette condition le 171023 car rendu compte que quand modif le code de etape10 , cela provoquait une erreur ci dessous (document.getElementById(name).type)
        //recommenté le  2510 car vois pas le pb finalement... et en plus la ligne incriminée n'est pas dans le if!
        //if(name != undefined) { 
          // type est le type d'evenement (ex: type)
          // suppression espace gauche, pas forcément nécesssaire puisquee la regexp demande un premier caractère en maj
          var tp = typeof(value[name]); // string meme si c'est un num de tel...
          var ob = document.getElementById(name);

          if(tp === 'string') {
            var sansEspaceDebut = value[name].trimStart(); // ne suffit pas, il faut les 2 lignes en dessous
            if(sansEspaceDebut != value[name]) {
              
              ob.value = sansEspaceDebut;
              //console.log("correction espace debut : | " + sansEspaceDebut + "|");
            }
         // }

          var typeduChamp = document.getElementById(name).type; // ex: tel
          
          //  gestion du tel: lhéo autorise de 1 à 25 avec éventuellement un "+" mais dans la base on a pour ainsi dire tjrs à une lg de 14
          if(typeduChamp == "tel") {
            if((ob.value.length == 2 || ob.value.length == 5 || ob.value.length == 8 || ob.value.length == 11)) {
              ob.value += ' ';
            }
          }

          //console.log(">>", value, name, type); //name = nom du champ qui a bougé type: "change"
          gestionEvenements(value, name);
        }
      });
  
      return () => subscription.unsubscribe();
    }, [watch]);
      


    return (
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        {React.Children.map(children, child => {
          return child != null && child.props.id
            ? React.createElement(child.type, {
                ...{
                  setValue,
                  register,
                  getValues,
                  errors: errors ,
                  control,

                  useWatch,

                  ...child.props,
                }
              })
            : child;
        })}
      </form>
    );
}