import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import { useNavigate } from "react-router-dom";

import { DonneesUtilisateurContext } from '../contexte.js';

import { apiFormAppelList } from '../Datas/dataFormations.js'
import { apiFormAppelListTypesCertif  } from '../Datas/dataDiplomes.js'

import Table, {SelectColumnFilter} from '../Components/Table.jsx'
import {MessageErreurApi} from '../Components/Messages.jsx';




export default Profile;
/**
 * Return template of Profil page
 *
 * @component
 * @summary imported in Route
 * @param {  }
 * @return { HTMLElement }
*/
function Profile() {
    const navigate = useNavigate(); // utile pour changer de page
    const { utilisateurRouteLogin, utilisateurBearer } = useContext(DonneesUtilisateurContext);

    const [dataApiEtat, setDataApiEtat] = useState('');
    const [dataApiTypsDip, setDataApiTypesDip] = useState('');
    const [dataLoaded, setDataLoaded] = useState('0');
    const [messageProbleme, setMessageProbleme] = useState();

    const columns = useMemo(
        () => [
          {
            Header: 'Liste des états',
            columns: [
                { 
                Header: 'code', 
                accessor: 'ID_ACTIONS_FORMATION', // accessor is the "key" in the data 
    
                },
                { 
                Header: 'libellé', 
                accessor: 'LIB_ACTIONS_FORMATION', 
                }
    
            ],
          },
        ],
        []
      )
      const columnsTypesDips = useMemo(
        () => [
          {
            Header: 'Liste des types',
            columns: [
                { 
                Header: 'code', 
                accessor: 'code_type_diplome',
    
                },
                { 
                Header: 'libellé', 
                accessor: 'prefixe', 
                }
    
            ],
          },
        ],
        []
      )

    useEffect(() => {
        document.title = "Test"
        if (utilisateurBearer === '000000') { // si le status n'est pas bon on redirige sur l'acceuil
            navigate(utilisateurRouteLogin)
        }
        
        async function chargeDonnees() {  // on fait une fonction car useEffect ne tolere pas d'await direct
             var dataR = await apiFormAppelList(utilisateurBearer);
             
             switch (dataR.statusCode) {
                case 200:
                    if(global.config.modeDebugApp == 1) console.log("retour apiFormAppelList dataApi", dataR.dataApi);
                    setDataApiEtat(dataR.dataApi);
                  break;
                case 401:
                    setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
                  break;
                default:
                    setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
             }

            var dataR2 = await apiFormAppelListTypesCertif(utilisateurBearer);
            if(global.config.modeDebugApp == 1) console.log('retour apiFormAppelListTypesCertif : ', dataR2)
            
            switch (dataR2.statusCode) {
                case 200:
                    setMessageProbleme("Les données ont bien été recuperées");
                    setDataApiTypesDip(dataR2.dataApi);
                  break;
                case 401:
                    setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
                  break;
                default:
                    setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
            }
        }

         chargeDonnees() // la fonction va recuperer tute les données dont nous avons besoin
         setDataLoaded(1)
    }, []);
    function chargeDonneesAutre () {
      if(global.config.modeDebugApp == 1) console.log('chargeDonneesAutre')
      var test =  [ {
        "iD_ACTIONS_FORMATION": 255,
        "liB_ACTIONS_FORMATION": "Enregistrements résiduels (supprimés par schedule)"
      }]
      setDataApiEtat(test);
    }
    async function chargeDonneesAutre2 () {
      var dataR = await apiFormAppelList(utilisateurBearer);
      if(global.config.modeDebugApp == 1) console.log('retour apiFormAppelList 2 : ', dataR)
      
      switch (dataR.statusCode) {
         case 200:
             setMessageProbleme("Les données ont bien été recuperées");
             if(global.config.modeDebugApp == 1) console.log("retour apiFormAppelList 3 dataApi", dataR.dataApi);
             setDataApiEtat(dataR.dataApi);
           break;
         case 401:
             setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
           break;
         default:
             setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
    }

    return (
        <main>
            <MessageErreurApi message={messageProbleme}  />
            vos infos :<br />
            bearer : {utilisateurBearer}<br />
         {/*   data issue de l'api /list : {dataApiEtat}<br /> 
         `l ${dataApiEtat} l` */ }
         {dataLoaded && dataApiEtat.length > 0 && <Table columns={columns} data={dataApiEtat} />}
         <button onClick={() => chargeDonneesAutre()} >recharge etats</button>
         <button onClick={() => chargeDonneesAutre2()} >recharge etats2</button>
         {dataLoaded && dataApiTypsDip.length > 0 && <Table columns={columnsTypesDips} data={dataApiTypsDip} />}


        </main>
    )
}