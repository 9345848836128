import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form'; // pour Form.Check

import { apiTypeCertifAppelBase } from '../Datas/pro_dataAppellationTypesDiplome.js'
import { recupereNiveauxCI } from '../Datas/datasAutre.js'
import { DonneesUtilisateurContext } from '../contexte.js';


import {MessageErreurApi} from './Messages.jsx';
import LoadingSpinner from "./LoadingSpinner.js";
import ButtonDeBase, {ButtonVoirFiche, ButtonVoirFiche2 } from './ButtonsCustom.jsx';
import Table, {SelectColumnFilter} from './Table.jsx';


/**
 * Return une section permettant de rechercher des types certifs
 *
 * @component
 * @summary page de recherche pro des types de certifs
 * @param {  }
 * @return { HTMLElement }
*/
function AdmRechercheTypeDip() {
    const navigate = useNavigate(); // utile pour changer de page

    const { setBearer, utilisateurRouteLogin, utilisateurBearer, utilisateurRole} = useContext(DonneesUtilisateurContext);
    const [messageProbleme, setMessageProbleme] = useState();

    const [dataApiCiPro, setDataApiCiPro] = useState();
    const [dataLoadedRechTypeDip, setDataLoadedRechTypeDip] = useState(true);
    const [chpRechProTypeDipLib, setChpRechProTypeDipLib] = useState(localStorage.getItem('chpRechProTypeDipLib') ? localStorage.getItem('chpRechProTypeDipLib') : "");
    const [chpRechProTypeDipNiveau, setChpRechProTypeDipNiveau] = useState(localStorage.getItem('chpRechProTypeDipNiveau') ? localStorage.getItem('chpRechProTypeDipNiveau') : "-");
    const [chpRechProTypeDipEtat, setChpRechProTypeDipEtat] = useState(localStorage.getItem('chpRechProTypeDipEtat') ? localStorage.getItem('chpRechProTypeDipEtat') : 'true');
    const [chpRechProTypeDipAvecSyn, setChpRechProTypeDipAvecSyn] = useState(localStorage.getItem('chpRechProTypeDipAvecSyn') ? localStorage.getItem('chpRechProTypeDipAvecSyn') : 'true');

    const columnsCi = useMemo(
        () => [
          {
            id:'listesCertifs',
            hideHeader : false,
            trieDefautRp : 'intitule_type_diplome',
            columns: [
                { 
                    Header: 'Code', 
                    accessor: 'code_type_diplome',
                    //minWidth: 50,
                },
                {  
                    Header: 'Préfixe', 
                    accessor: 'prefixe', 
                },
                {  
                    Header: 'Libellé', 
                    accessor: 'intitule_type_diplome', 
                },
                { 
                    Header: 'Etat', 
                    accessor: 'etat_lib',
                },
                {
                    Header: 'Appellations suppl.', 
                    disableSortBy: true, /*obligé comme cette colonne content desinfos complexes*/
                    Cell: ({ row }) => (  
                      <>
                        {row.original.listeTypesDiplomeAppellationsPourMoteur != null && 
                          row.original.listeTypesDiplomeAppellationsPourMoteur.map((ap, index) => (
                            <span key={ap.types_diplome_pour_moteurs_id}>{ap.intitule_singulier} {ap.prefixe} ({ap.source})<br /></span>
                          ))}
                      </>
                      ),
                },
                {
                    Header: () => <>Actions</>,
                    accessor: 'actions', 
                    id: "actions_center",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                        <>
                        {global.config.codesEtatsCiEnCours.includes(row.original.etat_code) ? 
                         <>
                            <ButtonVoirFiche2 className="fas fa-pen-to-square" 
                            lien={`/admin/moteur/synonymes-types-certification/${row.original.code_type_diplome}`} 
                            title={`Gérer les synonymes de la fiche ${row.original.code_type_diplome}`} />
                        </>
                        :
                        <a title="Ce type de diplome n'est plus actif, cliquez pour savoir s'il est remplacé" onClick={function() {
                            alert("Ce type de diplôme n'est plus actif et ne peut pas être utilisé");
                            }}>
                            <ButtonVoirFiche disabled="disabled" />
                        </a>
                        }
                        </>
                    ),
                },
            ]
          },
        ],
        []
    )


    async function effectueRechercheTypeCi() {
        let chaineRecherche = "?";
        if (chpRechProTypeDipLib && chpRechProTypeDipLib != null) chaineRecherche += "intitule-recherche=" + chpRechProTypeDipLib + '&';
        if (chpRechProTypeDipNiveau && chpRechProTypeDipNiveau != null && chpRechProTypeDipNiveau != '-')  chaineRecherche += "code-niveau=" + chpRechProTypeDipNiveau + '&';
        if (chpRechProTypeDipEtat && chpRechProTypeDipEtat == "true")  chaineRecherche += "code-etat=0,4,11&";
        if (chpRechProTypeDipAvecSyn && chpRechProTypeDipAvecSyn == "true")  chaineRecherche += "avec-appellation=1&";

        if(global.config.modeDebugApp == 1) console.log("chaineRecherche", chaineRecherche)

        if (chaineRecherche.length == 1) {setMessageProbleme('Veuillez saisir au moins un critère'); return false; }
        else {
            localStorage.setItem('chpRechProTypeDipLib', chpRechProTypeDipLib)
            localStorage.setItem('chpRechProTypeDipNiveau', chpRechProTypeDipNiveau)
            localStorage.setItem('chpRechProTypeDipEtat', chpRechProTypeDipEtat);
            localStorage.setItem('chpRechProTypeDipAvecSyn', chpRechProTypeDipAvecSyn);

            setDataLoadedRechTypeDip(false);
            setMessageProbleme("");
            const dataR = await apiTypeCertifAppelBase(utilisateurBearer, 'Search' + chaineRecherche);
            if(global.config.modeDebugApp == 1)  console.log('retour apiTypeCertifAppelBase : ', dataR);
            setDataLoadedRechTypeDip(true);

            switch (dataR.statusCode) {
            case 200:
                setDataApiCiPro(dataR.dataApi); // dataR.dataApi
                break;
            case 401:
                    setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
                    break;
            case 400:
                // on test si c'est une chaine string qui est retourné par l'api
                if(typeof dataR.dataApi === 'string' || dataR.dataApi instanceof String) setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "Il y a plus de XXX résultats ({XXXX), veuillez affiner votre recherche"
                else setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
                break;
            default:
                    setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
            }
            
        }
    }
    const handleSubmitRechCi = async (e) => {
        e.preventDefault();
        effectueRechercheTypeCi();
    }

    const [niveauxCi, SetNiveauxCi] = useState(null); // pour recup données du select
    async function chargementsConsecutifs() {
        var pb = false;
        var ret = await recupereNiveauxCI(utilisateurBearer);
        if(ret != null) SetNiveauxCi(ret); else pb = true;

        effectueRechercheTypeCi();

        if(pb == true ) {
            setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
    }

   useEffect(() => {
    //console.log('useffect RechercheCertif')
    if (utilisateurBearer === '000000' || utilisateurRole != "AdminExtranet" )  navigate(utilisateurRouteLogin); // si pas identifié ou role NOK on redirige sur l'acceuil
    chargementsConsecutifs();
   }, []);


    return (
        <>
        {niveauxCi != null &&
            <>
            <MessageErreurApi message={messageProbleme} />
            <LoadingSpinner dataLoaded={dataLoadedRechTypeDip}/>
            <form onSubmit={handleSubmitRechCi} className="form-rech-ci">
                <div className="partie-form-rech-ci">
                    <input className="form-control input-lib" placeholder='Extrait du libellé (6 lettres minimum)' type="text" id="chpRechProTypeDipLib" 
                            onChange={(e) => {setChpRechProTypeDipLib(e.target.value)}} 
                            value={chpRechProTypeDipLib == null ? '' : chpRechProTypeDipLib}/>

                    <select className="form-control custom-select" id="chpRechProTypeDipNiveau" title="Niveau" 
                        onChange={(e) => {setChpRechProTypeDipNiveau(e.target.value);}}
                        value={chpRechProTypeDipNiveau == null ? '-' : chpRechProTypeDipNiveau} >
                        <option value="-">Tous les niveaux</option>
                        {niveauxCi && niveauxCi.map((item) => {
                        return (
                            <option value={item.code} key={item.code}>
                                {item.libelle}
                            </option>
                        );
                        })}
                    </select>

                </div>
                <div className="partie-form-rech-ci-bt">
                    {/* pas d'utilité, ca rl'api filtre déjà ce qui est archivé... <Form.Check type="switch"  id="chpRechProTypeDipEtat" label="Voir uniquement les types publiées" 
                            checked={(chpRechProTypeDipEtat === "true")} 
                    onChange={() => chpRechProTypeDipEtat === "true" ? setChpRechProTypeDipEtat("false") : setChpRechProTypeDipEtat("true")} />*/}
                    <Form.Check type="switch"  id="chpRechProTypeDipAvecSyn" label="Voir uniquement les types de certifs ayant des synonymes/appellations" 
                            checked={(chpRechProTypeDipAvecSyn === "true")} 
                            onChange={() => chpRechProTypeDipAvecSyn === "true" ? setChpRechProTypeDipAvecSyn("false") : setChpRechProTypeDipAvecSyn("true")} />
                    <ButtonDeBase typeSubmit={true} lib="Rechercher" disabled={(
                                                                                (!chpRechProTypeDipLib || chpRechProTypeDipLib.length < 5) &&
                                                                                (!chpRechProTypeDipAvecSyn || chpRechProTypeDipAvecSyn == 0) &&
                                                                                chpRechProTypeDipNiveau == '-'
                                                                                ) || !dataLoadedRechTypeDip} />
                </div>
            </form>

            {dataLoadedRechTypeDip && dataApiCiPro && 
                <> {/*section className="certifications-recherche"*/}
                    {dataLoadedRechTypeDip && dataApiCiPro && dataApiCiPro.length > 0 && 
                        <>
                            <p className="titre-rep-certif">
                                {dataApiCiPro.length} 
                                {dataApiCiPro.length > 0 ? 
                                    <> types de certification trouvées - cliquez sur l'un des boutons <span className="fas fa-pen-to-square picto-legende"></span> de la liste pour gérer les synonymes d'un type de certification</>
                                    : 
                                    <> types de certification trouvé - cliquez sur la <span className="fas fa-pen-to-square picto-legende"></span> pour gérer ses synonymes </>}
                            </p>    
                            <Table columns={columnsCi} data={dataApiCiPro} noDisplayNbResult={true} />
                        </>
                    }
                    {dataApiCiPro.length == 0 ? <div>Aucun type n'a été trouvé, veuillez modifier vos critères.</div> : null}
                </>
            }
            </>
        }
        </>
    )

}


export default AdmRechercheTypeDip;

AdmRechercheTypeDip.propTypes = {
}