import PropTypes from 'prop-types';
import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

import { DonneesUtilisateurContext } from '../../contexte.js';

import { apiFormGetInfosDiv } from '../../Datas/dataFormations.js'
import { apiActAjout, apiActSuppr, apiActCopy } from '../../Datas/dataActions.js'

import LiensPrecedentSuivant from '../../Components/LiensPrecedentSuivant.jsx';
import LoadingSpinner from "../../Components/LoadingSpinner";
import MessageInfoBox, {MessageErreurApi} from '../../Components/Messages.jsx';
import Table from '../../Components/Table.jsx'
import ButtonDeBase, {ButtonVoirFiche2, ButtonSuppr, ButtonCopy } from '../../Components/ButtonsCustom.jsx';
import { appelGetSetInfosObjetsFormActSess, retourneCauseInterdictionAct } from '../../fonctionsDiv.js';
//import FormDemandeChangementInfoStructure from '../../Components/FormDemandeChangementInfoStructure.jsx';

/**
 * Return Formulaire étape 4 (actions liste ou page permettant d'en ajouter une)
 *
 * @component
 * @summary page étape 4
 * @param {  }
 * @return { HTMLElement }
*/
function FormEtape4(props) {
    let numEtape = 4;

    const formationNumber = props.formationNumber;

    const navigate = useNavigate(); // utile pour changer de page
    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const [dataApi, setDataApi] = useState();
    const [dataLoaded, setDataLoaded] = useState(true);
    const [messageProbleme, setMessageProbleme] = useState();
    const [messageBase, setMessageBase] = useState('');

    const [choixCreaAct, setChoixCreaAct] = useState();
    const handleCloseModalIndicAvantCrea = () => {setShowModalIndicAvantCrea(false); setChoixCreaAct("")};
    const [showModalIndicAvantCrea, setShowModalIndicAvantCrea] = useState(false);

    const [showInfoDuplic, setShowInfoDuplic] = useState();
    const handleCloseModalInfoDuplic = () => { setShowInfoDuplic("")};

    const [btCreerActif, setBtCreerActif] = useState(true);


    const columns = useMemo(
        () => [
          {
            id:'listesActions',
            hideHeader : false,
            trieDefautRp : 'LIB_LHEO_DICT_FINANCEUR',
            columns: [
                { 
                  Header: 'N° de l\'action', 
                  accessor: 'ID_ACTIONS_SESSIONS',
                },
                { 
                  Header: () => <>Numéro(s)<br />de session</>,
                  accessor: 'idSessions',
                  disableSortBy: true,
                },
                { 
                  Header: 'Adresse(s)', 
                  accessor: 'adresses',
                  disableSortBy: true,
                },
                { 
                  Header: 'Ville(s)', 
                  accessor: 'villes',
                  disableSortBy: true,
                },
                { 
                  Header: 'Cp', 
                  accessor: 'cps',
                  disableSortBy: true,
                },
                { 
                  Header: 'Mesure', 
                  accessor: 'LIB_MESURES'
                },
                { 
                  Header: 'Financeur', 
                  accessor: 'LIB_LHEO_DICT_FINANCEUR'
                },
                { 
                  Header: () => <>Présentiel ou <br />à distance</>,
                  accessor: 'LIB_LHEO_MODALITE_ENSEIGNEMENT',
                },
                {
                  Header: () => <>Contrat<br />Pro</>,
                  id: "ACCES_CONTRAT_PRO_center", 
                  Cell: ({ row }) => (  
                      <>
                          {row.original.ACCES_CONTRAT_PRO != null &&
                            <>
                             {row.original.ACCES_CONTRAT_PRO == false  ? <>Non</> : <>Oui</> }
                            </>
                          }
                      </>
                      ),
                },
                {
                Header: () => <>Date(s)<br />début</>,
                accessor: 'debuts',
                disableSortBy: true,
                // modification des des dates pour éviter un débordement...
                //si plus de 5 dates (lg 44) :  point suspension entre la plus ancienne et la plus recente
                Cell: ({ row }) => (  
                    <>
                    {row.original.debuts && row.original.debuts.length > 44 ? row.original.debuts.substring(0,8) + "..." + row.original.debuts.substring(row.original.debuts.length-8) : row.original.debuts}
                    </>
                    ),
                },
                {
                  Header: () => <></>,
                    accessor: 'actions', 
                    id: "actions_center", // le fait de mettre "center" dans l'id permet de center le contenu de la colone
                    disableSortBy: true,
                    Cell: ({ row }) => (
                      <>
                        <ButtonVoirFiche2 className="fas fa-pen-to-square" lien={`/formation/etape5/${formationNumber}/action-${row.original.ID_ACTIONS_SESSIONS}`} />
                        {/* commenté, le bonton à l'avantage de ne pas permetre l'ouverture onglet
                        <NavLink title="Accéder à la fiche" to={`/formation/etape5/${formationNumber}/action-${row.original.ID_ACTIONS_SESSIONS}`}>
                          <ButtonVoirFiche className="fas fa-pen-to-square" />
                        </NavLink> */}

                        {props.infosProprietes["ACTIONS_FORMATION|other_possibiliteAjoutAct"].editable == 1 && row.original.Etat_LHEO_Valide == 1 ?
                            <NavLink title={`Dupliquer cette action ${row.original.ID_ACTIONS_FORMATION}/${row.original.ID_ACTIONS_SESSIONS}`} to="" onClick={() => handleClickCopyAction(row.original.ID_ACTIONS_SESSIONS, props.infosGenFormation.nbActions)}>
                            <ButtonCopy />
                          </NavLink>
                            :
                            <NavLink title="Pour dupliquer une action, vous devez avoir les droits et elle doit être valide" to="">
                              <ButtonCopy disabled="disabled" />
                            </NavLink>
                        }

                        {/* si l'action est conventionnée ou qu'elle a une session périmée de moins d'un certain nombre de jour ou pas terminée, on ne permet pas la suppression */}
                        { (row.original.cause_intervention_interdite == null || row.original.cause_intervention_interdite == "") ?
                            <NavLink title={`Supprimer ${row.original.ID_ACTIONS_FORMATION}/${row.original.ID_ACTIONS_SESSIONS}`} to="" onClick={() => handleClickSupprAction(row.original.ID_ACTIONS_SESSIONS, row.original.nbSessions)}>
                              <ButtonSuppr />
                            </NavLink>
                            :
                            <NavLink title={retourneCauseInterdictionAct(row.original.cause_intervention_interdite)}
                                    to="" 
                                    onClick={() => hdleClickNoPossible(row.original.cause_intervention_interdite)}>
                              <ButtonSuppr disabled="disabled" />
                            </NavLink>
                        }
                    </>
                    ),
                },
            ]
          },
        ],
        []
    )

    function  hdleClickNoPossible (cause_intervention_interdite) {
      alert(retourneCauseInterdictionAct(cause_intervention_interdite, 1));
    }

    //navigate(`/formation/` + dataR.dataApi + '/' + nomeEtape + '/action-' + dataR.dataApi);
    const handleClickSupprAction  = async (codeAction, nbSessions) => {
      var texte = nbSessions == 0 ? "Etes vous sûr de vouloir supprimer cette action ?" : "Cette action contient " + nbSessions + " session(s), êtes vous sûr de vouloir la supprimer ?";
      var confirmation = confirm(texte);
      if(confirmation == true) {
        setDataLoaded(false);
        var dataR = await apiActSuppr(utilisateurBearer, codeAction);
        if(global.config.modeDebugApp == 1) console.log('retour apiActSuppr : ', dataR);
        switch (dataR.statusCode) {
            case 204:
              //alert("L'action a bien été supprimée");
              await recupererListeCompleteAct();
              break;
            case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
            default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
        setDataLoaded(true);
      }
    }
    const handleClickCopyAction  = async (codeAction, nbActions) => {
      var texte = 'Cette fonction permet de dupliquer toutes les données liées à une action.\r\n'
      texte += 'Vous aurez ensuite la possibilité de modifier l\'ensemble des informations de l\'action qui aura été créée.\r\n\r\n'
      texte += 'La duplication est utile si vous avez par exemple référencé une première action en présentielle et que vous proposez une action similaire à distance, ou si vous souhaitez déclarer une nouvelle action avec une durée différente.\r\n';
      texte += 'Attention : si vous voulez simplement renseigner une nouvelle session avec de nouvelles dates sur une action existante, il ne faut pas dupliquer ou ajouter une action mais simplement ajouter une nouvelle session à l\'action.\r\n';
      var confirmation = confirm(texte);
      if(confirmation == true) {
        setDataLoaded(false);
        var dataR = await apiActCopy(utilisateurBearer, codeAction);
        if(global.config.modeDebugApp == 1) console.log('retour apiActCopy : ', dataR);
        switch (dataR.statusCode) {
            case 200:
              alert("L'action a bien été copiée. \r\nVous allez maintenant être redirigé sur la nouvelle action, merci d'y apporter les modifications nécessaires et de la compléter en y ajoutant une session");
              navigate(`/formation/etape5/${formationNumber}/action-${dataR.dataApi}`);
              break;
            case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
            default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
        setDataLoaded(true);
      }
    }

    async function recupererListeCompleteAct() {
        const dataR = await apiFormGetInfosDiv(utilisateurBearer, formationNumber, "/actions");
        if(global.config.modeDebugApp == 1) console.log('retour apiFormGetInfosDiv dans recupererListeCompleteAct : ', dataR);
        
        switch (dataR.statusCode) {
            case 200:
                let presenceInvalide = false;
                //boucle sur le contenu renvoyé par l'api pour savoir s'il y a des incomplètes
                dataR.dataApi.forEach((el) => {
                    if(el.Etat_LHEO_Valide == 0) {
                      presenceInvalide = true;
                    }   
                });
                let messageBase = "Cette page liste les actions de votre formation et vous permet d'en ajouter de nouvelles.<br />";
                if(dataR.dataApi.length > 0 && presenceInvalide == true ) messageBase += "Les actions qui ne sont pas valides sont repérées sur fond orange.<br />";
                if(dataR.dataApi.length > 0) messageBase += 'Vous pouvez en ajouter une en cliquant sur le bouton "Créer une action".<br />';
                else messageBase += 'Vous pouvez en créer une en cliquant sur le bouton "Créer une action".<br />';

                //messageBase = "Cette page liste les actions de votre formation.<br />La notion d'action regroupe ce qui contribue à la conception, à l'organisation, et à la mise en œuvre d'une formation.<br />";
                //messageBase += "Rendez-vous <span style='font-size:1.2em;'>dans une action existante</span> pour <span style='font-size:1.2em;'>gérer les dates de vos sessions</span> ou en ajouter de nouvelles.<br /><br />";
               // messageBase += "⚠️ Ajoutez une nouvelle action seulement si aucune action existante ne correspond à votre besoin (ex : durées ou une modalité différente).";
                setMessageBase(messageBase);
                setDataApi(dataR.dataApi);
                //pas suffisant car la validité de cette étape dépense des autres actions : 
                //var objaAmodif = JSON.parse(JSON.stringify(props.infosGenFormation)); // on clone (duplique) l'objet pour que tout fonctionne bien
                //objaAmodif["nbActions"] = dataR.dataApi.length;
                //objaAmodif["etape4"] = dataR.dataApi.length > 0 ? "ok" : "nok";
                //props.SetInfosGenFormation(objaAmodif);

                // pour que le systeme calcule la validité Lhéo....
                var retAppel = await appelGetSetInfosObjetsFormActSess(utilisateurBearer, formationNumber, null, null, props.SetInfosGenFormation, setMessageProbleme);
              break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
    }

    const handleClickCreateAction = async (stdOUprofessionnalisation='std') => { // certifOuPro contiendra 'std' ou 'professionnalisation'
      setBtCreerActif(false);
      var dataR = await apiActAjout(utilisateurBearer, formationNumber, stdOUprofessionnalisation);
      switch (dataR.statusCode) {
          case 200:
              //pas suffisant car la validité de cette étape dépend des autres actions : 
              //var objaAmodif = JSON.parse(JSON.stringify(props.infosGenFormation)); // on clone (duplique) l'objet pour que tout fonctionne bien
              //objaAmodif["nbActions"] = objaAmodif["nbActions"]+1;
              //objaAmodif["etape4"] = "ok";
              //props.SetInfosGenFormation(objaAmodif);

              navigate(`/formation/etape5/${formationNumber}/action-${dataR.dataApi}`);
            break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
            break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }    
    }

    async function chargementsConsecutifs() {
      setMessageProbleme("");
      setDataLoaded(false);
      await  recupererListeCompleteAct();
      setDataLoaded(true);
    }
    useEffect(() => {
        if(choixCreaAct != undefined && choixCreaAct != "") {
            setShowModalIndicAvantCrea(true);
            //console.log(choixCreaAct);
        }
    }, [choixCreaAct]);


    useEffect(() => {
      if(global.config.modeDebugApp == 1) console.log('useffect FormEtape4');
      document.title = `Action(s) de la formation ${formationNumber}`;
      chargementsConsecutifs();
    }, []);



    return (
      <>
        <LoadingSpinner dataLoaded={dataLoaded}/>
        <MessageErreurApi message={messageProbleme} />
        { props.infosProprietes["ACTIONS_FORMATION|other_possibiliteAjoutAct"].editable == 1 &&
          <MessageInfoBox message={messageBase} />
        }
        <section className="actions-associees">
            { dataLoaded && dataApi &&
            <>
              <div className="titre">
                {dataApi.length > 1  && `Votre formation possède actuellement ${dataApi.length} actions : `}
                {dataApi.length == 1 && `Votre formation possède actuellement 1 action : `}
              </div>
              {dataApi.length > 0 ? <Table columns={columns} data={dataApi} noPagination={true} noDisplayNbResult={true} noFilter={false} /> : <div>Aucune action n'a été trouvée</div>}
            
              <div className="partie-bouton-crea">
              {props.infosProprietes["ACTIONS_FORMATION|other_possibiliteAjoutAct"].editable == 1 ?
                <>
                  {props.infosGenFormation.certifOuPro == "certif"  &&
                    <ButtonDeBase onClick={() =>setChoixCreaAct("professionnalisation")} lib="Créer une action en contrat de professionnalisation" /> 
                  }
                  <ButtonDeBase onClick={() =>handleClickCreateAction("std")} lib="Créer une action" disabled={btCreerActif == true ? false : true} />

                  {dataApi.length > 0 &&
                    <ButtonDeBase onClick={() =>setShowInfoDuplic("ok")} lib="Dupliquer une action" className="fas fa-copy" /> 
                  }

                </>
              :
                <div>
                  Vous ne pouvez pas ajouter d'action sur cette formation
                  {props.infosProprietes["ACTIONS_FORMATION|other_possibiliteAjoutAct"].aide != "" && <b><br />Cause : {props.infosProprietes["ACTIONS_FORMATION|other_possibiliteAjoutAct"].aide}</b>}
                </div>
              }
              </div>
            </>
            }
            {showModalIndicAvantCrea &&
              <Modal size="lg" show={showModalIndicAvantCrea} onHide={handleCloseModalIndicAvantCrea} backdrop="static" keyboard={false} centered aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                          ℹ️ Information
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                      Attention, une action préparée dans le cadre du contrat de professionnalisation ne sera pas financée par pôle emploi.<br /><br /><b>Confirmez-vous vouloir créer une telle action</b> ?

                      <br /><br /><b>Rappel :</b><br />
                      Le contrat de professionnalisation a pour but d’acquérir une qualification professionnelle reconnue :
                      <ul>
                        <li>&bull; diplôme ou titre professionnel enregistré dans le Répertoire national des certifications professionnelles - RNCP ;</li>
                        <li>&bull; certificat de qualification professionnelle (CQP) de branche ou interbranche ;</li>
                        <li>&bull; qualification reconnue dans les classifications d’une convention collective nationale.</li>
                      </ul>
                      <b>⚠️ Attention le contrat de professionnalisation n'est pas financé par pôle emploi.</b><br />
                    </div>
                    <div className="partie-bouton-crea">
                        <ButtonDeBase onClick={() =>handleClickCreateAction(choixCreaAct)} lib="👌 Ok, allons y " disabled={btCreerActif == true ? false : true} />
                        <ButtonDeBase onClick={() =>handleCloseModalIndicAvantCrea()} lib="Annuler la création" className="fas fa-ban sd" />
                    </div>
                </Modal.Body>
              </Modal>
            }
            {showInfoDuplic &&
              <Modal size="lg" show={showInfoDuplic} onHide={handleCloseModalInfoDuplic} backdrop="static" keyboard={false} centered aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                          ℹ️ Information
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{fontSize: "1.1em"}}>
                      Cette fonction permet de dupliquer toutes les données liées à une action.<br />
                      Vous aurez ensuite la possibilité de modifier l'ensemble des informations de l'action qui aura été créée.<br /><br />

                      La duplication est utile si vous avez par exemple référencé une première action en présentielle et que vous proposez une action similaire à distance, ou si vous souhaitez déclarer une nouvelle action avec une durée différente.<br /><br />

                      ⚠️Attention : si vous voulez simplement renseigner une nouvelle session avec de nouvelles dates sur une action existante, il ne faut pas dupliquer ou ajouter une action mais simplement ajouter une nouvelle session à l'action.<br /><br />

                      Pour dupliquer une action, cliquez sur le picto <span className="fas fa-copy picto-legende"></span> situé à droite de l'action originale.
                    </div>
                    <div className="partie-bouton-crea">
                        <ButtonDeBase onClick={() =>handleCloseModalInfoDuplic()} lib="👌 J'ai compris" />
                    </div>
                </Modal.Body>
              </Modal>
            }
        </section>

        <LiensPrecedentSuivant  formationNumber={formationNumber} numEtape={numEtape} infosGenFormation={props.infosGenFormation} notifBeCareful={false} /> 
      </>
    )

}

FormEtape4.propTypes = {
  formationNumber: PropTypes.number.isRequired,
  SetInfosGenFormation : PropTypes.func,
  infosGenFormation: PropTypes.object,
}

export default FormEtape4;