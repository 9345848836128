import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import { useNavigate } from "react-router-dom";

import { apiRecupereInfoGet } from '../../Datas/datasAutre.js'

import { DonneesUtilisateurContext } from '../../contexte.js';
import '../../config.js';

import MessageInfoBox, {MessageErreurApi} from '../../Components/Messages.jsx';
import LoadingSpinner from "../../Components/LoadingSpinner.js";
import ButtonDeBase, {ButtonVoirFiche2} from "../../Components/ButtonsCustom.jsx";
import CsvDownloader from "../../Components/CsvDownloader.jsx";

import Table, {SelectColumnFilter} from '../../Components/Table.jsx';

/**
 * Return Page pro Moteur de recherche des données quiforme
 *
 * @component
 * @summary pour affichage recherche organisme
 * @param {  }
 * @return { HTMLElement }
*/
function AdmQuiformeDiffsList() {
    const navigate = useNavigate(); // utile pour changer de page

    const { setBearer, utilisateurRouteLogin, utilisateurBearer, utilisateurRole } = useContext(DonneesUtilisateurContext);
    const [dataApi, setDataApi] = useState();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [messageProbleme, setMessageProbleme] = useState('');
    const [indicationSimple, setIndicationSimple] = useState('');
    const [messageBase, setMessageBase] = useState('');


    const columns = useMemo(
        () => [
          {
            id:'listesResultats',
            hideHeader : false,
            trieDefautRp : 'siret',
            columns: [
                {  
                Header: 'SIRET', 
                accessor: 'siret', 
                id: "siret", // utile pour trie par défaut
                },
                { 
                Header: () => <>Libellé de l'organisme<br />Brof</>,
                accessor: 'db_LIB_COMPANIES',
                },
                { 
                Header: () => <>Libellé de l'organisme<br />Qf</>,
                accessor: 'qf_LIB_COMPANIES',  
                },
                { 
                Header: () => <>UAI<br />Brof</>,
                accessor: 'db_Numero_UAI', 
                disableSortBy: true,
                },
                { 
                Header: () => <>UAI<br />Qf</>,
                accessor: 'qf_Numero_UAI', 
                disableSortBy: true,
                },                
                {
                Header: () => <>Numéro DE<br />Brof</>,
                accessor: 'db_NUM_DE', 
                disableSortBy: true,
                },
                {
                Header: () => <>Numéro DE<br />Qf</>,
                accessor: 'qf_NUM_DE', 
                disableSortBy: true,
                },



                { 
                Header: () => <>FC<br />Brof</>,
                accessor: 'db_Qualiopi_Form_FormationContinue', 
                disableSortBy: true,
                },
                { 
                Header: () => <>FC<br />Qf</>,
                accessor: 'qf_Qualiopi_Form_FormationContinue', 
                disableSortBy: true,
                },
                {
                Header: () => <>bilan Comp.<br />Brof</>,
                accessor: 'db_Qualiopi_Form_BilanCompetence', 
                disableSortBy: true,
                },
                {
                Header: () => <>bilan Comp.<br />Qf</>,
                accessor: 'qf_Qualiopi_Form_BilanCompetence', 
                disableSortBy: true,
                },
                { 
                Header: () => <>VAE<br />Brof</>,
                accessor: 'db_Qualiopi_Form_VAE', 
                disableSortBy: true,
                },
                { 
                Header: () => <>VAE<br />Qf</>,
                accessor: 'qf_Qualiopi_Form_VAE', 
                disableSortBy: true,
                },
                {
                Header: () => <>Appr<br />Brof</>,
                accessor: 'db_Qualiopi_Form_Apprentissage', 
                disableSortBy: true,
                },
                {
                Header: () => <>Appr<br />Qf</>,
                accessor: 'qf_Qualiopi_Form_Apprentissage', 
                disableSortBy: true,
                },


                { 
                Header: () => <>Adresse<br />Brof</>, 
                accessor: 'db_ADDRESS1', 
                //disableSortBy: true,
                },
                { 
                Header: () => <>Adresse<br />Qf</>,
                accessor: 'qf_ADDRESS1', 
                //disableSortBy: true,
                },
                {
                Header: () => <>Cp<br />Brof</>,
                accessor: 'db_CODE_POSTAL', 
                disableSortBy: true,
                },
                {
                Header: () => <>Cp<br />Qf</>,
                accessor: 'qf_CODEPOSTAL', 
                disableSortBy: true,
                },
                {
                Header: () => <>Code<br />Insee Brof</>,
                accessor: 'db_CODE_INSEE', 
                disableSortBy: true,
                },
                {
                Header: () => <>Code<br />Insee Qf</>,
                accessor: 'qf_CODE_INSEE', 
                disableSortBy: true,
                },

                {
                Header: () => <></>,
                    accessor: 'actions', 
                    id: "actions_center", // le fait de mettre "center" dans l'id permet de center le contenu de la colone
                    disableSortBy: true,
                    Cell: ({ row }) => (  
                    <>
                       <ButtonVoirFiche2 className="fas fa-pen-to-square" 
                                        lien={`edit/${row.original.ID_COMPANIES}`} 
                                        title={`Accéder à la fiche ${row.original.ID_COMPANIES}'}`} />
                    </>
                    ),
                },
            ]
          },
        ],
        []
    )

    const [champRechQuiformeProLibOuSiret, setChampRechQuiformeProLibOuSiret] = useState(localStorage.getItem('champRechQuiformeProLibOuSiret') ? localStorage.getItem('champRechQuiformeProLibOuSiret') : "");
    const [champRechQuiformeProLieu, setChampRechQuiformeProLieu] = useState(localStorage.getItem('champRechQuiformeProLieu') ? localStorage.getItem('champRechQuiformeProLieu') : "");
    const [champRechQuiformeProTypeDiff, setChampRechQuiformeProTypeDiff] = useState(localStorage.getItem('champRechQuiformeProTypeDiff') ? localStorage.getItem('champRechQuiformeProTypeDiff') : '-');

    const handleSubmit = async (e) => {
        e.preventDefault();
        effectueRecherche();
    }
    const handleClickReinit = async (e) => {
        e.preventDefault();
        setChampRechQuiformeProLibOuSiret(""); localStorage.setItem('champRechQuiformeProLibOuSiret',"");
        setChampRechQuiformeProTypeDiff("-"); localStorage.setItem('champRechQuiformeProTypeDiff',"-");
        setChampRechQuiformeProLieu(""); localStorage.setItem('champRechQuiformeProLieu',"");
    }
            
   //  /organismes/search?intitule-recherche=MonCERI&ville=nans&departement=83&id-formation=248502&id-action=1531682&id-session=493451
   async function effectueRecherche() {
        if(global.config.modeDebugApp == 1) console.log(`fct effectueRecherche lancée`);

        let chaineRecherche = "?";
        if (champRechQuiformeProLibOuSiret && champRechQuiformeProLibOuSiret.length > 0) chaineRecherche += "intitule-recherche=" + champRechQuiformeProLibOuSiret + '&';
        else setChampRechQuiformeProLibOuSiret("");
        
        if (champRechQuiformeProLieu && champRechQuiformeProLieu.length > 0)  {   
            if(champRechQuiformeProLieu.length == 2 && !isNaN(champRechQuiformeProLieu)) chaineRecherche += "departement=" + champRechQuiformeProLieu + '&';
            else chaineRecherche += "ville=" + champRechQuiformeProLieu + '&';
        }
        else setChampRechQuiformeProLieu("");



        if (champRechQuiformeProTypeDiff != '-')  chaineRecherche += "type-difference=" + champRechQuiformeProTypeDiff + '&';

        localStorage.setItem('champRechQuiformeProLibOuSiret', champRechQuiformeProLibOuSiret); 
        localStorage.setItem('champRechQuiformeProLieu', champRechQuiformeProLieu); 
        localStorage.setItem('champRechQuiformeProTypeDiff', champRechQuiformeProTypeDiff);
        
        if (chaineRecherche.length == 1) {
            setMessageProbleme('Veuillez saisir au moins un critère'); 
            return false; 
        }
        else {  
            setMessageProbleme("");
            setIndicationSimple("");
            setDataLoaded(false);

            const dataR = await apiRecupereInfoGet(utilisateurBearer, "quiforme/search" + chaineRecherche);
            if(global.config.modeDebugApp == 1) console.log(`+++++++retour apiRecupereInfoGet avec quiforme/search` + chaineRecherche + ` : `, dataR);
            setDataLoaded(true);

            switch (dataR.statusCode) {
                case 200:
                    setDataApi(dataR.dataApi);
                  return true;
                case 400:
                    var dataObjetVide = new Object();
                    setDataApi(dataObjetVide);
                    setIndicationSimple(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "Trop de resultat"
                    return true;
                case 401:
                    setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized 
                  break;
                default:
                    setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
            }            
            return false;
        }
        
    }

    async function chargementsConsecutifs() {
        setDataLoaded(false);
        if(!(!champRechQuiformeProLibOuSiret && !champRechQuiformeProLieu && champRechQuiformeProTypeDiff == "-")) effectueRecherche();
        setDataLoaded(true);
    }
    

    useEffect(() => {
        if(global.config.modeDebugApp == 1) console.log('useffect 1st');
        document.title = "Quiforme : recherche des différences"
        if (utilisateurBearer === '000000' || utilisateurRole != "AdminExtranet" )  navigate(utilisateurRouteLogin); // si pas identifié ou role NOK on redirige sur l'acceuil
        setMessageBase("Gestion des différences entre les données en base et Quiforme : Effectuez une recherche puis cliquez sur le pictogramme <span class='enrobage-boutton picto-legende'><span class='fas fa-pen-to-square'></span></span> pour intervenir sur les informations d'un of.");
        chargementsConsecutifs();

        /*2 methodes ci dessous marchent pas du tout "Dans un composant React, il est recommandé d'utiliser les refs pour accéder aux éléments du DOM plutôt que d'utiliser des identifiants (IDs), car cela s'aligne mieux avec le modèle de programmation de React. "
        const selectElement = document.getElementById("champRechQuiformeProTypeDiff");
        if (selectElement) {
            const optionToColor = selectElement.options[1];
            optionToColor.style.color = "red";
        }
        else console.log('navre 1');

        const optionToColor = document.querySelector('#champRechQuiformeProTypeDiff option:first-child');   
        if (optionToColor) {
          optionToColor.style.color = 'red';
        }
        else console.log('navre 2');*/
    }, []);
   var isRechercherDesactive = (!champRechQuiformeProLibOuSiret && !champRechQuiformeProLieu && champRechQuiformeProTypeDiff == "-") || !dataLoaded;

   return (     
        <main className="sous-bloc">            
            {process.env.REACT_APP_MY_ENV != 'production' && <MessageErreurApi message={global.config.msgPrTechnique.msgSrvTest} />}            
            <MessageErreurApi message={messageProbleme}  />
            <LoadingSpinner dataLoaded={dataLoaded} />

            { dataLoaded > 0 &&
            <>
                <MessageInfoBox message={messageBase} />
                <form onSubmit={handleSubmit}>
                    <div className="partie-form">
                        <input className="form-control" placeholder="Libellé / Siret / Id de l'organisme" type="text" id="champRechQuiformeProLibOuSiret" value={champRechQuiformeProLibOuSiret == null ? '' : champRechQuiformeProLibOuSiret} onChange={(e) => {setChampRechQuiformeProLibOuSiret(e.target.value)}} />
                        <input className="form-control" placeholder='Ville ou département' type="text" id="champRechQuiformeProLieu" value={champRechQuiformeProLieu == null ? '' : champRechQuiformeProLieu} onChange={(e) => {setChampRechQuiformeProLieu(e.target.value)}} />
                    </div>

                    <div className="partie-form">
                        <select className="form-control custom-select" id="champRechQuiformeProTypeDiff" value={champRechQuiformeProTypeDiff == null ? '-' : champRechQuiformeProTypeDiff} onChange={(e) => {setChampRechQuiformeProTypeDiff(e.target.value)}}>
                            <option value="-">Tout les différences détectées</option>
                            <option value="diff_identite">Uniquement les différences "identité"</option>
                            <option value="diff_qualiopi">Uniquement les différences "qualiopi"</option>
                            <option value="diff_adresse">Uniquement les différences "adresse"</option>
                        </select>
                    </div>
                    <div className="partie-form-bt">
                        <CsvDownloader urlApi="/quiforme/GetCsv" boutonLib="Télécharger cette liste au format CSV" boutonTitle="Télécharger en CSV"
                                       nomFicCible="Liste des differences Quiforme.csv"  />
                        <a href="" onClick={handleClickReinit} rel="noopener">Réinitialiser</a>

                        <ButtonDeBase typeSubmit={true} lib="Rechercher" disabled={isRechercherDesactive} />
                    </div>
                </form>
                { dataApi &&
                <>
                    {dataApi.length > 0 && <Table columns={columns} data={dataApi} noPagination={null} noDisplayNbResult={null} noFilter={null} paginationOntop={true} />}
                    {dataApi.length == 0 && indicationSimple.length == 0 &&  <div>Aucun organisme n'a été trouvé.<br /><br /></div>}
                    {indicationSimple.length > 0 &&  <MessageInfoBox message={indicationSimple} />}
                </>
                }

            </> } 
        </main>
    )
}


export default AdmQuiformeDiffsList;