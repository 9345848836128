import React from "react"
import SubMenu from "./SubMenu"


//  return (<div className={`${isVisible ? 'block' : 'hidden'} absolute w-48`} >
//<div className="mt-4 rounded-tr-base rounded-tl-base bg-gray-50 rounded-bl-base rounded-br-base px-4 py-4 w-full">
const Menu = ({ isVisible ,itemsFinaux , displayBadge }) => {

    return (<div className={`${isVisible ? 'block' : 'hidden'} absolute w-48`} >
        <div className="bg-gray-50 px-4 w-full">
            <SubMenu items={itemsFinaux} displayBadge={displayBadge} />
        </div>
    </div>)
}
export default Menu