var objRetourInvalide = {"statusCode": 400, "dataApi": [] }

//ne sera plus utlisé à therme, préférer apiCertifAppelBase
export async function apiFormAppelListTypesCertif(utilisateurBearer) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiCertifications.url + 'types-diplomes';
    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi}`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200) return objRetour;

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiFormAppelListTypesCertif', error_1);
        return objRetour;
    }
}

export async function apiCertifAppelBase(utilisateurBearer, suiteUrl) {
    if (utilisateurBearer == ""  || suiteUrl == "") return objRetourInvalide;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiCertifications.url + suiteUrl;
    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi}`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200 && response.status != 400) return objRetour;  // 400 : un message personnalisé est retourné dans ce cas, ex : "Il y a plus de XXX résultats ({XXXX), veuillez affiner votre recherche"

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiCertifAppelBase', error_1);
        return objRetour;
    }
}

///////////////////////////// partie prof /////////////////////////////



export async function apiProfAppelBase(utilisateurBearer, suiteUrl) {
    if (utilisateurBearer == ""  || suiteUrl == "") return objRetourInvalide;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiProfessionnalisants.url + suiteUrl;
    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi}`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200 && response.status != 400) return objRetour;  // 400 : un message personnalisé est retourné dans ce cas, ex : "Il y a plus de XXX résultats ({XXXX), veuillez affiner votre recherche"


        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiProfAppelBase', error_1);
        return objRetour;
    }
}

// inspi apiPostFormulaire1
export async function apiProfPostNouvelleProf(utilisateurBearer, datasToPost) {
    if (utilisateurBearer == "") return objRetourInvalide;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(datasToPost)// attendu par l'api : {LIB_PROFESSIONNALISANTS} https://www.techomoro.com/submit-a-form-data-to-rest-api-in-a-react-app/
    };

    var urlApi = global.config.apiProfessionnalisants.url;
    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi}`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200) return objRetour;
    
        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiProfPostNouvelleProf', error_1);
        return objRetour;
    }
}
/*
export async function apiProfPutProfNsf(utilisateurBearer, codeDiplomeAasso, datasToPost) {
    if (utilisateurBearer == "") return objRetourInvalide;
    // on adapte le tableau d'objet qu'on va envoyer pour qu'il ait les 2 propriétés attendues ID_PROFESSIONNALISANTS et ID_NSF
    for (var occ in datasToPost) {
        datasToPost[occ].ID_PROFESSIONNALISANTS = codeDiplomeAasso
        datasToPost[occ].ID_NSF = datasToPost[occ].code;
    }

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json' 
        },
        body: JSON.stringify(datasToPost) // attendu par l'api : [{ID_PROFESSIONNALISANTS ID_NSF}}
    };

    var urlApi = global.config.apiProfessionnalisants.url + codeDiplomeAasso + '/nsf/';
    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi}`);
    
    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200) return objRetour;
    
        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiProfPutProfNsf', error_1);
        return objRetour;
    }
}*/
/*
export async function apiProfPutProfRome(utilisateurBearer, codeDiplomeAasso, datasToPost) {
    if (utilisateurBearer == "") return objRetourInvalide;
    // on adapte le tableau d'objet qu'on va envoyer pour qu'il ait les 2 propriétés attendues ID_PROFESSIONNALISANTS et ID_ROME_EM
    for (var occ in datasToPost) {
        datasToPost[occ].ID_PROFESSIONNALISANTS = codeDiplomeAasso
        datasToPost[occ].ID_ROME_EM = datasToPost[occ].code;
    }

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json' 
        },
        body: JSON.stringify(datasToPost) // attendu par l'api : [{ID_PROFESSIONNALISANTS ID_ROME_EM}}
    };

    var urlApi = global.config.apiProfessionnalisants.url + codeDiplomeAasso + '/rome/';
    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi}`);
    
    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200) return objRetour;
    
        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiProfPutProfRome', error_1);
        return objRetour;
    }
}
*/
export async function apiProfPutProfFormacode(utilisateurBearer, codeDiplomeAasso, datasToPost, formacodePrincipal) {
    if (utilisateurBearer == "") return objRetourInvalide;
    // on adapte le tableau d'objet qu'on va envoyer pour qu'il ait les 2 propriétés attendues ID_PROFESSIONNALISANTS et ID_FORMACODES
    var isPinrcipal = null;
    for (var occ in datasToPost) {
        isPinrcipal = (datasToPost[occ].code == formacodePrincipal) ? true :false;

        datasToPost[occ].ID_PROFESSIONNALISANTS = codeDiplomeAasso
        datasToPost[occ].ID_FORMACODES = datasToPost[occ].code;
        datasToPost[occ].FORMACODE_PRINCIPAL = isPinrcipal;            
    }

    if (isPinrcipal == null) return objRetourInvalide;

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json' 
        },
        body: JSON.stringify(datasToPost) // attendu par l'api : [{ID_PROFESSIONNALISANTS ID_FORMACODES}}
    };

    var urlApi = global.config.apiProfessionnalisants.url + codeDiplomeAasso + '/formacode/';
    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi}`);
    
    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200) return objRetour;
    
        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiProfPutProfFormacode', error_1);
        return objRetour;
    }
}