import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { DonneesUtilisateurProvider } from './contexte.js';

import './config.js';
  
// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
//<React.StrictMode> était la balise superieure qui a été enlevée pour éviter que les usefect ne ce  lancent 2 fois
root.render(
        <DonneesUtilisateurProvider>
            <App />
        </DonneesUtilisateurProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// to add header lien to font-awsome :
const link = document.createElement("link");
link.href = "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
link.type = "text/css";
link.rel = "stylesheet";

document.getElementsByTagName("head")[0].appendChild(link);

// message de confirmation si l'utilisateur tente de naviguer ailleur ou de fermer sa fenetre
// un message auto se déclenche automatiquement quand reload ou fermeture onglet (firefox detrecte modif dom), donc inutile 
//window.onbeforeunload = function(){ return 'Etes vous sur de vouloir quitter le site ? SI vous étiez en trina de saisir un formulaire, esodnnées seront perdues'; };