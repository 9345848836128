import PropTypes from 'prop-types';
import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import { useNavigate } from "react-router-dom";
import {Link } from "react-router-dom";

import { DonneesUtilisateurContext } from '../../contexte.js';

import { apiFormGetInfosDiv } from '../../Datas/dataFormations.js'
import { apiActAjout, apiActSuppr } from '../../Datas/dataActions.js'

import LoadingSpinner from "../../Components/LoadingSpinner";

import MessageInfoBox, {MessageErreurApi} from '../../Components/Messages.jsx';
import Table, {SelectColumnFilter} from '../../Components/Table.jsx'
import ButtonDeBase, {ButtonVoirFiche, ButtonSuppr } from '../../Components/ButtonsCustom.jsx';
import FormControl from "../../Components/Form/FormControl";

import {  Container,  Form,  Button,  Row,  Col} from "react-bootstrap";  // InputGroup, FormControl -Col pour afficher les dates  sur meme ligne
import { useForm, useWatch } from "react-hook-form"; //Controller ? useWatch pour controler lors de la saisie
/**
 * Return Formulaire étape 3 (données simples de la formation)
 *
 * @component
 * @summary page étape 2
 * @param {  }
 * @return { HTMLElement }
*/
function FormEtape2_test(props) {
    const formationNumber = props.formationNumber;

    const navigate = useNavigate(); // utile pour changer de page
    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const [dataApi, setDataApi] = useState();
    const [dataLoaded, setDataLoaded] = useState(true);
    const [messageProbleme, setMessageProbleme] = useState();

    async function recupererInfosPrincipales() {
      const dataR = await apiFormGetInfosDiv(utilisateurBearer, formationNumber);
      if(global.config.modeDebugApp == 1) console.log('retour apiFormGetInfosDiv dans recupererInfosPrincipales : ', dataR);
      setDataLoaded(false);
      switch (dataR.statusCode) {
          case 200:
              setDataApi(dataR.dataApi);
            break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
            break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
      setDataLoaded(true);
    }

    useEffect(() => {
      if(global.config.modeDebugApp == 1) console.log('useffect FormEtape2');
      recupererInfosPrincipales();
    }, []);

    function ControleNbLettre({ control, what, max })  {
      const cible = useWatch({ //https://react-hook-form.com/api/usewatch pour controler lors de la saisie
        control : control,
        name: what,
      });
      
      let lgSaisie = Number(cible.length);
      let carRestant = max - lgSaisie;
      let retour =  carRestant > 0 ? 
          <>caractères restants : {carRestant}</>
        : 
          <span className="alert-depassement">caractères restants : {carRestant}</span>;

      return <small className="form-text">{retour}</small>
    }


    const initState = {
      CONTENU_FORMATION: "test",
      OBJECTIF_FORMATION: "testobj",
      DATE1: "2022-01-05",
      DATE2: "2023-12-05",
      termsAndConditions: false
    };
  
      
    const onChangeCheckBox = (event) => {
        alert(event.target.value);
    }

    // eslint-disable-next-line no-unused-vars
    const [initialValues, setInitialValues] = useState(initState);
  
    const onSubmit = async (values) => {
      if(values.DATE1 > values.DATE2) {
        alert("Les dates sont NOK");
        return false;
      }
      if(global.config.modeDebugApp == 1) console.log("Values:::", values);
      if(global.config.modeDebugApp == 1) console.log("Values:::", JSON.stringify(values));
    };
  
    const onError = (error) => {
      console.log("ERROR:::", error);
    };
  
    const {
      register, handleSubmit, getValues, watch, formState: { errors }, control } = useForm({ // control pour controler lors de la saisie
      mode: "onTouched", // Validation will trigger on the first blur event. After that, it will trigger on every change event.
      //reValidateMode: "onSubmit", le fait de commenter permet de revalider lors de la saisie val possible : onChange (defaut) | onBlur | onSubmit
      defaultValues: initialValues
    });

    useEffect(() => {
      const subscription = watch((value, { name, type }) => {
        if(global.config.modeDebugApp == 1) console.log(">>", value, name, type); //name = nom du champ qui a bougé type: "change"

        if(value.DATE1 > value.DATE2) console.log("oula");
      });
  
      return () => subscription.unsubscribe();
    }, [watch]);
    // inspi https://codesandbox.io/s/y74yf?file=/src/App.js:517-530
     // de base le message était ainsi :
     //{...register("CONTENU_FORMATION", { required: "Ce champs est obligatoire",  maxLength: 2 })}
     // mais ai changé pour faire comme le https://codesandbox.io/s/react-hook-form-apply-validation-q5m00 (2d code de https://react-hook-form.com/get-started#Quickstart)
    
     var maxLengthCF = props.infosProprietes.CONTENU_FORMATION.max_length;
     
     return (
        <>
        <LoadingSpinner dataLoaded={dataLoaded}/>
        <MessageErreurApi message={messageProbleme} />

        <Container className="my-0">
            <Form onSubmit={handleSubmit(onSubmit, onError)}>
              <FormControl id="OBJECTIF_FORMATION" infosProprietes={props.infosProprietes} register={register} control={control} errors={errors} useWatch={useWatch}/>

              <Form.Group className="mb-3" controlId="CONTENU_FORMATION"> {/*controlId permet de mettre un for  dans le label et un id identique dnas le controle*/ }
                <Form.Label>Contenu Formation</Form.Label>
                <Form.Control 
                  type="text" 
                  placeholder="Contenu Formation"
                  {...register("CONTENU_FORMATION", { required: true,  maxLength: maxLengthCF })}
                />
                <ControleNbLettre control={control} what="CONTENU_FORMATION" max={maxLengthCF} />
                {/*errors.CONTENU_FORMATION && (
                  <Form.Text className="text-danger">
                    {errors.CONTENU_FORMATION.message}

                  </Form.Text>
                )*/}
                {errors.CONTENU_FORMATION && (
                  <Form.Text className="text-danger">
                    {errors?.CONTENU_FORMATION?.type === "required" && <p>Cette information est requise</p>}
                    {errors?.CONTENU_FORMATION?.type === "maxLength" && (
                      <p>Ce champs ne doit pas contenir plus de {maxLengthCF} caractères</p>
                    )}
                  </Form.Text>
                )}
              </Form.Group>
              <Row className="mb-3">
                <Form.Group as={Col} md="2" controlId="DATE1">
                  <Form.Label>Date 1</Form.Label>
                  <Form.Control type="date"
                                {...register("DATE1", { required: true })}></Form.Control>
                  {errors.DATE1 && (
                    <Form.Text className="text-danger">
                      {errors?.DATE1?.type === "required" && <p>Cette information est requise</p>}
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="2" controlId="DATE2">
                  <Form.Label>Date 2</Form.Label>
                  <Form.Control type="date" 
                                {...register("DATE2", 
                                             { required: true }                                
                                )}></Form.Control>
                  {errors.DATE2 && (
                    <Form.Text className="text-danger">
                      {errors?.DATE2?.type === "required" && <p>Cette information est requise</p>}
                    </Form.Text>
                  )}
                </Form.Group>
              </Row>
              <Form.Check
                className="my-3"
                type="radio"
                label="PayPal or Credit Card"
                id="PayPal"
                name="paymentMethod"
                value="PayPal"
            
                onChange={onChangeCheckBox}
              />
            <ButtonDeBase typeSubmit={true} lib="Enregistrer" />
          </Form>
        </Container>
 
        </>
    )

}

FormEtape2_test.propTypes = {
  formationNumber: PropTypes.number,
  SetInfosGenFormation : PropTypes.func,
  infosGenFormation: PropTypes.object,
  infosProprietes: PropTypes.object,
}

export default FormEtape2_test;