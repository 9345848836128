import PropTypes from 'prop-types';
import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import { useNavigate, NavLink } from "react-router-dom";

import { verifMaintenance } from '../Datas/datasAutre.js'
import { apiFormAppelList, apiFormSuppr } from '../Datas/dataFormations.js'
import { apiActAppelGetInfosDiv, apiActSuppr } from '../Datas/dataActions.js'
import { apiSessAppelGetInfosDiv, apiSessSuppr } from '../Datas/dataSessions.js'
import { DonneesUtilisateurContext } from '../contexte.js';

import MessageInfoBox, {MessageErreurApi} from '../Components/Messages.jsx';
import LoadingSpinner from "../Components/LoadingSpinner";
import {ButtonVoirFiche2, ButtonSuppr} from "../Components/ButtonsCustom.jsx";
import Table from '../Components/Table.jsx';
import { retourneCauseInterdictionForm, retourneCauseInterdictionAct, retourneCauseInterdictionSess } from '../fonctionsDiv.js';

/**
 * Return Page principale de gestion des formations
 *
 * @component
 * @summary pour affichage recherche formations
 * @param {  }
 * @return { HTMLElement }
*/
function FormationIncompletes(props) {
    const navigate = useNavigate();
    const { setBearer, utilisateurRouteLogin, utilisateurBearer, setNbFormAttente, setNbFormIncomplete, setNbActAttente, setNbActIncomplete, setNbSessAttente, setNbSessIncomplete } = useContext(DonneesUtilisateurContext);
    const [dataApi, setDataApi] = useState();
    const [dataApiAct, setDataApiAct] = useState();
    const [dataApiSess, setDataApiSess] = useState();

    const [dataLoadedForm, setDataLoadedForm] = useState(true);
    const [dataLoadedAct, setDataLoadedAct] = useState(true);
    const [dataLoadedSess, setDataLoadedSess] = useState(true);
    const [enMaintenance, setEnMaintenance] = useState(false);
    const [msgMaintenance , setMsgMaintenance] = useState(false);

    const [messageProbleme, setMessageProbleme] = useState();
    const [messageBase, setMessageBase] = useState();
    var typeDePb = props.typeDePb ; //attenteCorrection ou incompletes
    var paramsAppelApi = (typeDePb == 'attenteCorrection') ? "?invalide-co=true" : "?invalide-lheo=true";

    const columns = useMemo(
        () => [
          {
            id:'listesFormations',
            hideHeader : false,
            trieDefautRp : 'LIB_ACTIONS_FORMATION',
            columns: [
                {  
                Header: 'libellé', 
                accessor: 'LIB_ACTIONS_FORMATION', 
                id: "LIB_ACTIONS_FORMATION",
                },
                { 
                Header: 'Ville(s)', 
                accessor: 'villes', 
                disableSortBy: true,
                },
                /*{ 
                Header: 'CPs', 
                accessor: 'cps', 
                disableSortBy: true,
                },*/
                { 
                Header: 'Mesure', 
                accessor: 'mesures_libs', 
                disableSortBy: true,
                },
                { 
                Header: 'Financeur', 
                accessor: 'financeurs_libs', 
                disableSortBy: true,
                },
                { 
                Header: () => <>Numéro(s)<br />de session</>,
                accessor: 'idSessions', 
                disableSortBy: true,
                // troncature des id pour éviter un débordement... (nb, autre facon de faire le match popur compter le nb de fois ou le car est présent : match(new RegExp(",","gi")) )
                // si plus de 4 virgules (donc plus de 5 id) on ne retient que le premier
                Cell: ({ row }) => (  
                    <>
                    {row.original.idSessions && row.original.nbSessions > 4 ?
                        row.original.idSessions.substring(0, row.original.idSessions.indexOf(",", 1)) + "... \r\n(" + row.original.nbSessions + " sessions)" 
                    : 
                        row.original.idSessions}
                    </>
                    ),
                },
                { 
                Header: () => <>Date(s)<br />début</>,
                accessor: 'debuts',
                disableSortBy: true,
                // modification des des dates pour éviter un débordement...
                //si plus de 5 dates (lg 44) :  point suspension entre la plus ancienne et la plus recente
                Cell: ({ row }) => (  
                    <>
                    {row.original.debuts && row.original.debuts.length > 44 ? row.original.debuts.substring(0,8) + "..." + row.original.debuts.substring(row.original.debuts.length-8) : row.original.debuts}
                    </>
                    ),
                },
                {  
                Header: () => <>Code<br />Rncp</>,
                accessor: 'codesRncps', 
                disableSortBy: true,
                },
                // commenté car l'api ne fait "plus de jointure sur ACTIONS_CERTIFICATIONs si liste des invalides depuis le 1501 pour gagner en temps de réponse"
                //{  
                //Header: () => <>Contrat<br />de Pro.</>,
                //id: "accessContratPro_center", 
                //Cell: ({ row }) => (  
                //    <>
                //        {/* remplacement de 1/0 par oui/non, une cellule peut contenir les deux à la fois */
                //        row.original.accessContratPro != null && row.original.accessContratPro.replace('0', 'Oui').replace('1', 'Non')
                //        }
                //    </>
                //    ),
                //},
                {
                Header: () => <></>,
                    accessor: 'actions', 
                    id: "actions_center", // le fait de mettre "center" dans l'id permet de center le contenu de la colone
                    disableSortBy: true,
                    Cell: ({ row }) => (  
                    <>
                        <ButtonVoirFiche2 className="fas fa-pen-to-square" 
                                          lien={`../formation/etape1/${row.original.ID_ACTIONS_FORMATION}`} 
                                          title={`Accéder à la fiche ${row.original.ID_ACTIONS_FORMATION} (créée le : ${row.original.DATE_CREATION_fr} ${row.original.DATE_MODIF_fr != null ? `, modifiée le : ${row.original.DATE_MODIF_fr})` : ')'}`} />
                        {/*<Link title={`Accéder à la fiche ${row.original.ID_ACTIONS_FORMATION}`}  to={`../formation/etape1/${row.original.ID_ACTIONS_FORMATION}`}>
                            <ButtonVoirFiche className="fas fa-pen-to-square" />
                    </Link>*/}
                        { (row.original.cause_intervention_interdite == null || row.original.cause_intervention_interdite == "") ?
                            <NavLink title={`Supprimer ${row.original.ID_ACTIONS_FORMATION}`} to="" onClick={() => handleClickSuppFormation(row.original.ID_ACTIONS_FORMATION, row.original.nbSessions)}>
                              <ButtonSuppr />
                            </NavLink>
                            :
                            <NavLink title={retourneCauseInterdictionForm(row.original.cause_intervention_interdite)}
                                    to="" 
                                    onClick={() => hdleClickNoPossibleForm(row.original.cause_intervention_interdite)}>
                              <ButtonSuppr disabled="disabled" />
                            </NavLink>
                        }
                    </>
                    ),
                },
            ]
          },
        ],
        []
    )

    function  hdleClickNoPossibleForm (cause_intervention_interdite) {
        alert(retourneCauseInterdictionForm(cause_intervention_interdite, 1));
    }

    const columnsAct = useMemo(
        () => [
          {
            id:'listesActions',
            hideHeader : false,
            trieDefautRp : 'LIB_ACTIONS_FORMATION',
            columns: [
                { 
                  Header: 'N° de l\'action', 
                  accessor: 'ID_ACTIONS_SESSIONS',
                },
                { 
                  Header: 'Libellé de la fomation', 
                  accessor: 'LIB_ACTIONS_FORMATION', 
                },
                { 
                  Header: 'Adresse(s)', 
                  accessor: 'adresses',
                  disableSortBy: true,
                },
                { 
                  Header: 'Ville(s)', 
                  accessor: 'villes',
                  disableSortBy: true,
                },
                { 
                  Header: 'Cp', 
                  accessor: 'cps',
                  disableSortBy: true,
                },
                { 
                  Header: 'Financeur', 
                  accessor: 'LIB_MESURES',
                },
                {
                Header: () => <>Date(s)<br />début</>,
                accessor: 'debuts',
                disableSortBy: true,
                // modification des des dates pour éviter un débordement...
                //si plus de 5 dates (lg 44) :  point suspension entre la plus ancienne et la plus recente
                Cell: ({ row }) => (  
                    <>
                    {row.original.debuts && row.original.debuts.length > 44 ? row.original.debuts.substring(0,8) + "..." + row.original.debuts.substring(row.original.debuts.length-8) : row.original.debuts}
                    </>
                    ),
                },
                {
                  Header: () => <></>,
                    accessor: 'actions', 
                    id: "actions_center", // le fait de mettre "center" dans l'id permet de center le contenu de la colone
                    disableSortBy: true,
                    Cell: ({ row }) => (
                      <>
                        <ButtonVoirFiche2 className="fas fa-pen-to-square" lien={`/formation/etape5/${row.original.ID_ACTIONS_FORMATION}/action-${row.original.ID_ACTIONS_SESSIONS}`} />
                        {/*<Link title={`Accéder à la fiche ${row.original.ID_ACTIONS_SESSIONS}`} to={`/formation/etape5/${row.original.ID_ACTIONS_FORMATION}/action-${row.original.ID_ACTIONS_SESSIONS}`}>
                          <ButtonVoirFiche className="fas fa-pen-to-square" />
                        </Link>*/}
                        { (row.original.cause_intervention_interdite == null || row.original.cause_intervention_interdite == "") ?
                            <NavLink title={`Supprimer ${row.original.ID_ACTIONS_FORMATION}/${row.original.ID_ACTIONS_SESSIONS}`} to="" onClick={() => handleClickSupprAction(row.original.ID_ACTIONS_SESSIONS, row.original.nbSessions)}>
                              <ButtonSuppr />
                            </NavLink>
                            :
                            <NavLink title={retourneCauseInterdictionAct(row.original.cause_intervention_interdite)}
                                    to="" 
                                    onClick={() => hdleClickNoPossibleAct(row.original.cause_intervention_interdite)}>
                              <ButtonSuppr disabled="disabled" />
                            </NavLink>
                        }
                    </>
                    ),
                },
            ]
          },
        ],
        []
    )

    function  hdleClickNoPossibleAct (cause_intervention_interdite) {
      alert(retourneCauseInterdictionAct(cause_intervention_interdite, 1));
    }

    const columnsSess = useMemo(
      () => [
        {
          id:'listesSessions',
          hideHeader : false,
          trieDefautRp : 'LIB_ACTIONS_FORMATION',
          columns: [
              { 
                Header: 'N° de session', 
                accessor: 'ID_ACTIONS_SESSION_PERIODE',
              },
              { 
                Header: 'Libellé de la fomation', 
                accessor: 'LIB_ACTIONS_FORMATION', 
              },
              { 
                Header: 'Adresses', 
                accessor: 'adresses',
                disableSortBy: true,
              },
              { 
                Header: 'Villes', 
                accessor: 'villes',
                disableSortBy: true,
              },
              { 
                Header: 'Cps', 
                accessor: 'cps',
                disableSortBy: true,
              },
              { 
                Header: 'Financeur', 
                accessor: 'LIB_MESURES',
              },
              { 
                Header: 'Date début', 
                accessor: 'DATE_DEBUT',
              },
              { 
                Header: 'Date fin', 
                accessor: 'DATE_FIN',
              },
              {
                Header: () => <></>,
                  accessor: 'actions', 
                  id: "actions_center", // le fait de mettre "center" dans l'id permet de center le contenu de la colone
                  disableSortBy: true,
                  Cell: ({ row }) => (
                    <>
                      <ButtonVoirFiche2 className="fas fa-pen-to-square" lien={`/formation/etape10/${row.original.ID_ACTIONS_FORMATION}/action-${row.original.ID_ACTIONS_SESSIONS}/session-${row.original.ID_ACTIONS_SESSION_PERIODE}`} />
                      {/*<Link title={`Accéder à la fiche ${row.original.ID_ACTIONS_SESSION_PERIODE}`} to={`/formation/etape10/${row.original.ID_ACTIONS_FORMATION}/action-${row.original.ID_ACTIONS_SESSIONS}/session-${row.original.ID_ACTIONS_SESSION_PERIODE}`}>
                        <ButtonVoirFiche className="fas fa-pen-to-square" />
                  </Link>*/}
                      { (row.original.cause_intervention_interdite == null || row.original.cause_intervention_interdite == "") ?
                            <NavLink title={`Supprimer ${row.original.ID_ACTIONS_FORMATION}/${row.original.ID_ACTIONS_SESSIONS}/${row.original.ID_ACTIONS_SESSION_PERIODE}`} to="" onClick={() => handleClickSupprSession(row.original.ID_ACTIONS_SESSION_PERIODE)}>
                              <ButtonSuppr />
                            </NavLink>
                            :
                            <NavLink title={retourneCauseInterdictionSess(row.original.cause_intervention_interdite)}
                                    to="" 
                                    onClick={() => hdleClickNoPossibleSess(row.original.cause_intervention_interdite)}>
                              <ButtonSuppr disabled="disabled" />
                            </NavLink>
                      }
                  </>
                  ),
              },
          ]
        },
      ],
      []
  )

  function  hdleClickNoPossibleSess (cause_intervention_interdite) {
    alert(retourneCauseInterdictionSess(cause_intervention_interdite, 1));
  }

///////// partie formation
    const handleClickSuppFormation  = async (codeAction, nbSessions) => {
        var confirmation = confirm("Cette formation contient " + nbSessions + " sessions, êtes vous sûr de vouloir la supprimer ?");
        if(confirmation == true) {
          var dataR = await apiFormSuppr(utilisateurBearer, codeAction);
          if(global.config.modeDebugApp == 1) console.log('retour apiFormSuppr : ', dataR);
          switch (dataR.statusCode) {
              case 204:
                  //alert("La formation a bien été supprimée");
                  recupererListeCompleteForm();
                break;
              case 401:
                  setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
                break;
              default:
                  setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
          }
        }
    }

    async function recupererListeCompleteForm() {
        setDataLoadedForm(false);
        var dataR = await apiFormAppelList(utilisateurBearer, paramsAppelApi);
        if(global.config.modeDebugApp == 1) console.log('retour apiFormAppelList invalides : ', dataR)
        setDataLoadedForm(true);

        switch (dataR.statusCode) {
           case 200:
                setDataApi(dataR.dataApi);
                if(typeDePb == 'attenteCorrection') setNbFormAttente(dataR.dataApi.length);
                else setNbFormIncomplete(dataR.dataApi.length);

                return dataR.dataApi;
           case 401:
               setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
             break;
           default:
               setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
           return false;
       }
   }
///////// fin partie formation

///////// partie action
    const handleClickSupprAction  = async (codeAction, nbSessions) => {
      var confirmation = confirm("Cette action contient " + nbSessions + " sessions, êtes vous sûr de vouloir la supprimer ?");
      if(confirmation == true) {
        var dataR = await apiActSuppr(utilisateurBearer, codeAction);
        if(global.config.modeDebugApp == 1) console.log('retour apiActSuppr : ', dataR);
        switch (dataR.statusCode) {
            case 204:
                //alert("L'action a bien été supprimée");
                recupererListeCompleteAct();
              break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
              break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
      }
    }
    async function recupererListeCompleteAct() {
        setDataLoadedAct(false);
        const dataR = await apiActAppelGetInfosDiv(utilisateurBearer, paramsAppelApi);
        if(global.config.modeDebugApp == 1) console.log('retour apiActAppelGetInfosDiv dans recupererListeCompleteAct invalides : ', dataR);
        setDataLoadedAct(true);
        switch (dataR.statusCode) {
            case 200:
                setDataApiAct(dataR.dataApi);
                if(typeDePb == 'attenteCorrection') setNbActAttente(dataR.dataApi.length);
                else setNbActIncomplete(dataR.dataApi.length);

                return dataR.dataApi;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
            break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
            return false;
        }
    }
///////// fin partie action

///////// partie session
const handleClickSupprSession  = async (codeAction) => {
  var confirmation = confirm("Etes vous sûr de vouloir supprimer cette session ?");
  if(confirmation == true) {
    var dataR = await apiSessSuppr(utilisateurBearer, codeAction);
    if(global.config.modeDebugApp == 1) console.log('retour apiActSuppr : ', dataR);
    switch (dataR.statusCode) {
        case 204:
            //alert("La session a bien été supprimée");
            recupererListeCompleteSess();
          break;
        case 401:
            setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
          break;
        default:
            setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
    }
  }
}
async function recupererListeCompleteSess() {
    setDataLoadedSess(false);
    const dataR = await apiSessAppelGetInfosDiv(utilisateurBearer, paramsAppelApi);
    if(global.config.modeDebugApp == 1) console.log('retour apiSessAppelGetInfosDiv dans recupererListeCompleteSess invalides : ', dataR);
    setDataLoadedSess(true);

    switch (dataR.statusCode) {
        case 200:
            setDataApiSess(dataR.dataApi);
            if(typeDePb == 'attenteCorrection') setNbSessAttente(dataR.dataApi.length);
            else setNbSessIncomplete(dataR.dataApi.length);
            return dataR.dataApi;
        case 401:
            setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
        break;
        default:
            setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        return false;
    }

}
///////// fin partie session

    async function chargementTableaux() {
      var retVerif = await verifMaintenance(setEnMaintenance, setMsgMaintenance);

      //console.log(`retSesss : ${retSesss.length}  retActs : ${retActs.length} retForms : ${retForms.length}`);
      //if(retForms != false && retActs != false && retSesss != false) { // attention  false si 0 résutlat !
      if(retVerif == true) {
        var retForms = await recupererListeCompleteForm();
        var retActs = await recupererListeCompleteAct();
        var retSesss = await recupererListeCompleteSess();

        let messageBase = "";
        if(retSesss.length > 0 && retActs.length > 0 && retForms.length > 0) {
          if(typeDePb == 'attenteCorrection') {
            messageBase = "Vous avez des sessions, des actions et des formations en attente de correction.<br />";
          } else {
            messageBase = "Vous avez des sessions, des actions et des formations incomplètes.<br />";
            //messageBase += "L'état des formations étant dépendant de leurs actions et celui des actions dépendant de leurs sessions, vous devez d'abord éditer les sessions pour y apporter les compléments d'informations.<br />";
            //messageBase += "Vous devez d'abord vous rendre sur les sessions pour y apporter les compléments d'informations attendus.<br />";
            messageBase += "Pour simplifier les corrections, nous vous proposons de compléter les sessions dans un premier temps puis d'intervenir sur les actions qui nécessiteraient un complément d'information et de terminer par les formations.<br />";
          }
        }
        else if(retSesss.length == 0 && retActs.length > 0 && retForms.length > 0) {
          if(typeDePb == 'attenteCorrection') {
            messageBase = "Vous avez des actions et des formations en attente de correction.<br />";
          } else {
            messageBase = "Vous avez des actions et des formations incomplètes.<br />";
            messageBase += "Pour simplifier les corrections, nous vous proposons de compléter les actions dans un premier temps puis d'intervenir sur les formations qui nécessiteraient un complément d'information dans un second temps.<br />";
          }
        }
        else if(retSesss.length == 0 && retActs.length == 0 && retForms.length > 0) {
          if(typeDePb == 'attenteCorrection') {
            messageBase = "Vous avez des formations en attente de correction.<br />";
          } else {
            messageBase = "Vous avez des formations incomplètes.<br />";
            messageBase += "Veuillez les éditer afin d'y apporter les compléments d'informations attendus.";
          }
        }

        setMessageBase(messageBase);
      }        
    }

    useEffect(() => {
        if(global.config.modeDebugApp == 1) console.log('useffect FormationIncompletes')
        document.title = (typeDePb == 'attenteCorrection') ? "Vos fiches en attente de correction" : "Vos fiches incomplètes et non diffusées";
        setMessageProbleme("");
        if (utilisateurBearer === '000000')  navigate(utilisateurRouteLogin); // si le status n'est pas bon on redirige sur l'acceuil
        else chargementTableaux();
    }, [typeDePb]);


    return (     
        <main className="sous-bloc2 fixe-taille-max-large">
            <MessageErreurApi message={messageProbleme}  />
            <LoadingSpinner dataLoaded={ dataLoadedForm && dataLoadedAct  && dataLoadedSess }/>
            {enMaintenance == true && <MessageErreurApi message={msgMaintenance} />}

            { dataLoadedForm > 0 && dataApi && dataLoadedAct > 0 && dataApiAct && dataLoadedSess > 0 && dataApiSess && enMaintenance == false &&
            <>
                {(dataApiSess.length > 0) || dataApiAct.length > 0 || (dataApi.length > 0) ?
                <>
                  <MessageInfoBox message={messageBase} />

                  { dataApiSess.length > 0 &&
                  <div className="sous-partie">
                      <MessageInfoBox nomClasse="message-info2"
                        message= {(typeDePb == 'attenteCorrection') ? "Sessions nécessitant une correction :" : "Sessions nécessitant une modification :"} /> 
                      <Table columns={columnsSess} data={dataApiSess} noPagination={true} noDisplayNbResult={true} noFilter={true}  />
                  </div> } 

                  { dataApiAct.length > 0 &&
                  <div className="sous-partie">
                      <MessageInfoBox nomClasse="message-info2"
                      message= {(typeDePb == 'attenteCorrection') ? "Actions nécessitant une correction :" : "Actions nécessitant une modification :"} /> 
                      <Table columns={columnsAct} data={dataApiAct} noPagination={true} noDisplayNbResult={true} noFilter={true} />
                  </div> } 

                  { dataApi.length > 0 &&
                  <div className="sous-partie">
                      <MessageInfoBox nomClasse="message-info2" 
                      message= {(typeDePb == 'attenteCorrection') ? "Formations nécessitant une correction :" : "Formations nécessitant une modification :"} /> 
                      <Table columns={columns} data={dataApi} noPagination={true} noDisplayNbResult={true} noFilter={true} />
                  </div> } 
                </>
                :
                <div style={{paddingTop: "50px"}}><MessageInfoBox message="Félicitation, toutes vos fiches sont valides 👍!" /></div>
                }
            </>
            }
        </main>
    )
}

FormationIncompletes.propTypes = {
  typeDePb: PropTypes.string, //attenteCorrection ou incompletes
}
export default FormationIncompletes;