import { useEffect, useState, useContext } from 'react'; // useMemo pour Table.jsx
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";  // Button, Row, Coln InputGroup, FormControl -Col pour afficher les dates  sur meme ligne
//import Modal from 'react-bootstrap/Modal';

import { DonneesUtilisateurContext } from '../contexte.js';

import { recupererProprietes, appelRecupInfoGet, confirmationSiCLickSUrLien, ajusteDonneesBool } from '../fonctionsDiv.js';
import { apiQuiformeCompanieModif } from '../Datas/datasCompanies.js'

import LoadingSpinner from "../Components/LoadingSpinner.js";
import {MessageErreurApi, MessageInfoChampsObligatoires} from '../Components/Messages.jsx';
import ButtonDeBase from '../Components/ButtonsCustom.jsx';
import FormRHF from "../Components/Form/Form.jsx";
import FormControl from "../Components/Form/FormControl.jsx";
import FormSelect from "../Components/Form/FormSelect.jsx";
import FormHidden from "../Components/Form/FormHidden.jsx";

import AdmListHistoQuiforme from '../Components/AdmListHistoQuiforme.jsx'

/**
 * Return Formulaire 
 * ATTENTION a noter est utilisé par plusieurs routes : organisme/:idCompanie 
 *                                                      quiforme/diff-quiforme/edit/:idCompanie
 *                                                      quiforme/introuvables-quiforme/edit/:idCompanie
 * @component
 * @summary page d'édition d'un organisme avec les différences quiforme affichées si présentes

 * @param {  }
 * @return { HTMLElement }
*/
function AdmEditCompanie(props) {
    var { idCompanie } = useParams();
    var origEditOrg = props.origEditOrg; //editOrg ou editQuiforme ou editQuiforme2

    const navigate = useNavigate(); // utile pour changer de page
    const { setBearer, utilisateurRouteLogin, utilisateurBearer, utilisateurRole } = useContext(DonneesUtilisateurContext);
    const [infosProprietes, SetInfosProprietes] = useState(undefined); // contient les infos sur les champ (taille max, caractre obligatoire, éditable...)
    const [dataApi, setDataApi] = useState();
    const [dataVille, SetDataVille] = useState(); // pour recup données du select
    const [aumoinsUneModif, setAumoinsUneModif] = useState(false); // pour avertir l'utilisateur en cas de sortie sans  enregistrement

    const [dataLoaded, setDataLoaded] = useState(false);
    const [messageProbleme, setMessageProbleme] = useState(null);

    //const [enregistrer, setEnregistrer] = useState();
    //const handleCloseModal = () => {setShowModalIndicAvantCrea(false); setEnregistrer("")};
    //const [showModalIndicAvantCrea, setShowModalIndicAvantCrea] = useState(false);

    async function recupererInfosPrincipales() {
      var ret = await appelRecupInfoGet(utilisateurBearer, `organismes/${idCompanie}`, setMessageProbleme)
      //if(global.config.modeDebugApp == 1) console.log(`retour appelRecupInfoGet avec organismes/${idCompanie} : `, ret);

      if(ret != null) {
        // pour que ca puisse s'afficher dans un select (pas utile si s'était des checkbox) (voir aussi a la soumission)
        ret.companieEtAdresse["Qualiopi_Form_Apprentissage"] = ajusteDonneesBool(ret.companieEtAdresse["Qualiopi_Form_Apprentissage"]); 
        ret.companieEtAdresse["Qualiopi_Form_BilanCompetence"] = ajusteDonneesBool(ret.companieEtAdresse["Qualiopi_Form_BilanCompetence"]); 
        ret.companieEtAdresse["Qualiopi_Form_FormationContinue"] = ajusteDonneesBool(ret.companieEtAdresse["Qualiopi_Form_FormationContinue"]); 
        ret.companieEtAdresse["Qualiopi_Form_VAE"] = ajusteDonneesBool(ret.companieEtAdresse["Qualiopi_Form_VAE"]); 

        if(ret.orgQuiforme != null) {
          ret.orgQuiforme["qf_Qualiopi_Form_Apprentissage"] = ajusteDonneesBool(ret.orgQuiforme["qf_Qualiopi_Form_Apprentissage"]); 
          ret.orgQuiforme["qf_Qualiopi_Form_BilanCompetence"] = ajusteDonneesBool(ret.orgQuiforme["qf_Qualiopi_Form_BilanCompetence"]); 
          ret.orgQuiforme["qf_Qualiopi_Form_FormationContinue"] = ajusteDonneesBool(ret.orgQuiforme["qf_Qualiopi_Form_FormationContinue"]); 
          ret.orgQuiforme["qf_Qualiopi_Form_VAE"] = ajusteDonneesBool(ret.orgQuiforme["qf_Qualiopi_Form_VAE"]);
        }
        setDataApi(ret);
        
        return true;
    } 
    return false;
    }

    //pour effectuer des actions en fonction de la saisie de l'utilisateur
    function gestionEvenements(value, name){
      setAumoinsUneModif(true); document.getElementById("hidden_marque_une_modif").value = 1;
      if(global.config.modeDebugApp == 1) console.log (`modif de ${name} : ${value[name]}`);
    }

    const onSubmit = async (values) => {
      for(let i in values) if(values[i] === "") values[i] = null; // sinon génère une erreur à cause de ID_LHEO_OBJECTIF_GENERAL_FORMATION if(values.ID_LHEO_OBJECTIF_GENERAL_FORMATION == "") values.ID_LHEO_OBJECTIF_GENERAL_FORMATION = null;
      if(global.config.modeDebugApp == 1) console.log("Values:::", values);
      if(global.config.modeDebugApp == 1) console.log("Values:::", JSON.stringify(values));

      setDataLoaded(false);  // utile pour pas que l'utilisateur soumette plusieurs fois attention provoquait une réinit des données donc plus aucune donnée
      setAumoinsUneModif(false);

      // pour que ca puisse s'afficher dans un select (pas utile si s'était des checkbox) (voir aussi a la soumission)
      values.Qualiopi_Form_Apprentissage = (values.Qualiopi_Form_Apprentissage == "1") ? true : false; 
      values.Qualiopi_Form_BilanCompetence = (values.Qualiopi_Form_BilanCompetence == "1") ? true : false; 
      values.Qualiopi_Form_FormationContinue = (values.Qualiopi_Form_FormationContinue == "1") ? true : false; 
      values.Qualiopi_Form_VAE = (values.Qualiopi_Form_VAE == "1") ? true : false; 

      values.qf_Qualiopi_Form_Apprentissage = (values.qf_Qualiopi_Form_Apprentissage == "1") ? true : false; 
      values.qf_Qualiopi_Form_BilanCompetence = (values.qf_Qualiopi_Form_BilanCompetence == "1") ? true : false; 
      values.qf_Qualiopi_Form_FormationContinue = (values.qf_Qualiopi_Form_FormationContinue == "1") ? true : false; 
      values.qf_Qualiopi_Form_VAE = (values.qf_Qualiopi_Form_VAE == "1") ? true : false;

      var dataR = await apiQuiformeCompanieModif(utilisateurBearer, values, idCompanie) // on envoie sans faire JSON.stringify
      if(global.config.modeDebugApp == 1) console.log('retour apiActModif : ', dataR);
      setDataLoaded(true);  // utile pour pas que l'utilisateur soumette plusieurs fois

      switch (dataR.statusCode) {
          case 200: 
              break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
              return false;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
              return false;
      }

      alert("L'enregistrement s'est bien effectué.\r\n\r\n");
      
      if(origEditOrg== "editQuiforme") navigate(`/admin/quiforme/diff-quiforme/`);
      else if(origEditOrg== "editQuiforme2") navigate(`/admin/quiforme/introuvables-quiforme/`);
      else navigate(`/admin/change-org/`);
    };
  
    const onError = (error) => {
      console.log("ERROR:::", error);
    };
    /// eslint-disable-next-line no-unused-vars

    async function chargementsConsecutifs() { //attention si 2 asynchrones sont lancées en même temps à la suite, il faut faire un await sur le premier :
        setDataLoaded(false);
      
        var pb = false;
        var ret = await appelRecupInfoGet(utilisateurBearer, "villes?allville=1", setMessageProbleme);
        if(ret == null) pb = true;
        else SetDataVille(ret);

        if(pb == false) {
            var ret = await recupererProprietes(utilisateurBearer, null, null, null, SetInfosProprietes, setMessageProbleme);
            if(ret == false) pb = true;
        }

        if(pb == false) {
            var ret = await recupererInfosPrincipales();
            if(ret == false) pb = true;
        }
        if(pb == true) setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut

        setDataLoaded(true);
    }

    useEffect(() => {
      if(global.config.modeDebugApp == 1) console.log('useffect AdmEditCompanie');
      if(global.config.modeDebugApp == 1) console.log(`origEditOrg ${origEditOrg}`);
      document.title = `Edition de l 'organisme ${idCompanie}`;
      if (utilisateurBearer === '000000' || utilisateurRole != "AdminExtranet" )  navigate(utilisateurRouteLogin); // si pas identifié ou role NOK on redirige sur l'acceuil
      setMessageProbleme(null);
      chargementsConsecutifs();

      var links = document.querySelectorAll('a');
         
      // Parcours des liens pour ajouter gestionnaire d'événement au clic
      for (var i = 0; i < links.length; i++) {
        links[i].addEventListener('click', confirmationSiCLickSUrLien);
      }
      // Nettoyage de l'écouteur d'événement lorsque le composant est démonté
      return () => {      
        for (var i = 0; i < links.length; i++) {
          links[i].removeEventListener('click', confirmationSiCLickSUrLien);
        }
      };
    }, []);

    useEffect(() => {
      setAumoinsUneModif(false);
    }, [dataApi]);

    const InputWithDiv = (props) => {
        return (<div className="alignement-horizontal-champs-form2">
             <FormControl {...props} noCount="1" />
        </div>)
    };

    const FormSelectWithDivSpeVille = (props) => {
      var partieQuiforme = ''; 

      if(dataApi.orgQuiforme != null) {
        var valsQuiforme = dataApi.orgQuiforme;
        var valsBrof = dataApi.companieEtAdresse;

        var valCODE_INSEE_Quiforme = valsQuiforme["qf_CODE_INSEE"];
        var valCODE_INSEE_Brof = valsBrof["qf_CODE_INSEE"];
        var valCP_Quiforme = valsQuiforme["qf_CODEPOSTAL"];
        var valCP_Brof = valsBrof["CODE_POSTAL"];

        var villeQuiforme = dataApi["villeQuiforme"];

        if (valCODE_INSEE_Quiforme == null && valCP_Quiforme == null) 
            partieQuiforme = <div className="complementInfo">[Aucune donnée Quiforme]</div> 

        else if ((valCODE_INSEE_Quiforme != null && valCODE_INSEE_Quiforme == valCODE_INSEE_Brof) ||
                  (valCP_Quiforme != null && valCP_Quiforme ==  valCP_Brof) ) 
            partieQuiforme = <div className="complementInfo green">[Donnée Quiforme identique]</div> 
        else {
            var content = "";
            if(villeQuiforme != null) content += villeQuiforme + ' ';
            if(valCODE_INSEE_Quiforme != null) content += " INSEE : " + valCODE_INSEE_Quiforme + ' ';
            if(valCP_Quiforme != null) content += " CP : " + valCP_Quiforme + ' ';
            partieQuiforme = <div className="complementInfo red">{content}</div>;
        }
      }

      return (<div className="alignement-horizontal-champs-form2">
            <FormSelect {...props} />
            {partieQuiforme}
      </div>)
    };

    const FormCheckWithDiv = (props) => {
        var ValDonneeTierce = dataApi.orgQuiforme != null ? dataApi.orgQuiforme[props.champQuiforme] : null;
        var valBrof = dataApi.companieEtAdresse[props.id];
        var partieQuiforme = ''; 
        if (ValDonneeTierce == null) 
            partieQuiforme = <div className="complementInfo">[Aucune donnée Quiforme]</div> 

        else if (ValDonneeTierce === valBrof) 
            partieQuiforme = <div className="complementInfo green">[Donnée Quiforme identique]</div> 
        else {
            if(ValDonneeTierce == 1)    partieQuiforme = <div className="complementInfo red"><i className="fas fa-square-check"></i> "Oui" coté Quiforme</div>;
            else                    partieQuiforme = <div className="complementInfo red"><i className="fas fa-square-xmark"></i> "Non" coté Quiforme</div>;
        }

        return (<div className="alignement-horizontal-champs-form2">
             {/*<FormCheck {...props} />*/}
             <FormSelect {...props} options={global.config.optsOuiNon}/>
             {partieQuiforme}
        </div>)
    };

    const FormSelectWithDivSpeChoixMaj = (props) => {
      //attention ici on utilie les données de la table COMPANIES_ctrl_Quiforme pour que l'utilisateur choisisse quoi faire
      if(dataApi.orgQuiforme != null) {
        var valsQuiforme = dataApi.orgQuiforme;
        // dans valsQuiforme : verif_identite verif_adresse verif_qualiopi" diff_identite diff_adresse diff_qualiopi

        if(props.id == "verif_identite" && valsQuiforme["diff_identite"] == "o" ||
          props.id == "verif_adresse" && valsQuiforme["diff_adresse"] == "o" ||
          props.id == "verif_qualiopi" && valsQuiforme["diff_qualiopi"] == "o" ) 
          return (<div className="alignement-horizontal-champs-form2">
                <FormSelect {...props} />
          </div>)
      }

    };

    /*useEffect(() => {
      if(enregistrer != undefined && enregistrer != "") {
          setShowModalIndicAvantCrea(true);
          //console.log(enregistrer);
      }
  }, [enregistrer]);*/

    return (
      <>
        <div onClick={() => window.history.back()} className="normal-navig-item"><span className="fas fa-circle-chevron-left"></span>Retour à la page précédente</div>

        <LoadingSpinner dataLoaded={dataLoaded}/>
        <MessageErreurApi message={messageProbleme} />

        { dataLoaded && infosProprietes != null && dataApi && dataApi != null && messageProbleme == null &&
        <>
        <Container className="my-0 form-quiforme">

            <FormRHF onSubmit={onSubmit} onError={onError} dataApi={dataApi.companieEtAdresse} gestionEvenements={gestionEvenements} >

                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <div style={{marginTop:"10px", marginBottom:"20px", minWidth: "700px"}}>
                    <MessageInfoChampsObligatoires />
                  </div>
                  <div>
                    <ButtonDeBase typeSubmit={true} lib="Enregistrer" disabled={aumoinsUneModif == true ? false : true} />
                    {/* <ButtonDeBase typeSubmit={false} lib="Enregistrer" disabled={aumoinsUneModif == true ? false : true}  onClick={() =>setShowModalIndicAvantCrea(true)}/>*/}
                  </div>
                </div>

                <FormControl id="SIRET" classSpecifique="input-inline st" infosProp={infosProprietes["companieEtAdresse|SIRET"]}  />
                <FormControl id="SHORT_LIB" classSpecifique="input-inline mt" infosProp={infosProprietes["companieEtAdresse|SHORT_LIB"]} />

                <InputWithDiv id="LIB_COMPANIES" classSpecifique="input-inline mt" infosProp={infosProprietes["companieEtAdresse|LIB_COMPANIES"]} 
                              ValDonneeTierce={dataApi.orgQuiforme != null ? dataApi.orgQuiforme["qf_LIB_COMPANIES"] : null} sourceDonneeTierce="Quiforme" />
                <InputWithDiv id="NUM_DE" classSpecifique="input-inline st" infosProp={infosProprietes["companieEtAdresse|NUM_DE"]}
                              ValDonneeTierce={dataApi.orgQuiforme != null ? dataApi.orgQuiforme["qf_NUM_DE"] : null} sourceDonneeTierce="Quiforme" />
                <InputWithDiv id="Numero_UAI"  classSpecifique="input-inline st" infosProp={infosProprietes["companieEtAdresse|Numero_UAI"]} 
                              ValDonneeTierce={dataApi.orgQuiforme != null ? dataApi.orgQuiforme["qf_Numero_UAI"] : null} sourceDonneeTierce="Quiforme" />
                

                <FormCheckWithDiv id="Qualiopi_Form_VAE" infosProp={infosProprietes["companieEtAdresse|Qualiopi_Form_VAE"]} typeCheckbox="checkbox" 
                                  champQuiforme="qf_Qualiopi_Form_VAE" classSpecifique="input-inline" />
                <FormCheckWithDiv id="Qualiopi_Form_Apprentissage" infosProp={infosProprietes["companieEtAdresse|Qualiopi_Form_Apprentissage"]} typeCheckbox="checkbox" 
                                  champQuiforme="qf_Qualiopi_Form_Apprentissage" classSpecifique="input-inline" />
                <FormCheckWithDiv id="Qualiopi_Form_BilanCompetence" infosProp={infosProprietes["companieEtAdresse|Qualiopi_Form_BilanCompetence"]} typeCheckbox="checkbox" 
                                  champQuiforme="qf_Qualiopi_Form_BilanCompetence" classSpecifique="input-inline" />
                <FormCheckWithDiv id="Qualiopi_Form_FormationContinue" infosProp={infosProprietes["companieEtAdresse|Qualiopi_Form_FormationContinue"]} typeCheckbox="checkbox" 
                                  champQuiforme="qf_Qualiopi_Form_FormationContinue" classSpecifique="input-inline" />
                
                <FormControl id="Denomination" classSpecifique="input-inline mt" infosProp={infosProprietes["ADDRESSES|Denomination"]}  noCount="1"/>
                <InputWithDiv id="ADDRESS1" classSpecifique="input-inline mt" infosProp={infosProprietes["ADDRESSES|ADDRESS1"]} 
                              ValDonneeTierce={dataApi.orgQuiforme != null ? dataApi.orgQuiforme["qf_ADDRESS1"] : null} sourceDonneeTierce="Quiforme"  noCount="1" />
                <FormControl id="ADDRESS2" classSpecifique="input-inline mt" infosProp={infosProprietes["ADDRESSES|ADDRESS2"]}  noCount="1"/>
                <FormControl id="ADDRESS3" classSpecifique="input-inline mt" infosProp={infosProprietes["ADDRESSES|ADDRESS3"]}  noCount="1"/>
                {/* <FormSelectWithDiv id="ID_GEO_VILLES" infosProp={infosProprietes["ADDRESSES|ID_GEO_VILLES"]}  options={dataVille} ValDonneeTierce={dataApi["villeQuiforme"]} valsBrof={dataApi.companieEtAdresse["LIB_GEO_VILLES"]}/> */}
                <FormSelectWithDivSpeVille id="ID_GEO_VILLES" classSpecifique="input-inline" infosProp={infosProprietes["ADDRESSES|ID_GEO_VILLES"]} options={dataVille} />

                {dataApi.orgQuiforme != null &&
                  <><br /><hr /><p>Indiquer la suite à donner concernant les différences trouvées avec Qualiopi :</p></>}
                {dataApi.orgQuiforme != null &&
                <FormSelectWithDivSpeChoixMaj id="verif_identite" classSpecifique="input-inline" infosProp={infosProprietes["companieEtAdresse|verif_identite"]} options={global.config.optsVerifsQuiforme} />}
                {dataApi.orgQuiforme != null &&
                  <FormSelectWithDivSpeChoixMaj id="verif_adresse" classSpecifique="input-inline" infosProp={infosProprietes["companieEtAdresse|verif_adresse"]} options={global.config.optsVerifsQuiforme} />}
                {dataApi.orgQuiforme != null &&
                  <FormSelectWithDivSpeChoixMaj id="verif_qualiopi" classSpecifique="input-inline" infosProp={infosProprietes["companieEtAdresse|verif_qualiopi"]} options={global.config.optsVerifsQuiforme} />}



                <div className="partie-bouton-droite">
                  <ButtonDeBase typeSubmit={true} lib="Enregistrer" disabled={aumoinsUneModif == true ? false : true} />
                </div>
             <FormHidden id="hidden_marque_une_modif" />
          </FormRHF>

          {/*showModalIndicAvantCrea &&
            <Modal size="lg" show={showModalIndicAvantCrea} onHide={handleCloseModal} backdrop="static" keyboard={false} centered aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                ?? Choix ??
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                </div>
                <div className="partie-bouton-crea">
                    <ButtonDeBase typeSubmit={false} onClick={() =>handleCloseModal()} lib="?? Ok, allons y " />
                    <ButtonDeBase typeSubmit={false} onClick={() =>handleCloseModal()} lib="Je verrai plus tard..." className="fas fa-ban sd" />
                </div>
            </Modal.Body>
            </Modal>
                */}
        </Container>
        <AdmListHistoQuiforme idCompanie={idCompanie} />
        </>
        }

      </>
    )

}

export default AdmEditCompanie;