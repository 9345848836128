import { useEffect, useState, useContext } from 'react';
import { NavLink, useNavigate, useSearchParams, useParams } from "react-router-dom";
import Select, { components } from 'react-select';
import { Container, OverlayTrigger, Tooltip } from "react-bootstrap";  // Button, Row, Coln InputGroup, FormControl -Col pour afficher les dates  sur meme ligne
import {apiPcAjouts  } from '../../Datas/dataPcf.js'

import { DonneesUtilisateurContext } from '../../contexte.js';
import { appelRecupInfoGet, recupererProprietes, onError } from '../../fonctionsDiv.js'

import MessageInfoBox, {MessageErreurApi, MessageInfoChampsObligatoires} from '../../Components/Messages.jsx';
/////////////

import {apiPersonModifOuCrea } from '../../Datas/dataPcf.js'
import LoadingSpinner from "../../Components/LoadingSpinner";
import ButtonDeBase from '../../Components/ButtonsCustom.jsx';
import FormRHF from "../../Components/Form/Form";
import FormControl from "../../Components/Form/FormControl";
import FormSelect from "../../Components/Form/FormSelect";

/**
 * Return template 
 *
 * @component
 * @summary imported in Route
 * @param {  }
 * @return { HTMLElement }
*/
function PcfEditionPerson() {
    const { setBearer, utilisateurRouteLogin, utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const navigate = useNavigate(); // utile pour changer de page

    const [searchParams, setSearchParams] = useSearchParams(); // pour recuperer les paramètre de l'url querystring (ajouter useSearchParams de react-router-dom)
    const gobackto =  searchParams.get("gobackto");
    const { IdPerson } = useParams();

    const [aumoinsUneModif, setAumoinsUneModif] = useState(false); // pour avertir l'utilisateur en cas de sortie sans  enregistrement

    const [infosProprietes, SetInfosProprietes] = useState(undefined); // contient les infos sur les champ (taille max, caractre obligatoire, éditable...)
    const [dataApiCoord, setDataApiCoord] = useState();
    const [dataApiContact, setDataApiContact] = useState(null);
    const [dataCivilite, SetDataCivilite] = useState(null);

    const [messageProbleme, setMessageProbleme] = useState();
    const [dataLoaded, setDataLoaded] = useState(false);

    var tabCoordsRecuperes = []; // va servir a alimenter un tableau d'objet permettant de réafficher les coordonnées sélectionnées dans react-select
    const [tabObjCoordsPreselectionnes, SetTabObjCoordsPreselectionnes] = useState(); // pour que le reactselect affiche ce qui a déjà été selectionné

    // pour react-select
    const [coordsSelectionnes, setCoordsSelectionnes] = useState(); // sera vide si l'utilisateur n'a pas fait de modif, tableau d'objet: 0: Object { value: 81099, label: "Autre public" }
    const [modifCoords, setModifCoords] = useState(false);
    function handleChangeSelectCoord (selectedOptions)  {
      setCoordsSelectionnes(selectedOptions);
      setModifCoords(true);
      setAumoinsUneModif(true);
      //console.log(`Options selected:`, selectedOptions);
    }

    //pour effectuer des actions en fonction de la saisie de l'utilisateur (fonction lancé par react-hook-form)
    function gestionEvenements(value, name){
        setAumoinsUneModif(true);
        if(global.config.modeDebugApp == 1) console.log (`modif de ${name} : ${value[name]}`);
    }

    const onSubmit = async (values) => {

        for(let i in values) if(values[i] === "") values[i] = null; // sinon génère une erreur à cause de "ID_GENDERS":""
        if(global.config.modeDebugApp == 1) console.log("Values:::", values);
        if(global.config.modeDebugApp == 1) console.log("Values:::", JSON.stringify(values));

        if(modifCoords == true) {
            if(coordsSelectionnes.length == 0) {
                alert(`Vous devez sélectionner au moins un lieu`);
                return false;
            }
        }
        if(modifCoords == false && IdPerson == undefined) { // IdPerson == undefined pour cas d'une création
            alert(`Vous devez sélectionner au moins un lieu`);
            return false;
        }

        setDataLoaded(false);
        var dataR = await apiPersonModifOuCrea(utilisateurBearer, values, values.ID_PERSONS) // on envoie sans faire JSON.stringify
        if(global.config.modeDebugApp == 1) console.log('retour apiPersonModifOuCrea : ', dataR);
        setDataLoaded(true);

        switch (dataR.statusCode) {
            case 200: 
                //alert("L'enregistrement s'est bien effectué.");
                setAumoinsUneModif(false);
                if(dataR.dataApi < 1) {
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
                return false;
                }
                if(values.ID_PERSONS == undefined)  values.ID_PERSONS = dataR.dataApi;  // CAS d'une création
                break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
                return false;
            case 400:
                setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "Cette personne est déjà référencée"
                  return false;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
                return false;
        }
        if(values.ID_PERSONS == undefined) {
            setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
            return false;
        }

        if (modifCoords == true) { // si les liens avec les coordonnées ont changés
            //on adapte le tableau SIMPLE (alors que pour les langues dans les actions s'était en tab d'objet) qu'on va envoyer pour qu'il ait les propriétés attendues
            var tabIdsCoordsAenregistrer = [];
            coordsSelectionnes.forEach((el) => {
                tabIdsCoordsAenregistrer.push(el.value);  
            });
            if(global.config.modeDebugApp == 1) console.log("coordsSelectionnes", coordsSelectionnes);

            var dataR = await apiPcAjouts(utilisateurBearer, tabIdsCoordsAenregistrer, values.ID_PERSONS)
            if(global.config.modeDebugApp == 1) console.log('retour apiPcAjouts : ', dataR);

            switch (dataR.statusCode) {
                case 200: 
                    alert("Enregistrement effectué.");
                    if(gobackto != null) navigate(gobackto);
                    else navigate(`/lieux-et-contacts`)
                    break;
                case 401:
                    setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
                    break;
                default:
                    setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
            }
        }
        else {
            if(gobackto != null) navigate(gobackto);
            else navigate(`/lieux-et-contacts`)
        }
    };

    
    async function chargementsConsecutifs() {
        var pb = false;
        setDataLoaded(false);

        var ret = await recupererProprietes(utilisateurBearer, null, null, null, SetInfosProprietes, setMessageProbleme);
        if(ret == false) pb = true;

        //recupération des données du contact (pour récupérer les éléments déjà associés juste en dessous)
        if(IdPerson != undefined) {
            var ret = await appelRecupInfoGet(utilisateurBearer, "persons/" + IdPerson, setMessageProbleme);
            if(ret != null) setDataApiContact(ret); 
            else pb = true;

            if(pb == false) {
                // recherche des pcf associés à cette personne
                var ret = await appelRecupInfoGet(utilisateurBearer, "lieux/liste-pcf/" + IdPerson, setMessageProbleme);
                if(ret != null) tabCoordsRecuperes = ret; 
                else pb = true;
            }
        }

        //recuperation des coordonnées possibles
        if(pb == false) {
            var ret = await appelRecupInfoGet(utilisateurBearer, "coordonnees?type=2", setMessageProbleme);
            if(ret != null) {
                var coordsPourSelect = [];
                var preselectionnes = [];
                ret.forEach((el) => {                    
                    let ligne = {'value': el.ID_COORDONNEES, 'label':  el.lib_concatene, isFixed: false };

                    tabCoordsRecuperes.forEach((elPreselectionnes) => {
                        if (elPreselectionnes.ID_COORDONNEES == el.ID_COORDONNEES)  {
                            if(elPreselectionnes.est_utilise_pcf== true) ligne.isFixed = true; // pour que le lien ne soit pas supprimable, cf isFixed plus en dessous
                            //if(global.config.modeDebugApp == 1) ligne.label = elPreselectionnes.ID_PCF + '<-id_pcf ' + ligne.label; //pratique pour debuger
                            preselectionnes.push(ligne);
                        }
                    });

                    coordsPourSelect.push(ligne);
                });
                if(global.config.modeDebugApp == 1) console.log("coordsPourSelect :",  coordsPourSelect);
                if(global.config.modeDebugApp == 1) console.log("coordonnées preselectionnées :",  preselectionnes);
                setDataApiCoord(coordsPourSelect); // attention ici la cible ne contiendra pas les données brutes de l'api mais un tab d'objet
                if(preselectionnes.length > 0) SetTabObjCoordsPreselectionnes(preselectionnes);    
            }
            else pb = true;
        }

        var ret = await appelRecupInfoGet(utilisateurBearer, "civilites", setMessageProbleme);
        if(ret != null) SetDataCivilite(ret); 
        else pb = true;

        if(pb == true ) setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        setDataLoaded(true);
    }
    useEffect(() => {
       if (utilisateurBearer === '000000') navigate(utilisateurRouteLogin); // si le status n'est pas bon on redirige sur l'acceuil
       document.title = (IdPerson == undefined) ? "Ajout d'un contact" : "Modification d'un contact";
       chargementsConsecutifs();
    }, []);

    const MultiValueRemove = (props) => {
      if (props.data.isFixed) {
        return null;
      }
      return <components.MultiValueRemove {...props} />;
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            <div>
            Vous pouvez sélectionner plusieurs lieux pour indiquer où se trouve ce contact.<br /><br />
            L'information "utilisé X fois" vous indique que ce lieu est positionné sur X formations.<br /><br /><br />
            {tabObjCoordsPreselectionnes && tabObjCoordsPreselectionnes.length > 0 &&
            <>A noter qu'un lieu n'est pas supprimmable quand il est associé à des formations.</>}
            </div>
        </Tooltip>
    );
    return (  
        <section className="sous-bloc2">
            <LoadingSpinner dataLoaded={dataLoaded}/>

            <div onClick={() => window.history.back()} className="normal-navig-item"><span className="fas fa-circle-chevron-left"></span>Retour à la page précédente</div>

            <div className="ariane" >
                <span className="ariane-titre">{IdPerson != undefined ? "Modification d'un contact" : "Saisissez les informations du nouveau contact" }</span>
            </div>

            {dataLoaded && dataApiCoord && dataCivilite && 
                <Container className="my-0 ajout-coord">
                    <MessageErreurApi message={(messageProbleme == "Cette personne est déjà référencée") ? "Vous essayez de créer un contact qui existe déjà, si vous souhaitez l'associer à un nouveau lieu, éditez sa fiche depuis la page précédente." : messageProbleme } />

                    <FormRHF onSubmit={onSubmit} onError={onError}  dataApi={dataApiContact} gestionEvenements={gestionEvenements} >
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div style={{marginTop:"10px", marginBottom:"20px", minWidth: "700px"}}>
                            <MessageInfoChampsObligatoires />
                            </div>
                            <div>
                                <ButtonDeBase typeSubmit={true} lib="Enregistrer" disabled={aumoinsUneModif == true ? false : true} />
                            </div>
                        </div>

                        {dataApiCoord && dataApiCoord.length > 0 &&
                            <>
                            <div className="titre">
                                Lieu(x) où se trouve cette personne :
                                <OverlayTrigger  placement="right" overlay={renderTooltip} > 
                                    <button className="simply" type='button'><span className="fas fa-circle-question"></span></button>
                                </OverlayTrigger>
                            </div>
                            <small>
                                Sélectionnez dans la liste ou saisissez une partie de l'adresse dans le champ ci-dessous
                            </small>
                            <Select
                                onChange={handleChangeSelectCoord}
                                closeMenuOnSelect={false}   
                                defaultValue={tabObjCoordsPreselectionnes}
                                isMulti
                                options={dataApiCoord} 
                                isClearable={false} 
                                components={{ MultiValueRemove }} />
                            </>
                            /* cf https://stackoverflow.com/questions/67302509/react-select-multi-select-one-option-not-clearable :
                            isClearable={false} permet de ne pas afficher (masquer) le bouton qui permet de tout supprimer)
                            components={{ MultiValueRemove }}  permet de ne pas permettre la suppression des éléments
                            */
                        } 

                        
                        {IdPerson != undefined && <span>Le lieu n'existe pas ? <NavLink to="/lieux-et-contacts">revenez à la page précédente</NavLink> afin de l'ajouter.</span>}

                        <br /><br />
                        {dataApiContact && dataApiContact.nb_utilisation != undefined && dataApiContact > 1 != undefined && <MessageInfoBox message = {`⚠️Cette personne est référencée à <u>${dataApiContact.nb_utilisation}</u> reprise(s).<br />
                        Les modifications que vous porterez ici prendront effet là où elle est référencée.`} /> }

                        <FormSelect id="ID_GENDERS" infosProp={infosProprietes["PERSONS|ID_GENDERS"]} options={dataCivilite}/>

                        <FormControl id="FIRST_NAME" infosProp={infosProprietes["PERSONS|FIRST_NAME"]} style=" inline-input" />
                        <FormControl id="LAST_NAME" infosProp={infosProprietes["PERSONS|LAST_NAME"]} style=" inline-input"/>
                        <br />

                        <div style={{paddingTop: "10px", paddingBottom: "5px"}}>ℹ️ Vous pouvez indiquer ci-dessous le téléphone et le courriel de la personne.</div>

                        <FormControl id="TELMOBILE" infosProp={infosProprietes["COORDONNEES|TELMOBILE"]}  style=" inline-input" />
                        {/*<FormCheckWithDiv id="TELMOBILE_VISIBLE" infosProp={infosProprietes["COORDONNEES|TELMOBILE_VISIBLE"]} typeCheckbox="checkbox" />*/}
                        <FormControl id="TELFIXE" infosProp={infosProprietes["COORDONNEES|TELFIXE"]} style=" inline-input"/>
                        {/*<FormCheckWithDiv id="TELFIXE_VISIBLE" infosProp={infosProprietes["COORDONNEES|TELFIXE_VISIBLE"]} typeCheckbox="checkbox" />*/}
                        <br />

                        <FormControl id="COURRIEL" infosProp={infosProprietes["COORDONNEES|COURRIEL"]} />
                        {/*<FormCheckWithDiv id="COURRIEL_VISIBLE" infosProp={infosProprietes["COORDONNEES|COURRIEL_VISIBLE"]} typeCheckbox="checkbox" />*/}

                        <div className="partie-bouton-droite">
                            <ButtonDeBase typeSubmit={true} lib="Enregistrer" disabled={aumoinsUneModif == true ? false : true} />
                        </div>

                    </FormRHF>
                </Container>
            }
            
        </section>
    )
}
  
export default PcfEditionPerson;