import PropTypes from 'prop-types';

/**
 * Affiche les dates et l'identifiant d'une session (passés en props)
 *
 * @component
 * @summary importé dans les composant  d'édition des sessions
 * @return { HTMLElement }
 * 
 *     <div className='info-basic'>
        Edition de la session N° <b>{props.sessionNumber}</b>
        {props.infosGenFormation && props.infosGenFormation.sess_DATE_CREATION_fr != "" && <><br />Date de création : {props.infosGenFormation.sess_DATE_CREATION_fr}<br />Date de modification : {props.infosGenFormation.sess_DATE_MODIF_fr} <br /></>}
  </div>
*/


/**
 * Affiche les dates et l'identifiant d'une session (passés en props)
 *
 * @component
 * @summary importé dans les composant  d'édition des sessions
 * @return { HTMLElement }
 * 
 * <b>Information sur cette session :</b><br />
*/
export function InfosGeneriquesSession2(props) {
  return (
    <div className='info-basic'>
        {props.infosGenFormation && props.infosGenFormation.sess_DATE_CREATION_fr != "" && 
        <div style={{textAlign : "right"}}>
          {!isNaN(props.sessionNumber) &&
          <>
          {props.infosGenFormation.sess_DATE_CREATION_fr != null && <>Date de création de cette session : {props.infosGenFormation.sess_DATE_CREATION_fr}<br /></> }
          {props.infosGenFormation.sess_DATE_MODIF_fr != null && <>Date de modification de cette session : {props.infosGenFormation.sess_DATE_MODIF_fr}</> }
          {(props.infosGenFormation.sess_DATE_CREATION_fr == null  
            && props.infosGenFormation.sess_DATE_MODIF_fr == null && !isNaN(props.actionNumber)) &&
              <>
              {
              props.infosGenFormation.act_DATE_MODIF_fr != null ?
              <>Date de modification de l'action : {props.infosGenFormation.act_DATE_MODIF_fr}</> :
              <>Date de création de l'action : {props.infosGenFormation.form_DATE_CREATION_fr}</>
              }
              </>
          }
          </>}

          {isNaN(props.sessionNumber) && !isNaN(props.actionNumber) &&
          <>
          Date de création de cette action : {props.infosGenFormation.act_DATE_CREATION_fr}<br />
          {props.infosGenFormation.act_DATE_MODIF_fr != null && <>Date de modification : {props.infosGenFormation.act_DATE_MODIF_fr}</> }
          </>}

          {isNaN(props.actionNumber) && !isNaN(props.formationNumber) &&
          <>
          Date de création de cette formation : {props.infosGenFormation.form_DATE_CREATION_fr}<br />
          {props.infosGenFormation.form_DATE_MODIF_fr != null && <>Date de modification : {props.infosGenFormation.form_DATE_MODIF_fr}</> }
          </>}
        </div>}
    </div>
  );
}

InfosGeneriquesSession2.propTypes = {
    formationNumber: PropTypes.number.isRequired,
    actionNumber: PropTypes.number,
    sessionNumber: PropTypes.number,
    infosGenFormation: PropTypes.object,
}
