import { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Container } from "react-bootstrap"; 

import { apiRecupereInfoGet } from '../../Datas/datasAutre.js'
import {apiAddrdModifOuCrea , apiPcfModifOuCrea } from '../../Datas/dataPcf.js'
import { recupererProprietes, appelRecupInfoGet } from '../../fonctionsDiv.js'

import { DonneesUtilisateurContext } from '../../contexte.js';

import {MessageErreurApi, MessageInfoChampsObligatoires} from '../../Components/Messages.jsx';

//////////
import {apiCoordModifOuCrea } from '../../Datas/dataPcf.js'
import LoadingSpinner from "../../Components/LoadingSpinner";
import FormRHF from "../../Components/Form/Form";
import FormControl from "../../Components/Form/FormControl";
import FormSelect from "../../Components/Form/FormSelect";
import ButtonDeBase from '../../Components/ButtonsCustom.jsx';
/////////

/**
 * Return template 
 *
 * @component
 * @summary imported in Route
 * @param {  }
 * @return { HTMLElement }
*/
function PcfEditionCoord() {
    const { IdDeCoord } = useParams();

    const {  setBearer, utilisateurRouteLogin, utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const [infosProprietes, SetInfosProprietes] = useState(undefined); // contient les infos sur les champ (taille max, caractre obligatoire, éditable...)
    const [dataApi, setDataApi] = useState(null);
    const navigate = useNavigate(); // utile pour changer de page

    const [searchParams, setSearchParams] = useSearchParams(); // pour recuperer les paramètre de l'url querystring (ajouter useSearchParams de react-router-dom)
    const gobackto =  searchParams.get("gobackto");

    const [messageProbleme, setMessageProbleme] = useState();
    const [dataLoaded, setDataLoaded] = useState(true);
    
    const [aumoinsUneModif, setAumoinsUneModif] = useState(false); // pour avertir l'utilisateur en cas de sortie sans  enregistrement
    const [dataVille, SetDataVille] = useState(null);
    const [adresse2Visible, setAdresse2Visible] = useState(false);
    const [adresse3Visible, setAdresse3Visible] = useState(false);
    const [adresse4Visible, setAdresse4Visible] = useState(false);

    const onSubmit = async (values) => {
      if(global.config.modeDebugApp == 1) console.log("Values:::", values);
      if(global.config.modeDebugApp == 1) console.log("Values:::", JSON.stringify(values));
 
      if(values.URLWEB != null &&  values.URLWEB.length > 4 && values.URLWEB.substring(0,8) != 'https://') {
        var ret = confirm(`La plupart des urls commencent maintenant par https://.\r\nVous confirmez que votre site n'est pas accessible avec une telle url ?`);
        if (ret == false) {
          alert(`Parfait, veuillez donc modifier l'url\r\n${values.URLWEB}`);
          var ob = document.getElementById("URLWEB");
          ob.focus();
          return false;
        }
      }
      var isCreation = (values.ID_COORDONNEES == undefined) ? true : false;
      if(global.config.modeDebugApp == 1) console.log('isCreation : ', isCreation);


      var dataR = await apiAddrdModifOuCrea(utilisateurBearer, values, values.ID_ADDRESSES) // on envoie sans faire JSON.stringify
      if(global.config.modeDebugApp == 1) console.log('retour apiAddrdModifOuCrea : ', dataR);

      switch (dataR.statusCode) {
        case 200: 
          if(values.ID_ADDRESSES == undefined)  values.ID_ADDRESSES = dataR.dataApi;  // CAS d'une création
          break;
        case 401:
          setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
          return false;
        case 400:
          setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "Ce lieu est déjà référencé"
          return false;
        default:
          setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
          return false;
      }

      var dataR = await apiCoordModifOuCrea(utilisateurBearer, values, values.ID_COORDONNEES) // on envoie sans faire JSON.stringify
      if(global.config.modeDebugApp == 1) console.log('retour apiCoordModifOuCrea : ', dataR);
 
      switch (dataR.statusCode) {
        case 200: 
          setAumoinsUneModif(false);
          if(isCreation == true)  values.ID_COORDONNEES = dataR.dataApi;  // CAS d'une création
          break;
        case 401:
          setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
          return false;
        default:
          setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
          return false;
      }

      if(isCreation == true)  { // CAS d'une création
        // values va docn contenir ID_COORDONNEES, cela permettra de créé l'id pcf
        var dataR = await apiPcfModifOuCrea(utilisateurBearer, values) // on envoie sans faire JSON.stringify
        if(global.config.modeDebugApp == 1) console.log('retour apiPcfModifOuCrea : ', dataR);
  
        switch (dataR.statusCode) {
            case 200: 
              alert("L'enregistrement s'est bien effectué.");
              if(gobackto != null) navigate(gobackto);
              else navigate(`/lieux-et-contacts`);
              break;
            case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
              break;
            default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
      }
      else {
        alert("L'enregistrement s'est bien effectué.");
        navigate(`/lieux-et-contacts`);
      }
    };

    const onError = (error) => {
      console.log("ERROR:::", error);
    };

    function gestionEvenements(value, name){
      setAumoinsUneModif(true);
      if(global.config.modeDebugApp == 1) console.log (`modif de ${name} : ${value[name]}`);
      if(name == "ADDRESS1") {
        if(value[name] != "") setAdresse2Visible(true); else setAdresse2Visible(false);
      }
      else if(name == "ADDRESS2") {
        if(value[name] != "") setAdresse3Visible(true); else setAdresse3Visible(false);
      }
      else if(name == "ADDRESS3") {
        if(value[name] != "") setAdresse4Visible(true); else setAdresse4Visible(false);
      }
    }
  
    async function recupererInfosPrincipales() {
      const dataR = await apiRecupereInfoGet(utilisateurBearer,  "lieux/" + IdDeCoord + "/getpouredit");
      if(global.config.modeDebugApp == 1) console.log('retour apiRecupereInfoGet dans recupererInfosPrincipales de PcfEditionCoord (lieux/" + IdDeCoord + "/getpouredit) : ', dataR);
      switch (dataR.statusCode) {
          case 200:
            //dataR.dataApi.ID_PCF = dataR.dataApi.pcf_main.ID_PCF;
            dataR.dataApi.ID_COORDONNEES = dataR.dataApi.pcf_coordonnee.ID_COORDONNEES;
            dataR.dataApi.TELMOBILE = dataR.dataApi.pcf_coordonnee.TELMOBILE;
            dataR.dataApi.TELFIXE = dataR.dataApi.pcf_coordonnee.TELFIXE;
            dataR.dataApi.COURRIEL = dataR.dataApi.pcf_coordonnee.COURRIEL;
            dataR.dataApi.URLWEB = dataR.dataApi.pcf_coordonnee.URLWEB;
            
            dataR.dataApi.ID_ADDRESSES = dataR.dataApi.pcf_address.ID_ADDRESSES;
            dataR.dataApi.Denomination = dataR.dataApi.pcf_address.Denomination;
            dataR.dataApi.ADDRESS1 = dataR.dataApi.pcf_address.ADDRESS1;
            dataR.dataApi.ADDRESS2 = dataR.dataApi.pcf_address.ADDRESS2;
            dataR.dataApi.ADDRESS3 = dataR.dataApi.pcf_address.ADDRESS3;
            dataR.dataApi.ADDRESS4 = dataR.dataApi.pcf_address.ADDRESS4;
            dataR.dataApi.ID_GEO_VILLES = dataR.dataApi.pcf_address.ID_GEO_VILLES;

            setDataApi(dataR.dataApi);
            if(dataR.dataApi.ADDRESS1 != null) setAdresse2Visible(true);
            if(global.config.modeDebugApp == 1) console.log("dataApi : ", dataR.dataApi)
            return true;
            break;
          case 401:
            setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
            break;
          default:
            setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        return false;
      }
    }

    async function chargementsConsecutifs() {
      var pb = false;
      setDataLoaded(false);
      if(IdDeCoord != undefined) {
        var ret = await recupererInfosPrincipales();
        if(ret != true) pb = true;
      }
      
      var ret = await appelRecupInfoGet(utilisateurBearer, "villes", setMessageProbleme);
      if(ret == null) pb = true;
      else SetDataVille(ret);

      if(pb == false ) {
        var ret = await recupererProprietes(utilisateurBearer, null, null, null, SetInfosProprietes, setMessageProbleme);
        if(ret == false) pb = true;
      }

      if(pb == true ) setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      setDataLoaded(true);
    }

    useEffect(() => {
      if(global.config.modeDebugApp == 1) console.log('useffect PcfAEditionCoord');
       if (utilisateurBearer === '000000') navigate(utilisateurRouteLogin); // si le status n'est pas bon on redirige sur l'acceuil
       document.title = (IdDeCoord == undefined) ? "Ajout d'un lieu" : "Modification d'un lieu";
       setMessageProbleme(null);
       chargementsConsecutifs();
    }, []);


    ////////////
    /*const FormCheckWithDiv = (props) => {
      return (<div className="alignement-horizontal-champs-form">
        <FormCheck {...props} />
      </div>)
      };
      pour faire : <FormCheckWithDiv id="TELFIXE_VISIBLE" infosProp={props.infosProprietes["COORDONNEES|TELFIXE_VISIBLE"]} typeCheckbox="checkbox" />
                    if(gobackto != null) navigate(gobackto);
              else navigate(`/lieux-et-contacts`);
      */
    //////////////////  

    return (
      <section className="sous-bloc2">

        {/*<NavLink className="main-nav-item" to={gobackto != null ? gobackto : "lieux-et-contacts"}>Retour à la page précédente</NavLink>*/}
        <div onClick={() => window.history.back()} className="normal-navig-item"><span className="fas fa-circle-chevron-left"></span>Retour à la page précédente</div>

        <div className="ariane" >
          <span className="ariane-titre">{IdDeCoord != undefined ? "Modification d'un lieu" : "Saisissez les informations du nouveau lieu" }</span>
        </div>

        {dataLoaded && infosProprietes != null && 
        <>
          <LoadingSpinner dataLoaded={dataLoaded}/>
          <Container className="my-0 ajout-coord">
            <MessageErreurApi message={messageProbleme} />
        
            <FormRHF onSubmit={onSubmit} onError={onError} dataApi={dataApi} gestionEvenements={gestionEvenements} >
              <div style={{display: "flex", justifyContent: "space-between"}}>
                <div style={{marginTop:"10px", marginBottom:"20px", minWidth: "700px"}}>
                  <MessageInfoChampsObligatoires />
                </div>
                <div>
                    <ButtonDeBase typeSubmit={true} lib="Enregistrer" disabled={aumoinsUneModif == true ? false : true} />
                </div>
              </div>

              <FormControl id="Denomination" infosProp={infosProprietes["ADDRESSES|Denomination"]} />
              <FormControl id="ADDRESS1" infosProp={infosProprietes["ADDRESSES|ADDRESS1"]} />
              {adresse2Visible && <FormControl id="ADDRESS2" infosProp={infosProprietes["ADDRESSES|ADDRESS2"]} />}
              {adresse3Visible && <FormControl id="ADDRESS3" infosProp={infosProprietes["ADDRESSES|ADDRESS3"]} />}
              {adresse4Visible && <FormControl id="ADDRESS4" infosProp={infosProprietes["ADDRESSES|ADDRESS4"]} />}
              <FormSelect id="ID_GEO_VILLES" infosProp={infosProprietes["ADDRESSES|ID_GEO_VILLES"]} options={dataVille}/>

              <div style={{paddingTop: "10px", paddingBottom: "5px"}}>⚠️ Merci d'indiquer ci-dessous uniquement le téléphone et le courriel <b>générique</b> afférent à ce lieu.</div>

              {/*dataApi != null && dataApi.nb_utilisation_ID_COORDONNEES != "" && <MessageInfoBox message = {`Coordonnée (référencée à ${dataApi.nb_utilisation_ID_COORDONNEES} reprise(s)).`} /> */}
              <FormControl id="TELMOBILE" infosProp={infosProprietes["COORDONNEES|TELMOBILE"]} style=" inline-input" />
              <FormControl id="TELFIXE" infosProp={infosProprietes["COORDONNEES|TELFIXE"]} style=" inline-input" />

              <FormControl id="COURRIEL" infosProp={infosProprietes["COORDONNEES|COURRIEL"]} />
              <FormControl id="URLWEB" infosProp={infosProprietes["COORDONNEES|URLWEB"]} />



              <div className="partie-bouton-droite">
                  <ButtonDeBase typeSubmit={true} lib="Enregistrer" disabled={aumoinsUneModif == true ? false : true} />
              </div>            
            </FormRHF>
          </Container>
        </>
        }
      </section>
    )
}
  
export default PcfEditionCoord;