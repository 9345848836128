import PropTypes from 'prop-types';
import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import Form from 'react-bootstrap/Form'; // pour Form.Check

import { apiCertifAppelBase } from '../Datas/dataDiplomes.js'
import { recupereNiveauxCI } from '../Datas/datasAutre.js'
import { DonneesUtilisateurContext } from '../contexte.js';


import {MessageErreurApi} from '../Components/Messages.jsx';
import LoadingSpinner from "../Components/LoadingSpinner";
import ButtonDeBase, {ButtonPourList, ButtonVoirFiche } from '../Components/ButtonsCustom.jsx';
import Table, {SelectColumnFilter} from '../Components/Table.jsx';


/**
 * Return une section permettant de rechercher des certifs
 *
 * @component
 * @summary page de recherche des certifs
 * @param {  }
 * @return { HTMLElement }
*/
function RechercheCertif(props) {
    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const [messageProbleme, setMessageProbleme] = useState();


    const [dataApiCi, setDataApiCi] = useState();
    const [dataLoadedRechCi, setDataLoadedRechCi] = useState(true);
    const [champRechCiCodeRs, setChampRechCiCodeRs] =  useState(""); //useState(localStorage.getItem('champRechCiCodeRs') ? localStorage.getItem('champRechCiCodeRs') : "");
    const [champRechCiCodeRncp, setChampRechCiCodeRncp] =  useState(""); //useState(localStorage.getItem('champRechCiCodeRncp') ? localStorage.getItem('champRechCiCodeRncp') : "");
    const [champRechCiCodeCi, setChampRechCiCodeCi] =  useState(""); //useState(localStorage.getItem('champRechCiCodeCi') ? localStorage.getItem('champRechCiCodeCi') : "");
    const [champRechCiLib, setChampRechCiLib] =  useState(""); //useState(localStorage.getItem('champRechCiLib') ? localStorage.getItem('champRechCiLib') : "");
    const [champRechCiNiveau, setChampRechCiNiveau] =  useState("-"); //useState(localStorage.getItem('champRechCiNiveau') ? localStorage.getItem('champRechCiNiveau') : "-");
    const [champRechCiEtat, setChampRechCiEtat] =  useState('true'); //useState(localStorage.getItem('champRechCiEtat') ? localStorage.getItem('champRechCiEtat') : 'true');
    
    const columnsCi = useMemo(
        () => [
          {
            id:'listesCertifs',
            hideHeader : false,
            trieDefautRp : 'intitule_diplome',
            columns: [
                { 
                    Header: 'Code', 
                    accessor: 'code_diplome',
                    //minWidth: 50,
                },
                {  
                    Header: 'Libellé', 
                    accessor: 'intitule_diplome', 
                    Cell: ({ row }) => (
                        <a title="Voir la fiche" className="lib" onClick={() => props.handleClickViewCerifBootStrap(row.original.code_diplome)}>
                            {row.original.intitule_diplome}
                            <ButtonVoirFiche />
                        </a>
                    ),
                },
                { 
                    Header: 'Rs/Rncp', 
                    accessor: 'rsRncp',
                },
                { 
                    Header: 'Nom du ou des certificateur(s)', 
                    accessor: 'certificateur_lib',
                    disableSortBy: true,
                },
                { 
                    Header: () => <>Echéance<br />Rncp/Rs</>, 
                    accessor: 'DATES_FIN_ENREGISTREMENTrech_rncp_rs_formated',
                },
                { 
                    Header: 'Etat', 
                    accessor: 'etat_lib',
                },
                {  
                //<a title={global.config.codesEtatsCiEnCours.includes(row.original.etat_code) ? "Associer": "Associer (Attention ce diplome n'est plus actif!)"} onClick={() => props.handleClickAssoCerif(row.original.code_diplome, row.original.etat_code)}>
                //<ButtonPourList disabled={global.config.codesEtatsCiEnCours.includes(row.original.etat_code) ? "" : "disabled"} />
                //</a>               
                    Header: () => <>Associer</>,
                    accessor: 'actions', 
                    id: "actions_depreciees_center", // actions_depreciees_center permet de mettre une couleur différente à la cellule dans Table.jsx
                    disableSortBy: true,
                    Cell: ({ row }) => (
                        <>
                        {global.config.codesEtatsCiEnCours.includes(row.original.etat_code) ? 
                        <a title="Associer" onClick={() => props.handleClickAssoCerif(row.original.code_diplome, row.original.etat_code)}>
                            <ButtonPourList />
                        </a>
                        :
                        <a title="Ce diplome n'est plus actif, cliquez pour savoir s'il est remplacé" onClick={function() {
                            let suite_remplacement = "";
                            if(row.original.listeDiplomesRemplacants && row.original.listeDiplomesRemplacants.length > 0) {
                                row.original.listeDiplomesRemplacants.forEach((remplacants) => {
                                    suite_remplacement += ', ' + remplacants.code;
                                })
                                if (row.original.listeDiplomesRemplacants.length == 1) alert("Ce diplôme n'est plus actif et ne peut pas être associé, il est remplacé par le diplôme " + suite_remplacement.substring(2));
                                else alert("Ce diplôme n'est plus actif et ne peut pas être associé, il est remplacé par les diplômes : " + suite_remplacement.substring(2));
                            }
                            else alert("Ce diplôme n'est plus actif et ne peut pas être associé");
                            }}>
                            <ButtonPourList disabled="disabled" />
                        </a>
                        }
                        </>
                    ),
                },
            ]
          },
        ],
        []
    )


    async function effectueRechercheCi() {
        let chaineRecherche = "?";
        if (champRechCiLib && champRechCiLib != null) chaineRecherche += "intitule-recherche=" + champRechCiLib + '&';
        if (champRechCiCodeRs && champRechCiCodeRs != null)  chaineRecherche += "code-rs=" + champRechCiCodeRs + '&';
        if (champRechCiCodeRncp && champRechCiCodeRncp != null)  chaineRecherche += "code-rncp=" + champRechCiCodeRncp + '&';
        if (champRechCiCodeCi && champRechCiCodeCi != null)  chaineRecherche += "code-diplome=" + champRechCiCodeCi + '&';
        if (champRechCiNiveau && champRechCiNiveau != null && champRechCiNiveau != '-')  chaineRecherche += "code-niveau=" + champRechCiNiveau + '&';

        if (chaineRecherche.length == 1) {setMessageProbleme('Veuillez saisir au moins un critère'); return false; } // si aucun des critères précédents renseignés... car sinon le serveur rame trop

        if (champRechCiEtat && champRechCiEtat == "true")  chaineRecherche += "code-etat=0,4,11&";

        localStorage.setItem('champRechCiCodeRs', champRechCiCodeRs) // au depart cela était fait sur le onChange (e.target.value) mais posait pb pour le checkbox.. et puis c'est mieux ainsi
        localStorage.setItem('champRechCiCodeCi', champRechCiCodeCi)
        localStorage.setItem('champRechCiCodeRncp', champRechCiCodeRncp)
        localStorage.setItem('champRechCiLib', champRechCiLib)
        localStorage.setItem('champRechCiNiveau', champRechCiNiveau)
        localStorage.setItem('champRechCiEtat', champRechCiEtat);
        
        setDataLoadedRechCi(false);
        setMessageProbleme("");
        const dataR = await apiCertifAppelBase(utilisateurBearer, 'Search' + chaineRecherche);
        if(global.config.modeDebugApp == 1) console.log('retour apiCertifAppelBase : ', dataR);

        switch (dataR.statusCode) {
        case 200:
            //modification du contenu renvoyé par l'api
            let nouveauTabRep = [];
            dataR.dataApi.forEach((el) => {
                // on va couper les intitulé trop long
                let libCourt = (el.intitule_diplome.length > global.config.lgMaxChaineTab)  ? el.intitule_diplome.substring(0, global.config.lgMaxChaineTab-3) + '...' :  el.intitule_diplome;
                var rsRncp = (el.code_rncp_string != null) ? el.code_rncp_string : '';
                if(el.codes_inventaire != null) {
                    if(rsRncp != "") rsRncp += ' ' + el.codes_inventaire ;
                    else rsRncp = el.codes_inventaire;
                }
                let ligne = { ...el, 'intitule_diplome': libCourt, 'libLong': el.intitule_diplome, 'rsRncp': rsRncp}
                nouveauTabRep.push(ligne);           
            });
            setDataApiCi(nouveauTabRep); // dataR.dataApi
            break;
        case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
                break;
        case 400:
            // on test si c'est une chaine string qui est retourné par l'api
            if(typeof dataR.dataApi === 'string' || dataR.dataApi instanceof String) setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "Il y a plus de XXX résultats ({XXXX), veuillez affiner votre recherche"
            else setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
            break;
        default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
        setDataLoadedRechCi(true);

    }
    const handleSubmitRechCi = async (e) => {
        e.preventDefault();
        effectueRechercheCi();
    }

    const [niveauxCi, SetNiveauxCi] = useState(null); // pour recup données du select
    async function chargementsConsecutifs() {
        var pb = false;
        var ret = await recupereNiveauxCI(utilisateurBearer);
        if(ret != null) SetNiveauxCi(ret); else pb = true;
        //var array = [{code: 3, libelle: 'simulation de select niveau'}];
        //SetNiveauxCi(array);

        if(pb == true ) {
            setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
    }

   useEffect(() => {
    //console.log('useffect RechercheCertif')
    chargementsConsecutifs();
   }, []);


        return (
            <>
            {niveauxCi != null &&
                <>
                <div className="titre-rech-certif">Rechercher une certification à associer à votre formation<br /><br /></div>
                <MessageErreurApi message={messageProbleme} />
                <LoadingSpinner dataLoaded={dataLoadedRechCi}/>
                <form onSubmit={handleSubmitRechCi} className="form-rech-ci">
                    <div className="partie-form-rech-ci">
                    { /* value={champRechCiCodeRs != null && champRechCiCodeRs}*/}
                        <input className="form-control input-codes" placeholder='Code RS' type="text" id="champRechCiCodeRs" 
                               onChange={(e) => {setChampRechCiCodeRs(e.target.value)}} />
                        <input className="form-control input-codes" placeholder='Code Rncp' type="text" id="champRechCiCodeRncp" onChange={(e) => {setChampRechCiCodeRncp(e.target.value)}} />
                        <input className="form-control input-codes" placeholder='Code Certif Info' type="text" id="champRechCiCodeCi" 
                               onChange={(e) => {
                                    if (!isNaN(e.target.value)) {setChampRechCiCodeCi(e.target.value)}
                                    else alert('Veuillez entrer un nombre');
                                }} />
                    </div>
                    <div className="partie-form-rech-ci">
                        <input className="form-control input-lib" placeholder='Extrait du libellé (6 lettres minimum)' type="text" id="champRechCiLib" onChange={(e) => {setChampRechCiLib(e.target.value)}} />

                        <select className="form-control custom-select" id="champRechCiNiveau" title="Niveau" onChange={(e) => {setChampRechCiNiveau(e.target.value);}}>
                            <option value="-">Tous les niveaux</option>
                            {niveauxCi && niveauxCi.map((item) => {
                            return (
                                <option value={item.code} key={item.code}>
                                    {item.libelle}
                                </option>
                            );
                            })}
                        </select>

                    </div>
                    <div className="partie-form-rech-ci-bt">
                        <Form.Check type="switch"  id="champRechCiEtat" label="Voir uniquement les fiches publiées" 
                                checked={(champRechCiEtat === "true")} 
                                onChange={() => champRechCiEtat === "true" ? setChampRechCiEtat("false") : setChampRechCiEtat("true")} />
                        <ButtonDeBase typeSubmit={true} lib="Rechercher" disabled={(
                                                                                    (!champRechCiCodeRs || champRechCiCodeRs.length < 3) && 
                                                                                    (!champRechCiCodeRncp || champRechCiCodeRncp.length < 5) && 
                                                                                    (!champRechCiCodeCi || champRechCiCodeCi.length < 3) && 
                                                                                    (!champRechCiLib || champRechCiLib.length < 5) &&
                                                                                    champRechCiNiveau == '-'
                                                                                    ) || !dataLoadedRechCi} />
                    </div>
                </form>

                {dataLoadedRechCi && dataApiCi && 
                    <> {/*section className="certifications-recherche"*/}
                        {dataLoadedRechCi && dataApiCi && dataApiCi.length > 0 && 
                            <>
                                <p className="message-info">
                                    {dataApiCi.length} 
                                    {dataApiCi.length > 1 ? 
                                        <> certifications trouvées - cliquez sur l'un des boutons <span className="button-list-ajout-in-lib"><span className="fas fa-plus"></span></span> de la liste pour associer une certification à votre formation</>
                                        : 
                                        <> certification trouvée - cliquez sur le bouton <span className="button-list-ajout-in-lib"><span className="fas fa-plus"></span></span> dans la liste pour l'associer à votre formation</>}
                                </p>    
                                <Table columns={columnsCi} data={dataApiCi} noDisplayNbResult={true} />
                            </>
                        }
                        {dataApiCi.length == 0 ? <div>Aucune fiche certif info n'a été trouvée, veuillez modifier vos critères.</div> : null}
                    </>
                }
                </>
            }
            </>
        )

}


export default RechercheCertif;

RechercheCertif.propTypes = {
    handleClickAssoCerif: PropTypes.func,
    handleClickViewCerifBootStrap: PropTypes.func
}