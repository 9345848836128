import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import {Link, useNavigate } from "react-router-dom";

import { DonneesUtilisateurContext } from '../../contexte.js';

import { apiRecupereInfoGet } from '../../Datas/datasAutre.js'
import { apiCoordSuppr } from '../../Datas/dataPcf.js'

import ButtonDeBase, { ButtonSuppr, ButtonVoirFiche } from '../../Components/ButtonsCustom.jsx';
import LoadingSpinner from "../../Components/LoadingSpinner";
import MessageInfoBox, {MessageErreurApi} from '../../Components/Messages.jsx';
import Table from '../../Components/Table.jsx';



/**
 * Return Page des lieux et des contacts
 *
 * @component
 * @summary page lieux et contacts
 * @param {  }
 * @return { HTMLElement }
*/
function ListeLieux({ lieuxCharges, setLieuxCharges }) { // déstructuration  des props (https://openclassrooms.com/fr/courses/7008001-debutez-avec-react/7136837-partagez-votre-state-entre-differents-composants)
    const navigate = useNavigate(); // utile pour changer de page
    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const [dataApi, setDataApi] = useState();
    const [dataLoaded, setDataLoaded] = useState(true);
    const [messageProbleme, setMessageProbleme] = useState();

   function  hdleClickNoPossible () {
      alert("Impossible de supprimer ce lieu car il est utilisé dans certaines formations");
   }

    //pour react-table https://stackoverflow.com/questions/56922381/how-to-include-url-in-react-table-cell
    const columns = useMemo(
        () => [
          {
            id:'listesLieux',
            hideHeader : false,
            trieDefautRp : 'LIB_GEO_VILLES',
            columns: [
              { 
                Header: 'Dénomination', 
                accessor: 'Denomination',
              },
                { 
                  Header: 'Adresse', 
                  accessor: 'ADDRESS1',
                },
                {  
                  Header: 'Code postal', 
                  accessor: 'CODE_POSTAL', 
                },
                { 
                  Header: 'Ville', 
                  accessor: 'LIB_GEO_VILLES',
                },
                { 
                  Header: 'Téléphone fixe', 
                  accessor: 'TELFIXE',
                },
                { 
                  Header: 'Mobile', 
                  accessor: 'TELMOBILE',
                },
                { 
                    Header: 'Courriel', 
                    accessor: 'COURRIEL',
                },
                { 
                  Header: 'Url site web', 
                  accessor: 'URLWEB',
                },

                {
                  Header: () => <></>,
                    accessor: 'actions', 
                    id: "asso_center",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                        <>
                          <Link title={`Accéder à la fiche ${row.original.ID_COORDONNEES}`} to={`/lieux-et-contacts/edition-coord/${row.original.ID_COORDONNEES}`}>
                            <ButtonVoirFiche className="fas fa-pen-to-square" />
                          </Link>
                          {row.original.est_utilise == false ?
                            <a title={`Supprimer ${row.original.ID_COORDONNEES}`} onClick={() => handleClickSupprLieu(row.original.ID_COORDONNEES)}>
                              <ButtonSuppr />
                            </a>
                            :
                            <a title="Suppression impossible" onClick={() => hdleClickNoPossible()}>
                              <ButtonSuppr disabled="disabled"  />
                            </a>
                          }
                        </>
                    ),
                },
            ]
          },
        ],
        []
    )
 

    const handleClickSupprLieu  = async (codeCoordASuppr) => {
        setDataLoaded(false);
        setMessageProbleme();
        var dataR = await apiCoordSuppr(utilisateurBearer, codeCoordASuppr);

        switch (dataR.statusCode) {
            case 204 :
                await recupLieux(); // reload la liste
                alert("La suppression a bien été effectuée");
                
              break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
        setDataLoaded(true);
    }

    
    async function recupLieux() {
        setDataLoaded(false);
        const dataR = await apiRecupereInfoGet(utilisateurBearer, "lieux/lieux-liste");
        if(global.config.modeDebugApp == 1) console.log(`retour apiRecupereInfoGet avec "lieux/lieux-liste" : `, dataR);

        switch (dataR.statusCode) {
            case 200:
                setDataApi(dataR.dataApi);
              break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
        setDataLoaded(true);
    }

    const handleClickCreateLieu = () => {
      navigate(`/lieux-et-contacts/creation-pcf`);
    }

    useEffect(() => {
      if(global.config.modeDebugApp == 1)  console.log('useffect ListeLieux')
      recupLieux(); 
      setLieuxCharges(true);
    }, []);
   
   var messageInfo = "Les lieux déclarés ici servent à renseigner vos formations";
    return (
        <>
          {process.env.REACT_APP_MY_ENV != 'production' && <MessageErreurApi message={global.config.msgPrTechnique.msgSrvTest} />}
          <MessageErreurApi message={messageProbleme} />
          <LoadingSpinner dataLoaded={dataLoaded} />

          {dataLoaded &&
          <>
            <section>
                <br />
                {dataApi && dataApi.length > 1 && <MessageInfoBox message={messageInfo} />}
                {dataApi && dataApi.length > 0 && <Table columns={columns} data={dataApi} noFilter={false} />}
                {dataApi && dataApi.length == 0 && <div>Aucun lieu de formation n'a encore été déclaré.</div>}
            </section>
            <div className="centrage-deux-elements">
              <div className="align-div-right ">Un lieu manquant ?</div>
              {/*<div className="align-div-right ">Une coordonnées <span style={{fontSize: "2em"}}>🏫</span>ou une personne 🤵 manquante ?</div> */} 
                <div className="align-div-left">
                  <ButtonDeBase onClick={handleClickCreateLieu} lib="Ajouter un lieu de formation 🏫" />
                </div>
            </div>
          </>
          }

        </>
    )

}

export default ListeLieux;