import { MD5 } from '../fonctionsDiv.js'

var objRetourInvalide = {"statusCode": 400, "dataApi": [] }

// appel une route différente en fonction du type d'utilisateur (of ou pro bo)
export async function apiLogin(email, password, orgAsimulerSiret, asAnOrg) {
    //https://stackoverflow.com/questions/70812409/send-post-data-by-using-axios-in-react-ts nok
    var details = {
        'login': email,
        'password': MD5(password),
        'orgAsimulerSiret': orgAsimulerSiret
    };
    var paramsTab = [];
    for (var property in details) {
        if(details[property] != undefined) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            paramsTab.push(encodedKey + "=" + encodedValue);
        }
    }
    var paramsString = paramsTab.join("&");

    const requestOptions = {
        method: 'POST'
    };
    try {
        var url =                   global.config.apiAuth.url + 'login?' + paramsString;;
        if(asAnOrg == true) url =   global.config.apiAuth.url + 'login-pro?' + paramsString;

        const response = await fetch(url, requestOptions) // call api to log in ?login=devapps&password=devapp ?login=devapps&password=devapps
        if(global.config.modeDebugApp == 1) console.log(` ${url} .. response de l\'api :`, response);
        return response;
    } catch (error_1) {
        if(global.config.modeDebugApp == 1) console.log('error apiLogin', error_1);
        return;
    }
}

export async function apiProChangOrg(utilisateurBearer, idCompanie) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };
    try {
         var url = global.config.apiAuth.url + 'change-org?id-companie=' + idCompanie;
        const response = await fetch(url, requestOptions)
        if(global.config.modeDebugApp == 1) console.log(url + ' ... response de l\'api :', response)
        return response;
    } catch (error_1) {
        console.log('error apiProChangOrg', error_1);
        return;
    }
}



export async function apiCheckSiret(siret) {
    if (siret == "") return objRetourInvalide;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiAuth.url + "check-siret/" + siret;
    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi}`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200) return objRetour;

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiCheckSiret', error_1);
        return objRetour;
    }
}


export async function apiInformationsQuiforme(siret) {
    if (siret == "") return objRetourInvalide;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiAuth.url + "informations-quiforme/" + siret;
    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi}`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200 && response.status != 401) return objRetour;  // 401 : un message personnalisé est retourné dans ce cas

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiInformationsQuiforme', error_1);
        return objRetour;
    }
}

export async function apiProcedureMdpEnvoieMail(siret) {
    if (siret == "") return objRetourInvalide;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiAuth.url + "envoi-procedure-password/" + siret;
    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi}`);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200) return objRetour;

        return objRetour;
    } catch (error_1) {
        console.log('error apiProcedureMdpEnvoieMail', error_1);
        return objRetour;
    }
}

// model apiLogin ci dessus
export async function apiReinitMotDePasse(login, password, tempKeyNewPwd) {
    //https://stackoverflow.com/questions/70812409/send-post-data-by-using-axios-in-react-ts not ok, vu ca ausi mais pas ok non plus : https://stackoverflow.com/questions/70812409/send-post-data-by-using-axios-in-react-ts
    var details = {
        'login': login,
        'password': password,
        'cleTemporaire': tempKeyNewPwd,
    };
    var paramsTab = [];
    for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        paramsTab.push(encodedKey + "=" + encodedValue);
    }
    var paramsString = paramsTab.join("&");

    const requestOptions = {
        method: 'POST',
    };
    try {
        //console.log('paramsString :', paramsString)
        const response = await fetch(global.config.apiAuth.url + 'reinitialisation-mot-passe?' + paramsString, requestOptions) 
        if(global.config.modeDebugApp == 1) console.log('response de l\'api apiReinitMotDePasse :', response)
        const data = await response.text(); // au lieu de .json(), data contient le token normalement

        if (response.status != 200) { //===  400 si bad
            return
        }
        //console.log('response.status : ', response.status);
        //console.log('success sign in data : ', data);
        return data;
    } catch (error_1) {
        console.log('error apiReinitMotDePasse', error_1);
        return;
    }
}