var objRetourInvalide = {"statusCode": 400, "dataApi": [] }


//method= PUT ou DELETE
export async function apiSessAssocDissoCertif(utilisateurBearer, sessionNumber, idPrimaire, method='PUT') {
    if (utilisateurBearer == "" || idPrimaire == "" || method == "") return objRetourInvalide;

    const requestOptions = {
        method: method,
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json'
        },
    };

    var urlApi = global.config.apiSessionBase.url  + "/" + sessionNumber + "/certifications-sessions";
    urlApi += "/" + idPrimaire;
    if(global.config.modeDebugApi == 1) console.log('appel ' + urlApi + ' avec methode ' + method);

    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200 && response.status != 400) return objRetour;  // 400 : un message personnalisé est retourné dans ce cas, ex : "La fiche certif info est déjà associée"

        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiSessAssocDissoContact', error_1);
        return objRetour;
    }
}



export async function apiPutBlocs(utilisateurBearer, ID_ACTIONS_SESSIONS_PERIODE_BLOCSaAsso, datasToPost) {
    if (utilisateurBearer == "") return objRetourInvalide;

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${utilisateurBearer}`,
            'Content-Type': 'application/json' 
        },
        body: JSON.stringify(datasToPost) // attendu par l'api : [{ID_ACTIONS_SESSIONS_PERIODE_BLOCS CODE_BLOC}}
    };

    //var urlApi = global.config.apiProfessionnalisants.url + ID_ACTIONS_SESSIONS_PERIODE_BLOCSaAsso + '/formacode/';
    var urlApi = global.config.apiSessionBase.url  + "/" + ID_ACTIONS_SESSIONS_PERIODE_BLOCSaAsso + "/blocs-gestion";
    if(global.config.modeDebugApi == 1) console.log(`appel ${urlApi}`);
    
    var objRetour = {statusCode : '', dataApi : ''};
    try {
        const response = await fetch(urlApi, requestOptions);
        objRetour.statusCode = response.status; //===  400 si bad, 401 unauthorized
        if (response.status != 200) return objRetour;
    
        objRetour.dataApi = await response.json();
        return objRetour;
    } catch (error_1) {
        console.log('error apiPutBlocs', error_1);
        return objRetour;
    }
}