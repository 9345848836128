import PropTypes from 'prop-types';
import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import Modal from 'react-bootstrap/Modal';
import { NavLink } from "react-router-dom";

import { DonneesUtilisateurContext } from '../../contexte.js';

import { apiFormGetInfosDiv, apiFormAssocDissoContact } from '../../Datas/dataFormations.js'
import { appelGetSetInfosObjetsFormActSess, appelRecupInfoGet } from '../../fonctionsDiv.js';

import LiensPrecedentSuivant from '../../Components/LiensPrecedentSuivant.jsx';
import LoadingSpinner from "../../Components/LoadingSpinner";
import MessageInfoBox, { MessageErreurApi, MessageInfoNoDroitErreurApi } from '../../Components/Messages.jsx';
import Table from '../../Components/Table.jsx'
import { ButtonSuppr } from '../../Components/ButtonsCustom.jsx';



/**
 * Return Formulaire étape 3 (association du ou des contacts de formation)
 *
 * @component
 * @summary page étape 3
 * @param {  }
 * @return { HTMLElement }
*/
function FormEtape3(props) {
    let numEtape = 3;

    const formationNumber = props.formationNumber;

    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const [dataApi, setDataApi] = useState();
    const [dataLoaded, setDataLoaded] = useState(true);
    const [messageProbleme, setMessageProbleme] = useState();
    const [msgInfoF, setMsgInfoF] = useState();

    const handleCloseModal = () => setShowModal(false);
    const [showModal, setShowModal] = useState(false);

    const [contactSelectionne, setContactSelectionne] = useState(false);

    //pour react-table https://stackoverflow.com/questions/56922381/how-to-include-url-in-react-table-cell
    const columns = useMemo(
        () => [
          {
            id:'listeContactsDeLaFormation',
            hideHeader : false,
            trieDefautRp : 'identite_nom_prenom',
            columns: [
                { 
                  Header: 'Identité', 
                  accessor: 'identite_nom_prenom',
                },
                { 
                  Header: 'Adresse', 
                  accessor: 'ADDRESS1',
                },
                { 
                  Header: 'Ville', 
                  accessor: 'LIB_GEO_VILLES',
                },
                { 
                  Header: 'Cp', 
                  accessor: 'CODE_POSTAL',
                },
                { 
                  Header: 'Tél fixe', 
                  accessor: 'PERSONS_TELFIXE',
                },
                { 
                  Header: 'Courriel', 
                  accessor: 'PERSONS_COURRIEL',
                },
                { 
                  Header: 'Type de contact', 
                  accessor: 'LIB_LHEO_TYPE_CONTACT',
                },
                {
                  Header: () => <>Principal</>,
                    accessor: 'PRINCIPAL', 
                    id: "PRINCIPAL_center",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                      <>
                      { (row.original.noEditablePLease != 1) ?
                          <>
                            {row.original.PRINCIPAL == true ? 
                              <span className="enrobage-boutton"><span className="fas fa-star"></span></span>
                              :
                              <a title= "Définir comme principal"
                              onClick={() => handleClickSetPrincipal(row.original.ID_ACTIONS_FORMATIONS_CONTACT)}>
                                <span className="enrobage-boutton"><span className="far fa-star"></span></span>
                              </a>
                            }
                          </>
                        :
                        <>
                        {row.original.PRINCIPAL == true ? 
                          <span className="enrobage-boutton"><span className="fas fa-star"></span></span>
                          :
                          <span className="enrobage-boutton"><span className="far fa-star"></span></span>
                        }
                        </>
                      }
                      </>
                    ),
                },
                {
                  Header: () => <></>,
                    accessor: 'actions', 
                    id: "asso_center",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                      <>
                      { (row.original.noEditablePLease != 1) ?
                        <a title="Dissocier" onClick={() => handleClickDisso(row.original.ID_ACTIONS_FORMATIONS_CONTACT)}>
                          <ButtonSuppr />
                        </a>
                      :
                        <ButtonSuppr disabled="disabled" />
                      }
                      </>
                    ),
                },
            ]
          },
        ],
        []
    )
 
    const handleChoixContact = async (codeContactAasso) => {
      setContactSelectionne(codeContactAasso);
      setShowModal(true);
    }
    const handleClickAsso = async (codeTypeContact) => {
          setDataLoaded(false);
          setMessageProbleme();
          setShowModal(false); // on cache la modale
          var dataR = await apiFormAssocDissoContact(utilisateurBearer, formationNumber, contactSelectionne, 'PUT', "", codeTypeContact);

          switch (dataR.statusCode) {
              case 200:
                  await recupContactsDeLaFormation(); // reload la liste
                  alert("L'association a bien été effectuée.");
                break;
              case 401:
                  setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
                break;
                case 400:
                  setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas
                break;
              default:
                  setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
          }
          setDataLoaded(true);
    }

    const handleClickDisso  = async (ID_ACTIONS_FORMATIONS_CONTACT) => {
        setDataLoaded(false);
        setMessageProbleme();
        // pas possible cart dataApi.length  n'est pas raffraichi, et nbContactsFormation non plus.. c'est donc l'api qui gère,pa s plus mal
        //if(dataApi && dataApi.length == 1) {
        //  alert("Une formation doit avoir au moins un contact. \r\nEn conséquence, vous devez auparavant associer un nouveau contact afin de pouvoir dissocier ce contact là dans un second temps.")
        //  return false;
        //}
        var dataR = await apiFormAssocDissoContact(utilisateurBearer, formationNumber, ID_ACTIONS_FORMATIONS_CONTACT, 'DELETE');

        switch (dataR.statusCode) {
            case 200:
                await recupContactsDeLaFormation(); // reload la liste
                if(dataR.dataApi != "") alert(dataR.dataApi);
                else alert("La dissociation a bien été effectuée");
              break;
              case 400:
                setMessageProbleme(dataR.dataApi);
                break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
        setDataLoaded(true);
    }
    const handleClickSetPrincipal  = async (ID_ACTIONS_FORMATIONS_CONTACT) => {
      var dataR = await apiFormAssocDissoContact(utilisateurBearer, formationNumber, ID_ACTIONS_FORMATIONS_CONTACT, 'POST', '/setprincipal');
      if(global.config.modeDebugApp == 1) console.log('retour apiFormAssocDissoContact : ', dataR);
      switch (dataR.statusCode) {
          case 200:
            await recupContactsDeLaFormation(); // reload la liste
            alert("Modification effectuée.");
            break;
            case 400:
              setMessageProbleme(dataR.dataApi);
              break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
            break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
    }

    async function recupContactsDeLaFormation() {
        const dataR = await apiFormGetInfosDiv(utilisateurBearer, formationNumber, "/contacts");
        if(global.config.modeDebugApp == 1) console.log('retour apiFormGetInfosDiv avec "XXX/contacts" : ', dataR);

        switch (dataR.statusCode) {
            case 200:
                if(props.infosProprietes["ACTIONS_FORMATIONS_CONTACT|other_dataEditable"].editable  == false) {
                  //modification du contenu renvoyé par l'api (ajout d'une information)
                  let nouveauTabRep = [];
                  dataR.dataApi.forEach((el) => {
                      // on va ajouter une notion de non modifiable
                      let ligne = { ...el, 'noEditablePLease': 1}
                      nouveauTabRep.push(ligne);           
                  });
                  //console.log("dataApi modifié:", nouveauTabRep);
                  setDataApi(nouveauTabRep);
                }
                else setDataApi(dataR.dataApi);

                // pour que le systeme calcule la validité Lhéo après l'enregistrement....
                var retAppel = await appelGetSetInfosObjetsFormActSess(utilisateurBearer, formationNumber, null, null, props.SetInfosGenFormation, setMessageProbleme);
                //if(retAppel == true) {
                  //var objaAmodif = JSON.parse(JSON.stringify(props.infosGenFormation)); // on clone (duplique) l'objet pour que tout fonctionne bien
                  //objaAmodif.nbContactsFormation = dataR.dataApi.length;
                  //objaAmodif.etape3 = dataR.dataApi.length > 0 ? "ok" : "nok";
                  //props.SetInfosGenFormation(objaAmodif);
                //}
              return true;
            case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
            return false;
        }
    }

    const [contactsPourChoix, SetContactsPourChoix] = useState(); // pour recup données du select
    const [typesContactPourChoix, SetTypesContactPourChoix] = useState(); // pour recup données du select
    async function chargementsConsecutifs() {
        var pb = false;
        setDataLoaded(false);

        var ret = await recupContactsDeLaFormation();
        if(ret != true) pb = true;

        if(props.infosProprietes["ACTIONS_FORMATIONS_CONTACT|other_dataEditable"].editable == 1) {
          if(pb == false) {
            var ret = await appelRecupInfoGet(utilisateurBearer, "persons/contacts-liste?type=1,2,3", setMessageProbleme); // recuperation des contacts pour liste select
            if(ret != null) SetContactsPourChoix(ret); else pb = true;
          }
          if(pb == false) {
            var ret = await appelRecupInfoGet(utilisateurBearer, "LHEO_TYPE_CONTACT", setMessageProbleme); // recuperation des types de contact pour liste select
            if(ret != null) SetTypesContactPourChoix(ret); else pb = true; 
          }
        }

        if(pb == true ) setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut

        setDataLoaded(true);
    }

    useEffect(() => {
      if(global.config.modeDebugApp == 1)  console.log('useffect FormEtape3, props.infosGenFormation : ', props.infosGenFormation)
      document.title = `Contact(s) de la formation ${formationNumber}`;
      chargementsConsecutifs();
      setMsgInfoF("Le contact de l'organisme responsable est par défaut automatiquement ajouté ici lorsqu'une formation est créée.<br />Une formation peut être associée à un ou plusieurs contacts.");
    }, []);

    return (
        <>
          <LoadingSpinner dataLoaded={dataLoaded} />
          <MessageInfoBox message={msgInfoF} />
          <MessageErreurApi message={messageProbleme} />

          {dataLoaded && dataApi &&
          <>
            { props.infosProprietes["ACTIONS_FORMATIONS_CONTACT|other_dataEditable"].editable == 0 ?
              <MessageInfoNoDroitErreurApi />
            :
              <>
                <select className="form-control custom-select" id="theList" 
                        title="Contacts possibles" 
                        onChange={(e) => {handleChoixContact(e.target.value);}}>
                    <option value="" key="-">Sélectionner un contact à associer à cette formation</option>
                    {/* on affiche le contact * et les personnes seulement 
                    déja filtré lors de l'appel : item.PCF_TYPE == 1 || item.PCF_TYPE == 3  
                    nb : l'appel pour cet utilisation sert aussi dans gestion des lieux....*/}
                    {contactsPourChoix && contactsPourChoix.map((item) => {
                        return (                      
                            (item.PERSONS_LAST_NAME != null || item.PERSONS_FIRST_NAME != null) &&
                              <option value={item.ID_PCF} key={item.ID_PCF}>
                                {item.lib_concatene_adr_person}
                              </option>
                        );
                        })}
                </select>
                {showModal && typesContactPourChoix &&
                <Modal size="lg" show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} centered aria-labelledby="example-modal-sizes-title-lg">
                  <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Précisez le type de contact
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <select className="form-control custom-select" id="types-contact-list" 
                            title="Types de contact" 
                            onChange={(e) => {handleClickAsso(e.target.value);}}>
                        <option value="" key="-">Sélectionner un type de contact</option>
                        {typesContactPourChoix && typesContactPourChoix.map((item) => {
                            return (
                                <option value={item.id} key={item.id}>
                                    {item.lib}
                                </option>
                            );
                            })}
                    </select>
                  </Modal.Body>
                </Modal>
                }
                Vous ne trouvez pas le bon contact 👀 ? 
                Vous pouvez ajouter un contact à votre organisme en <NavLink className="main-nav-item" style={{textDecoration: "underline"}} 
              to={"/lieux-et-contacts/creation-personne?gobackto=/formation/etape3/" + formationNumber}>cliquant ici</NavLink><br /><br />
              </>
            }

            <section className="contacts-associes">
                <div className="titre">{dataApi.length > 1 ? `${dataApi.length} contacts sont actuellement associés à votre formation :` : "Un contact est actuellement associé à votre formation : "}</div>
                {dataApi && dataApi.length > 0 && <Table columns={columns} data={dataApi} noPagination={true} noDisplayNbResult={true} noFilter={true} />}
                {dataApi && dataApi.length == 0 && <div>Aucun contact n'est associé.</div>}
            </section>
          </>
          }

          <LiensPrecedentSuivant formationNumber={formationNumber} numEtape={numEtape} infosGenFormation={props.infosGenFormation} notifBeCareful={false} />
        </>
    )

}

FormEtape3.propTypes = {
  formationNumber: PropTypes.number.isRequired,
  SetInfosGenFormation : PropTypes.func,
  infosGenFormation: PropTypes.object,
}

export default FormEtape3;