import PropTypes from 'prop-types';
import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import { useNavigate } from "react-router-dom";
import {  Form, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from 'react-select';
import { DonneesUtilisateurContext } from '../../contexte.js';

import { apiActAppelGetInfosDiv, apiActModif, apiPutActObjs } from '../../Datas/dataActions.js'
import { apiRecupereInfoGet } from '../../Datas/datasAutre.js'

import LiensPrecedentSuivant from '../../Components/LiensPrecedentSuivant.jsx';
import LoadingSpinner from "../../Components/LoadingSpinner";
import MessageInfoBox, {MessageErreurApi, MessageInfoChampsObligatoires} from '../../Components/Messages.jsx';
import ButtonDeBase from '../../Components/ButtonsCustom.jsx';
import FormRHF from "../../Components/Form/Form";
import FormControl from "../../Components/Form/FormControl";
import FormSelect from "../../Components/Form/FormSelect";
import FormHidden from "../../Components/Form/FormHidden";
import { appelGetSetInfosObjetsFormActSess, dateModifCharAmericAvecSepToAmericSansSep, dateModifCharAmericSansSepToAmericAvecSep, 
        appelRecupInfoGet, validateDate, confirmationSiCLickSUrLien, ajusteDonneesBool} from '../../fonctionsDiv.js';
/**
 * Return Formulaire étape 5 (informations principales d'une action)
 *
 * @component
 * @summary page étape 5
 * @param {  }
 * @return { HTMLElement }
*/
function FormEtape5(props) {
    let numEtape = 5;

    const formationNumber = props.formationNumber;
    const actionNumber = props.actionNumber;
    
    const navigate = useNavigate(); // utile pour changer de page
    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const [dataApi, setDataApi] = useState();

    const [aumoinsUneModif, setAumoinsUneModif] = useState(false); // pour avertir l'utilisateur en cas de sortie sans  enregistrement

    const [dataLoaded, setDataLoaded] = useState(false);
    const [messageProbleme, setMessageProbleme] = useState();

    var tabPublicsRecuperes = []; // va servir a alimenter un tableau d'objet permettant de réafficher les publics sélectionnées dans react-select
    var tabModalitesPedaRecuperes = []; // idem pour les modalités pédagogiques
    var tabLanguesRecuperes = []; // idem pour les langues


    async function recupererInfosPrincipales() {
      const dataRip = await apiActAppelGetInfosDiv(utilisateurBearer, "/"+actionNumber);
      if(global.config.modeDebugApp == 1) console.log('retour apiActAppelGetInfosDiv dans recupererInfosPrincipales : ', dataRip);
      switch (dataRip.statusCode) {
          case 200:
            dataRip.dataApi.actionDataPrincipales.DATE_MODIFfr =    new Date(dataRip.dataApi.actionDataPrincipales.DATE_MODIFICATION_SESSIONS).toLocaleDateString("fr");
            dataRip.dataApi.actionDataPrincipales.DATE_CREATIONfr = new Date(dataRip.dataApi.actionDataPrincipales.DATE_CREATION_SESSIONS).toLocaleDateString("fr");

              // gestion des champs bouléens oui/non qui doivent avoir true ou false (alimentés par optsOuiNon)
              dataRip.dataApi.actionDataPrincipales.NIVEAU_ENTREE_OBLIGATOIRE = ajusteDonneesBool(dataRip.dataApi.actionDataPrincipales.NIVEAU_ENTREE_OBLIGATOIRE); 
              dataRip.dataApi.actionDataPrincipales.PRISE_EN_CHARGE_FRAIS_POSSIBLE = ajusteDonneesBool(dataRip.dataApi.actionDataPrincipales.PRISE_EN_CHARGE_FRAIS_POSSIBLE); 
              dataRip.dataApi.actionDataPrincipales.conventionnement = ajusteDonneesBool(dataRip.dataApi.actionDataPrincipales.conventionnement); 

              if(dataRip.dataApi.actionDataPrincipales.date_information1 != null) { 
                dataRip.dataApi.actionDataPrincipales.date_information1 = dateModifCharAmericSansSepToAmericAvecSep(dataRip.dataApi.actionDataPrincipales.date_information1);
                setDateInfo2Visible(true);
              }
              if(dataRip.dataApi.actionDataPrincipales.date_information2 != null) {
                dataRip.dataApi.actionDataPrincipales.date_information2 = dateModifCharAmericSansSepToAmericAvecSep(dataRip.dataApi.actionDataPrincipales.date_information2);
                setDateInfo3Visible(true);
              }

              if(dataRip.dataApi.actionDataPrincipales.date_information3 != null) {
                dataRip.dataApi.actionDataPrincipales.date_information3 = dateModifCharAmericSansSepToAmericAvecSep(dataRip.dataApi.actionDataPrincipales.date_information3);
              }

              // FOAD :  0 formation entièrement présentielle, 1 mixte, 2 formation entièrement à distance :
              if(dataRip.dataApi.actionDataPrincipales.ID_MODALITE_ENSEIGNEMENT != null && dataRip.dataApi.actionDataPrincipales.ID_MODALITE_ENSEIGNEMENT > 0) {                 
                setIsaDistance(true);
              }

              tabPublicsRecuperes = dataRip.dataApi.lesPublics;
              tabModalitesPedaRecuperes = dataRip.dataApi.lesModalitesPeda;
              tabLanguesRecuperes = dataRip.dataApi.lesLangues;

              //console.log("les publics :",  tabPublicsRecuperes);
              //SetTabPublicsRecuperes(dataRip.dataApi.lesPublics);
              setDataApi(dataRip.dataApi.actionDataPrincipales);
              //console.log("dataApi", dataRip.dataApi)
              //console.log("-----------------------ID_MODALITE_ENSEIGNEMENT", dataRip.dataApi.actionDataPrincipales.ID_MODALITE_ENSEIGNEMENT)
              return true;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
            break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut + " [code : A1]"); // Problème technique par défaut
        return false;
      }
    }

    //pour effectuer des actions en fonction de la saisie de l'utilisateur (fonction lancé par react-hook-form)
    const [dateInfo2Visible, setDateInfo2Visible] = useState(false);
    const [dateInfo3Visible, setDateInfo3Visible] = useState(false);
    const [isaDistance, setIsaDistance] = useState(false);
    function gestionEvenements(value, name){
      setAumoinsUneModif(true); document.getElementById("hidden_marque_une_modif").value = 1;
      if(global.config.modeDebugApp == 1) console.log (`modif de ${name} : ${value[name]}`);
      if(name == "date_information1") {
        if(value[name] != "") setDateInfo2Visible(true); else setDateInfo2Visible(false);
      }
      else if(name == "date_information2") {
        if(value[name] != "") setDateInfo3Visible(true); else setDateInfo3Visible(false);
      }

      else if(name == "ID_MODALITE_ENSEIGNEMENT") {
        if(value[name] > 0) setIsaDistance(true); else setIsaDistance(false);
      }

      // mise en évidence des problèmes de cohérence des heures lors de la saisie
      /*
      else if(name == "nombre_heures_total" || name == "HEURE_CENTRE" || name == "HEURE_ENTREPRISE") {
        var somme = (parseInt(value["HEURE_CENTRE"]) + parseInt(value["HEURE_ENTREPRISE"]));

        var ob = document.getElementById(name);
        if (somme > parseInt(value["nombre_heures_total"])) ob.classList.add("alert-input");
        else {
          var ob1 = document.getElementById("nombre_heures_total");
          ob1.classList.remove("alert-input");
          var ob2 = document.getElementById("HEURE_CENTRE");
          ob2.classList.remove("alert-input");
          var ob3 = document.getElementById("HEURE_ENTREPRISE");
          ob3.classList.remove("alert-input");
        }
      }*/
    }
    const tabDatesAgerer = ["date_information1", "date_information2", "date_information3"]; // declaration tableau dates a modifier 
    const onSubmit = async (values) => {
      setMessageProbleme();

      var dateMin = '1990-01-01';
      var dateMax = '2079-06-06';
      var testdateOk = 1;
      tabDatesAgerer.forEach((el) => {
        if( values[el] == '    -  -  ') { // pour les date_information* : apparement parfois contient '        ' en base (update fait pour corrgier ce 260423)
                                         // '    -  -  ' était soumis, cette sécurité a donc été ajoutée, ce qui a pour effet de maj à null encas de maj
          if(global.config.modeDebugApp == 1) console.log("date avec des tirets mis a null dans", el);
          values[el] = null;
        }

        if(document.getElementById(el) != null && values[el] != ""  && values[el] != null ) {          
          if(global.config.modeDebugApp == 1) console.log("date qui va être vérifiée : ", values[el]);
          if(testdateOk == 1) {
            if(values[el] >  dateMax) { // la comparaison est ok même avec les tirets
              alert("La date ne doit pas être supérieure au 06/06/2079");
              testdateOk = 0;
            }
            else if(testdateOk == 1 && values[el] < dateMin) {
              alert("La date est trop ancienne, elle doit être supérieure au 01/01/1990");
              testdateOk = 0;
            }
            else if(testdateOk == 1 && validateDate(values[el]) == false) {
              alert("La date est incorrecte");
              testdateOk = 0;
            }

            if(testdateOk == 0){
              document.getElementById(el).focus();
              return false;
            }
          }
        }
      });
      if(testdateOk == 0) return false;
/*
      if(values.HEURE_CENTRE != null && values.HEURE_ENTREPRISE != null && values.nombre_heures_total != null) {
        var somme = (parseInt(values.HEURE_CENTRE) + parseInt(values.HEURE_ENTREPRISE));
        if (somme > parseInt(values.nombre_heures_total)) {
          alert(`La somme des heures en centre et en entreprise (${somme}) est supérieure au nombre d'heures totales, veuillez apporter les modifications nécessaires.`);
          var ob = document.getElementById("HEURE_CENTRE");
          ob.focus();
          return false;
        }
      }
*/

      //si l'utilisateur indique une "formation mixte" (1) ou une "formation entièrement à distance" (2) :
      //les nombres d'heure centre et entreprise sont masqués, seule le nombre d'heure totale est saisissable et obligatoire, heure minimum à 3
      //(à noter que l'of pourra, dans le cadre du mixte pare exemple, afficher des informations supplémentaires dans le champ texte "durée indicative"
      if(values.ID_MODALITE_ENSEIGNEMENT > 0) {
        if(values.nombre_heures_total == null || values.nombre_heures_total < 3) {
          alert(`Le total du nombre d'heures est obligatoire et doit ête supérieur à 2.`);
          var ob = document.getElementById("nombre_heures_total");
          ob.focus();
          return false;
        }
        values.HEURE_CENTRE = null;
        values.HEURE_ENTREPRISE = null;
      }
      // 0 formation entièrement présentielle :
      // le nombre d'heure centre reste obligatoire, mais l’heure minimum passe à 3
      // le nombre d'heure entreprise reste obligatoire, l'utilisateur peut indiquer 0 (comme actuellement)
      // le nombre d'heure totale ne peut plus être saisi, c'est la somme des 2
      else { //if(values.ID_MODALITE_ENSEIGNEMENT == 0) 
        if(values.HEURE_CENTRE == null || values.HEURE_CENTRE < 3) {
          alert(`Le nombre d'heures en centre est obligatoire et doit ête supérieur à 2.`);
          var ob = document.getElementById("HEURE_CENTRE");
          ob.focus();
          return false;
        }
        if(values.HEURE_ENTREPRISE == null || values.HEURE_ENTREPRISE === '') { // attention bien faire ===, avec == '', 0 est évalué comme vraie
          alert(`Le nombre d'heures en entreprise est obligatoire, vous pouvez indiquer 0 s'il n'y a pas d'heure en entreprise.`);
          var ob = document.getElementById("HEURE_ENTREPRISE");
          ob.focus();
          return false;
        }
        values.nombre_heures_total = (parseInt(values.HEURE_CENTRE) + parseInt(values.HEURE_ENTREPRISE));

        if(global.config.modeDebugApp == 1) console.log("nombre_heures_total calculé : ", values.nombre_heures_total);
      }

      if(values.DUREE_INDICATIVE != null && values.DUREE_INDICATIVE.length > 1 && !isNaN(values.DUREE_INDICATIVE)) { // verif si c'est un entier (Number.isInteger(values.DUREE_INDICATIVE) nok car on a  une string de base)
        alert(`La durée indicative ne peut se résumer à un chiffre, veuillez indiquer une unité.`);
        var ob = document.getElementById("DUREE_INDICATIVE");
        ob.focus();
        return false;
      }
      if(modifPublics == true) {
        if(publicsSelectionnes.length > global.config.nbPublicViseMaxParAction) {
          alert(`Vous ne pouvez pas sélectionner plus de ${global.config.nbPublicViseMaxParAction} publics visés.`);
          return false;
        }
        else if(publicsSelectionnes.length == 0) {
          alert(`Vous devez sélectionner au moins un public visé.`);
          return false;
        }
      }
      else if(tabObjPublicPreselectionnes.length == 0){ // si pas de modif on doit verifier qu'il y en avait au moins un au chargement
        alert(`Vous devez sélectionner au moins un public visé.`);
        return false;
      }
      if(modifModalitesPeda == true) {
        if(modalPedaSelectionne.length > global.config.nbModalitesPedaMaxParAction) {
          alert(`Vous ne pouvez pas sélectionner plus de ${global.config.nbModalitesPedaMaxParAction} modalités pédagogiques.`);
          return false;
        }
      }
      if(modifLangues == true) {
        if(languesSelectionnees.length > global.config.nbLanguesMaxParAction) {
          alert(`Vous ne pouvez pas sélectionner plus de ${global.config.nbLanguesMaxParAction} langues.`);
          return false;
        }
        else if(languesSelectionnees.length == 0) {
          alert(`Vous devez sélectionner au moins 1 langue.`);
          return false;
        }
      }

      // L'utilisateur ne peut:
      //mettre une durée inférieure à 35h si "temps plein"				
      //mettre une durée  supérieur à 35h si "temps partiel"				
      //indiquer "temps plein" si la durée inférieure à 35h 				
      //indiquer "temps partiel" si la durée superieure à 35h 				
      // ID_RYTHME_FORMATION :
      //2 = Temps plein continu 5 = Temps plein discontinu
      // 3 = Temps partiel continu 4 = Temps partiel discontinu
      /*if((values.ID_RYTHME_FORMATION == 2 || values.ID_RYTHME_FORMATION == 5) && values.HEURES_HEBDOMADAIRES != null && values.HEURES_HEBDOMADAIRES < 35) {
        alert(`Vous ne pouvez pas indiquer un rythme de la formation "plein" et indiquer une durée hebdomadaire de la formation inférieure à 35h.`);
        var ob = document.getElementById("ID_RYTHME_FORMATION");
        ob.focus();
        return false;
      }
      if((values.ID_RYTHME_FORMATION == 3 || values.ID_RYTHME_FORMATION == 4) && values.HEURES_HEBDOMADAIRES != null && values.HEURES_HEBDOMADAIRES > 35) {
        alert(`Vous ne pouvez pas indiquer un rythme de la formation "partiel" et indiquer une durée hebdomadaire de la formation supérieure à 35h.`);
        var ob = document.getElementById("ID_RYTHME_FORMATION");
        ob.focus();
        return false;
      }*/

      //if(values.ID_PCF_INFORMATION == "") values.ID_PCF_INFORMATION = null; // conventionnement nombre_heures_totatl... utile pour les select non obligatoire sinon génère une erreur, aurait pu faire aussi delete person.age mais la gestion coté api serait à faire
      for(let i in values) if(values[i] === "") values[i] = null; // sinon génère une erreur

      // gestion des champs bouléens oui/non qui doivent avoir true ou false (alimentés par optsOuiNon)
      values.NIVEAU_ENTREE_OBLIGATOIRE = (values.NIVEAU_ENTREE_OBLIGATOIRE == "1") ? true : false; 
      values.PRISE_EN_CHARGE_FRAIS_POSSIBLE = (values.PRISE_EN_CHARGE_FRAIS_POSSIBLE == "1") ? true : false; 
      values.conventionnement = (values.conventionnement == "1") ? true : false; 

      values.date_information1 = dateModifCharAmericAvecSepToAmericSansSep(values.date_information1); // conversion des dates au format attendu
      values.date_information2 = dateModifCharAmericAvecSepToAmericSansSep(values.date_information2);
      values.date_information3 = dateModifCharAmericAvecSepToAmericSansSep(values.date_information3);

      if(global.config.modeDebugApp == 1) console.log("Values:::", values);
      if(global.config.modeDebugApp == 1) console.log("Values:::", JSON.stringify(values));

      setDataLoaded(false);  // utile pour pas que l'utilisateur soumette plusieurs fois attention provoquait une réinit des données donc plus aucune donnée
      setAumoinsUneModif(false);


      var dataR = await apiActModif(utilisateurBearer, values, actionNumber) // on envoie sans faire JSON.stringify
      if(global.config.modeDebugApp == 1) console.log('retour apiActModif : ', dataR);

      switch (dataR.statusCode) {
          case 200: 
              break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              return false;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut + " [code : A2]"); // Problème technique par défaut
              return false;
      }

      if (modifPublics == true) {
        // on adapte le tableau d'objet qu'on va envoyer pour qu'il ait les 2 propriétés attendues ID_ACTIONS_SESSIONS et ID_LHEO_PUBLIC_VISE
        var publicsAenregistrer = [];
        publicsSelectionnes.forEach((el) => {
          let ligne = {'ID_ACTIONS_SESSIONS': actionNumber, 'ID_LHEO_PUBLIC_VISE':  el.value}
          publicsAenregistrer.push(ligne);  
        });
        if(global.config.modeDebugApp == 1) console.log("publicsAenregistrer : ", publicsAenregistrer);

        // on associe les codes publics
        var dataR = await apiPutActObjs(utilisateurBearer, "public", actionNumber, publicsAenregistrer);
        if(global.config.modeDebugApp == 1) console.log('retour apiPutActObjs avec public : ', dataR);
        
        switch (dataR.statusCode) {
            case 200:
                if (dataR.dataApi < 1) { // doit y avoir au moins un public !
                    setMessageProbleme(global.config.msgPrTechnique.msgDefaut + " [code : A3]"); // Problème technique par défaut
                    return false;
                }
              break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
                return false;
            case 400:
                setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "l'action ne peut être associée à plus de XXX publics"
                return false;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut + " [code : A4]"); // Problème technique par défaut
                return false;
        }
      }

      if (modifModalitesPeda == true) {
        // on adapte le tableau d'objet qu'on va envoyer pour qu'il ait les 2 propriétés attendues ID_ACTIONS_SESSIONS et CODE_FORMACODE
        var modalPedaAenregistrer = [];
        modalPedaSelectionne.forEach((el) => {
          let ligne = {'ID_ACTIONS_SESSIONS': actionNumber, 'CODE_FORMACODE':  el.value}
          modalPedaAenregistrer.push(ligne);  
        });
        if(global.config.modeDebugApp == 1) console.log("modalPedaAenregistrer", modalPedaAenregistrer);

        // on associe les modalités pédagogiques
        var dataR = await apiPutActObjs(utilisateurBearer, "modal-peda", actionNumber, modalPedaAenregistrer);
        if(global.config.modeDebugApp == 1) console.log('retour apiPutActObjs avec modal-peda : ', dataR);
        
        switch (dataR.statusCode) {
            case 200:
                /*if (dataR.dataApi < 1) { //nok car il peut y avoir 0 si l'utilisateur déselectione tout, c'est optionnel
                    setMessageProbleme(global.config.msgPrTechnique.msgDefaut + " [code : A5]"); // Problème technique par défaut
                    return false;
                }*/
              break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
                return false;
            case 400:
                setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "l'action ne peut être associée à plus de XXX modalités pédagogiques"
                return false;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut + " [code : A6]"); // Problème technique par défaut
                return false;
        }
      }

      if (modifLangues == true) {
        // on adapte le tableau d'objet qu'on va envoyer pour qu'il ait les 2 propriétés attendues ID_ACTIONS_SESSIONS et CODE_LANGUES
        var languesAenregistrer = [];
        languesSelectionnees.forEach((el) => {
          let ligne = {'ID_ACTIONS_SESSIONS': actionNumber, 'CODE_LANGUES':  el.value}
          languesAenregistrer.push(ligne);  
        });
        if(global.config.modeDebugApp == 1) console.log("languesAenregistrer : ", languesAenregistrer);

        // on associe les langues
        var dataR = await apiPutActObjs(utilisateurBearer, "langue", actionNumber, languesAenregistrer);
        if(global.config.modeDebugApp == 1) console.log('retour apiPutActObjs avec langue : ', dataR);
        
        switch (dataR.statusCode) {
            case 200:
                if (dataR.dataApi < 1) { // doit y avoir au moins une langue !
                    setMessageProbleme(global.config.msgPrTechnique.msgDefaut + " [code : A7]"); // Problème technique par défaut
                    return false;
                }
              break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
                return false;
            case 400:
                setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "l'action ne peut être associée à plus de XXX modalités pédagogiques"
                return false;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut + " [code : A8]"); // Problème technique par défaut
                return false;
        }
      }

      // si on arrive jusqu'ici c'est que tout s'est bien passé

      // pour que le systeme calcule la validité Lhéo après l'enregistrement....
      var retAppel = await appelGetSetInfosObjetsFormActSess(utilisateurBearer, formationNumber, actionNumber, null, props.SetInfosGenFormation, setMessageProbleme);

      if(retAppel == true) {
        //var objaAmodif = JSON.parse(JSON.stringify(props.infosGenFormation)); // on clone (duplique) l'objet pour que tout fonctionne bien
        //objaAmodif["etape5"] = "ok";
        //props.SetInfosGenFormation(objaAmodif);
        alert("L'enregistrement s'est bien effectué.\r\n\r\nVous allez maintenant être dirigé à l'étape suivante \"Formateur(s) de l'action\".");
        navigate(`/formation/etape6/${formationNumber}/action-${actionNumber}`);
      }
      else setMessageProbleme(global.config.msgPrTechnique.msgDefaut + " [code : A9]"); // Problème technique par défaut
      setDataLoaded(true);  // utile pour pas que l'utilisateur soumette plusieurs fois
    };
  
    const onError = (error) => {
      console.log("ERROR:::", error);
    };
    /// eslint-disable-next-line no-unused-vars

    //const [dataRYTHME_FORMATION, SetDataRYTHME_FORMATION] = useState(); // pour recup données du select
    const [dataLHEO_MODALITE_ENSEIGNEMENT, SetDataLHEO_MODALITE_ENSEIGNEMENT] = useState();// pour recup données du select

    const [dataLHEO_PUBLIC_VISE, SetDataLHEO_PUBLIC_VISE] = useState(); // ici la cible ne contiendra pas les données brutes de l'api mais un tab d'objet, ex : 0: Object { value: 81099, label: "Autre public" }
    const [tabObjPublicPreselectionnes, SetTabObjPublicPreselectionnes] = useState(); // pour que le reactselect affiche ce qui a déjà été selectionné

    const [dataMODALITES_PEDAGOGIQUES, SetDataMODALITES_PEDAGOGIQUES] = useState(); // ici la cible ne contiendra pas les données brutes de l'api mais un tab d'objet ,ex : 0: Object { value: 81099, label: "Autre public" } 
    const [tabObjModalPedaPreselectionnes, SetTabObjModalPedaPreselectionnes] = useState(); // pour que le reactselect affiche ce qui a déjà été selectionné

    const [dataLANGUES, SetDataLANGUES] = useState(); // ici la cible ne contiendra pas les données brutes de l'api mais un tab d'objet
    const [tabObjLanguesPreselectionnes, SetTabObjLanguesPreselectionnes] = useState(); // pour que le reactselect affiche ce qui a déjà été selectionné
    
    
    const [lieuxPossibles, SetLieuxPossibles] = useState(null); 
    async function recupLieux() {
        const dataR = await apiRecupereInfoGet(utilisateurBearer, "lieux/lieux-pour-info");
        if(global.config.modeDebugApp == 1) console.log(`+++++++++++++++++++++retour apiRecupereInfoGet avec "lieux/lieux-pour-info" : `, dataR);

        switch (dataR.statusCode) {
            case 200:
              if(dataR.dataApi != null) {
                // on adapte les données pour qu'elles soient ok pour le select (tableau d'objet {'id': XXX, 'lib': XXX})
                var pourSelect = [];
                dataR.dataApi.forEach((el) => {
                    let ligne = {'id': el.ID_PCF, 'lib':  el.lib_concatene};
                    pourSelect.push(ligne);
                });
                SetLieuxPossibles(pourSelect);
              }
              return true;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut + " [code : A10]"); // Problème technique par défaut
            return false;
        }
    }

    async function chargementsConsecutifs() {
      setDataLoaded(false);
      var pb = false;
      var codeErreur = '';

      // pour que le systeme calcule la validité Lhéo.... au chargement de cette page, permet de préciser le nb de sessions actuel pour l'action en cours
      var retAppel = await appelGetSetInfosObjetsFormActSess(utilisateurBearer, formationNumber, actionNumber, null, props.SetInfosGenFormation, setMessageProbleme);
      if(retAppel != true) {pb = true; codeErreur = "11a;"}

      if(pb == false) {
        let retP = await recupererInfosPrincipales();
        if(retP != true) {pb = true; codeErreur = "11b;"}
      }

      /*if(pb == false) {
        var ret = await appelRecupInfoGet(utilisateurBearer, "RYTHME_FORMATION", setMessageProbleme);// recuperation des données pour la liste du select du form
        if(ret != null) SetDataRYTHME_FORMATION(ret); else {pb = true; codeErreur = "11c;"}
      }*/
      if(pb == false) {
        var retLieu = await recupLieux(); // ces 2 lignes sont pour le choix du lieu d'information
        if(retLieu != true) {pb = true; codeErreur = "11d;"}
      }
      if(pb == false) {
        // recuperation des données pour la liste des publics, react-select attend un tableau d'objet, ex : 0: Object { value: 81099, label: "Autre public" }
        // on recupere aussi les objets préselectionnés (tabObjPublicPreselectionnes)
        var ret = await appelRecupInfoGet(utilisateurBearer, "LHEO_PUBLIC_VISE", setMessageProbleme);
        if(ret != null) {
          var publicsPourSelect = [];
          var preselectionnes = [];
          ret.forEach((el) => {
              let ligne = {'value': el.id, 'label':  el.lib};
              publicsPourSelect.push(ligne);
              tabPublicsRecuperes.forEach((elPreselectionnes) => {
                  if (elPreselectionnes.ID_LHEO_PUBLIC_VISE ==  el.id)  preselectionnes.push(ligne);
              });
          });
          //console.log(publicsPourSelect);
          //console.log("les publics preselectionnes :",  preselectionnes);
          SetDataLHEO_PUBLIC_VISE(publicsPourSelect); // attention ici la cible ne contiendra pas les données brutes de l'api mais un tab d'objet,ex : 0: Object { value: 81099, label: "Autre public" }
          //if(tabPublicsRecuperes.length > 0) 
          SetTabObjPublicPreselectionnes(preselectionnes);
        } else {pb = true; codeErreur = "11e;"}
      }

      // idem pour les modalités pedagogiques
      if(pb == false) {
        var ret = await appelRecupInfoGet(utilisateurBearer, "MODALITES_PEDAGOGIQUES", setMessageProbleme);
        if(ret != null) {
          var modalitesPourSelect = [];
          var preselectionnes = [];
          ret.forEach((el) => {
              let ligne = {'value': el.id, 'label':  el.lib};
              modalitesPourSelect.push(ligne);
              tabModalitesPedaRecuperes.forEach((elPreselectionnes) => {
                  if (elPreselectionnes.CODE_FORMACODE ==  el.id)  preselectionnes.push(ligne);
              });
          });
          //console.log("modalitesPourSelect :",  modalitesPourSelect);
          //console.log("les modalités pédagogiques preselectionnes :",  preselectionnes);
          SetDataMODALITES_PEDAGOGIQUES(modalitesPourSelect); // attention ici la cible ne contiendra pas les données brutes de l'api mais un tab d'objet
          if(tabModalitesPedaRecuperes.length > 0) SetTabObjModalPedaPreselectionnes(preselectionnes);
        } else {pb = true; codeErreur = "11f;"}
      }

      // idem pour les langues
      if(pb == false) {
        var ret = await appelRecupInfoGet(utilisateurBearer, "LANGUE_ISO_639_1", setMessageProbleme);
        if(ret != null) {
          var languesPourSelect = [];
          var preselectionnes = [];
          ret.forEach((el) => {
              let ligne = {'value': el.id, 'label':  el.lib};
              languesPourSelect.push(ligne);
              tabLanguesRecuperes.forEach((elPreselectionnes) => {
                  if (elPreselectionnes.CODE_LANGUES ==  el.id)  preselectionnes.push(ligne);
              });
          });
          SetDataLANGUES(languesPourSelect); // attention ici la cible ne contiendra pas les données brutes de l'api mais un tab d'objet
          if(tabLanguesRecuperes.length > 0) SetTabObjLanguesPreselectionnes(preselectionnes);
        } else {pb = true; codeErreur = "11g;"}
      }

      if(pb == false) {
        var ret = await appelRecupInfoGet(utilisateurBearer, "LHEO_MODALITE_ENSEIGNEMENT", setMessageProbleme);
        if(ret != null) SetDataLHEO_MODALITE_ENSEIGNEMENT(ret); else {pb = true; codeErreur = "11h;"}
      }

      if(pb == true) setMessageProbleme(global.config.msgPrTechnique.msgDefaut + " [code : " + codeErreur + "]"); // Problème technique par défaut

      setDataLoaded(true);
    }

    useEffect(() => {
        if(global.config.modeDebugApp == 1) console.log('useffect FormEtape5')
        document.title = `Edition de l'action ${actionNumber}`;
        if(props.infosGenFormation.LIB_ACTIONS_FORMATION != "" && props.infosGenFormation.LIB_ACTIONS_FORMATION != null) {
          document.title += ` de la formation "${props.infosGenFormation.LIB_ACTIONS_FORMATION}" ${formationNumber}`;
        }
        setMessageProbleme(null);
        chargementsConsecutifs();

        var links = document.querySelectorAll('a');
         
        // Parcours des liens pour ajouter gestionnaire d'événement au clic
        for (var i = 0; i < links.length; i++) {
          links[i].addEventListener('click', confirmationSiCLickSUrLien);
        }
        // Nettoyage de l'écouteur d'événement lorsque le composant est démonté
        return () => {      
          for (var i = 0; i < links.length; i++) {
            links[i].removeEventListener('click', confirmationSiCLickSUrLien);
          }
        };
    }, []);

    useEffect(() => {
      setAumoinsUneModif(false);
    }, [dataApi]);
  

    // pour react-select
    const [publicsSelectionnes, setPublicsSelectionnes] = useState(); // sera vide si l'utilisateur n'a pas fait de modif, tableau d'objet: 0: Object { value: 81099, label: "Autre public" }
    const [modifPublics, setModifPublics] = useState(false);
    function handleChangeSelectPublic (selectedOptions)  {
      setPublicsSelectionnes(selectedOptions);
      setModifPublics(true);
      setAumoinsUneModif(true);
      //console.log(`Options selected:`, selectedOptions);
    }
    const [modalPedaSelectionne, setModalPedaSelectionne] = useState(); // sera vide si l'utilisateur n'a pas fait de modif, tableau d'objet
    const [modifModalitesPeda, setModifModalitesPeda] = useState(false);
    function handleChangeSelectModalPeda (selectedOptions)  {
      setModalPedaSelectionne(selectedOptions);
      setModifModalitesPeda(true);
      setAumoinsUneModif(true);
    }
    
    const [languesSelectionnees, setLanguesSelectionnees] = useState(false);
    const [modifLangues, setModifLangues] = useState(false);
    function handleChangeSelectLangue (selectedOptions)  {
      setLanguesSelectionnees(selectedOptions);
      setModifLangues(true);

      setAumoinsUneModif(true);
    }
    // fin pour react-select
    
    const renderTooltip = (aide) => (
        <Tooltip id="button-tooltip">
          <div dangerouslySetInnerHTML={{ __html:  aide }}></div>
        </Tooltip>
    );

    return (
      <>
        <LoadingSpinner dataLoaded={dataLoaded}/>
        <MessageErreurApi message={messageProbleme} />
{/* pas utile en principe         && dataLHEO_MODALITE_ENSEIGNEMENT && dataLHEO_MODALITE_ENSEIGNEMENT != null && */}
        { dataLoaded && dataApi && dataApi != null && props.infosProprietes != null && messageProbleme == null &&
        <Container className="my-0">
            {/*publics && publics.map(({ code, libelle }, index) => (
                    <span >{code} {libelle}</span>
            ))*/}

            <FormRHF onSubmit={onSubmit} onError={onError} dataApi={dataApi} gestionEvenements={gestionEvenements} >
              {props.infosProprietes["ACTIONS_SESSIONS|other_dataEditable"].editable == 1 &&
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <div style={{marginTop:"10px", marginBottom:"20px", minWidth: "700px"}}>
                    <MessageInfoChampsObligatoires />
                  </div>
                  <div>
                    <ButtonDeBase typeSubmit={true} lib="Enregistrer" disabled={aumoinsUneModif == true ? false : true} />
                  </div>
                </div>
              }
              {props.infosProprietes["ACTIONS_SESSIONS|other_dataEditable"].editable == 0 && props.infosProprietes["ACTIONS_SESSIONS|other_dataEditable"].aide != "" &&
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <MessageInfoBox message={props.infosProprietes["ACTIONS_SESSIONS|other_dataEditable"].aide} />
                </div>
              }

              <Form.Group className="mb-3 required">
                  <Form.Label>{props.infosProprietes["ACTIONS_SESSIONS|other_dataLHEO_PUBLIC_VISE"].lib_form}</Form.Label>
                  {props.infosProprietes["ACTIONS_SESSIONS|other_dataLHEO_PUBLIC_VISE"].aide &&
                  <OverlayTrigger  placement="right" overlay={renderTooltip(props.infosProprietes["ACTIONS_SESSIONS|other_dataLHEO_PUBLIC_VISE"].aide)} > 
                            <button className="simply" type='button'><span className="fas fa-circle-question"></span></button>
                  </OverlayTrigger>
                  }
                  {dataLHEO_PUBLIC_VISE && dataLHEO_PUBLIC_VISE.length > 0 &&
                  <Select
                    isDisabled= {props.infosProprietes["ACTIONS_SESSIONS|other_dataLHEO_PUBLIC_VISE"].editable == 0 && "disabled"}
                    onChange={handleChangeSelectPublic}
                    closeMenuOnSelect={false}   
                    defaultValue={tabObjPublicPreselectionnes}
                    isMulti
                    options={dataLHEO_PUBLIC_VISE} />
                  }
              </Form.Group>

              <FormControl id="info_public_vise" infosProp={props.infosProprietes["ACTIONS_SESSIONS|info_public_vise"]} />


              {/*<FormCheck id="NIVEAU_ENTREE_OBLIGATOIRE" infosProp={props.infosProprietes["ACTIONS_SESSIONS|NIVEAU_ENTREE_OBLIGATOIRE"]} typeCheckbox="switch" /> */}
              <FormSelect id="NIVEAU_ENTREE_OBLIGATOIRE" infosProp={props.infosProprietes["ACTIONS_SESSIONS|NIVEAU_ENTREE_OBLIGATOIRE"]} options={global.config.optsOuiNon} />

              {dataApi.ACCES_CONTRAT_PRO == true  && <FormControl id="MODALITES_ALTERNANCE" infosProp={props.infosProprietes["ACTIONS_SESSIONS|MODALITES_ALTERNANCE"]} />}

              {/*<FormSelect id="ID_RYTHME_FORMATION" infosProp={props.infosProprietes["ACTIONS_SESSIONS|ID_RYTHME_FORMATION"]} options={dataRYTHME_FORMATION} />*/}
              <FormControl id="rythme_formation" infosProp={props.infosProprietes["ACTIONS_SESSIONS|rythme_formation"]} />

              <FormControl id="CONDITIONS_SPECIFIQUES" infosProp={props.infosProprietes["ACTIONS_SESSIONS|CONDITIONS_SPECIFIQUES"]} />
              {/*<FormCheck id="PRISE_EN_CHARGE_FRAIS_POSSIBLE" infosProp={props.infosProprietes["ACTIONS_SESSIONS|PRISE_EN_CHARGE_FRAIS_POSSIBLE"]} typeCheckbox="checkbox" /> */}
              <FormSelect id="PRISE_EN_CHARGE_FRAIS_POSSIBLE" infosProp={props.infosProprietes["ACTIONS_SESSIONS|PRISE_EN_CHARGE_FRAIS_POSSIBLE"]} options={global.config.optsOuiNon} />

              <FormSelect id="MODALITES_ENTREES_SORTIES" infosProp={props.infosProprietes["ACTIONS_SESSIONS|MODALITES_ENTREES_SORTIES"]} options={global.config.optsDictModalitesEntreesSorties} />

              <Form.Group className="mb-3 required">
                  <Form.Label>{props.infosProprietes["ACTIONS_SESSIONS|other_dataLANGUES"].lib_form}</Form.Label>
                  {props.infosProprietes["ACTIONS_SESSIONS|other_dataLANGUES"].aide &&
                  <OverlayTrigger  placement="right" overlay={renderTooltip(props.infosProprietes["ACTIONS_SESSIONS|other_dataLANGUES"].aide)} > 
                            <button className="simply" type='button'><span className="fas fa-circle-question"></span></button>
                  </OverlayTrigger>
                  }

                  {dataLANGUES && dataLANGUES.length > 0 &&
                  <Select
                    isDisabled= {props.infosProprietes["ACTIONS_SESSIONS|other_dataLANGUES"].editable == 0 && "disabled"}
                    onChange={handleChangeSelectLangue}
                    closeMenuOnSelect={false}   
                    defaultValue={tabObjLanguesPreselectionnes}
                    isMulti
                    options={dataLANGUES} />
                  }
              </Form.Group>

              <FormSelect id="ID_MODALITE_ENSEIGNEMENT" infosProp={props.infosProprietes["ACTIONS_SESSIONS|ID_MODALITE_ENSEIGNEMENT"]} options={dataLHEO_MODALITE_ENSEIGNEMENT} />

              {!isaDistance  && 
                <FormControl id="HEURE_CENTRE" infosProp={props.infosProprietes["ACTIONS_SESSIONS|HEURE_CENTRE"]} style=" inline-input" />
              }
              {!isaDistance  && 
                <FormControl id="HEURE_ENTREPRISE" infosProp={props.infosProprietes["ACTIONS_SESSIONS|HEURE_ENTREPRISE"]} style=" inline-input" />
              }
              {isaDistance  && 
              <FormControl id="nombre_heures_total" infosProp={props.infosProprietes["ACTIONS_SESSIONS|nombre_heures_total"]} style=" inline-input" />
              }

              <FormControl id="DUREE_INDICATIVE" infosProp={props.infosProprietes["ACTIONS_SESSIONS|DUREE_INDICATIVE"]}/>

              <Form.Group className="mb-3" >
                  <Form.Label>{props.infosProprietes["ACTIONS_SESSIONS|other_dataMODALITES_PEDAGOGIQUES"].lib_form}</Form.Label>
                  {props.infosProprietes["ACTIONS_SESSIONS|other_dataMODALITES_PEDAGOGIQUES"].aide &&
                  <OverlayTrigger  placement="right" overlay={renderTooltip(props.infosProprietes["ACTIONS_SESSIONS|other_dataMODALITES_PEDAGOGIQUES"].aide)} > 
                            <button className="simply" type='button'><span className="fas fa-circle-question"></span></button>
                  </OverlayTrigger>
                  }

                  {dataMODALITES_PEDAGOGIQUES && dataMODALITES_PEDAGOGIQUES.length > 0 &&
                  <Select
                    isDisabled= {props.infosProprietes["ACTIONS_SESSIONS|other_dataMODALITES_PEDAGOGIQUES"].editable == 0 && "disabled"}
                    onChange={handleChangeSelectModalPeda}
                    closeMenuOnSelect={false}   
                    defaultValue={tabObjModalPedaPreselectionnes}
                    isMulti
                    options={dataMODALITES_PEDAGOGIQUES} />
                  }
              </Form.Group>

              <FormControl id="MODALITES_PEDAGOGIQUE" infosProp={props.infosProprietes["ACTIONS_SESSIONS|MODALITES_PEDAGOGIQUE"]} />
              {/* ne pas afficher la durée du cycle si c'est de l'apprentissage */}
              {props.infosGenFormation.act_is_apprentissage == true && 
                <FormControl id="DUREE_CYCLE" infosProp={props.infosProprietes["ACTIONS_SESSIONS|DUREE_CYCLE"]} />
              }

              <FormSelect id="ID_PCF_INFORMATION" infosProp={props.infosProprietes["ACTIONS_SESSIONS|ID_PCF_INFORMATION"]} options={lieuxPossibles} />     

              <FormControl id="date_information1" infosProp={props.infosProprietes["ACTIONS_SESSIONS|date_information1"]} style=" inline-input" />
              {dateInfo2Visible &&
                <FormControl id="date_information2" infosProp={props.infosProprietes["ACTIONS_SESSIONS|date_information2"]} style=" inline-input" />
              }
              {dateInfo3Visible &&
                <FormControl id="date_information3" infosProp={props.infosProprietes["ACTIONS_SESSIONS|date_information3"]} style=" inline-input" />
              }
         

              <FormControl id="RESTAURATION" infosProp={props.infosProprietes["ACTIONS_SESSIONS|RESTAURATION"]} />
              <FormControl id="HEBERGEMENT" infosProp={props.infosProprietes["ACTIONS_SESSIONS|HEBERGEMENT"]} />
              <FormControl id="TRANSPORT" infosProp={props.infosProprietes["ACTIONS_SESSIONS|TRANSPORT"]} />
              <FormControl id="ACCES_HANDICAPES" infosProp={props.infosProprietes["ACTIONS_SESSIONS|ACCES_HANDICAPES"]} />
              <FormControl id="FRAIS_RESTANTS" infosProp={props.infosProprietes["ACTIONS_SESSIONS|FRAIS_RESTANTS"]} />
              
              <FormControl id="PRIX_HORAIRE_TTC" infosProp={props.infosProprietes["ACTIONS_SESSIONS|PRIX_HORAIRE_TTC"]} />
              <FormControl id="prix_total_TTC" infosProp={props.infosProprietes["ACTIONS_SESSIONS|prix_total_TTC"]}  />
{/* decimal pas géré*/}
              <FormControl id="DETAIL_CONDITION_PRISE_EN_CHARGE" infosProp={props.infosProprietes["ACTIONS_SESSIONS|DETAIL_CONDITION_PRISE_EN_CHARGE"]} />
              <FormControl id="INFOS_PERIMETRE_RECRUTEMENT" infosProp={props.infosProprietes["ACTIONS_SESSIONS|INFOS_PERIMETRE_RECRUTEMENT"]} />

              {/* partie conventionnement a ne pas afficher si c'est du non conv */}    
              {/*<FormCheck id="conventionnement" infosProp={props.infosProprietes["ACTIONS_SESSIONS|conventionnement"]} typeCheckbox="checkbox" />*/}        
              {props.infosGenFormation.eo_accessibilite != "noconv" && 
              <FormSelect id="conventionnement" infosProp={props.infosProprietes["ACTIONS_SESSIONS|conventionnement"]} options={global.config.optsOuiNon} />
              }
              {props.infosGenFormation.eo_accessibilite != "noconv" && <FormControl id="DUREE_CONVENTIONNEE" infosProp={props.infosProprietes["ACTIONS_SESSIONS|DUREE_CONVENTIONNEE"]} />}
              {props.infosGenFormation.eo_accessibilite != "noconv" && <FormControl id="NB_PLACES_FINANCEES" infosProp={props.infosProprietes["ACTIONS_SESSIONS|NB_PLACES_FINANCEES"]} />}

              {/* désactivé, géré dès le clique bouton permettant de créer une action
              <FormCheck id="ACCES_CONTRAT_PRO" infosProp={props.infosProprietes["ACTIONS_SESSIONS|ACCES_CONTRAT_PRO"]} typeCheckbox="checkbox" /> */}

              <FormControl id="HEURES_HEBDOMADAIRES" infosProp={props.infosProprietes["ACTIONS_SESSIONS|HEURES_HEBDOMADAIRES"]} />
              
              {props.infosProprietes["ACTIONS_SESSIONS|other_dataEditable"].editable == 1 &&
                <div className="partie-bouton-droite">
                  <ButtonDeBase typeSubmit={true} lib="Enregistrer" disabled={aumoinsUneModif == true ? false : true} />
                </div>
              }

              <FormHidden id="hidden_marque_une_modif" />
          </FormRHF>
        </Container>
        }

        <LiensPrecedentSuivant  formationNumber={formationNumber} actionNumber={actionNumber} numEtape={numEtape} infosGenFormation={props.infosGenFormation} notifBeCareful={aumoinsUneModif} />
      </>
    )

}

FormEtape5.propTypes = {
  formationNumber: PropTypes.number.isRequired,
  SetInfosGenFormation : PropTypes.func,
  infosGenFormation: PropTypes.object,
}

export default FormEtape5;