import { useEffect} from 'react';
import { NavLink } from "react-router-dom";

/**
 * Return Page Cgv
 *
 * @component
 * @summary Cgv
 * @param {  }
 * @return { HTMLElement }
*/
function Cgv() {
    useEffect(() => {
      document.title = "Cgv";
    }, []);

    return (
        <>
            <div className='logo-login'>
            <NavLink to="/"><img src={`${process.env.PUBLIC_URL}/img/logo_CarifOref.png`} alt="Carif Oref Paca" className="feature-icon" /></NavLink>
            </div>
            <div className='cgv-conteneur'>


<div className='titre'>
    <strong>Conditions G&eacute;n&eacute;rales d'Utilisation<br />de la Base de donn&eacute;es R&eacute;gionale de l&rsquo;Offre de Formation<br />(BROF)</strong>
</div>

<div onClick={() => window.history.back()} className="normal-navig-item"><span className="fas fa-circle-chevron-left"></span>Retour à la page précédente</div>

<p className='preambule'>Pr&eacute;ambule</p>

<p>La BROF est la Base de donn&eacute;es R&eacute;gionale de l'Offre de Formation en r&eacute;gion Provence - Alpes - C&ocirc;te d'Azur.</p>
<p>Elle permet aux Organismes de formation de r&eacute;f&eacute;rencer et de mettre &agrave; jour leurs donn&eacute;es pour apporter une meilleure visibilit&eacute; de leur offre de formation aupr&egrave;s des diff&eacute;rents publics et institutions concern&eacute;s.</p>
<p>Le site <a href="https://extranet-formation.cariforef.fr">extranet-formation.cariforef.fr</a> est &eacute;dit&eacute; et exploit&eacute; par le CARIF - OREF de la r&eacute;gion Provence - Alpes - C&ocirc;te d'Azur.</p>

<p><strong>GIP CARIF - OREF Provence - Alpes - C&ocirc;te d'Azur</strong></p>
<p>22 rue Sainte Barbe <br /> 13002 MARSEILLE</p>
<p>T&eacute;l&eacute;phone : 04 42 82 43 23</p>



<p className='titre-art'>Article 1 : D&eacute;finitions</p>
<p>Les termes utilis&eacute;s avec une majuscule au sein des pr&eacute;sentes Conditions G&eacute;n&eacute;rales d'Utilisation ont les significations suivantes :</p>
<p><strong>Conditions G&eacute;n&eacute;rales d'Utilisation ou CGU </strong>: D&eacute;signent le pr&eacute;sent document ainsi que ses versions successives.</p>
<p><strong>Contributeur </strong>: Toute personne travaillant au sein d'un Organisme de formation et disposant d'un droit d'acc&egrave;s &agrave; la BROF.</p>
<p><strong>Organisme de formation </strong>: Toute structure de droit priv&eacute; ou de droit public (soci&eacute;t&eacute;, association ou formateur ind&eacute;pendant) fran&ccedil;ais ou &eacute;tranger, qui dispense des formations professionnelles en application de conventions ou de contrats de formation professionnelle et, pour les organismes de formation fran&ccedil;ais, titulaire d'un num&eacute;ro de d&eacute;claration d'activit&eacute; attribu&eacute; par la DREETS.</p>

<img className='addr_et_ct_carif' src={`${process.env.PUBLIC_URL}/img/addr_et_ct_carif.png`} alt="Carif Oref Paca" />

<p><strong>Personne habilit&eacute;e </strong>: Tout Utilisateur ou Contributeur.</p>

<p><strong>Utilisateur </strong>: Toute personne physique ou morale, publique ou priv&eacute;e qui visite ou utilise la BROF</p>
<p>(y compris les Organismes de formation).</p>


<p className='titre-art'>Article 2 : Objet</p>
<p>Les pr&eacute;sentes Conditions G&eacute;n&eacute;rales d'Utilisation ont pour objet de d&eacute;finir les modalit&eacute;s d'acc&egrave;s et d'utilisation de l'outil BROF <a href="https://extranet-formation.cariforef.fr">extranet-formation.cariforef.fr</a> par les Organismes de formation.</p>
<p>L'acc&egrave;s &agrave; la BROF implique n&eacute;cessairement l'acceptation sans r&eacute;serve des pr&eacute;sentes CGU par tout Utilisateur ainsi que le respect des dispositions l&eacute;gales et r&eacute;glementaires en vigueur.</p>


<p className='titre-art'>Article 3 : Acc&egrave;s &agrave; la BROF PACA</p>
<p className='sst-art'>3.1 - Conditions d'acc&egrave;s</p>
<p>L'Utilisateur doit disposer d'un compte afin d'acc&eacute;der &agrave; la BROF. Seuls les Organismes de formation justifiant d'une activit&eacute; de formation en r&eacute;gion Provence - Alpes - C&ocirc;te d'Azur (si&egrave;ge social, &eacute;tablissement ou, &agrave; d&eacute;faut, r&eacute;alisation d'actions de formation dans la r&eacute;gion Provence - Alpes - C&ocirc;te d&rsquo;Azur) peuvent s'inscrire sur la BROF.</p>

<p className='sst-art'>3.2 - Cr&eacute;ation de compte</p>
<p>Lors de son inscription, un identifiant et un mot de passe seront attribu&eacute;s &agrave; chaque Utilisateur.</p>

<p className='sst-art'>3.3 - Changement et r&eacute;cup&eacute;ration de mot de passe</p>
<p>Si l'Utilisateur a perdu ou oubli&eacute; son mot de passe ou s'il souhaite le changer, il devra se rendre sur<br /> <a href="https://extranet-formation.cariforef.fr">extranet-formation.cariforef.fr</a> et cliquer sur &laquo; Mot de passe oubli&eacute; &raquo; puis suivre les indications.</p>

<p className='sst-art'>3.4 - Confidentialit&eacute; et s&eacute;curit&eacute; des identifiants et mots de passe</p>

<p>La saisie de l'identifiant et du mot de passe de la Personne habilit&eacute;e vaut preuve de sa connexion &agrave; son espace priv&eacute;. Cet identifiant et ce mot de passe sont strictement personnels et confidentiels. Ils seront demand&eacute;s &agrave; la Personne habilit&eacute;e &agrave; chacune de ses connexions &agrave; son espace priv&eacute;.</p>
<p>Afin d'assurer la s&eacute;curit&eacute; de la BROF, du syst&egrave;me d'information g&eacute;r&eacute; par le GIP CARIF - OREF Provence - Alpes - C&ocirc;te d&rsquo;Azur et d'&eacute;viter toute intrusion frauduleuse par des personnes non habilit&eacute;es, les mots de passe des Personnes habilit&eacute;es devront &ecirc;tre constitu&eacute;s d'au moins 8 caract&egrave;res.</p>
<p>Ils ne devront pas &ecirc;tre communiqu&eacute;s ni partag&eacute;s avec des tiers. La Personne habilit&eacute;e reconna&icirc;t &ecirc;tre le seul et unique responsable, &agrave; l'&eacute;gard de et/ou de toute autre personne (physique ou morale), de l'utilisation de ses &eacute;l&eacute;ments d'identification par des tiers ou des actions ou d&eacute;clarations faites par l'interm&eacute;diaire de la saisine de son identifiant et mot de passe sur son espace priv&eacute;.</p>


<p className='titre-art'>Article 4 : Engagement et responsabilit&eacute; de l'Organisme de formation</p>

<p className='sst-art'>4.1 - Mat&eacute;riel utilis&eacute;</p>
<p>Tout Utilisateur de la BROF reconna&icirc;t disposer de la comp&eacute;tence technique et des moyens n&eacute;cessaires pour y acc&eacute;der et l'utiliser. &Agrave; ce titre, l'Utilisateur reconna&icirc;t avoir v&eacute;rifi&eacute; que la configuration informatique qu'il utilise ne contient aucun virus, qu'elle est en parfait &eacute;tat de fonctionnement et qu'elle est &agrave; jour des derni&egrave;res versions de son navigateur.</p>
<p>En cons&eacute;quence, le GIP CARIF - OREF Provence - Alpes - C&ocirc;te d&rsquo;Azur ne pourra en aucun cas, &ecirc;tre tenu responsable de dysfonctionnements techniques ou mat&eacute;riels qui pourraient avoir directement pour origine une mauvaise manipulation, une n&eacute;gligence ou une d&eacute;faillance technique ou toute autre difficult&eacute; trouvant son origine chez l'Utilisateur.</p>

<p className='sst-art'>4.2 - Contenu</p>

<p>L'Organisme de formation est le seul et entier responsable de la qualit&eacute; et de la compl&eacute;tude des informations le concernant. &Agrave; ce titre, il s'engage &agrave; saisir des donn&eacute;es exactes, fiables, compl&egrave;tes et &agrave; les mettre r&eacute;guli&egrave;rement &agrave; jour.</p>
<p>L'Organisme de formation s'engage &eacute;galement &agrave; ce que les donn&eacute;es saisies ne portent pas atteinte aux droits des tiers et qu'elles ne soient pas illicites et/ou contraires &agrave; l'ordre public.</p>
<p>L'Organisme de formation s'engage &agrave; signaler au GIP CARIF - OREF Provence - Alpes - C&ocirc;te d&rsquo;Azur toutes les anomalies constat&eacute;es concernant ses propres donn&eacute;es.</p>

<p className='sst-art'>4.3 - Liens hypertextes</p>

<p>L'Organisme de formation a la possibilit&eacute; d'ins&eacute;rer des liens hypertextes dans le cadre de sa saisie d'informations sur la BROF. La responsabilit&eacute; du GIP CARIF - OREF Provence - Alpes - C&ocirc;te d&rsquo;Azur ne saurait cependant &ecirc;tre engag&eacute;e quant aux contenus des sites ainsi associ&eacute;s.</p>


<p className='titre-art'>Article 5 : R&ocirc;le et responsabilit&eacute; du GIP CARIF - OREF Provence - Alpes - C&ocirc;te d&rsquo;Azur</p>
<p>En mettant &agrave; disposition la BROF aux Organismes de formation, le GIP CARIF - OREF Provence - Alpes - C&ocirc;te d&rsquo;Azur permet &agrave; ces derniers de se d&eacute;clarer aupr&egrave;s du grand public et des acteurs emploi-formation - institutionnels ou priv&eacute;s et de g&eacute;rer les informations les concernant.</p>
<p>Dans le cadre de sa mission de collecte des informations relatives &agrave; l&rsquo;offre de formation en apprentissage et de formation professionnelle continue &agrave; destination des personnes sans emploi (<a href="https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000043688656">D&eacute;cret n&deg; 2021-792 du 22 juin 2021 relatif aux missions des centres d'animation, de ressources et d'information sur la formation - observatoires r&eacute;gionaux de l'emploi et de la formation</a>), le GIP CARIF - OREF Provence - Alpes - C&ocirc;te d&rsquo;Azur se r&eacute;serve le droit de contr&ocirc;ler, modifier si n&eacute;cessaire et/ou de demander les modifications &agrave; l'Organisme de formation afin qu'il apporte lui-m&ecirc;me des corrections.</p>
<p>En fonction des besoins et des demandes des pouvoirs publics, et sans qu'il ne puisse garantir la compl&eacute;tude des donn&eacute;es saisies, le GIP CARIF - OREF Provence - Alpes - C&ocirc;te d&rsquo;Azur se r&eacute;serve le droit d'int&eacute;grer lui-m&ecirc;me des donn&eacute;es concernant des Organismes de formation et des actions de formation.</p>
<p>Ne pouvant contr&ocirc;ler l'ensemble des sites internet sur lesquels la BROF renvoie par l'interm&eacute;diaire de liens<br /> hypertextes, le GIP CARIF - OREF Provence - Alpes - C&ocirc;te d&rsquo;Azur n'est en aucun cas responsable de leurs contenus et des cons&eacute;quences de leur utilisation.</p>
<p>Le GIP CARIF - OREF Provence - Alpes - C&ocirc;te d&rsquo;Azur, au travers de la BROF, met tout en &oelig;uvre pour offrir aux Utilisateurs des outils et/ou informations disponibles et v&eacute;rifi&eacute;es. Toutefois, le GIP CARIF - OREF Provence - Alpes - C&ocirc;te d&rsquo;Azur ne peut cependant garantir l&rsquo;enti&egrave;re exactitude et la pertinence de ces informations et ne saurait &ecirc;tre tenu responsable des erreurs ou inexactitudes de celles-ci.</p>
<p>De la m&ecirc;me fa&ccedil;on, l'attention de l'Utilisateur est sp&eacute;cifiquement attir&eacute;e sur le fait que la BROF, comme toute application informatique, est susceptible de dysfonctionnements, anomalies, erreurs ou interruptions. Par cons&eacute;quent le GIP CARIF - OREF Provence - Alpes - C&ocirc;te d&rsquo;Azur ne saurait &ecirc;tre tenu responsable d'une absence temporaire ou d&eacute;finitive de disponibilit&eacute; de fonctionnalit&eacute;s, ni de la pr&eacute;sence d'un virus sur son site.</p>


<p className='titre-art'>Article 6 : Utilisation des donn&eacute;es/Donn&eacute;es &agrave; caract&egrave;re personnel</p>
<p>Les informations communiqu&eacute;es par l'Utilisateur dans le cadre de l'acc&egrave;s et de l'utilisation de la BROF peuvent comporter des donn&eacute;es &agrave; caract&egrave;re personnel. Tout traitement de donn&eacute;es &agrave; caract&egrave;re personnel est soumis aux dispositions l&eacute;gales et r&eacute;glementaires en vigueur en mati&egrave;re de protection des donn&eacute;es &agrave; caract&egrave;re personnel.</p>
<p>Les informations recueillies sur la BROF (descriptifs des Organismes de formation et des actions de formation) sont destin&eacute;es &agrave; mettre en valeur et communiquer sur l&rsquo;offre de formation disponible en r&eacute;gion Provence-Alpes-C&ocirc;te d&rsquo;Azur.</p>
<p>&Agrave; ce titre, ces informations sont mises &agrave; disposition des professionnels emploi-formation, du grand public et des partenaires, institutionnels et priv&eacute;s, notamment par leur diffusion :</p>
<ul>
<li>Directement sur les sites <a href="https://www.orientation-regionsud.fr">www.orientation-regionsud.fr</a> et <a href="https://www.cariforef-provencealpescotedazur.fr/">www.cariforef-provencealpescotedazur.fr</a></li>
<li>Sur tous les syst&egrave;mes g&eacute;rant, &agrave; titre principal ou secondaire, des informations relatives &agrave; la formation.</li>
</ul>
<p>Le fondement juridique du traitement est votre consentement<em>.</em></p>
<p>L&rsquo;utilisation des donn&eacute;es issues de la BROF par ces syst&egrave;mes tiers est effectu&eacute;e sous leur enti&egrave;re responsabilit&eacute;. Par cons&eacute;quent, le Carif-Oref PACA ne peut &ecirc;tre tenu responsable de cette utilisation, tant totale que partielle.</p>
<p>Les informations recueillies sur la BROF peuvent &eacute;galement faire l&rsquo;objet d&rsquo;extractions &agrave; des fins statistiques et d&lsquo;information.</p>
<p>Les donn&eacute;es &agrave; caract&egrave;re personnel sont conserv&eacute;es pour une dur&eacute;e n&rsquo;exc&eacute;dant pas celle n&eacute;cessaire au regard des finalit&eacute;s de notre traitement.</p>
<p>Il n&rsquo;y a pas de transfert de vos donn&eacute;es en dehors de l&rsquo;union europ&eacute;enne.</p>
<p>Pour toute information sur l&rsquo;utilisation de vos donn&eacute;es ou pour exercer vos droits informatique et libert&eacute;s (droit d&rsquo;acc&egrave;s, droit d&rsquo;opposition, droit de suppression), vous pouvez contacter le d&eacute;l&eacute;gu&eacute; &agrave; la protection des donn&eacute;es du CARIF OREF Provence-Alpes-C&ocirc;te d'Azur (<a href="mailto:dpd@cariforef.fr">dpd@cariforef.fr</a>).</p>
<p>Vous disposez &eacute;galement de la facult&eacute; d'introduire une r&eacute;clamation aupr&egrave;s de la Commission nationale de l'informatique et des libert&eacute;s.</p>


<p className='titre-art'>Article 7 : Non-respect des Conditions G&eacute;n&eacute;rales d'Utilisation ou des dispositions l&eacute;gales et r&eacute;glementaires</p>
<p>En cas de non-respect des pr&eacute;sentes Conditions G&eacute;n&eacute;rales d'Utilisation ou de violation de dispositions l&eacute;gales et/ou r&eacute;glementaires avec ou par l'entremise de la BROF, le GIP CARIF - OREF Provence - Alpes - C&ocirc;te d&rsquo;Azur pourra prendre les mesures conservatoires qui s'imposent et autoris&eacute;es par la loi afin de faire respecter lesdites Conditions G&eacute;n&eacute;rales d'Utilisation.</p>

<p>Les parties s'engagent, en cas de difficult&eacute;s dans l'ex&eacute;cution du contrat et &agrave; soumettre leur diff&eacute;rend &agrave; une proc&eacute;dure amiable de conciliation.</p>

<p>&Agrave; ce titre, la partie qui souhaite mettre en jeu la proc&eacute;dure amiable de conciliation devra notifier &agrave; l'autre partie, par lettre recommand&eacute;e avec demande d'avis de r&eacute;ception, son intention de mettre en jeu ladite proc&eacute;dure en pr&eacute;cisant les difficult&eacute;s d'application rencontr&eacute;es. La proc&eacute;dure consisterait au recours &agrave; une tierce personne, d&eacute;cid&eacute;e par les 2 parties, de trancher le litige.</p>

<p>Pendant toute la p&eacute;riode durant laquelle de telles difficult&eacute;s seraient constat&eacute;es, les parties conviennent que la continuit&eacute; des prestations pr&eacute;vues aux Conditions d'utilisation l'emporte sur toute autre consid&eacute;ration.</p>

<p>&Agrave; d&eacute;faut d'accord intervenu entre les parties dans les deux mois suivant la notification vis&eacute;e plus haut, les parties retrouveront toute libert&eacute; d'action. Aucune tentative judiciaire ne serait recevable avant qu&rsquo;il y ait une proc&eacute;dure amiable.</p>



<p className='titre-art'>Article 8 : Modification/R&eacute;vision des Conditions G&eacute;n&eacute;rales d'Utilisation</p>
<p>Le GIP CARIF - OREF Provence - Alpes - C&ocirc;te d&rsquo;Azur se r&eacute;serve la possibilit&eacute; de modifier, &agrave; tout moment et sans pr&eacute;avis, les pr&eacute;sentes Conditions G&eacute;n&eacute;rales d'Utilisation afin notamment de les adapter aux &eacute;volutions l&eacute;gales et r&eacute;glementaires ou de faire &eacute;voluer les fonctionnalit&eacute;s propos&eacute;es.</p>
<p>Dans ce cas, les CGU modifi&eacute;es entrent en vigueur &agrave; la date de leur mise en ligne. L'Utilisateur est r&eacute;put&eacute; avoir accept&eacute; la derni&egrave;re version des CGU en vigueur. Tout refus, m&ecirc;me partiel, des pr&eacute;sentes CGU &eacute;quivaudra &agrave; une renonciation &agrave; tout usage de la BROF.</p>



<p className='titre-art'>Article 9 : Limitations de responsabilit&eacute;</p>

<p>Le GIP CARIF &ndash; OREF Provence - Alpes - C&ocirc;te d'Azur est responsable de la qualit&eacute; et de la v&eacute;racit&eacute; du contenu qu'il publie.</p>

<p>La BROF ne pourra &ecirc;tre tenu responsable des dommages directs et indirects caus&eacute;s au mat&eacute;riel de l'Utilisateur, lors de l'acc&egrave;s au site internet <a href="https://extranet-formation.cariforef.fr">extranet-formation.cariforef.fr</a>, et r&eacute;sultant soit de l'utilisation d'un mat&eacute;riel ne r&eacute;pondant pas aux sp&eacute;cifications indiqu&eacute;es au point 4, soit de l'apparition d'un bug ou d'une incompatibilit&eacute;.</p>

<p>Le GIP CARIF &ndash; OREF Provence - Alpes - C&ocirc;te d'Azur ne pourra &eacute;galement &ecirc;tre tenu responsable des dommages indirects (tels par exemple qu'une perte de march&eacute; ou perte d'une chance) cons&eacute;cutifs &agrave; la diffusion de l&rsquo;offre de formation d&rsquo;un Organisme de formation.</p>



<p className='titre-art'>Article 10 : Loi - Juridiction applicable</p>
<p>Les pr&eacute;sentes Conditions G&eacute;n&eacute;rales d'Utilisation sont soumises au droit fran&ccedil;ais. Il en est ainsi tant pour les r&egrave;gles de fond que pour les r&egrave;gles de forme.</p>
<p>Tout litige relatif &agrave; l'acceptation, l'ex&eacute;cution ou l'interpr&eacute;tation des pr&eacute;sentes rel&egrave;vera de la comp&eacute;tence des<br /> juridictions fran&ccedil;aises.</p>
<p>Aucune tentative judiciaire ne serait recevable avant la mise en &oelig;uvre d&rsquo;une proc&eacute;dure amiable, telle que d&eacute;crite dans l&rsquo;article 7.</p>
</div>

        </>
    )

}

export default Cgv;