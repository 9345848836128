import PropTypes from 'prop-types';
import { useEffect, useState, useContext,  useMemo } from 'react'; // useMemo pour Table.jsx
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { DonneesUtilisateurContext } from '../../contexte.js';

import { apiSessAssocDissoCertif } from '../../Datas/dataSessionsBloc.js'
import { apiSessAppelGetInfosDiv } from '../../Datas/dataSessions.js'

import LiensPrecedentSuivant from '../../Components/LiensPrecedentSuivant.jsx';
import LoadingSpinner from "../../Components/LoadingSpinner";
import MessageInfoBox, { MessageErreurApi, MessageInfoNoDroitErreurApi } from '../../Components/Messages.jsx';
import ButtonDeBase, { ButtonVoirFiche, ButtonSuppr } from '../../Components/ButtonsCustom.jsx';
import Table from '../../Components/Table.jsx';
import BlocsCompetences from '../../Components/BlocsCompetences.jsx';
import { appelGetSetInfosObjetsFormActSess, appelRecupInfoGet } from '../../fonctionsDiv.js';

/**
 * Return Formulaire étape 11 (certif préparées avec les blocs....) basé sur 13
 *
 * @component
 * @summary page étape 11
 * @param {  }
 * @return { HTMLElement }
*/
function FormEtape11(props) {
    let numEtape = 11;

    const formationNumber = props.formationNumber;
    const actionNumber = props.actionNumber;
    const sessionNumber = props.sessionNumber;

    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const [dataApi, setDataApi] = useState();
    const [aumoinsUneModif, setAumoinsUneModif] = useState(false); // pour avertir l'utilisateur en cas de sortie sans  enregistrement

    const [dataLoaded, setDataLoaded] = useState(true);
    const [messageProbleme, setMessageProbleme] = useState();

    const [msgInfoF, setMsgInfoF] = useState();
    const [choixID_ACTIONS_SESSIONS_PERIODE_BLOCS, setChoixID_ACTIONS_SESSIONS_PERIODE_BLOCS] = useState();
    const [choixLIB_COMPANIES, setChoixLIB_COMPANIES] = useState();

    const [certificationsPossibles, SetCertificationsPossibles] = useState(); // pour recup données du select


    const columns1 = useMemo(
      () => [
        {
          id:'listesCertifs',
          hideHeader : false,
          trieDefautRp : 'LIB_LHEO_TYPE_VALIDATION_BLOCS',
          columns: [
              { 
                Header: 'Validation bloc', 
                accessor: 'LIB_LHEO_TYPE_VALIDATION_BLOCS',
                id: "LIB_LHEO_TYPE_VALIDATION_BLOCS",
                Cell: ({ row }) => (
                  <>
                  {row.original.LIB_LHEO_TYPE_VALIDATION_BLOCS}
                  { (row.original.noEditablePLease != 1) && 
                    <>
                    {row.original.ID_LHEO_TYPE_VALIDATION_BLOCS != null &&
                    <NavLink title="gérer les blocs de compétences de cette certification" to="" onClick={() => handleClickChoixID_ACTIONS_SESSIONS_PERIODE_BLOCS(row.original.ID_ACTIONS_SESSIONS_PERIODE_BLOCS, row.original.LIB_COMPANIES)}>
                      {props.infosGenFormation["sess_deja_exportee"] == false ?
                      <ButtonVoirFiche className="fas fa-pen-to-square" />
                      :
                      <ButtonVoirFiche />
                      }
                    </NavLink>
                    }
                    </>
                  }
                </>
                ),
              },
              {  
                Header: 'Intitulé du diplôme', 
                accessor: 'intitule_diplome', 
              },
              { 
                Header: 'Code certif info', 
                accessor: 'code_diplome',
              },
              { 
                Header: 'Code Rncp', 
                accessor: 'code_rncp',
              },
              { 
                Header: 'Etat', 
                accessor: 'etat_lib',
                id: "etat_lib_center",
              },
              { 
                Header: 'Formateur', 
                accessor: 'LIB_COMPANIES',
              },
              {
                Header: () => <></>,
                  accessor: 'actions', 
                  id: "suppr_conditionnel_center",
                  disableSortBy: true,

                  Cell: ({ row }) => (
                    <>
                      { (row.original.noEditablePLease != 1) ?
                        <a title="Dissocier" onClick={() => handleClickDisso(row.original.ID_ACTIONS_SESSIONS_PERIODE_CERTIFICATION)}>
                          <ButtonSuppr />
                        </a>
                      :
                        <ButtonSuppr disabled="disabled" />
                      }
                    </>
                  ),
              },
          ]
        },
      ],
      []
    )
    //idem columns1 mais sans colonne action (si un seul resultat)
    const columns2 = useMemo(
      () => [
        {
          id:'listesCertifs',
          hideHeader : false,
          trieDefautRp : 'LIB_LHEO_TYPE_VALIDATION_BLOCS',
          columns: [
              { 
                Header: 'Validation bloc', 
                accessor: 'LIB_LHEO_TYPE_VALIDATION_BLOCS',
                id: "LIB_LHEO_TYPE_VALIDATION_BLOCS",
                Cell: ({ row }) => (
                  <>
                  {row.original.LIB_LHEO_TYPE_VALIDATION_BLOCS}
                  { (row.original.noEditablePLease != 1) && 
                    <>
                    {row.original.ID_LHEO_TYPE_VALIDATION_BLOCS != null &&
                    <NavLink title="gérer les blocs de compétences de cette certification" to="" onClick={() => handleClickChoixID_ACTIONS_SESSIONS_PERIODE_BLOCS(row.original.ID_ACTIONS_SESSIONS_PERIODE_BLOCS, row.original.LIB_COMPANIES)}>
                      {props.infosGenFormation["sess_deja_exportee"] == false ?
                      <ButtonVoirFiche className="fas fa-pen-to-square" />
                      :
                      <ButtonVoirFiche />
                      }
                    </NavLink>
                    }
                    </>
                  }
                </>
                ),
              },
              {  
                Header: 'Intitulé du diplôme', 
                accessor: 'intitule_diplome', 
              },
              { 
                Header: 'Code certif info', 
                accessor: 'code_diplome',
              },
              { 
                Header: 'Code Rncp', 
                accessor: 'code_rncp',
              },
              { 
                Header: 'Etat', 
                accessor: 'etat_lib',
                id: "etat_lib_center",
              }
          ]
        },
      ],
      []
    )

    const handleClickChoixID_ACTIONS_SESSIONS_PERIODE_BLOCS  = async (ID_ACTIONS_SESSIONS_PERIODE_BLOCS, LIB_COMPANIES) => {
      setChoixID_ACTIONS_SESSIONS_PERIODE_BLOCS(ID_ACTIONS_SESSIONS_PERIODE_BLOCS);
      setChoixLIB_COMPANIES(LIB_COMPANIES);
      if(global.config.modeDebugApp == 1) console.log(`formateur de la certif choisie : ${choixLIB_COMPANIES}`)
   }

    const handleClickDisso  = async (idPrimaire) => {
      if(certificationsPossibles && certificationsPossibles.length == 1) {
        alert("Vous ne pouvez dissocier cette certification de cette session car c'est la seule qui est déclarée au niveau du formateur.")
        return false;
      }
      setDataLoaded(false);
      setMessageProbleme();

      var dataR = await apiSessAssocDissoCertif(utilisateurBearer, sessionNumber, idPrimaire, 'DELETE');

      switch (dataR.statusCode) {
          case 200:
              await recupListeCertifsAssocieesAsession(); // reload la liste
              if(dataR.dataApi != "") alert(dataR.dataApi);
              else alert("La dissociation a bien été effectuée");
            break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
            break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
      setDataLoaded(true);
    }

    /*const handleChoixCertifDepuisSelect = async (idsSelectionnes) => {
      setDataLoaded(false);
      setMessageProbleme();
      var idDiplome = idsSelectionnes;

      var dataR = await apiSessAssocDissoCertif(utilisateurBearer, sessionNumber, idDiplome, 'PUT');

      switch (dataR.statusCode) {
          case 200:
              await recupListeCertifsAssocieesAsession(); // reload la liste
              alert("L'association a bien été effectuée.");
            break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
            break;
            case 400:
              setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas
            break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
      setDataLoaded(true);
    }*/

    async function recupListeCertifsAssocieesAsession() {
      const dataR = await apiSessAppelGetInfosDiv(utilisateurBearer, "/"+sessionNumber + "/certifications-sessions");
      if(global.config.modeDebugApp == 1) console.log('retour certifications-sessions dans recupListeCertifsAssocieesAsession : ', dataR);
      switch (dataR.statusCode) {
          case 200:
              if(props.infosProprietes["ACTIONS_SESSIONS_PERIODE_CERTIFICATION|other_dataEditable"].editable  == false) {
                //modification du contenu renvoyé par l'api (ajout d'une information)
                let nouveauTabRep = [];
                dataR.dataApi.forEach((el) => {
                    // on va ajouter une notion de non modifiable
                    let ligne = { ...el, 'noEditablePLease': 1}
                    nouveauTabRep.push(ligne);           
                });
                //console.log("dataApi modifié:", nouveauTabRep);
                setDataApi(nouveauTabRep);
              }
              else setDataApi(dataR.dataApi);
              //console.log("dataApi :", dataR.dataApi)

              var msg = dataR.dataApi.length > 1 ? 'Les certifications préparées par cette session sont énumérées ici (ce sont par défaut l\'ensemble des certifications précisées à l\'étape "Lieu et certifications")<br />Vous pouvez retirer les certifications qui ne sont pas préparées par cette session, ou préciser les blocs de compétences préparés quand il y en a.':
              'La certification préparée par cette session est rappelée ici (c\'est la certification précisée à l\'étape "Lieu et certifications").';
              setMsgInfoF(msg);

              var objaAmodif = JSON.parse(JSON.stringify(props.infosGenFormation)); // on clone (duplique) l'objet pour que tout fonctionne bien
              objaAmodif["etape11"] = dataR.dataApi.length > 0 ? "ok" : "nok";
              props.SetInfosGenFormation(objaAmodif);

              var retAppel = await appelGetSetInfosObjetsFormActSess(utilisateurBearer, formationNumber, actionNumber, sessionNumber, props.SetInfosGenFormation, setMessageProbleme);
              if(retAppel != true) setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut

              return true;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
            break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        return false;
      }
    }

    async function chargementsConsecutifs(noCertifsPossibles=0) {
      setDataLoaded(false);
      let retP = await recupListeCertifsAssocieesAsession(); // certifs associés à la session
      if(retP == true) {
        var pb = false;

        if(noCertifsPossibles == 0) {
          var ret = await appelRecupInfoGet(utilisateurBearer, "formateurs/certifications-pour-session/" + actionNumber, setMessageProbleme);
          if(ret != null) SetCertificationsPossibles(ret); else pb = true; // recuperation des certifications possibles pour liste select
        }

        if(pb == true ) setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
      setDataLoaded(true);
    }

   useEffect(() => {
      if(global.config.modeDebugApp == 1) console.log('useffect FormEtape11');
    	document.title = `Certification(s) et bloc(s) de compétence de la session ${sessionNumber}`;

      setMessageProbleme(null);
      chargementsConsecutifs();
   }, []);

   const [majTab, setMajTab] = useState(false);
   useEffect(() => {
      if(global.config.modeDebugApp == 1) console.log('useffect majTab');
      if(majTab == true) {
        setAumoinsUneModif(true);
        setMessageProbleme(null);
        chargementsConsecutifs(1);
        setMajTab(false);
      }
   }, [majTab]);

   useEffect(() => {
      setAumoinsUneModif(false);
    }, [dataApi]);
    
 
    return (
      <>
        <LoadingSpinner dataLoaded={dataLoaded}/>
        <MessageErreurApi message={messageProbleme} />

        {dataLoaded && dataApi && dataApi != null && props.infosProprietes != null &&
        <Container className="my-0">
            { /* commenté le 020123, Lydia dit que ce n'est pas utile
            props.infosProprietes["ACTIONS_SESSIONS_PERIODE_CERTIFICATION|other_dataEditable"].editable == 0 ?
              <MessageInfoNoDroitErreurApi />
            :
              <>
                <MessageInfoBox message={msgInfoF} />
                {certificationsPossibles && certificationsPossibles.length > 1 ?
                  <section className="section-generale">
                      <div className="titre">Ajouter des certifications à cette session</div><br />

                        <select className="form-control custom-select" id="theList" 
                                title="Certifications" 
                                onChange={(e) => {handleChoixCertifDepuisSelect(e.target.value);}}>
                            <option value="" key="-">Sélectionner une certification parmi celles du formateur à associer à cette session</option>
                            {certificationsPossibles.map((item) => {
                                return (
                                  <option value={item.code_diplome} key={item.code_diplome}>
                                  {item.lib_concatene}
                                  </option>
                                );
                                })}
                        </select>

                  </section>
                :
                  <>
                    {certificationsPossibles && certificationsPossibles.length == 0 &&
                      <>Aucune certification possible. Ceci n'est pas normal, veuillez contacter le support "{global.config.admin.mail}"</>
                    }
                  </>
                }
              </>*/
            }

            <section className="section-generale">
                <div className="titre">{dataApi.length > 1 ? "Certifications avec blocs" : "Certification avec blocs"} de cette session</div><br />
                {dataApi.length > 0 ?
                  <>
                    {(props.infosProprietes["ACTIONS_SESSIONS_PERIODE_CERTIFICATION|other_dataEditable"].editable != 0 &&
                      props.infosGenFormation["sess_deja_exportee"] == false) &&
                      <>
                        {dataApi.length > 1 ? 
                          <MessageInfoBox message="Si une certification contient des blocs de compétence, vous pouvez les gérer en cliquant sur le pictogramme<span class='enrobage-boutton'><span class='fas fa-pen-to-square'></span></span> associé." nomClasse="message-info2" />
                        :
                          <MessageInfoBox message="Si la certification contient des blocs de compétence, vous pouvez les gérer en cliquant sur le pictogramme<span class='enrobage-boutton'><span class='fas fa-pen-to-square'></span></span> associé." nomClasse="message-info2" />
                        }
                      </>
                    }
                    <Table columns={dataApi.length > 1 ? columns1 : columns2} data={dataApi} noPagination={true} noDisplayNbResult={true} noFilter={true} />
                  </>
                :
                  <div>Cette session n'est liée à aucune certification.</div>
                }
            </section>

            {choixID_ACTIONS_SESSIONS_PERIODE_BLOCS && <BlocsCompetences ID_ACTIONS_SESSIONS_PERIODE_BLOCS={choixID_ACTIONS_SESSIONS_PERIODE_BLOCS}
                                                                         LIB_COMPANIES={choixLIB_COMPANIES}
                                                                         setChoixID_ACTIONS_SESSIONS_PERIODE_BLOCS={setChoixID_ACTIONS_SESSIONS_PERIODE_BLOCS} 
                                                                         setMajTab={setMajTab} 
                                                                         infosGenFormation={props.infosGenFormation} />}
        </Container>
        }

        <LiensPrecedentSuivant formationNumber={formationNumber} actionNumber={actionNumber} sessionNumber={sessionNumber} numEtape={numEtape} infosGenFormation={props.infosGenFormation} notifBeCareful={aumoinsUneModif} />
      </>
    )

}

FormEtape11.propTypes = {
  formationNumber: PropTypes.number.isRequired,
  actionNumber: PropTypes.number.isRequired,
  sessionNumber: PropTypes.number.isRequired,
  SetInfosGenFormation : PropTypes.func,
  infosGenFormation: PropTypes.object,
}

export default FormEtape11;