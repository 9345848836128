
import { Form} from "react-bootstrap";


/**
 * Composant affichant un champ bootstrap "controle"
 *
 * @component
 * @return { HTMLElement }
*/
function FormHidden({ id, ...rest }) {
    var idElement = id;
    
    return (
        <>
        {idElement == null ?
            <>idElement indefini</>
        : 
            <Form.Group 
                controlId={idElement} >

                <Form.Control 
                    type=''
                    disabled= {"disabled"}
                    as= "input"
                    className="hidden" 
                />

            </Form.Group>
        }
        </>

    )
}

export default FormHidden