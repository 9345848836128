import { useState, createContext } from 'react';

// inspi https://github.com/OpenClassrooms-Student-Center/7150606-React-intermediaire/blob/P2C2-solution/src/utils/context/index.jsx;
//et pour persistance, cf localStorage https://openclassrooms.com/fr/courses/7008001-debutez-avec-react/7137079-declenchez-des-effets-avec-useeffect (https://github.com/OpenClassrooms-Student-Center/7008001-Debutez-avec-React/blob/P3C3-Solution/src/components/App.js)
//https://fr.reactjs.org/docs/context.html

export const DonneesUtilisateurContext = createContext()

export const DonneesUtilisateurProvider = ({ children }) => {
    const savedDatasLocalStorage = localStorage.getItem('DatasLocalStorage')
    const savedDatasLocalStorageOrg = localStorage.getItem('LocalStorageOrg')
    const savedDatasLocalStorageNomOrg = localStorage.getItem('LocalStorageNomOrg')
    const savedDatasLocalStorageIdMesureOf = localStorage.getItem('LocalStorageIdMesureOf')
    const savedDatasLocalStorageUtilisateurId = localStorage.getItem('LocalStorageUtilisateurId')
    const savedDatasLocalStorageRole = localStorage.getItem('LocalStorageRole')

    const savedDatasLocalStorageRouteLogin = localStorage.getItem('LocalStorageRouteLogin')
    const savedDatasLocalStorageNbFormTot = localStorage.getItem('LocalStorageNbFormTot')
    const savedDatasLocalNbFormAttente =    localStorage.getItem('LocalStorageNbFormAttente')
    const savedDatasLocalStorageNbFormIncomplete = localStorage.getItem('LocalStorageNbFormIncompl')
    const savedDatasLocalNbActAttente =    localStorage.getItem('LocalStorageNbActAttente')
    const savedDatasLocalStorageNbActIncomplete = localStorage.getItem('LocalStorageNbActIncompl')
    const savedDatasLocalNbSessAttente =    localStorage.getItem('LocalStorageNbSessAttente')
    const savedDatasLocalStorageNbSessIncomplete = localStorage.getItem('LocalStorageNbSessIncompl')

    const [utilisateurBearer, setUtilisateurBearer] = useState(savedDatasLocalStorage ? JSON.parse(savedDatasLocalStorage) : '000000')
    const [utilisateurOrg, setUtilisateurOrg] = useState(savedDatasLocalStorageOrg ? JSON.parse(savedDatasLocalStorageOrg) : '')
    const [utilisateurNomOrg, setUtilisateurNomOrg] = useState(savedDatasLocalStorageNomOrg ? JSON.parse(savedDatasLocalStorageNomOrg) : '')
    const [utilisateurIdMesureOf, setUtilisateurIdMesureOf] = useState(savedDatasLocalStorageIdMesureOf ? JSON.parse(savedDatasLocalStorageIdMesureOf) : '')
    const [utilisateurUtilisateurId, setUtilisateurUtilisateurId] = useState(savedDatasLocalStorageUtilisateurId ? JSON.parse(savedDatasLocalStorageUtilisateurId) : '')  
    const [utilisateurRole, setUtilisateurRole] = useState(savedDatasLocalStorageRole ? JSON.parse(savedDatasLocalStorageRole) : '')

    const [utilisateurRouteLogin, setUtilisateurRouteLogin] = useState(savedDatasLocalStorageRouteLogin ? JSON.parse(savedDatasLocalStorageRouteLogin) : '/')
    const [utilisateurNbFormTot, setUtilisateurNbFormTot] = useState(savedDatasLocalStorageNbFormTot ? JSON.parse(savedDatasLocalStorageNbFormTot) : 0)
    const [utilisateurNbFormAttente, setUtilisateurNbFormAttente] = useState(savedDatasLocalNbFormAttente ? JSON.parse(savedDatasLocalNbFormAttente) : 0);
    const [utilisateurNbFormIncomplete, setUtilisateurNbFormIncomplete] = useState(savedDatasLocalStorageNbFormIncomplete ? JSON.parse(savedDatasLocalStorageNbFormIncomplete) : 0);

    const [utilisateurNbActAttente, setUtilisateurNbActAttente] = useState(savedDatasLocalNbActAttente ? JSON.parse(savedDatasLocalNbActAttente) : 0);
    const [utilisateurNbActIncomplete, setUtilisateurNbActIncomplete] = useState(savedDatasLocalStorageNbActIncomplete ? JSON.parse(savedDatasLocalStorageNbActIncomplete) : 0);

    const [utilisateurNbSessAttente, setUtilisateurNbSessAttente] = useState(savedDatasLocalNbSessAttente ? JSON.parse(savedDatasLocalNbSessAttente) : 0);
    const [utilisateurNbSessIncomplete, setUtilisateurNbSessIncomplete] = useState(savedDatasLocalStorageNbSessIncomplete ? JSON.parse(savedDatasLocalStorageNbSessIncomplete) : 0);

    const setBearer = (donnee) => {
        setUtilisateurBearer(donnee)
        localStorage.setItem('DatasLocalStorage', JSON.stringify(donnee))
    }
    const setOrg = (donnee) => {
        setUtilisateurOrg(donnee)
        localStorage.setItem('LocalStorageOrg', JSON.stringify(donnee))
    }
    const setNomOrg = (donnee) => {
        setUtilisateurNomOrg(donnee)
        localStorage.setItem('LocalStorageNomOrg', JSON.stringify(donnee))
    }
    const setIdMesureOf = (donnee) => {
        setUtilisateurIdMesureOf(donnee)
        localStorage.setItem('LocalStorageIdMesureOf', JSON.stringify(donnee))
    }
    const setUtilisateurId = (donnee) => {
        setUtilisateurUtilisateurId(donnee)
        localStorage.setItem('LocalStorageUtilisateurId', JSON.stringify(donnee))
    }
    const setRole = (donnee) => {
        setUtilisateurRole(donnee)
        localStorage.setItem('LocalStorageRole', JSON.stringify(donnee))
    }

    const setRouteLogin = (donnee) => {
        setUtilisateurRouteLogin(donnee)
        localStorage.setItem('LocalStorageRouteLogin', JSON.stringify(donnee))
    }
    const setNbFormTot = (donnee) => {
        setUtilisateurNbFormTot(donnee)
        localStorage.setItem('LocalStorageNbFormTot', JSON.stringify(donnee))
    }
    const setNbFormAttente = (donnee) => {
        setUtilisateurNbFormAttente(donnee)
        localStorage.setItem('LocalStorageNbFormAttente', JSON.stringify(donnee))
    }
    const setNbFormIncomplete = (donnee) => {
        setUtilisateurNbFormIncomplete(donnee)
        localStorage.setItem('LocalStorageNbFormIncompl', JSON.stringify(donnee))
    }


    const setNbActAttente = (donnee) => {
        setUtilisateurNbActAttente(donnee)
        localStorage.setItem('LocalStorageNbActAttente', JSON.stringify(donnee))
    }
    const setNbActIncomplete = (donnee) => {
        setUtilisateurNbActIncomplete(donnee)
        localStorage.setItem('LocalStorageNbActIncompl', JSON.stringify(donnee))
    }


    const setNbSessAttente = (donnee) => {
        setUtilisateurNbSessAttente(donnee)
        localStorage.setItem('LocalStorageNbActAttente', JSON.stringify(donnee))
    }
    const setNbSessIncomplete = (donnee) => {
        setUtilisateurNbSessIncomplete(donnee)
        localStorage.setItem('LocalStorageNbSessIncompl', JSON.stringify(donnee))
    }

    return (
        <DonneesUtilisateurContext.Provider value={{ utilisateurBearer, setBearer, 
                                                     utilisateurOrg, setOrg,
                                                     utilisateurNomOrg, setNomOrg,
                                                     utilisateurIdMesureOf, setIdMesureOf,
                                                     utilisateurUtilisateurId, setUtilisateurId,
                                                     utilisateurRole, setRole,

                                                     utilisateurRouteLogin, setRouteLogin,
                                                     utilisateurNbFormTot, setNbFormTot, // utilisateurNbFormTot pas sutilisé
                                                     utilisateurNbFormAttente, setNbFormAttente,
                                                     utilisateurNbFormIncomplete, setNbFormIncomplete,

                                                     utilisateurNbActAttente, setNbActAttente,
                                                     utilisateurNbActIncomplete, setNbActIncomplete,

                                                     utilisateurNbSessAttente, setNbSessAttente,
                                                     utilisateurNbSessIncomplete, setNbSessIncomplete,
                                                      }}>
            {children}
        </DonneesUtilisateurContext.Provider>
    )
}

