import '../config.js';

/**
 * Component who return html cote of 404 page
 *
 * @component
 * @return { HTMLElement }
*/
function NotFound() {
   return (
   <div className="info-page-introuvable">Erreur 404 😱 La page demandée n'existe pas 👻.<br />
   Si vous pensez que c'est une erreur, veuillez contacter le support "{global.config.admin.mail}"
   </div>)
}

export default NotFound