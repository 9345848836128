import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import Form from 'react-bootstrap/Form'; // pour Form.Check

import { apiCertifAppelBase } from '../Datas/dataDiplomes.js'
import { recupereNiveauxCI } from '../Datas/datasAutre.js'
import { DonneesUtilisateurContext } from '../contexte.js';


import {MessageErreurApi} from './Messages.jsx';
import LoadingSpinner from "./LoadingSpinner.js";
import ButtonDeBase, {ButtonVoirFiche, ButtonVoirFiche2 } from './ButtonsCustom.jsx';
import Table, {SelectColumnFilter} from './Table.jsx';


/**
 * Return une section permettant de rechercher des certifs
 *
 * @component
 * @summary page de recherche pro des certifs
 * @param {  }
 * @return { HTMLElement }
*/
function AdmRechercheCertif(props) {
    const navigate = useNavigate(); // utile pour changer de page
    const { setBearer, utilisateurRouteLogin, utilisateurBearer, utilisateurRole} = useContext(DonneesUtilisateurContext);
    const [messageProbleme, setMessageProbleme] = useState();

    const [dataApiCiPro, setDataApiCiPro] = useState();
    const [dataLoadedRechCi, setDataLoadedRechCi] = useState(true);
    const [chpRechProCiCodeRs, setChpRechProCiCodeRs] = useState(localStorage.getItem('chpRechProCiCodeRs') ? localStorage.getItem('chpRechProCiCodeRs') : "");
    const [chpRechProCiCodeRncp, setChpRechProCiCodeRncp] = useState(localStorage.getItem('chpRechProCiCodeRncp') ? localStorage.getItem('chpRechProCiCodeRncp') : "");
    const [chpRechProCiCodeCi, setChpRechProCiCodeCi] = useState(localStorage.getItem('chpRechProCiCodeCi') ? localStorage.getItem('chpRechProCiCodeCi') : "");
    const [chpRechProCiLib, setChpRechProCiLib] = useState(localStorage.getItem('chpRechProCiLib') ? localStorage.getItem('chpRechProCiLib') : "");
    const [chpRechProCiNiveau, setChpRechProCiNiveau] = useState(localStorage.getItem('chpRechProCiNiveau') ? localStorage.getItem('chpRechProCiNiveau') : "-");
    const [chpRechProCiEtat, setChpRechProCiEtat] = useState(localStorage.getItem('chpRechProCiEtat') ? localStorage.getItem('chpRechProCiEtat') : 'true');
    const [chpRechProCiAvecSyn, setChpRechProCiAvecSyn] = useState(localStorage.getItem('chpRechProCiAvecSyn') ? localStorage.getItem('chpRechProCiAvecSyn') : 'true');

    const columnsCi = useMemo(
        () => [
          {
            id:'listesCertifs',
            hideHeader : false,
            trieDefautRp : 'intitule_diplome',
            columns: [
                { 
                    Header: 'Code', 
                    accessor: 'code_diplome',
                    //minWidth: 50,
                },
                {  
                    Header: 'Libellé', 
                    accessor: 'intitule_diplome', 
                    Cell: ({ row }) => (
                        <a title="Voir la fiche" className="lib" onClick={() => props.handleClickViewCerifBootStrap(row.original.code_diplome)}>
                            {row.original.intitule_diplome}
                            <ButtonVoirFiche />
                        </a>
                    ),
                },
                { 
                    Header: 'Rs/Rncp', 
                    accessor: 'rsRncp',
                },
                { 
                    Header: 'Nom du ou des certificateur(s)', 
                    accessor: 'certificateur_lib',
                    disableSortBy: true,
                },
                { 
                    Header: () => <>Echéance<br />Rncp/Rs</>, 
                    accessor: 'DATES_FIN_ENREGISTREMENTrech_rncp_rs_formated',
                },
                { 
                    Header: 'Etat', 
                    accessor: 'etat_lib',
                },

                {
                    Header: 'Appellations suppl.', 
                    disableSortBy: true, /*obligé comme cette colonne content desinfos complexes*/
                    Cell: ({ row }) => (  
                      <>
                        {row.original.listeDiplomesAppellationsPourMoteur != null && 
                          row.original.listeDiplomesAppellationsPourMoteur.map((ap, index) => (
                            <span key={ap.diplomes_pour_moteurs_id}>{ap.appellation_diplome} ({ap.source})<br /></span>
                          ))}
                      </>
                      ),
                },
                //{ aurait pu etre utilisé, strictement equivalent :
                //    Header: 'Appellations suppl2. (strictement identique)',
                //    Cell: ({ row }) => (
                //        <>
                //        {row.original.listeDiplomesAppellationsPourMoteur.map(({ code, appellation_diplome, source }, index) => (
                //        <span key={index}>{appellation_diplome} {source} {code} +++<br /></span>
                //        ))}
                //       </>
                 //   ),
                //}

                {
                    Header: () => <>Actions</>,
                    accessor: 'actions', 
                    id: "actions_center",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                        <>
                        {global.config.codesEtatsCiEnCours.includes(row.original.etat_code) ? 
                         <>
                            <a title="Voir la fiche" className="lib" onClick={() => props.handleClickViewCerifBootStrap(row.original.code_diplome)}>
                                <ButtonVoirFiche />
                            </a>
                            <ButtonVoirFiche2 className="fas fa-pen-to-square" 
                            lien={`/admin/moteur/synonymes-certifications/${row.original.code_diplome}`} 
                            title={`Gérer les synonymes de la fiche ${row.original.code_diplome}`} />
                        </>
                        :
                        <a title="Ce diplome n'est plus actif, cliquez pour savoir s'il est remplacé" onClick={function() {
                            let suite_remplacement = "";
                            if(row.original.listeDiplomesRemplacants && row.original.listeDiplomesRemplacants.length > 0) {
                                row.original.listeDiplomesRemplacants.forEach((remplacants) => {
                                    suite_remplacement += ', ' + remplacants.code;
                                })
                                if(row.original.listeDiplomesRemplacants.length == 1) alert("Ce diplôme n'est plus actif et ne peut pas être utilisé, il est remplacé par le " + suite_remplacement.substring(2));
                                else alert("Ce diplôme n'est plus actif et ne peut pas être utilisé, il est remplacé par les diplômes : " + suite_remplacement.substring(2));
                            }
                            else alert("Ce diplôme n'est plus actif et ne peut pas être utilisé");
                            }}>
                            <ButtonVoirFiche disabled="disabled" />
                        </a>
                        }
                        </>
                    ),
                },
            ]
          },
        ],
        []
    )


    async function effectueRechercheCi() {
        let chaineRecherche = "?";
        if (chpRechProCiLib && chpRechProCiLib != null) chaineRecherche += "intitule-recherche=" + chpRechProCiLib + '&';
        if (chpRechProCiCodeRs && chpRechProCiCodeRs != null)  chaineRecherche += "code-rs=" + chpRechProCiCodeRs + '&';
        if (chpRechProCiCodeRncp && chpRechProCiCodeRncp != null)  chaineRecherche += "code-rncp=" + chpRechProCiCodeRncp + '&';
        if (chpRechProCiCodeCi && chpRechProCiCodeCi != null)  chaineRecherche += "code-diplome=" + chpRechProCiCodeCi + '&';
        if (chpRechProCiNiveau && chpRechProCiNiveau != null && chpRechProCiNiveau != '-')  chaineRecherche += "code-niveau=" + chpRechProCiNiveau + '&';

        if (chaineRecherche.length == 1) {setMessageProbleme('Veuillez saisir au moins un critère'); return false; } // si aucun des critères précédents renseignés... car sinon le serveur rame trop

        if (chpRechProCiEtat && chpRechProCiEtat == "true")  chaineRecherche += "code-etat=0,4,11&";
        if (chpRechProCiAvecSyn && chpRechProCiAvecSyn == "true")  chaineRecherche += "avec-appellation=1&";

        if(global.config.modeDebugApp == 1) console.log("chaineRecherche", chaineRecherche)

        localStorage.setItem('chpRechProCiCodeRs', chpRechProCiCodeRs) // au depart cela était fait sur le onChange (e.target.value) mais posait pb pour le checkbox.. et puis c'est mieux ainsi
        localStorage.setItem('chpRechProCiCodeCi', chpRechProCiCodeCi)
        localStorage.setItem('chpRechProCiCodeRncp', chpRechProCiCodeRncp)
        localStorage.setItem('chpRechProCiLib', chpRechProCiLib)
        localStorage.setItem('chpRechProCiNiveau', chpRechProCiNiveau)
        localStorage.setItem('chpRechProCiEtat', chpRechProCiEtat);
        localStorage.setItem('chpRechProCiAvecSyn', chpRechProCiAvecSyn);

        setDataLoadedRechCi(false);
        setMessageProbleme("");
        const dataR = await apiCertifAppelBase(utilisateurBearer, 'Search' + chaineRecherche);
        if(global.config.modeDebugApp == 1)  console.log('retour apiCertifAppelBase : ', dataR);
        setDataLoadedRechCi(true);

        switch (dataR.statusCode) {
        case 200:
            //modification du contenu renvoyé par l'api
            let nouveauTabRep = [];
            dataR.dataApi.forEach((el) => {
                // on va couper les intitulé trop long
                let libCourt = (el.intitule_diplome.length > global.config.lgMaxChaineTab)  ? el.intitule_diplome.substring(0, global.config.lgMaxChaineTab-3) + '...' :  el.intitule_diplome;
                var rsRncp = (el.code_rncp_string != null) ? el.code_rncp_string : '';
                if(el.codes_inventaire != null) {
                    if(rsRncp != "") rsRncp += ' ' + el.codes_inventaire ;
                    else rsRncp = el.codes_inventaire;
                }
                let ligne = { ...el, 'intitule_diplome': libCourt, 'libLong': el.intitule_diplome, 'rsRncp': rsRncp}
                nouveauTabRep.push(ligne);
            });
            setDataApiCiPro(nouveauTabRep); // dataR.dataApi
            break;
        case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
                break;
        case 400:
            // on test si c'est une chaine string qui est retourné par l'api
            if(typeof dataR.dataApi === 'string' || dataR.dataApi instanceof String) setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "Il y a plus de XXX résultats ({XXXX), veuillez affiner votre recherche"
            else setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
            break;
        default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }

    }
    const handleSubmitRechCi = async (e) => {
        e.preventDefault();
        effectueRechercheCi();
    }

    const [niveauxCi, SetNiveauxCi] = useState(null); // pour recup données du select
    async function chargementsConsecutifs() {
        var pb = false;
        var ret = await recupereNiveauxCI(utilisateurBearer);
        if(ret != null) SetNiveauxCi(ret); else pb = true;

        effectueRechercheCi();

        if(pb == true ) {
            setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
    }

   useEffect(() => {
    //console.log('useffect RechercheCertif')
    if (utilisateurBearer === '000000' || utilisateurRole != "AdminExtranet" )  navigate(utilisateurRouteLogin); // si pas identifié ou role NOK on redirige sur l'acceuil
    chargementsConsecutifs();
   }, []);


    return (
        <>
        {niveauxCi != null &&
            <>
            <MessageErreurApi message={messageProbleme} />
            <LoadingSpinner dataLoaded={dataLoadedRechCi}/>
            <form onSubmit={handleSubmitRechCi} className="form-rech-ci">
                <div className="partie-form-rech-ci">
                { /* value={chpRechProCiCodeRs != null && chpRechProCiCodeRs}*/}
                    <input className="form-control input-codes" placeholder='Code RS' type="text" id="chpRechProCiCodeRs" 
                            onChange={(e) => {setChpRechProCiCodeRs(e.target.value)}} value={chpRechProCiCodeRs == null ? '' : chpRechProCiCodeRs} />
                    <input className="form-control input-codes" placeholder='Code Rncp' type="text" id="chpRechProCiCodeRncp" 
                            onChange={(e) => {setChpRechProCiCodeRncp(e.target.value)}} 
                            value={chpRechProCiCodeRncp == null ? '' : chpRechProCiCodeRncp}/>
                    <input className="form-control input-codes" placeholder='Code Certif Info' type="text" id="chpRechProCiCodeCi" 
                            onChange={(e) => {
                                if (!isNaN(e.target.value)) {setChpRechProCiCodeCi(e.target.value)}
                                else alert('Veuillez entrer un nombre');
                            }}
                            value={chpRechProCiCodeCi == null ? '' : chpRechProCiCodeCi} />
                </div>
                <div className="partie-form-rech-ci">
                    <input className="form-control input-lib" placeholder='Extrait du libellé (6 lettres minimum)' type="text" id="chpRechProCiLib" 
                            onChange={(e) => {setChpRechProCiLib(e.target.value)}} 
                            value={chpRechProCiLib == null ? '' : chpRechProCiLib}/>

                    <select className="form-control custom-select" id="chpRechProCiNiveau" title="Niveau" 
                        onChange={(e) => {setChpRechProCiNiveau(e.target.value);}}
                        value={chpRechProCiNiveau == null ? '-' : chpRechProCiNiveau} >
                        <option value="-">Tous les niveaux</option>
                        {niveauxCi && niveauxCi.map((item) => {
                        return (
                            <option value={item.code} key={item.code}>
                                {item.libelle}
                            </option>
                        );
                        })}
                    </select>

                </div>
                <div className="partie-form-rech-ci-bt">
                    <Form.Check type="switch"  id="chpRechProCiEtat" label="Voir uniquement les fiches publiées" 
                            checked={(chpRechProCiEtat === "true")} 
                            onChange={() => chpRechProCiEtat === "true" ? setChpRechProCiEtat("false") : setChpRechProCiEtat("true")} />
                    <Form.Check type="switch"  id="chpRechProCiAvecSyn" label="Voir uniquement les certifs ayant des synonymes/appellations" 
                            checked={(chpRechProCiAvecSyn === "true")} 
                            onChange={() => chpRechProCiAvecSyn === "true" ? setChpRechProCiAvecSyn("false") : setChpRechProCiAvecSyn("true")} />
                    <ButtonDeBase typeSubmit={true} lib="Rechercher" disabled={(
                                                                                (!chpRechProCiCodeRs || chpRechProCiCodeRs.length < 3) && 
                                                                                (!chpRechProCiCodeRncp || chpRechProCiCodeRncp.length < 5) && 
                                                                                (!chpRechProCiCodeCi || chpRechProCiCodeCi.length < 3) && 
                                                                                (!chpRechProCiLib || chpRechProCiLib.length < 5) &&
                                                                                (!chpRechProCiAvecSyn || chpRechProCiAvecSyn == 0) &&
                                                                                chpRechProCiNiveau == '-'
                                                                                ) || !dataLoadedRechCi} />
                </div>
            </form>

            {dataLoadedRechCi && dataApiCiPro && 
                <> {/*section className="certifications-recherche"*/}
                    {dataLoadedRechCi && dataApiCiPro && dataApiCiPro.length > 0 && 
                        <>
                            <p className="titre-rep-certif">
                                {dataApiCiPro.length} 
                                {dataApiCiPro.length > 0 ? 
                                    <> certifications trouvées - cliquez sur l'un des boutons <span className="fas fa-pen-to-square picto-legende"></span> de la liste pour gérer les synonymes d'une certification</>
                                    : 
                                    <> certification trouvée - cliquez sur la <span className="fas fa-pen-to-square picto-legende"></span> pour gérer ses synonymes </>}
                            </p>    
                            <Table columns={columnsCi} data={dataApiCiPro} noDisplayNbResult={true} />
                        </>
                    }
                    {dataApiCiPro.length == 0 ? <div>Aucune fiche certif info n'a été trouvée, veuillez modifier vos critères.</div> : null}
                </>
            }
            </>
        }
        </>
    )

}


export default AdmRechercheCertif;

AdmRechercheCertif.propTypes = {
    handleClickViewCerifBootStrap: PropTypes.func
}