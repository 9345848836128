import { useEffect, useState, useContext} from 'react'; // useMemo pour Table.jsx
import { useNavigate } from "react-router-dom";

import { DonneesUtilisateurContext } from '../../contexte.js';
import MessageInfoBox from '../../Components/Messages.jsx';
import AdmRechercheTypeDip from '../../Components/AdmRechercheTypeDip.jsx'

function AdmMoteurSynsTypesDipl() {
    const navigate = useNavigate(); // utile pour changer de page
    const { utilisateurRouteLogin, utilisateurBearer, utilisateurRole } = useContext(DonneesUtilisateurContext);
    const [messageBase, setMessageBase] = useState('');

   useEffect(() => {
    if(global.config.modeDebugApp == 1)  console.log('useffect AdmMoteurSynsTypesDipl')
    if (utilisateurBearer === '000000' || utilisateurRole != "AdminExtranet" )  navigate(utilisateurRouteLogin); // si pas identifié ou role NOK on redirige sur l'acceuil
    document.title = `Gestion des synonymes de types de certification`;
    setMessageBase("Gestion des synonymes de types de certification : recherchez le type de certification sur lequel vous souhaitez agir.");
   }, []);

   return (     
        <main className="sous-bloc">
            <MessageInfoBox message={messageBase} />
            <AdmRechercheTypeDip />
        </main>
    )
}
export default AdmMoteurSynsTypesDipl;