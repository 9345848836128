import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import {NavLink } from "react-router-dom";

import { DonneesUtilisateurContext } from '../../contexte.js';

import { apiAppellationTypeDiplomeGet, apiAppellationTypeDiplomeAjout, apiAppellationTypeDiplomeModif, apiAppellationTypeDiplomeSuppr } from '../../Datas/pro_dataAppellationTypesDiplome.js'

import LoadingSpinner from "../../Components/LoadingSpinner.js";
import MessageInfoBox, {MessageErreurApi, MessageInfoChampsObligatoires} from '../../Components/Messages.jsx';
import ButtonDeBase, {ButtonVoirFiche, ButtonSuppr } from '../../Components/ButtonsCustom.jsx';
import FormRHF from "../../Components/Form/Form.jsx";
import FormControl from "../../Components/Form/FormControl.jsx";

import FormHidden from "../../Components/Form/FormHidden.jsx";;
import Table from '../../Components/Table.jsx';
import { recupererProprietes, appelRecupInfoGet, confirmationSiCLickSUrLien } from '../../fonctionsDiv.js';

/**
 * Return Edition syn types de diplome
 *
 * @component
 * @summary modele étape 13 ex Recrutements
 * @param {  }
 * @return { HTMLElement }
*/
function AdmEditTypDipSyn() {
  const navigate = useNavigate(); // utile pour changer de page
  var { idTypeCertif } = useParams(); // codeTypeDiplome

    const { setBearer, utilisateurRouteLogin, utilisateurBearer, utilisateurRole } = useContext(DonneesUtilisateurContext);
    const [infosProprietes, SetInfosProprietes] = useState(undefined); // contient les infos sur les champ (taille max, caractre obligatoire, éditable...)
    const [dataApi, setDataApi] = useState();
    const [dataObjetPrincipal, setDataObjetPrincipal] = useState();
    const [aumoinsUneModif, setAumoinsUneModif] = useState(false); // pour avertir l'utilisateur en cas de sortie sans enregistrement

    const [dataLoaded, setDataLoaded] = useState(true);
    const [messageProbleme, setMessageProbleme] = useState();
    const [libBtAnnuler, setLibBtAnnuler] = useState("Annuler l'ajout");
    const [indicationSimple, setIndicationSimple] = useState();

     const columns = useMemo(
      () => [
        {
          id:'listeElements',
          hideHeader : false,
          columns: [
            { 
            Header: 'Synonyme/Appellation préfixe', 
              accessor: 'prefixe',
            },
            { 
              Header: 'Synonyme/Appellation libellé', 
                accessor: 'intitule_singulier',
              },
            { 
            Header: 'Commentaire/indication', 
              accessor: 'source',
            },
            {
              Header: () => <></>,
                accessor: 'actions', 
                id: "actions_center",
                disableSortBy: true,
                Cell: ({ row }) => (
                  <>
                  {row.original.source != 'types-diplome' ? 
                  <>
                    <NavLink title={`Modifier`} to="" onClick={() => recupererInfosPrincipales(row.original.types_diplome_pour_moteurs_id)}>
                          <ButtonVoirFiche className="fas fa-pen-to-square" />
                    </NavLink>
                      <NavLink title={`Supprimer`} to="" onClick={() => handleClickSupprElement(row.original.types_diplome_pour_moteurs_id)}>
                        <ButtonSuppr />
                      </NavLink>
                  </>
                  :
                  <a title="Ce terme ne peut pas être supprimé, c'est l'intitulé du type" onClick={function() {
                      alert("Ce terme ne peut pas être supprimé, c'est l'intitulé du type");
                      }}>
                      <ButtonVoirFiche disabled="disabled" />
                  </a>
                  }
                  </>
                ),
            },
          ]
        },
      ],
      []
    )
    const handleClickSupprElement  = async (codeAsupprimer) => {
      var confirmation = confirm("Etes vous sûr de vouloir supprimer cet élément ?");
      if(confirmation == true) {
        var dataR = await apiAppellationTypeDiplomeSuppr(utilisateurBearer, idTypeCertif, codeAsupprimer);
        if(global.config.modeDebugApp == 1) console.log('retour apiAppellationTypeDiplomeSuppr : ', dataR);
        switch (dataR.statusCode) {
            case 204:
                recupererListeCompleteElements();
              break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
              break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
      }
    }

    async function recupererListeCompleteElements() {
      // apiRecupereInfoGet ...???
      var ret = await appelRecupInfoGet(utilisateurBearer, "types-certif/autresAppellation?code-type-diplome="+idTypeCertif, setMessageProbleme);
      if(global.config.modeDebugApp == 1) console.log('retour types-certif/autresAppellation?code-type-diplome="+idTypeCertif : ', ret);

      if(ret != null) {
       setDataApi(ret);
       setIndicationSimple("Vous pouvez gérer ici les synonymes pour le type de certification " + ret.code_type_diplome + " intitulé '" + ret.intitule_type_diplome + "'")
       return false;
      }
      else return true;
      //setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
    }

    //pour effectuer des actions en fonction de la saisie de l'utilisateur
    function gestionEvenements(value, name){
      setMessageProbleme(null); // modif 10723 pour que l'éventuelle message précédent ne s'affiche plus su l'utilsateur a modifié sa saisie
      setAumoinsUneModif(true); document.getElementById("hidden_marque_une_modif").value = 1;

      if(global.config.modeDebugApp == 1) console.log (`modif de ${name} : ${value[name]}`);
    }


    async function recupererInfosPrincipales(idDetail) { 
      setShowForm(false); //important sinon bug quand on passe directement d'une occurence à l'autre

      const dataR = await apiAppellationTypeDiplomeGet(utilisateurBearer, idTypeCertif, idDetail);
      if(global.config.modeDebugApp == 1) console.log('retour apiAppellationTypeDiplomeGet dans recupererInfosPrincipales : ', dataR);
      switch (dataR.statusCode) {
        case 200:
          setDataObjetPrincipal(dataR.dataApi);
          //console.log("dataApi", dataR.dataApi)
          setLibBtAnnuler("Annuler la modification");
          setShowForm(true); 
          return true;
        case 401:
          setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
          break;
        default:
          setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
      return false;
    }

    const onSubmit = async (values) => {
      for(let i in values) if(values[i] === "") values[i] = null; // sinon génère une erreur : on met à null tout les champs vide (ract-hook-form semble le faire pour nous dans le cas ou on lui passe les valeurs par défaut en entré)

      if(global.config.modeDebugApp == 1) console.log("Values:::", values);
      if(global.config.modeDebugApp == 1) console.log("Values:::", JSON.stringify(values));
      
      setAumoinsUneModif(false); // pour éviter double enregistrement au double clique
      document.getElementById("hidden_marque_une_modif").value = 0;
      
      // création ou modification
      if(dataObjetPrincipal == null || dataObjetPrincipal.types_diplome_pour_moteurs_id == null) { //2d tst important comme c'est l'api qui pré rempli l'objet
        var dataR = await apiAppellationTypeDiplomeAjout(utilisateurBearer, values, idTypeCertif) // on envoie sans faire JSON.stringify
        if(global.config.modeDebugApp == 1) console.log('retour apiAppellationTypeDiplomeAjout : ', dataR);
      }
      else {
        var dataR = await apiAppellationTypeDiplomeModif(utilisateurBearer, values, dataObjetPrincipal.types_diplome_pour_moteurs_id)
        if(global.config.modeDebugApp == 1) console.log('retour apiAppellationTypeDiplomeModif : ', dataR);
      }

      switch (dataR.statusCode) {
          case 200: 
               alert("L'enregistrement s'est bien effectué.");
               await recupererListeCompleteElements();
               annulerChangements();
              break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
              break;
          case 400:
                setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas
              break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
    };
  
    function annulerChangements () { //si clique sur bouton annuler ou après enregistrement
      setMessageProbleme(null);
      setAumoinsUneModif(false);
      setShowForm(false);
      setDataObjetPrincipal(null);

      setLibBtAnnuler("Annuler l'ajout");
    }
    
    const onError = (error) => {
      console.log("ERROR:::", error);
    };

    async function chargementsConsecutifs() {
      setDataLoaded(false);
      let retP = await recupererListeCompleteElements();
      if(retP == true) {
        setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
      else {
        var ret = await recupererProprietes(utilisateurBearer, null, null, null, SetInfosProprietes, setMessageProbleme);
        if(ret == false) setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
      setDataLoaded(true);
    }

    useEffect(() => {
        if(global.config.modeDebugApp == 1) console.log('useffect AdmEditDipSyn');
        document.title = `Synonymes du type de certification ${idTypeCertif}`;
        if (utilisateurBearer === '000000' || utilisateurRole != "AdminExtranet" )  navigate(utilisateurRouteLogin); // si pas identifié ou role NOK on redirige sur l'acceuil
        setMessageProbleme(null);
        chargementsConsecutifs();

        var links = document.querySelectorAll('a');
         
        // Parcours des liens pour ajouter gestionnaire d'événement au clic
        for (var i = 0; i < links.length; i++) {
          links[i].addEventListener('click', confirmationSiCLickSUrLien);
        }
        // Nettoyage de l'écouteur d'événement lorsque le composant est démonté
        return () => {      
          for (var i = 0; i < links.length; i++) {
            links[i].removeEventListener('click', confirmationSiCLickSUrLien);
          }
        };
    }, []);

    useEffect(() => {
      setAumoinsUneModif(false);
    }, [dataApi]);
    

    const [showForm, setShowForm] = useState(false);
    return (
      <>
        <LoadingSpinner dataLoaded={dataLoaded}/>
        <MessageErreurApi message={messageProbleme} />
        <div onClick={() => window.history.back()} className="normal-navig-item"><span className="fas fa-circle-chevron-left"></span>Retour à la page précédente</div>

        {dataLoaded && dataApi && dataApi != null && 
        <Container className="my-0">
          {indicationSimple.length > 0 &&  <MessageInfoBox message={indicationSimple} />}

          {!showForm ? 
            <ButtonDeBase typeSubmit={true} lib="Ajouter un synonyme / une appellation" onClick={() => setShowForm(true)} />
          :
          <ButtonDeBase typeSubmit={true} lib={libBtAnnuler} className={"fas fa-arrow-rotate-left"} onClick={() =>annulerChangements(false)} />
          }

          {showForm && 
            <div>
              <FormRHF onSubmit={onSubmit} onError={onError} dataApi={dataObjetPrincipal} gestionEvenements={gestionEvenements} >             
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <div style={{marginTop:"10px", marginBottom:"20px", minWidth: "700px"}}>
                    <MessageInfoChampsObligatoires />
                  </div>
                  <div>
                    <ButtonDeBase typeSubmit={true} lib="Enregistrer" disabled={aumoinsUneModif == true ? false : true} />
                  </div>
                </div>
                <FormControl id="intitule_singulier" infosProp={infosProprietes["types_diplome_pour_moteur|intitule_singulier"]} />
                <FormControl id="prefixe" infosProp={infosProprietes["types_diplome_pour_moteur|prefixe"]} />
                <FormControl id="source" infosProp={infosProprietes["types_diplome_pour_moteur|source"]} />

                <div className="partie-bouton-droite">
                  <ButtonDeBase typeSubmit={true} lib="Enregistrer" disabled={aumoinsUneModif == true ? false : true} />
                </div>

                <FormHidden id="hidden_marque_une_modif" />
              </FormRHF>
            </div>
          }

          {dataApi && 
          <section className="section-generale">
              {dataApi.Appelations && dataApi.Appelations.length > 0 ? 
                <>
                  <div className="titre">Synonymes ou appellations déclarés</div><br />
                  <Table columns={columns} data={dataApi.Appelations} noPagination={true} noDisplayNbResult={true} noFilter={true} />
                </>
              :
              <div>Aucun synonyme n'a encore été déclaré.</div>
              }
          </section>
          }
          <br />
            
        </Container>
        }
      </>
    )

}


export default AdmEditTypDipSyn;