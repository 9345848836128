import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import { useNavigate } from "react-router-dom";

import { apiRecupereInfoGet } from '../../Datas/datasAutre.js'
import { apiQuiformeCompanieMasqueIntrouvable } from '../../Datas/datasCompanies.js'


import { DonneesUtilisateurContext } from '../../contexte.js';
import '../../config.js';

import MessageInfoBox, {MessageErreurApi} from '../../Components/Messages.jsx';
import LoadingSpinner from "../../Components/LoadingSpinner.js";
import {ButtonVoirFiche2, ButtonVoirFiche} from "../../Components/ButtonsCustom.jsx";
import CsvDownloader from "../../Components/CsvDownloader.jsx";

import Table, {SelectColumnFilter} from '../../Components/Table.jsx';

/**
 * Return Page pro liste des organismes non trouvés dans quiforme
 *
 * @component
 * @summary pour affichage liste des organismes non trouvés dans quiforme
 * @param {  }
 * @return { HTMLElement }
*/
function AdmQuiformeIntrouvables() {
    const navigate = useNavigate(); // utile pour changer de page

    const { setBearer, utilisateurRouteLogin, utilisateurBearer, utilisateurRole } = useContext(DonneesUtilisateurContext);
    const [dataApi, setDataApi] = useState();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [messageProbleme, setMessageProbleme] = useState('');
    const [indicationSimple, setIndicationSimple] = useState('');
    const [messageBase, setMessageBase] = useState('');

    const columns = useMemo(
        () => [
          {
            id:'listesResultats',
            hideHeader : false,
            trieDefautRp : 'SIRET',
            columns: [
                {  
                Header: 'ID COMPANIES', 
                accessor: 'ID_COMPANIES', 
                id: "ID_COMPANIES", // utile pour trie par défaut
                },
                { 
                Header: () => <>SIRET</>,
                accessor: 'SIRET', 
                },
                { 
                Header: () => <>Libellé de l'organisme</>,
                accessor: 'LIB_COMPANIES',  
                },
                { 
                Header: () => <>Sigle</>,
                accessor: 'SHORT_LIB',
                },
                { 
                Header: () => <>UAI</>,
                accessor: 'Numero_UAI', 
                },
                { 
                Header: () => <>Date vérif. Quiforme</>,
                accessor: 'date_traitementFr', 
                disableSortBy: true, // car date fr!
                },
                {
                Header: () => <></>,
                    accessor: 'actions', 
                    id: "actions_center", // le fait de mettre "center" dans l'id permet de center le contenu de la colone
                    disableSortBy: true,
                    Cell: ({ row }) => (  
                    <>
                        <a title="Masquer cet organisme" className="lib" onClick={() => handleClickChoixMasque(row.original.Quiforme_SysId)}>
                            <ButtonVoirFiche className="fas fa-eye-slash" />
                        </a> &nbsp;

                        <ButtonVoirFiche2 className="fas fa-pen-to-square" 
                                        lien={`edit/${row.original.ID_COMPANIES}`} 
                                        title={`Accéder à la fiche ${row.original.ID_COMPANIES}'}`} />
                    </>
                    ),
                },
            ]
          },
        ],
        []
    )

    const handleClickChoixMasque  = async (quiforme_SysId) => {
        setMessageProbleme("");
        setDataLoaded(false);
        const dataR = await apiQuiformeCompanieMasqueIntrouvable(utilisateurBearer, quiforme_SysId, 'PUT');
        setDataLoaded(true);

        switch (dataR.statusCode) {
            case 200:
                //navigate(`/admin/quiforme/introuvables-quiforme?q=1`); //nein ne recharge pas les données
                effectueRecherche();
                return true;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        } 
        
        return false;
    }            

   async function effectueRecherche() {
            setMessageProbleme("");
            setIndicationSimple("");
            setDataLoaded(false);

            const dataR = await apiRecupereInfoGet(utilisateurBearer, "quiforme/introuvables");
            if(global.config.modeDebugApp == 1) console.log(`+++++++retour apiRecupereInfoGet avec quiforme/introuvables`, dataR);
            setDataLoaded(true);

            switch (dataR.statusCode) {
                case 200:
                    setDataApi(dataR.dataApi);
                  return true;
                case 400:
                    var dataObjetVide = new Object();
                    setDataApi(dataObjetVide);
                    setIndicationSimple(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "Trop de resultat"
                    return true;
                case 401:
                    setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
                  break;
                default:
                    setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
            }            
            return false;
    }

    async function chargementsConsecutifs() {
        setDataLoaded(false);
        effectueRecherche();
        setMessageBase("Liste des organismes non trouvés dans quiforme : cliquez sur le pictogramme <span class='enrobage-boutton picto-legende'><span class='fas fa-eye-slash'></span></span> d'un of pour le faire disparaitre de cette liste.<br />Vous pouvez également modifier les informations de l'of en cliquant sur <span class='enrobage-boutton picto-legende'><span class='fas fa-pen-to-square'></span></span>. ");
        setDataLoaded(true);
    }
    

    useEffect(() => {
        if(global.config.modeDebugApp == 1) console.log('useffect 1st');
        document.title = "Quiforme : listes des introuvables"
        if (utilisateurBearer === '000000' || utilisateurRole != "AdminExtranet" )  navigate(utilisateurRouteLogin); // si pas identifié ou role NOK on redirige sur l'acceuil
        chargementsConsecutifs();
    }, []);


   return (     
        <main className="sous-bloc">            
            {process.env.REACT_APP_MY_ENV != 'production' && <MessageErreurApi message={global.config.msgPrTechnique.msgSrvTest} />}            
            <MessageErreurApi message={messageProbleme}  />
            <LoadingSpinner dataLoaded={dataLoaded} />

            { dataLoaded > 0 &&
            <>
                <MessageInfoBox message={messageBase} />
                <div style={{textAlign: "right", width: "100%"}}>
                <CsvDownloader urlApi="/quiforme/introuvablesGetCsv" boutonLib="Télécharger cette liste au format CSV" boutonTitle="Télécharger en CSV"
                               nomFicCible="Liste des Organismes non trouvés dans Quiforme.csv" />
                </div>
                { dataApi &&
                <>
                    {dataApi.length > 0 && <Table columns={columns} data={dataApi} noPagination={true} noDisplayNbResult={null} noFilter={true} paginationOntop={false} />}
                    {dataApi.length == 0 && indicationSimple.length == 0 &&  <div>Aucun organisme n'a été trouvé.<br /><br /></div>}
                    {indicationSimple.length > 0 &&  <MessageInfoBox message={indicationSimple} />}
                </>
                }

            </> } 
        </main>
    )
}


export default AdmQuiformeIntrouvables;