import PropTypes from 'prop-types';
import { NavLink } from "react-router-dom";
import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import Modal from 'react-bootstrap/Modal';

import { DonneesUtilisateurContext } from '../../contexte.js';

import { apiActAppelGetInfosDiv, apiActAssocDissoFormateur } from '../../Datas/dataActions.js'
import { apiRecupereInfoGet } from '../../Datas/datasAutre.js'

import LiensPrecedentSuivant from '../../Components/LiensPrecedentSuivant.jsx';
import LoadingSpinner from "../../Components/LoadingSpinner";
import MessageInfoBox, {MessageErreurApi} from '../../Components/Messages.jsx';
import Table from '../../Components/Table.jsx'
import { ButtonSuppr } from '../../Components/ButtonsCustom.jsx';
import FormDemandeAjoutAntenneFormateur from '../../Components/FormDemandeAjoutAntenneFormateur.jsx';
import { appelGetSetInfosObjetsFormActSess } from '../../fonctionsDiv.js';

/**
 * Return Formulaire étape 6 (association du ou des formateurs)
 *
 * @component
 * @summary page étape 6
 * @param {  }
 * @return { HTMLElement }
*/
function FormEtape6(props) {
    let numEtape = 6;

    const formationNumber = props.formationNumber;
    const actionNumber = props.actionNumber;

    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const [dataApi, setDataApi] = useState();
    const [dataLoaded, setDataLoaded] = useState(true);
    const [messageProbleme, setMessageProbleme] = useState();

    const [msgInfoF, setMsgInfoF] = useState();

    const handleCloseModalFormateurManquant = () => setShowModalFormateurManquant(false);
    const [showModalFormateurManquant, setShowModalFormateurManquant] = useState(false);

    //pour react-table https://stackoverflow.com/questions/56922381/how-to-include-url-in-react-table-cell
    const columns = useMemo(
        () => [
          {
            id:'listesOrganismeFormateurs',
            hideHeader : false,
            trieDefautRp : 'LIB_COMPANIES',
            columns: [
                { 
                  Header: 'Libellé organisme', 
                  accessor: 'LIB_COMPANIES',
                },
                { 
                  Header: 'Siret', 
                  accessor: 'SIRET',
                },
                {
                  Header: () => <>Principal</>,
                    accessor: 'PRINCIPAL', 
                    id: "PRINCIPAL_center",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                        <>
                          {row.original.PRINCIPAL == true ? 
                            <span className="enrobage-boutton"><span className="fas fa-star"></span></span>
                            :
                            <a title= "Définir comme principal"
                            onClick={() => handleClickSetPrincipal(row.original.ID_COMPANIES)}>
                              <span className="enrobage-boutton"><span className="far fa-star"></span></span>
                            </a>
                          }
                        </>
                    ),
                },
                {
                  Header: () => <></>,
                    accessor: 'actions', 
                    id: "asso_center",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                      <>
                      {/* si il y a déjà une session, on ne permet pas le changement de formateur */}
                      {props.infosGenFormation["nbSessions"] == 0 ? 
                          <a title="Dissocier" onClick={() => handleClickDisso(row.original.ID_COMPANIES)}>
                            <ButtonSuppr />
                          </a>
                          :
                          <a title="Suppression impossible, au moins une session existe" onClick={() => hdleClickNoPossible()}>
                            <ButtonSuppr disabled="disabled"  />
                          </a>
                      }
                      </>
                    ),
                },
            ]
          },
        ],
        []
    )
 
    function  hdleClickNoPossible () {
      alert("Impossible de supprimer cet organisme formateur car cette action a au moins une session.");
    }
    const handleClickAssoFormateur = async (codeOrgFormAasso) => {
          setDataLoaded(false);
          setMessageProbleme();
          var dataR = await apiActAssocDissoFormateur(utilisateurBearer, actionNumber, codeOrgFormAasso, 'PUT');

          switch (dataR.statusCode) {
              case 200:
                  await recupFormateursDeLaction(); // reload la liste
                  alert("L'association a bien été effectuée.");

                  var ret = await recuperOrganismesMemeSIREN();
                  if(ret != null) SetorganismesMemeSiren(ret); else pb = true;
                break;
              case 401:
                  setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
                break;
                case 400:
                  setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "L'organisme est déjà associé"
                break;
              default:
                  setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
          }
          setDataLoaded(true);
    }

    const handleClickDisso  = async (codeOrgFormAasso) => {
        //var confirmation = confirm('La dissociation d\'un organisme fomateur entraine la dissociation des lieux ainsi que des éventuels contacts de cet organisme qui étaient précisés dans la partie "Contact" des Sessions.\r\n Confirmez vous ?');
        var confirmation = true; // plus nécessaire car  pas de suppr possible si il y a une session à l'action
        if(confirmation == true) {
          setDataLoaded(false);
          setMessageProbleme();
          var dataR = await apiActAssocDissoFormateur(utilisateurBearer, actionNumber, codeOrgFormAasso, 'DELETE');

          switch (dataR.statusCode) {
              case 200:
                  var ret = await recuperOrganismesMemeSIREN();
                  if(ret != null) SetorganismesMemeSiren(ret); else pb = true;
                  await recupFormateursDeLaction(); // reload la liste

                  if(dataR.dataApi != "") alert(dataR.dataApi);
                  else alert("La dissociation a bien été effectuée");
                break;
              case 401:
                  setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
                break;
              default:
                  setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
          }
          setDataLoaded(true);
       }
    }
    const handleClickSetPrincipal  = async (codeOrgFormAasso) => {
      var dataR = await apiActAssocDissoFormateur(utilisateurBearer, actionNumber, codeOrgFormAasso, 'POST', '/setprincipal');
      if(global.config.modeDebugApp == 1) console.log('retour apiActAssocDissoFormateur : ', dataR);
      switch (dataR.statusCode) {
          case 200:
            await recupFormateursDeLaction(); // reload la liste
            alert("Modification effectuée.");
            break;
            case 400:
              setMessageProbleme(dataR.dataApi);
              break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
            break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
    }
    
    async function recupFormateursDeLaction() {
        const dataR = await apiActAppelGetInfosDiv(utilisateurBearer, "/" + actionNumber + "/formateurs");
        if(global.config.modeDebugApp == 1) console.log('retour apiActAppelGetInfosDiv : ', dataR);

        switch (dataR.statusCode) {
            case 200:
                setDataApi(dataR.dataApi);

                //var objaAmodif = JSON.parse(JSON.stringify(props.infosGenFormation)); // on clone (duplique) l'objet pour que tout fonctionne bien
                //objaAmodif.nbFormateurs = dataR.dataApi.length;
                //objaAmodif.etape6 = dataR.dataApi.length > 0 ? "ok" : "nok";
                //props.SetInfosGenFormation(objaAmodif);
                // pour que le systeme calcule la validité Lhéo....
                var retAppel = await appelGetSetInfosObjetsFormActSess(utilisateurBearer, formationNumber, actionNumber, null, props.SetInfosGenFormation, setMessageProbleme);
              break;
              case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
    }

    async function recuperOrganismesMemeSIREN() {
        const dataR = await apiRecupereInfoGet(utilisateurBearer, "organismes/meme-SIREN/" + actionNumber);
        if(global.config.modeDebugApp == 1) console.log(`retour apiRecupereInfoGet avec "organismes/meme-SIREN/${actionNumber}" : `, dataR);
        switch (dataR.statusCode) {
            case 200:            
                return dataR.dataApi;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
                return null;
        }
    }
    const [organismesMemeSiren, SetorganismesMemeSiren] = useState(); // pour recup données du select
    async function chargementsConsecutifs() {
        var pb = false;
        setDataLoaded(false);
        await recupFormateursDeLaction();

        var ret = await recuperOrganismesMemeSIREN();
        if(ret != null) SetorganismesMemeSiren(ret); else pb = true;

        if(pb == true ) setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut

        setDataLoaded(true);
    }

    useEffect(() => {
      if(global.config.modeDebugApp == 1)  console.log('useffect FormEtape6, props.infosGenFormation : ', props.infosGenFormation)
      document.title = `Formateur(s) de l'action ${actionNumber}`;
      chargementsConsecutifs();
      
      var msgPrincipal ="L'organisme responsable est par défaut désigné comme formateur lorsqu'une action est créée.<br />Une action peut être associée à un ou plusieurs organismes formateurs.";
      {/* si il y a déjà une session, on ne permet pas le changement de formateur */}
      if(props.infosGenFormation["nbSessions"]> 0) msgPrincipal += "<br /><br />Vous ne pouvez pas modifier cette partie car cette action a au moins une session.";
      setMsgInfoF(msgPrincipal);
    }, []);

    var messageInfoCiAsso = "L'organisme formateur principal est marqué par une étoile <span class='picto-legende'><span class='fas fa-star'></span></span>.";
    messageInfoCiAsso += "<br />Vous pouvez en désigner un autre en cliquant sur <span class='picto-legende2'><span class='far fa-star'></span></span>.";
  
    
    return (
        <>
          <LoadingSpinner dataLoaded={dataLoaded} />
          <MessageErreurApi message={messageProbleme} />
          <MessageInfoBox message={msgInfoF} />

          {dataLoaded && dataApi &&
          <section className="organismes-associes">
            {/* si il y a déjà une session, on ne permet pas le changement de formateur
            ci dessous petit effet pour avoir un background différent dans la liste en fonction du modulo de l'index idx (position du tabeleau fournit à map) */}
            { props.infosGenFormation["nbSessions"] == 0 &&
              <>
                { organismesMemeSiren.length > 0 &&
                <select className="form-control custom-select" id="theList" 
                        title="Organismes" 
                        onChange={(e) => {handleClickAssoFormateur(e.target.value);}}>
                    <option value="" key="-">Sélectionner un organisme à déclarer comme formateur pour cette action</option>
                    {organismesMemeSiren && organismesMemeSiren.map((item, idx) => {
                        return (
                            <option value={item.ID_COMPANIES} key={item.ID_COMPANIES} style={{ backgroundColor: idx % 2 === 0 ? '#fcfcfc' : 'white' }}>
                                {item.LIB_COMPANIES} {item.SIRET} 
                            </option>
                        );
                        })}
                </select>
                }
                Si vous souhaitez déclarer une nouvelle antenne à votre organisme (SIRET différent du siège), renseignez <NavLink className="main-nav-item" to="" onClick={() => setShowModalFormateurManquant(true)}>ce formulaire 📰</NavLink><br /><br />
              </> 
            }
            {/* liste des formateurs associés */}
            <div className="titre">{dataApi.length > 1 ? `${dataApi.length} organismes formateurs sont actuellement associés à votre action :` : "Un organisme formateur est actuellement associé à votre action : "}</div>
            {dataApi && dataApi.length > 1 && <MessageInfoBox message={messageInfoCiAsso} nomClasse="message-info2"/>}
            {dataApi && dataApi.length > 0 && <Table columns={columns} data={dataApi} noPagination={true} noDisplayNbResult={true} noFilter={true} />}
            {dataApi && dataApi.length == 0 && <div>Aucune organisme n'est associé.</div>}

          
            {showModalFormateurManquant &&
              <Modal size="lg" show={showModalFormateurManquant} onHide={handleCloseModalFormateurManquant} backdrop="static" keyboard={false} centered aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                  <Modal.Title id="modal-insciption">Demande d'ajout d'une antenne</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormDemandeAjoutAntenneFormateur handleCloseModal={handleCloseModalFormateurManquant} />
                </Modal.Body>
              </Modal>
            }
          </section>
          }

          <LiensPrecedentSuivant formationNumber={formationNumber} actionNumber={actionNumber} numEtape={numEtape} infosGenFormation={props.infosGenFormation} notifBeCareful={false} />
        </>
    )

}

FormEtape6.propTypes = {
  formationNumber: PropTypes.number.isRequired,
  actionNumber: PropTypes.number.isRequired,
  SetInfosGenFormation : PropTypes.func,
  infosGenFormation: PropTypes.object,
}

export default FormEtape6;