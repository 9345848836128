import PropTypes from 'prop-types';
import { InfosGeneriquesSession2 } from './InfosGeneriquesSession.jsx';

/**
 * Component for Fil d'Ariane
 *
 * @component
 * @return { HTMLElement }
*/
function FilAriane(props) {
        //console.log(`props : ${props}`)
    
    var complementLibCerifOuNonCertif = (props.infosGenFormation.certifOuPro == "certif") ? " <b>certifiante</b> " : " <b>non certifiante</b> ";
    var stringFilAriane = "Création d'une formation" + complementLibCerifOuNonCertif;

    var stringFilArianeLocation = "";

    if(props.infosGenFormation !== undefined) {
        if(props.infosGenFormation.LIB_ACTIONS_FORMATION != "" && props.infosGenFormation.LIB_ACTIONS_FORMATION != null) {
            stringFilAriane = 'Edition de la formation'+ complementLibCerifOuNonCertif + '"' + props.infosGenFormation.LIB_ACTIONS_FORMATION  + '"';
        }

        if(props.nomEtape == 'etape1') {
            if(props.infosGenFormation.certifOuPro ==  "pro") stringFilArianeLocation = `<b>Non certifiant</b> préparé par la formation ${props.formationNumber}`;
            else stringFilArianeLocation = `<b>Certification(s)</b> préparées par la formation ${props.formationNumber}`;
        }    
        //else if(props.nomEtape == 'etape1b') stringFilArianeLocation = `Non certifiant préparé par la formation ${props.formationNumber}`;
        else if(props.nomEtape == 'etape2')  stringFilArianeLocation = `Informations principales de la formation ${props.formationNumber}`;
        else if(props.nomEtape == 'etape3')  stringFilArianeLocation = `Contact(s) de la formation ${props.formationNumber}`;
        else if(props.nomEtape == 'etape4') stringFilArianeLocation = `Action(s) de la formation ${props.formationNumber}`;
        else if(props.nomEtape == 'etape5') {
            stringFilArianeLocation = `Informations principales de l'action ${props.actionNumber}`;
            if(props.infosGenFormation.isContratProfessionnalisation == true) stringFilArianeLocation += ' <b>préparée dans le cadre du contrat de professionnalisation</b>'
        }
        else if(props.nomEtape == 'etape6') stringFilArianeLocation = `Formateur(s) de l'action ${props.actionNumber}`;
        else if(props.nomEtape == 'etape7') stringFilArianeLocation = `Lieu(x) de formation et des certifications préparées pour l'action ${props.actionNumber}`;
        else if(props.nomEtape == 'etape8') stringFilArianeLocation = `Equipement(s) de l'action ${props.actionNumber}`;
        else if(props.nomEtape == 'etape9') stringFilArianeLocation = `Session(s) de l'action ${props.actionNumber}`;
        else if(props.nomEtape == 'etape10') stringFilArianeLocation = `Informations principales de la session ${props.sessionNumber}`;
        else if(props.nomEtape == 'etape11') stringFilArianeLocation = `Certification(s) et/ou blocs de compétences préparées par la session ${props.sessionNumber}`;
        else if(props.nomEtape == 'etape12') stringFilArianeLocation = `Contact(s) de la session ${props.sessionNumber}`;
        else if(props.nomEtape == 'etape13') stringFilArianeLocation = `Recrutement(s) de la session ${props.sessionNumber}`;
        else if(props.nomEtape == 'etape14') stringFilArianeLocation = `Cycle(s) de la session ${props.sessionNumber}`;

        if(!isNaN(props.sessionNumber) && props.infosGenFormation.sess_DEBUT_fr != null) stringFilArianeLocation +=  ` <b>du ${props.infosGenFormation.sess_DEBUT_fr} au ${props.infosGenFormation.sess_FIN_fr}</b>`; 

        return (        
            /*<div className="ariane" dangerouslySetInnerHTML={{ __html:  stringFilAriane }}></div>*/
            <div style={{display: "inline-flex", justifyContent: "space-between", width: "100%"}}>
                <div className="ariane" >
                    <span className="ariane-titre" dangerouslySetInnerHTML={{ __html:  stringFilAriane }}></span>
                    {stringFilArianeLocation != null && stringFilArianeLocation != "" &&
                    <>
                        <br/>
                        <span className="ariane-fleche">
                            <span className="fas fa-location-arrow"></span>
                        </span>
                        <span className="ariane-sous-titre" dangerouslySetInnerHTML={{ __html:  stringFilArianeLocation }}></span>
                    </>
                    }
                </div>
                <InfosGeneriquesSession2 infosGenFormation={props.infosGenFormation} 
                                         formationNumber={props.formationNumber} actionNumber={props.actionNumber} sessionNumber={props.sessionNumber} />
            </div>
        )
    }
    return (<></>)
}

FilAriane.propTypes = {
    infosGenFormation: PropTypes.object,
    nomEtape: PropTypes.string.isRequired,
    formationNumber: PropTypes.number.isRequired,
    actionNumber: PropTypes.number.isRequired,
    sessionNumber: PropTypes.number.isRequired,
}

export default FilAriane;