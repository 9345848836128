import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';

import { apiRecupereInfoGet } from '../Datas/datasAutre.js'
import { DonneesUtilisateurContext } from '../contexte.js';
import '../config.js';

import MessageInfoBox, {MessageErreurApi} from '../Components/Messages.jsx';
import LoadingSpinner from "../Components/LoadingSpinner.js";

import Table, {SelectColumnFilter} from '../Components/Table.jsx';

/**
 * Return l'historique d'un organisme (rapport à Quiforme)
 * @component
 * @summary page listnat les modif d'un organisme
 * @param {  }
 * @return { HTMLElement }
*/
function AdmListHistoQuiforme(props) {
    const navigate = useNavigate(); // utile pour changer de page

    const { setBearer, utilisateurRouteLogin, utilisateurBearer, utilisateurRole } = useContext(DonneesUtilisateurContext);
    const [dataApi, setDataApi] = useState();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [messageProbleme, setMessageProbleme] = useState('');
    const [indicationSimple, setIndicationSimple] = useState('');
    const [messageBase, setMessageBase] = useState('');


    const columns = useMemo(
        () => [
          {
            id:'listesResultats',
            hideHeader : false,
            trieDefautRp : 'StartDate',
            columns: [
                {  
                Header: 'Période début', 
                accessor: 'StartDate', 
                id: "StartDate", // utile pour trie par défaut
                disableSortBy: true,
                },
                {  
                Header: 'Période fin', 
                accessor: 'EndDate', 
                id: "EndDate", // utile pour trie par défaut
                disableSortBy: true,
                },
                {  
                Header: 'SIRET', 
                accessor: 'siret', 
                disableSortBy: true,
                },
                { 
                Header: () => <>Libellé de l'organisme<br />Brof</>,
                accessor: 'db_LIB_COMPANIES',
                disableSortBy: true,
                },
                { 
                Header: () => <>Libellé de l'organisme<br />Qf</>,
                accessor: 'qf_LIB_COMPANIES', 
                disableSortBy: true, 
                },
                { 
                Header: () => <>UAI<br />Brof</>,
                accessor: 'db_Numero_UAI', 
                disableSortBy: true,
                },
                { 
                Header: () => <>UAI<br />Qf</>,
                accessor: 'qf_Numero_UAI', 
                disableSortBy: true,
                },                
                {
                Header: () => <>Numéro DE<br />Brof</>,
                accessor: 'db_NUM_DE', 
                disableSortBy: true,
                },
                {
                Header: () => <>Numéro DE<br />Qf</>,
                accessor: 'qf_NUM_DE', 
                disableSortBy: true,
                },



                { 
                Header: () => <>FC<br />Brof</>,
                accessor: 'db_Qualiopi_Form_FormationContinue', 
                disableSortBy: true,
                },
                { 
                Header: () => <>FC<br />Qf</>,
                accessor: 'qf_Qualiopi_Form_FormationContinue', 
                disableSortBy: true,
                },
                {
                Header: () => <>bilan Comp.<br />Brof</>,
                accessor: 'db_Qualiopi_Form_BilanCompetence', 
                disableSortBy: true,
                },
                {
                Header: () => <>bilan Comp.<br />Qf</>,
                accessor: 'qf_Qualiopi_Form_BilanCompetence', 
                disableSortBy: true,
                },
                { 
                Header: () => <>VAE<br />Brof</>,
                accessor: 'db_Qualiopi_Form_VAE', 
                disableSortBy: true,
                },
                { 
                Header: () => <>VAE<br />Qf</>,
                accessor: 'qf_Qualiopi_Form_VAE', 
                disableSortBy: true,
                },
                {
                Header: () => <>Appr<br />Brof</>,
                accessor: 'db_Qualiopi_Form_Apprentissage', 
                disableSortBy: true,
                },
                {
                Header: () => <>Appr<br />Qf</>,
                accessor: 'qf_Qualiopi_Form_Apprentissage', 
                disableSortBy: true,
                },


                { 
                Header: () => <>Adresse<br />Brof</>, 
                accessor: 'db_ADDRESS1', 
                disableSortBy: true,
                },
                { 
                Header: () => <>Adresse<br />Qf</>,
                accessor: 'qf_ADDRESS1', 
                disableSortBy: true,
                },
                {
                Header: () => <>Cp<br />Brof</>,
                accessor: 'db_CODE_POSTAL', 
                disableSortBy: true,
                },
                {
                Header: () => <>Cp<br />Qf</>,
                accessor: 'qf_CODEPOSTAL', 
                disableSortBy: true,
                },
                {
                Header: () => <>Code<br />Insee Brof</>,
                accessor: 'db_CODE_INSEE', 
                disableSortBy: true,
                },
                {
                Header: () => <>Code<br />Insee Qf</>,
                accessor: 'qf_CODE_INSEE', 
                disableSortBy: true,
                },
            ]
          },
        ],
        []
    )
            
   async function effectueRecherche() {
        if(global.config.modeDebugApp == 1) console.log(`fct effectueRecherche lancée`);

            setMessageProbleme("");
            setIndicationSimple("");
            setDataLoaded(false);

            const dataR = await apiRecupereInfoGet(utilisateurBearer, "quiforme/" + props.idCompanie + "/historique");
            if(global.config.modeDebugApp == 1) console.log(`+++++++retour apiRecupereInfoGet avec quiforme/` + props.idCompanie + `/historique` + ` : `, dataR);
            setDataLoaded(true);

            switch (dataR.statusCode) {
                case 200:
                    setMessageBase("Historique pour cet organisme :");
                    setDataApi(dataR.dataApi);
                  return true;
                case 400:
                    var dataObjetVide = new Object();
                    setDataApi(dataObjetVide);
                    setIndicationSimple(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "Trop de resultat"
                    return true;
                case 401:
                    setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
                  break;
                default:
                    setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
            }            
            return false;        
    }

    async function chargementsConsecutifs() {
        setDataLoaded(false);
        effectueRecherche();
        setDataLoaded(true);
    }
    

    useEffect(() => {
        if(global.config.modeDebugApp == 1) console.log('useffect 1st');
        document.title = "Quiforme : recherche des différences"
        if (utilisateurBearer === '000000' || utilisateurRole != "AdminExtranet" )  navigate(utilisateurRouteLogin); // si pas identifié ou role NOK on redirige sur l'acceuil
        chargementsConsecutifs();
    }, []);


   return (     
        <main className="sous-bloc">
            <MessageErreurApi message={messageProbleme}  />
            <LoadingSpinner dataLoaded={dataLoaded} />

            { dataLoaded > 0 &&
            <>
                <MessageInfoBox message={messageBase} />

                { dataApi &&
                <>
                    {dataApi.length > 0 && <Table columns={columns} data={dataApi} noPagination={true} noDisplayNbResult={true} noFilter={null} paginationOntop={false} />}
                    {dataApi.length == 0 && indicationSimple.length == 0 &&  <div>Aucun historique n'a été trouvé.<br /><br /></div>}
                    {indicationSimple.length > 0 &&  <MessageInfoBox message={indicationSimple} />}
                </>
                }

            </> } 
        </main>
    )
}


export default AdmListHistoQuiforme;

AdmListHistoQuiforme.propTypes = {
    idCompanie: PropTypes.string.isRequired
}