import PropTypes from 'prop-types';
import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import Modal from 'react-bootstrap/Modal';
import { NavLink } from "react-router-dom";

import { DonneesUtilisateurContext } from '../../contexte.js';

import { apiSessAppelGetInfosDiv, apiSessAssocDissoContact } from '../../Datas/dataSessions.js';

import LiensPrecedentSuivant from '../../Components/LiensPrecedentSuivant.jsx';
import LoadingSpinner from "../../Components/LoadingSpinner";
import MessageInfoBox, {MessageErreurApi, MessageInfoNoDroitErreurApi } from '../../Components/Messages.jsx';
import Table from '../../Components/Table.jsx'
import { ButtonSuppr } from '../../Components/ButtonsCustom.jsx';
import { appelRecupInfoGet } from '../../fonctionsDiv.js'

/**
 * Return Formulaire étape 12 (association du ou des contacts de session) = diapo 23
 *
 * @component
 * @summary page étape 12
 * @param {  }
 * @return { HTMLElement }
*/
function FormEtape12(props) {
    let numEtape = 12;

    const formationNumber = props.formationNumber;
    const actionNumber = props.actionNumber;
    const sessionNumber = props.sessionNumber;

    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const [dataApi, setDataApi] = useState();
    const [dataLoaded, setDataLoaded] = useState(true);
    const [messageProbleme, setMessageProbleme] = useState();
    const [msgInfoF, setMsgInfoF] = useState();

    const handleCloseModal = () => setShowModal(false);
    const [showModal, setShowModal] = useState(false);

    const [contactSelectionne, setContactSelectionne] = useState(false);

    //pour react-table https://stackoverflow.com/questions/56922381/how-to-include-url-in-react-table-cell
    const columns = useMemo(
        () => [
          {
            id:'listeContactsDeLaSession',
            hideHeader : false,
            trieDefautRp : 'identite_nom_prenom',
            columns: [
                { 
                  Header: 'Identité', 
                  accessor: 'identite_nom_prenom',
                },
                { 
                  Header: 'Adresse', 
                  accessor: 'ADDRESS1',
                },
                { 
                  Header: 'Ville', 
                  accessor: 'LIB_GEO_VILLES',
                },
                { 
                  Header: 'Cp', 
                  accessor: 'CODE_POSTAL',
                },
                { 
                  Header: 'Tél fixe', 
                  accessor: 'PERSONS_TELFIXE',
                },
                { 
                  Header: 'Courriel', 
                  accessor: 'PERSONS_COURRIEL',
                },
                { 
                  Header: 'Type de contact', 
                  accessor: 'LIB_LHEO_TYPE_CONTACT',
                },
                {
                  Header: () => <>Principal</>,
                    accessor: 'PRINCIPAL', 
                    id: "PRINCIPAL_center",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                      <>
                      { (row.original.noEditablePLease != 1 && props.infosGenFormation.act_ID_REF_EXTERNE == "") ? //si c'est une offre flux on ne peut pas changer le principal (pour sudregion)
                          <>
                            {row.original.PRINCIPAL == true ? 
                              <span className="enrobage-boutton"><span className="fas fa-star"></span></span>
                              :
                              <a title= "Définir comme principal"
                              onClick={() => handleClickSetPrincipal(row.original.ID_ACTIONS_SESSIONS_PERIODE_CONTACT)}>
                                <span className="enrobage-boutton"><span className="far fa-star"></span></span>
                              </a>
                            }
                          </>
                        :
                        <>
                        {row.original.PRINCIPAL == true ? 
                          <span className="enrobage-boutton"><span className="fas fa-star"></span></span>
                          :
                          <span className="enrobage-boutton"><span className="far fa-star"></span></span>
                        }
                        </>
                      }
                      </>
                    ),
                },
                {
                  Header: () => <></>,
                    accessor: 'actions', 
                    id: "asso_center",
                    disableSortBy: true,
                    Cell: ({ row }) => (
                    <>
                      { (row.original.noEditablePLease != 1 && row.original.id_ref_externe == '') ? //si c'est un contact flux on ne peut le supprimer (pour sudregion)
                        <a title="Dissocier" onClick={() => handleClickDisso(row.original.ID_ACTIONS_SESSIONS_PERIODE_CONTACT)}>
                          <ButtonSuppr />
                        </a>
                      :
                        <ButtonSuppr disabled="disabled" />
                      }
                    </>
                    ),
                },
            ]
          },
        ],
        []
    )
 
    const handleChoixContact = async (codeContactAasso) => {
      setContactSelectionne(codeContactAasso);
      setShowModal(true);
    }
    const handleClickAsso = async (codeTypeContact) => {
          setDataLoaded(false);
          setMessageProbleme();
          setShowModal(false); // on cache la modale
          var dataR = await apiSessAssocDissoContact(utilisateurBearer, sessionNumber, contactSelectionne, 'PUT', "", codeTypeContact);

          switch (dataR.statusCode) {
              case 200:
                  await recupContactsDeLaSession(); // reload la liste
                  alert("L'association a bien été effectuée.");
                break;
              case 401:
                  setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
                break;
                case 400:
                  setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas
                break;
              default:
                  setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
          }
          setDataLoaded(true);
    }

    const handleClickDisso  = async (idPrimaire) => {
        setDataLoaded(false);
        setMessageProbleme();
        var dataR = await apiSessAssocDissoContact(utilisateurBearer, sessionNumber, idPrimaire, 'DELETE');

        switch (dataR.statusCode) {
            case 200:
                await recupContactsDeLaSession(); // reload la liste
                if(dataR.dataApi != "") alert(dataR.dataApi);
                else alert("La dissociation a bien été effectuée");
              break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
        setDataLoaded(true);
    }

    const handleClickSetPrincipal  = async (idPrimaire) => {
      var dataR = await apiSessAssocDissoContact(utilisateurBearer, sessionNumber, idPrimaire, 'POST', '/setprincipal');
      if(global.config.modeDebugApp == 1) console.log('retour apiFormAssocDissoContact : ', dataR);
      switch (dataR.statusCode) {
          case 200:
            await recupContactsDeLaSession(); // reload la liste
            alert("Modification effectuée.");
            break;
            case 400:
              setMessageProbleme(dataR.dataApi);
              break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
            break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
    }
    async function recupContactsDeLaSession() {
        const dataR = await apiSessAppelGetInfosDiv(utilisateurBearer, "/" + sessionNumber +  "/contacts");
        if(global.config.modeDebugApp == 1) console.log('retour apiSessAppelGetInfosDiv avec "XXX/contacts" : ', dataR);

        switch (dataR.statusCode) {
            case 200:
              if(props.infosProprietes["ACTIONS_SESSIONS_PERIODE_CONTACT|other_dataEditable"].editable  == false) {
                //modification du contenu renvoyé par l'api (ajout d'une information)
                let nouveauTabRep = [];
                dataR.dataApi.forEach((el) => {
                    // on va ajouter une notion de non modifiable
                    let ligne = { ...el, 'noEditablePLease': 1}
                    nouveauTabRep.push(ligne);           
                });
                //console.log("dataApi modifié:", nouveauTabRep);
                setDataApi(nouveauTabRep);
              }
              else setDataApi(dataR.dataApi);
                
                var objaAmodif = JSON.parse(JSON.stringify(props.infosGenFormation)); // on clone (duplique) l'objet pour que tout fonctionne bien
                objaAmodif.nbContactsSession = dataR.dataApi.length;
                // ne pas faire cela, vu que les contacts sont optionnels dans la session objaAmodif.etape12 = dataR.dataApi.length > 0 ? "ok" : "nok";
                props.SetInfosGenFormation(objaAmodif);
              break;
            case 401:
                setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
            default:
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
    }

    const [contactsPourChoix, SetContactsPourChoix] = useState(); // pour recup données du select
    const [typesContactPourChoix, SetTypesContactPourChoix] = useState(); // pour recup données du select
    async function chargementsConsecutifs() {
        var pb = false;
        setDataLoaded(false);
        await recupContactsDeLaSession();
 
        if(props.infosProprietes["ACTIONS_SESSIONS_PERIODE_CONTACT|other_dataEditable"].editable  == true) {
          var ret = await appelRecupInfoGet(utilisateurBearer, "persons/contacts-formateurs-action/" + actionNumber, setMessageProbleme);// recuperation des contacts pour liste select
          if(ret != null) SetContactsPourChoix(ret); else pb = true;
        }

        var ret = await appelRecupInfoGet(utilisateurBearer, "LHEO_TYPE_CONTACT", setMessageProbleme); // recuperation des types de contact pour liste select
        if(ret != null) SetTypesContactPourChoix(ret); else pb = true;

        if(pb == true ) setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut

        setDataLoaded(true);
    }

    useEffect(() => {
      if(global.config.modeDebugApp == 1)  console.log('useffect FormEtape12, props.infosGenFormation : ', props.infosGenFormation)
      document.title = `Contact(s) de la session ${sessionNumber}`;
      chargementsConsecutifs();
      setMsgInfoF("Une session peut être associée à un ou plusieurs contacts<br />Le renseignement de cette étape est facultatif.");
    }, []);

    return (
        <>
          <LoadingSpinner dataLoaded={dataLoaded} />
          <MessageErreurApi message={messageProbleme} />

          {dataLoaded && dataApi &&
          <>
            { props.infosProprietes["ACTIONS_SESSIONS_PERIODE_CONTACT|other_dataEditable"].editable == 0 ?
              <MessageInfoNoDroitErreurApi />
            :
              <>
              <MessageInfoBox message={msgInfoF} />

              <select className="form-control custom-select" id="theList" 
                      title="Contacts possibles" 
                      onChange={(e) => {handleChoixContact(e.target.value);}}>
                  <option value="" key="-">Sélectionner un contact parmi ceux du ou des formateurs à associer à cette session</option>
                  {contactsPourChoix && contactsPourChoix.map((item) => {
                      return (
                        <option value={item.ID_PCF} key={item.ID_PCF}>
                        {item.lib_concatene_adr_person}
                        </option>
                      );
                      })}
              </select>


              {/*cette modale ne s'affichera que lorsque l'utilsateur aura choisi le contact à associer (en second temps donc) */}
              {showModal && typesContactPourChoix &&
              <Modal size="lg" show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} centered aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                      Précisez le type de contact
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <select className="form-control custom-select" id="types-contact-list" 
                          title="Types de contact" 
                          onChange={(e) => {handleClickAsso(e.target.value);}}>
                      <option value="" key="-">Sélectionner un type de contact</option>
                      {typesContactPourChoix && typesContactPourChoix.map((item) => {
                          return (
                              <option value={item.id} key={item.id}>
                                  {item.lib}
                              </option>
                          );
                          })}
                  </select>
                </Modal.Body>
              </Modal>
              }
                Vous ne trouvez pas le bon contact 👀 ? 
                Vous pouvez ajouter un contact à votre organisme en <NavLink className="main-nav-item" style={{textDecoration: "underline"}} 
                to={"/lieux-et-contacts/creation-personne?gobackto=/formation/etape12/" + formationNumber + "/action-" + actionNumber + "/session-" + sessionNumber}>cliquant ici</NavLink><br /><br />
            </>
            }

            <section className="contacts-associes">
                <div className="titre">{dataApi.length > 1 ? "Contacts associés à la session :" : "Contact associé à la session : "}</div>
                {dataApi && dataApi.length > 0 && <Table columns={columns} data={dataApi} noPagination={true} noDisplayNbResult={true} noFilter={true} />}
                {dataApi && dataApi.length == 0 && <div>Aucun contact n'est associé.</div>}
            </section>
            <br />
          </>
          }

          <LiensPrecedentSuivant formationNumber={formationNumber} actionNumber={actionNumber} sessionNumber={sessionNumber} numEtape={numEtape} infosGenFormation={props.infosGenFormation} notifBeCareful={false} />
        </>
    )

}

FormEtape12.propTypes = {
  formationNumber: PropTypes.number.isRequired,
  actionNumber: PropTypes.number.isRequired,
  sessionNumber: PropTypes.number.isRequired,
  SetInfosGenFormation : PropTypes.func,
  infosGenFormation: PropTypes.object,
}

export default FormEtape12;