import PropTypes from 'prop-types';
import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import { Container } from "react-bootstrap";

import { DonneesUtilisateurContext } from '../../contexte.js';
import { apiSessAppelGetInfosDiv, apiSessModif } from '../../Datas/dataSessions.js'
import { appelGetSetInfosObjetsFormActSess, appelRecupInfoGet, confirmationSiCLickSUrLien } from '../../fonctionsDiv.js';

import LiensPrecedentSuivant from '../../Components/LiensPrecedentSuivant.jsx';
import LoadingSpinner from "../../Components/LoadingSpinner";
import MessageInfoBox, {MessageErreurApi, MessageInfoChampsObligatoires} from '../../Components/Messages.jsx';
import ButtonDeBase from '../../Components/ButtonsCustom.jsx';
import FormRHF from "../../Components/Form/Form";
import FormControl from "../../Components/Form/FormControl";
import FormSelect from "../../Components/Form/FormSelect";
import FormHidden from "../../Components/Form/FormHidden";
//import FormDemandeChangementSession from '../../Components/FormDemandeChangementSession.jsx';

import {dateModifSmallDatTimeToAmericAvecSep, verifOrdreDateDebutFin, dateSixMoisAnterieurYYYYmmdd, dateDansXXAnsYYYYmmdd, dateDansXXAnsddmmYYYY, validateDate} from '../../fonctionsDiv.js';
/**
 * Return Formulaire étape 10 (informations principales d'une session)
 *
 * @component
 * @summary page étape 10
 * @param {  }
 * @return { HTMLElement }
*/
function FormEtape10(props) {
    let numEtape = 10;

    const formationNumber = props.formationNumber;
    const actionNumber = props.actionNumber;
    const sessionNumber = props.sessionNumber;

    const { utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const [dataApi, setDataApi] = useState();
    const [aumoinsUneModif, setAumoinsUneModif] = useState(false); // pour avertir l'utilisateur en cas de sortie sans  enregistrement
    const [modifDateSess, setModifDateSess] = useState(false); // pour avertir l'utilisateur sur l'incidence du changement d'une date de session
    
    const [dataLoaded, setDataLoaded] = useState(true);
    const [messageProbleme, setMessageProbleme] = useState();

    const tabDatesAgerer = ["DATE_DEBUT", "DATE_FIN", "periode_inscription_debut", "periode_inscription_fin"]; // declaration tableau dates a modifier

    async function recupererInfosPrincipales() {
      const dataR = await apiSessAppelGetInfosDiv(utilisateurBearer, "/"+sessionNumber);
      if(global.config.modeDebugApp == 1) console.log('retour apiSessAppelGetInfosDiv dans recupererInfosPrincipales : ', dataR);
      switch (dataR.statusCode) {
        case 200:          
          for (let i = 0; i<tabDatesAgerer.length; i++) {
            if(dataR.dataApi[tabDatesAgerer[i]] != null) dataR.dataApi[tabDatesAgerer[i]] = dateModifSmallDatTimeToAmericAvecSep(dataR.dataApi[tabDatesAgerer[i]]);
          }
          //if(dataR.dataApi.urlweb1 != null) setUrlWeb2Visible(true);
          //if(dataR.dataApi.urlweb2 != null) setUrlWeb3Visible(true);

          setDataApi(dataR.dataApi);
          //console.log("dataApi", dataR.dataApi)
          return true;
        case 401:
          setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
          break;
        default:
          setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
      return false;
    }
    
    //pour effectuer des actions en fonction de la saisie de l'utilisateur
    //const [urlWeb2Visible, setUrlWeb2Visible] = useState(false);
    //const [urlWeb3Visible, setUrlWeb3Visible] = useState(false);
    var alerteFaite = false;
    function gestionEvenements(value, name){
      setAumoinsUneModif(true); document.getElementById("hidden_marque_une_modif").value = 1;
      if(global.config.modeDebugApp == 1) console.log (`modif de ${name} : ${value[name]}`);

      if(name == "etat_recrutement" && value[name] == 2 && props.infosGenFormation.nbRecrutements789 > 0) {
        var alertMsg = '';
        if (props.infosGenFormation.nbRecrutements789 == 1) alertMsg = "qu'un recrutement (inscription sur candidature, RDV individuel ou Information collective) est déclaré.";
        else alertMsg = "que " + props.infosGenFormation.nbRecrutements789 + " recrutements (inscription sur candidature, RDV individuel ou Information collective) sont déclarés.";
        alert("Attention : vous indiquez que l'état de recrutement est fermé alors " + alertMsg + "\r\nNotez bien que pôle emploi ne tiendra alors plus compte de ces recrutements.");
      }

      if(props.infosGenFormation["sess_deja_exportee"] == true && (name == "DATE_DEBUT" || name == "DATE_FIN")) {
        if(alerteFaite === false) alert("Attention : vous vous apprêtez à modifier les dates de la session.\r\n\r\nSi il y a eu un ou des devis dématérialisé(s), ou un ou des plan(s) de formation sur cette session, merci de recréer une session.\r\n\r\nToute modification de date peut avoir des conséquences sur le financement de cette session.");
        alerteFaite = true;
        setModifDateSess(true);
      }
      //if(name == "urlweb1") {
      //  if(value[name] != "") setUrlWeb2Visible(true); else setUrlWeb2Visible(false);
      //}
      //else if(name == "urlweb2") {
      //  if(value[name] != "") setUrlWeb3Visible(true); else setUrlWeb3Visible(false);
      //}
    }

    const onSubmit = async (values) => {
      if(props.infosGenFormation["sess_deja_exportee"] == true && modifDateSess == 1) {
        var choixNavig = confirm("Attention : vous avez modifié les dates de la session.\r\n\r\nSi il y a eu un ou des devis dématérialisé(s), ou un ou des plan(s) de formation sur cette session :\r\n\r\ntoute modification de date peut avoir des conséquences sur le financement de cette session.\r\n\r\nConfirmez-vous ?");
        if(!choixNavig) return false;
      }
      for(let i in values) if(values[i] === "") values[i] = null; // sinon génère une erreur : on met à null tout les champs vide (ract-hook-form semblait le faire mais ai eu un problème...)
      //var dateMin = '1990-01-01';
      var dateMin2 = dateSixMoisAnterieurYYYYmmdd()
      var dateMax = '2079-06-06';
      var testdateOk = 1;
      tabDatesAgerer.forEach((el) => {  // autre facon de faire que for(let i in tabDatesAgerer) 
        if(values[el] != "" && values[el] != null) { //document.getElementById(el) != null
          if(global.config.modeDebugApp == 1) console.log("date qui va être vérifiée : ", values[el]);
          if(testdateOk == 1) {
            if(values[el] >  dateMax) { // la comparaison est ok même avec les tirets
              alert("La date ne doit pas être supérieure au 06/06/2079");
              testdateOk = 0;
            }
            else if (testdateOk == 1 && values[el] < dateMin2) {
                alert("La date est trop ancienne, elle doit être de moins de six mois");
                testdateOk = 0;
            }
            /*
            fait par rp mais pas souhaité par Lydia pour le moment (290923)
            else if(testdateOk == 1 && el == "DATE_DEBUT" && values[el] < dateMin) {
              alert("La date de début est trop ancienne, elle doit être supérieure au 01/01/1990"); // ajout condition plus permissive sur la date de début le 26/0923
              testdateOk = 0;
            }
            else if(testdateOk == 1 && el == "DATE_FIN" && values[el] < dateMin2) {
              alert("La date de fin est trop ancienne, elle doit être de moins de six mois"); // ajout condition plus permissive sur la date de début le 26/0923
              testdateOk = 0;
            }*/
            else if(testdateOk == 1 && validateDate(values[el]) == false) {
              alert("La date est incorrecte");
              testdateOk = 0;
            }

            if(testdateOk == 0){
              document.getElementById(el).focus();
              return false;
            }
          }
        }
      });
      if(testdateOk == 0) return false;

      //if(values["DATE_DEBUT"] < dateSixMoisAnterieurYYYYmmdd()) {
      //  alert("La date de début est trop ancienne, elle doit être de moins de six mois");
      //  return false;
      //}
      var dateMaxFin = dateDansXXAnsYYYYmmdd(5) // 5 ans depuis le 280224 au lieu de 3
      if(values["DATE_FIN"] >  dateMaxFin) { // la comparaison est ok même avec les tirets
        var dateMaxFinFr = dateDansXXAnsddmmYYYY(5)
        alert(`Vous ne pouvez pas saisir une date de fin ultérieure au ${dateMaxFinFr}. Merci de nous contacter si vous devez saisir une date ultérieure`);
        document.getElementById("DATE_FIN").focus();
        return false;
      }

      var ret = verifOrdreDateDebutFin(values["DATE_DEBUT"], values["DATE_FIN"], "La date de fin de la session doit être supérieure à celle début");
      if (ret == false) return false;
      if(values.periode_inscription_debut != null && values.periode_inscription_fin != null) {
        var ret2 = verifOrdreDateDebutFin(values["periode_inscription_debut"], values["periode_inscription_fin"], "La date de fin de l'inscription doit être supérieure à celle du début");
        if (ret2 == false) return false;

        var ret3 = verifOrdreDateDebutFin(values["periode_inscription_debut"], values["DATE_DEBUT"], "La date de l'inscription doit être inférieure à la date de début");
        if (ret3 == false) return false;
      }

      if(global.config.modeDebugApp == 1) console.log("Values:::", values);
      if(global.config.modeDebugApp == 1) console.log("Values:::", JSON.stringify(values));

      setAumoinsUneModif(false); document.getElementById("hidden_marque_une_modif").value = 0;
      setDataLoaded(false); // utile pour pas que l'utilisateur soumette plusieurs fois
      setMessageProbleme(null);
      var dataR = await apiSessModif(utilisateurBearer, values, sessionNumber) // on envoie sans faire JSON.stringify
      if(global.config.modeDebugApp == 1) console.log('retour apiSessModif : ', dataR);

      switch (dataR.statusCode) {
          case 200: 
              //var objaAmodif = JSON.parse(JSON.stringify(props.infosGenFormation)); // on clone (duplique) l'objet pour que tout fonctionne bien
              //objaAmodif["etape10"] = "ok";
              //props.SetInfosGenFormation(objaAmodif);
              // pour que le systeme calcule la validité Lhéo après l'enregistrement....
            var retAppel = await appelGetSetInfosObjetsFormActSess(utilisateurBearer, formationNumber, actionNumber, sessionNumber, props.SetInfosGenFormation, setMessageProbleme);
            if(retAppel == true)  alert("L'enregistrement s'est bien effectué.");
            else setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
            break;
          case 401:
            setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
            break;
          case 400:
              setMessageProbleme(dataR.dataApi); // un message personnalisé est retourné dans ce cas
            break;
          default:
            setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
 
      setDataLoaded(true); // utile pour pas que l'utilisateur soumette plusieurs fois
    };
  
    const onError = (error) => {
      console.log("ERROR:::", error);
    };
    const [adressesPourChoix, SetAdressesPourChoix] = useState(); // pour recup données du select
    async function chargementsConsecutifs() {
      setDataLoaded(false);
      var pb = false;

      // on appelle cette methode au chargement comme dans l'étape 5 et 9, cela permet d'afficher correctement les pictos de validité lhéo et le bon nombre de contact,recrutement et cycles
      var retAppel = await appelGetSetInfosObjetsFormActSess(utilisateurBearer, formationNumber, actionNumber, sessionNumber, props.SetInfosGenFormation, setMessageProbleme);
      if(retAppel != true) pb = true;

      if(pb == false && props.infosProprietes["ACTIONS_SESSIONS_PERIODE|other_dataEditable"].editable == 1) {
        var ret = await appelRecupInfoGet(utilisateurBearer, "lieux/lieux-formateurs-action/" + actionNumber, setMessageProbleme); // recuperation des adresses pour liste select
        if(ret != null) SetAdressesPourChoix(ret);
        else pb = true;
      }

      if(pb == false) {
        let retP = await recupererInfosPrincipales();
        if(retP == false) pb = true;
      }
      if(pb == true ) setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      setDataLoaded(true);
    }

    useEffect(() => {
      if(global.config.modeDebugApp == 1) console.log('useffect FormEtape10');
    	document.title = `Edition de la session ${sessionNumber}`;
      if(props.infosGenFormation.LIB_ACTIONS_FORMATION != "" && props.infosGenFormation.LIB_ACTIONS_FORMATION != null) {
        document.title += ` de la formation "${props.infosGenFormation.LIB_ACTIONS_FORMATION}" ${formationNumber}`;
      }  
      setMessageProbleme(null);
      chargementsConsecutifs();

      var links = document.querySelectorAll('a');
         
      // Parcours des liens pour ajouter gestionnaire d'événement au clic
      for (var i = 0; i < links.length; i++) {
        links[i].addEventListener('click', confirmationSiCLickSUrLien);
      }
      // Nettoyage de l'écouteur d'événement lorsque le composant est démonté
      return () => {      
        for (var i = 0; i < links.length; i++) {
          links[i].removeEventListener('click', confirmationSiCLickSUrLien);
        }
      };
    }, []);
    useEffect(() => {
      setAumoinsUneModif(false);
    }, [dataApi]);

      return (
      <>
        <LoadingSpinner dataLoaded={dataLoaded}/>
        <MessageErreurApi message={messageProbleme} />
        {/* SURTOUT ne pas mettre dataLoaded && car après enregistrement les données précédement chargées sont réaffichées !! 
        messageProbleme == null && enlevé le 2206 pour afficher message quand probleme recrutement*/}
        { dataApi && dataApi != null && props.infosProprietes != null && 
        <Container className="my-0">
            {/* l'api a des exceptions pour rendre la session non éditable dès qu'elle est déjà partie, donc on affiche le formulaire de demande de modif que si besoin
            <FormDemandeChangementSession infosProprietes={props.infosProprietes} infosGenFormation={props.infosGenFormation}
                                          formationNumber={formationNumber} actionNumber={actionNumber} sessionNumber={sessionNumber} /> */}

            <FormRHF onSubmit={onSubmit} onError={onError} dataApi={dataApi} gestionEvenements={gestionEvenements} >
              {props.infosProprietes["ACTIONS_SESSIONS_PERIODE|other_dataEditable"].editable == 1 &&
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <div style={{marginTop:"10px", marginBottom:"20px", minWidth: "700px"}}>
                    <MessageInfoChampsObligatoires />
                  </div>
                  <div>
                    <ButtonDeBase typeSubmit={true} lib="Enregistrer" disabled={aumoinsUneModif == true ? false : true} />
                  </div>
                </div>
              }
              {props.infosProprietes["ACTIONS_SESSIONS_PERIODE|other_dataEditable"].editable == 0 && props.infosProprietes["ACTIONS_SESSIONS_PERIODE|other_dataEditable"].aide != "" &&
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <MessageInfoBox message={props.infosProprietes["ACTIONS_SESSIONS_PERIODE|other_dataEditable"].aide} />
                </div>
              }

              <FormControl id="DATE_DEBUT" infosProp={props.infosProprietes["ACTIONS_SESSIONS_PERIODE|DATE_DEBUT"]} style=" inline-input" />
              <FormControl id="DATE_FIN" infosProp={props.infosProprietes["ACTIONS_SESSIONS_PERIODE|DATE_FIN"]} style=" inline-input" />              

              <FormControl id="modalites_inscription" infosProp={props.infosProprietes["ACTIONS_SESSIONS_PERIODE|modalites_inscription"]} />

              <FormControl id="periode_inscription_debut" infosProp={props.infosProprietes["ACTIONS_SESSIONS_PERIODE|periode_inscription_debut"]} style=" inline-input" />
              <FormControl id="periode_inscription_fin" infosProp={props.infosProprietes["ACTIONS_SESSIONS_PERIODE|periode_inscription_fin"]} style=" inline-input" />  
              
              <FormSelect id="etat_recrutement" infosProp={props.infosProprietes["ACTIONS_SESSIONS_PERIODE|etat_recrutement"]} options={global.config.optsDictEtatRecrutement} />
              
              {props.infosProprietes["ACTIONS_SESSIONS_PERIODE|other_dataEditable"].editable == 1 && props.infosProprietes["ACTIONS_SESSIONS_PERIODE|etat_recrutement"].editable == 1 &&
              <MessageInfoBox message="Note : Pôle emploi ne prendra pas en compte certains recrutements déclarés si vous indiquez que l'état de recrutement est fermé." />
              }

              <FormSelect id="adresse_inscription" infosProp={props.infosProprietes["ACTIONS_SESSIONS_PERIODE|adresse_inscription"]} options={adressesPourChoix} />

              {/*
              <FormControl id="urlweb1" infosProp={props.infosProprietes["ACTIONS_SESSIONS_PERIODE|urlweb1"]} /> 
              {urlWeb2Visible && <FormControl id="urlweb2" infosProp={props.infosProprietes["ACTIONS_SESSIONS_PERIODE|urlweb2"]} /> }
              {urlWeb3Visible && <FormControl id="urlweb3" infosProp={props.infosProprietes["ACTIONS_SESSIONS_PERIODE|urlweb3"]} /> } */}

              {props.infosProprietes["ACTIONS_SESSIONS_PERIODE|other_dataEditable"].editable == 1 &&
                <div className="partie-bouton-droite">
                  <ButtonDeBase typeSubmit={true} lib="Enregistrer" disabled={aumoinsUneModif == true ? false : true} />
                </div>
              }

            <FormHidden id="hidden_marque_une_modif" />
          </FormRHF>
        </Container>
        }

        <LiensPrecedentSuivant  formationNumber={formationNumber} actionNumber={actionNumber} sessionNumber={sessionNumber} numEtape={numEtape} infosGenFormation={props.infosGenFormation} notifBeCareful={aumoinsUneModif} />
      </>
    )

}

FormEtape10.propTypes = {
  formationNumber: PropTypes.number.isRequired,
  actionNumber: PropTypes.number.isRequired,
  sessionNumber: PropTypes.number.isRequired,
  SetInfosGenFormation : PropTypes.func,
  infosGenFormation: PropTypes.object,
}

export default FormEtape10;