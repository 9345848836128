import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { DonneesUtilisateurContext } from '../contexte.js';

import LoadingSpinner from "../Components/LoadingSpinner";

const CsvDownloader = (props) => {
  //const [csvData, setCsvData] = useState('');

  if(props.urlApi.length < 1) throw new Error('Problème urlApi');
  if(props.nomFicCible.length < 5) throw new Error('Problème nomFicCible');

  const {utilisateurBearer } = useContext(DonneesUtilisateurContext);
  const [dataLoaded, setDataLoaded] = useState(true);

  const handleButtonClick = async () => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${utilisateurBearer}`,
            },
        };

        //var urlApi = global.config.apiFormationsBase.url + "/GetCsv"; marche aussi mais groupé par formationc omme la liste de résultats
        var urlApi = global.config.apiBase.url + props.urlApi; // "/CsvSessions"
        if(global.config.modeDebugApp == 1) console.log(urlApi)

        setDataLoaded(false);
        const objRetour = await fetch(urlApi, requestOptions);
        setDataLoaded(true);

        objRetour.statusCode = objRetour.status; //===  400 si bad, 401 unauthorized
        if (objRetour.status != 200) throw new Error('Problème status');

        let data = await objRetour.blob(); // attention utiliser blob() au lieu de text() sinon c'est mission impossible a convertir
        /* tests fait dans ce sens (echec) 
        data = data.replace(/^"(.*)"$/, '$1'); // Supprimer les guillemets entourant la chaîne
        //data = data.replace(/\r\n/g, '\n'); // n'a jamais fonctionné
        data = data.replace(/\|/g, "\n"); // saut de ligne ok pour excel

        // Encoder le contenu en latin-1
        //const encoder = new TextEncoder('windows-1252'); // ou iso-8859-1
        //const win1252Data = encoder.encode(data);

        //setCsvData(data); si nous voulions 2 boutons
        //const blob = new Blob([win1252Data], { type: 'text/csv; charset=windows-1252' });
        */
        // Télécharger le CSV
        const blob = new Blob([data], { type: 'text/csv;charset=windows-1252' });
    
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = props.nomFicCible;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

    } catch (error) {
      if(global.config.modeDebugApp == 1) console.error('Erreur de récupération des données CSV', error);
      alert(`${global.config.msgPrTechnique.msgDefaut}`);
    }
  };
/*
  const downloadCsv = () => {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'donnees.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
*/
  return (
    <>
      <LoadingSpinner dataLoaded={dataLoaded} message="Veuillez patienter..." className="spinner-container2" />

      {/*<button onClick={handleButtonClick}>Charger CSV depuis l'API</button>
      {csvData && <button onClick={downloadCsv}>Télécharger CSV</button>}*/}

      <button className="myside2" style={{fontSize: "0.9em", marginRight: "40px"}} onClick={handleButtonClick} type='button' 
              disabled={dataLoaded == true ? false : true}
              title={props.boutonLib}>{props.boutonTitle}<span className="fas fa-circle-arrow-down"></span>
    </button>
    </>
  );
};

CsvDownloader.propTypes = {
  urlApi : PropTypes.string.isRequired,
  nomFicCible : PropTypes.string.isRequired,
  boutonLib : PropTypes.string.isRequired,
  boutonTitle : PropTypes.string.isRequired
}

export default CsvDownloader;