import { useEffect, useState, useContext} from 'react';
import { useNavigate} from "react-router-dom";
import { DonneesUtilisateurContext } from '../contexte.js';

import { appelRecupInfoGet } from '../fonctionsDiv.js'

import {MessageErreurApi} from '../Components/Messages.jsx';
import LoadingSpinner from "../Components/LoadingSpinner";
import FaqComponent from "react-faq-component"; //https://www.npmjs.com/package/react-faq-component

import "../SCSS/Faq.scss";
/**
 * Return Page Fac
 *
 * @component
 * @summary Faq
 * @param {  }
 * @return { HTMLElement }
*/
function Faq() {
  const navigate = useNavigate(); // utile pour changer de page
  const { utilisateurRouteLogin, utilisateurBearer } = useContext(DonneesUtilisateurContext);

  const [messageProbleme, setMessageProbleme] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);

  const [tabCategoriesFInalesAvecFaq2, setTabCategoriesFInalesAvecFaq2] = useState(); 

  async function chargementsConsecutifs() {
    setMessageProbleme("");
    setDataLoaded(false);
    var pb = false;
    
    // retourne un tableau d'objet: ex d'un objet: "eo_faq_categorie_id": 1,"libelle": "catégorie 1"
    var retListCatFaq = await appelRecupInfoGet(utilisateurBearer, "faq/categories", setMessageProbleme); 
    if(retListCatFaq != null) {

        //La fonction forEach ne gère pas bien les opérations asynchrones, car elle ne peut pas attendre que des opérations asynchrones se terminent avant de passer à l'élément suivant de la boucle.
        //Pour résoudre ce problème : méthode map en combinaison avec Promise.all pour gérer les appels asynchrones de manière synchrone
        const promises = retListCatFaq.map(async (item) => {
            var ret = await appelRecupInfoGet(utilisateurBearer, "faq/Search?id-categorie=" + item.eo_faq_categorie_id, setMessageProbleme);
            if(ret != null) {
                var retListFaq = {title: item.libelle, rows:[]}
        
                ret.forEach((el) => {
                    let ligne = {'title': el.id, 'content':  el.lib};
                        ligne.title =  el.sujet
                        ligne.content =  el.contenu
                        retListFaq.rows.push(ligne);
                });
                if(global.config.modeDebugApp == 1) console.log(`retListFaq : `, retListFaq )
                return retListFaq;
            }
            else pb = true;
        });

        const resolvedData = await Promise.all(promises);
        //console.log("resolvedData : ", resolvedData);
        setTabCategoriesFInalesAvecFaq2(resolvedData);
    } else pb = true;
    //console.log("retListCatFaq : ", retListCatFaq);

    if(pb == true ) setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
    setDataLoaded(true);
  }

    useEffect(() => {
      document.title = "Faq";
      if (utilisateurBearer === '000000') navigate(utilisateurRouteLogin); // si le status n'est pas bon on redirige sur l'acceuil
      chargementsConsecutifs();
    }, []);

    const data = {
        title: "FAQ (How it works)",
        rows: [
            {
                title: "Lorem ipsum dolor sit amet,",
                content: `L`,
            },
            {
                title: "Nunc maximus, magna at ultricies elementum",
                content:
                    "N",
            },
        ],
    };

    //const styles = {
        //// bgColor: 'white',
        //titleTextColor: "blue",
        //rowTitleColor: "blue",
        //// rowContentColor: 'grey',
        //// arrowColor: "red",
    //}; passeer styles={styles} en param si activé
    
    const config = {
        // tabFocus: true,
        animate: true,
        //arrowIcon: "V",
        //openOnload: 0,
        //expandIcon: "+",
        //collapseIcon: "-",
    };
    
    return (
        <>
            <MessageErreurApi message={messageProbleme}  />
            <LoadingSpinner dataLoaded={dataLoaded} />
            <div style={{maxWidth: "1024px"}}>

                {tabCategoriesFInalesAvecFaq2  && tabCategoriesFInalesAvecFaq2.length > 0 &&
                <>
                    {tabCategoriesFInalesAvecFaq2.map((categorieAvecFaq) => (
                        categorieAvecFaq.rows && categorieAvecFaq.rows.length > 0 &&
                        <>
                            <FaqComponent data={categorieAvecFaq} config={config} />
                        </>
                    ))}

                </>
                }
            </div>


        </>
    )

}

export default Faq;