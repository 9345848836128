import { useEffect, useState, useContext,  useMemo} from 'react'; // useMemo pour Table.jsx
import { useNavigate } from "react-router-dom";

import { apiRecupereInfoGet } from '../Datas/datasAutre.js'
import { DonneesUtilisateurContext } from '../contexte.js';
import '../config.js';

import MessageInfoBox, {MessageErreurApi} from '../Components/Messages.jsx';
import LoadingSpinner from "../Components/LoadingSpinner";
import {afficheElement, afficheElementCheck} from '../fonctionsDiv.js';
import FormDemandeChangementInfoStructure from '../Components/FormDemandeChangementInfoStructure.jsx';

/**
 * Return Page accueil
 *
 * @component
 * @summary accueil
 * @param {  }
 * @return { HTMLElement }
*/
function MonOrganisme() {
    const navigate = useNavigate(); // utile pour changer de page

    const { setBearer, utilisateurRouteLogin, utilisateurBearer } = useContext(DonneesUtilisateurContext);
    //var jj = localStorage.getItem('dataApiLS') !== undefined ? JSON.parse(localStorage.getItem('dataApiLS')) : ""; pour setDataApi
    const [dataApi, setDataApi] = useState();
    const [dataApiPotentiel, setDataApiPotentiel] = useState();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [messageProbleme, setMessageProbleme] = useState();

    async function recupererInfosOrg() {  
        const dataR = await apiRecupereInfoGet(utilisateurBearer, "lieux/infos-detaillees-org"); // organismes avant, ne sert plus....
        if(global.config.modeDebugApp == 1) console.log(`retour apiRecupereInfoGet avec "lieux/infos-detaillees-org" : `, dataR);

        switch (dataR.statusCode) {
           case 200:
                setDataApi(dataR.dataApi);
             break;
           case 401:
               setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
             break;
           default:
               setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
       }
    }
    async function recupererListePotentiel() {  
        const dataR = await apiRecupereInfoGet(utilisateurBearer, "organismes/potentiel");
        if(global.config.modeDebugApp == 1) console.log(`retour apiRecupereInfoGet avec "organismes/potentiel" : `, dataR);

        switch (dataR.statusCode) {
           case 200:
                setDataApiPotentiel(dataR.dataApi);
             break;
           case 401:
               setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); setBearer('000000'); // Problème technique Unauthorized
             break;
           default:
               setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
       }
    }

    async function chargementsConsecutifs() {
        setMessageProbleme("");
        setDataLoaded(false);
        await recupererInfosOrg();
        await recupererListePotentiel();
        //await recupererVolumes(); // récupére nbFoTot, nbFoRejetLheo, nbFoRejetCarif, fait aussi dans Signin 
        setDataLoaded(true);
    }

    useEffect(() => {


        if(global.config.modeDebugApp == 1) console.log('useffect Mon organisme')
        document.title = "Mon organisme"
        if (utilisateurBearer === '000000')  navigate(utilisateurRouteLogin); // si le status n'est pas bon on redirige sur l'acceuil
        chargementsConsecutifs();
    }, []);

    const lienMail = `<a href='mailto:${global.config.personnelPaca.mail}'>${global.config.personnelPaca.mail}</a>`;
    const messageInfo = `<b>Informations sur votre siège social</b>`;

    return (     
        <main className="sous-bloc">
            <MessageErreurApi message={messageProbleme}  />
            <LoadingSpinner dataLoaded={dataLoaded}/>
            { dataLoaded > 0 && dataApi && dataApiPotentiel &&
            <>
                <FormDemandeChangementInfoStructure dataApi={dataApi} />
                <MessageInfoBox message={messageInfo} />

                <section className="detail-bloc-fiche" style={{width: "100%"}}>
                     {/* pour mettre les 3 blocs en lignes : style={{display: "flex", justifyContent: "space-between"}}*/}
                        {/* sur 2es blocs 2 et 3 :  style={{paddingLeft: "15px"}}*/}
                    <div>
                        <div>
                            <div className="titres-liste-big">{dataApi[0].LIB_COMPANIES}</div>
                            <div style={{paddingLeft: "15px"}}>
                                {afficheElement('Sigle', dataApi[0].SHORT_LIB, false, "intitule-detail-fiche2")}
                                {afficheElement('Statut', dataApi[0].LIB_LEGALSTATUS, false, "intitule-detail-fiche2")}
                                {afficheElement('N° SIRET', dataApi[0].SIRET_COMPANIES, false, "intitule-detail-fiche2")}
                                {afficheElement('N° UAI', dataApi[0].Numero_UAI, false, "intitule-detail-fiche2")}
                                {afficheElement('N° de déclaration d\'activité', dataApi[0].NUM_DE, false, "intitule-detail-fiche2")}
                            </div>
                        </div>

                        <div style={{paddingTop: "30px"}}>
                            {(dataApi[0].ADDRESS1 || dataApi[0].LIB_GEO_VILLES || dataApi[0].CODE_POSTAL
                             || dataApi[0].TELFIXE || dataApi[0].TELMOBILE || dataApi[0].COURRIEL || dataApi[0].URLWEB) &&
                            <>
                                <div className="titres-liste-big">Adresse</div>
                                    <div style={{paddingLeft: "15px"}}>
                                    {dataApi[0].ADDRESS1} {dataApi[0].ADDRESS2}<br />{dataApi[0].CODE_POSTAL} {dataApi[0].LIB_GEO_VILLES}

                                    {afficheElement('Tél fixe', dataApi[0].TELFIXE, false, "intitule-detail-fiche2")}
                                    {afficheElement('Tél mobile', dataApi[0].TELMOBILE, false, "intitule-detail-fiche2")}

                                    {afficheElement('Email', dataApi[0].COURRIEL, false, "intitule-detail-fiche2")}
                                    {afficheElement('Site Web', dataApi[0].URLWEB, false, "intitule-detail-fiche2")}
                                </div>
                            </>}
                        </div>

                        <div style={{paddingTop: "30px"}}>
                            {(dataApi[0].PERSONS_LAST_NAME || dataApi[0].PERSONS_TELFIXE || dataApi[0].PERSONS_TELMOBILE || dataApi[0].PERSONS_COURRIEL) &&
                            <>
                                <div className="titres-liste-big">Responsable de l'organisme</div>
                                <div style={{paddingLeft: "15px"}}>
                                    {afficheElement('Identité', `${dataApi[0].PERSONS_LAST_NAME} ${dataApi[0].PERSONS_FIRST_NAME}`, false, "intitule-detail-fiche2")}
                                    {afficheElement('Tél fixe', dataApi[0].PERSONS_TELFIXE, false, "intitule-detail-fiche2")}
                                    {afficheElement('Tél mobile', dataApi[0].PERSONS_TELMOBILE, false, "intitule-detail-fiche2")}

                                    {afficheElement('Email', dataApi[0].PERSONS_COURRIEL, false, "intitule-detail-fiche2")}
                                </div> 
                            </>}
                        </div>
                    </div>

                    {((dataApi[0].Qualiopi_Delivre && dataApi[0].Qualiopi_Delivre.substring(0,4) > 1900) || dataApi[0].Qualiopi_Expire || 
                    dataApi[0].Qualiopi_DocFournie ||  dataApi[0].Qualiopi_Form_Apprentissage || dataApi[0].Qualiopi_Form_BilanCompetence || 
                    dataApi[0].Qualiopi_Form_FormationContinue || dataApi[0].Qualiopi_Form_VAE) &&
                    <>
                    <br /><div className="titres-liste-big">Qualiopi :</div>
                    <div style={{display: "flex", justifyContent: "normal"}}>                        
                        {/*
                        <div style={{paddingRight: "15px"}}>                             
                                afficheElement('Délivré le ', convertSmallDateTimeToLocalDateStr(dataApi[0].Qualiopi_Delivre, undefined, 1), false, "intitule-detail-fiche2")
                                afficheElement('Expire le', convertSmallDateTimeToLocalDateStr(dataApi[0].Qualiopi_Expire, undefined, 1), false, "intitule-detail-fiche2")
                                afficheElementCheck('Documentation fournie', dataApi[0].Qualiopi_DocFournie, "intitule-detail-fiche2") 
                        </div>
                        */}
                        <div style={{paddingLeft: "15px"}}>
                                {/*<p className='text-important'>Les informations Qualiopi seront directement récupérées sur le fichier de la DGEFP (Traitement informatique en cours)</p>*/}
                                {afficheElementCheck('Apprentissage', dataApi[0].Qualiopi_Form_Apprentissage, "intitule-detail-fiche2")}
                                {afficheElementCheck('Bilan de compétences', dataApi[0].Qualiopi_Form_BilanCompetence, "intitule-detail-fiche2")}
                                {afficheElementCheck('Formation continue', dataApi[0].Qualiopi_Form_FormationContinue, "intitule-detail-fiche2")}
                                {afficheElementCheck('VAE', dataApi[0].Qualiopi_Form_VAE, "intitule-detail-fiche2")}
                        </div>
                    </div>
                    </>
                    }

                    <br />

                </section>

                {/* potentiel finalement pas affiché, voir en v2
                <MessageInfoBox message="Potentiel de votre organisme"  />
                {dataApiPotentiel.length > 0 ? <Table columns={columns} data={dataApiPotentiel} /> : <div>Votre organisme n'a actuellement aucun potentiel n'est actuellement.<br /><br /></div>}
                */}
            </> } 
        </main>
    )
}


export default MonOrganisme;