import PropTypes from 'prop-types';
import { useEffect, useState, useContext } from 'react';
import { useNavigate, NavLink, useSearchParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Modal from 'react-bootstrap/Modal';

import { verifMaintenance } from '../Datas/datasAutre.js'
import { apiLogin } from '../Datas/dataLogin.js'

import { DonneesUtilisateurContext } from '../contexte.js';
import {MessageErreurApi} from '../Components/Messages.jsx';
import ButtonDeBase from '../Components/ButtonsCustom.jsx';
import LoadingSpinner from "../Components/LoadingSpinner";
import ProcedureMdp from '../Components/ProcedureMdp.jsx';
import FormInscription from '../Components/FormInscription.jsx';
import { recupererVolumes } from '../fonctionsDiv.js';

/**
 * Return template with Sign in form
 *
 * @component
 * @summary imported in Route
 * @param {  }
 * @return { HTMLElement }
*/
function SignIn(props) {
    const {setRouteLogin, setBearer, setRole, setOrg, setNomOrg, setIdMesureOf, setUtilisateurId,  setNbFormTot, setNbFormAttente, setNbFormIncomplete, setNbActAttente, setNbActIncomplete, setNbSessAttente, 
        setNbSessIncomplete,      utilisateurBearer } = useContext(DonneesUtilisateurContext);
    const navigate = useNavigate(); // utile pour changer de page.

    const [searchParams, setSearchParams] = useSearchParams(); // pour recuperer les paramètre de l'url querystring (ajouter useSearchParams de react-router-dom)
    const logout =  searchParams.get("logout");
    
    const [login, setLogin] = useState(''); // '281' Admin
    const [password, setPassword] = useState(''); // '9696' pwd628586
    const [orgAsimulerSiret, setOrgAsimulerSiret] = useState(''); // 77566202600209

    let [errorMsg, seterrorMsg] = useState(''); // pour afficher un message derreur si champs nok
    const [dataLoaded, setDataLoaded] = useState(true);
    let [messageProbleme, setMessageProbleme] = useState('');

    const handleCloseModalMotPassOublie = () => setShowModalMotPassOublie(false);
    const [showModalMotPassOublie, setShowModalMotPassOublie] = useState(false);

    const handleCloseModalInscription = () => setShowModalInscription(false);
    const [showModalInscription, setShowModalInscription] = useState(false);
    const [enMaintenance, setEnMaintenance] = useState(false);
    const [msgMaintenance , setMsgMaintenance] = useState(false);

    /**
     * @summary fonction permettant de valider la saisie du formulaire
     * 
     * @return { Boolean }
     */
    function validate() {
        let errorMessage = '';

        if (!password) { errorMessage = 'Entrez l\'identifiant et le mot de passe' } //
        else if (!login) { errorMessage = 'Entrez l\'identifiant' }
        else if (!password) { errorMessage = 'Entrez le mot de passe' }
        if (errorMessage) { seterrorMsg(errorMessage); return false; }

        return true;
    };

    // lors de la soumission du formulaire  
    const handleSubmit = async (event) => {
        event.preventDefault() // On utilise la fonction preventDefault de notre objet event pour empêcher le comportement par défaut de cet élément lors du clic de la souris
        if (validate()) {
            if (typeof(Storage) === "undefined") {
                let pb = global.config.msgPrTechnique.msgDefaut + '<br /><br />Détail de l\'erreur à transférer : "Storage non supporté"';
                setMessageProbleme(pb);
                return 0;
            }

            setDataLoaded(false);
            const response = await apiLogin(login.trim(), password.trim(), orgAsimulerSiret.trim(), props.asAnOrg);        
            setDataLoaded(true);

            if(typeof response == 'undefined' || typeof response.status == 'undefined') { // si pas de retour
                setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
                return 0;
            }
            if (response.status == 200) { //===  400 si bad
                const bearer =  await response.text(); // au lieu de .json(), bearer contient le token

                var decodedBearer = jwt_decode(bearer); // https://www.npmjs.com/package/jwt-decode, https://jwt.io/ pour voir de quoi est composé le bearer
                var role = decodedBearer["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
                var OrgId = decodedBearer["OrgId"];
                var NomOrg = decodedBearer["NomOrg"];
                var IdMesureOf = decodedBearer["IdMesureOf"];
                var UtilisateurId = decodedBearer["UtilisateurId"];

                if (typeof(role) === "undefined" ||  typeof(OrgId) === "undefined" || typeof(NomOrg) === "undefined" || typeof(IdMesureOf) === "undefined" || 
                    typeof(UtilisateurId) === "undefined") {
                    let pb = global.config.msgPrTechnique.msgDefaut + '<br /><br />Détail de l\'erreur à transférer : " une propriété est manquante"';
                    setMessageProbleme(pb);
                    return 0;
                }

                setRole(role)
                setOrg(OrgId)
                setNomOrg(NomOrg)
                setIdMesureOf(IdMesureOf)
                setUtilisateurId(UtilisateurId)
                // console.log('role issu du bearer enregistré dans le contexte: ', role);
                //console.log('OrgId issu du bearer enregistré dans le contexte: ', OrgId);
                //console.log('NomOrg issu du bearer enregistré dans le contexte: ', NomOrg);
                if(global.config.modeDebugApp == 1) console.log('IdMesureOf issu du bearer enregistré dans le contexte: ', IdMesureOf);
                //console.log('UtilisateurId issu du bearer enregistré dans le contexte: ', UtilisateurId);


                setBearer(bearer) //Bearer enregistré dans le contexte uniquement si tout est ok précédement
                //console.log('Bearer enregistré dans le contexte : ', bearer);
                if (props.asAnOrg == true) setRouteLogin("/pro-bo-paca")
                else setRouteLogin("/")

                 // récupère nbFoTot, nbFoRejetLheo, nbFoRejetCarif, fait aussi dans FormationGen :
                await recupererVolumes(bearer, setNbFormTot, setNbFormAttente, setNbFormIncomplete, setNbActAttente, setNbActIncomplete, setNbSessAttente, setNbSessIncomplete, setMessageProbleme);

                if(role == "AdminExtranet") navigate(`/organisme`);
                else navigate(`/accueil`); // organisme formation 
            }
            else if (response.status == 401) {  // Compte non valide ou En maintenance
                const dataApi = await response.text();
                if(dataApi == "Compte non valide") setMessageProbleme(global.config.msgPrTechnique.msgBadIdentification);
                else if(dataApi == "En maintenance") setMessageProbleme(global.config.msgPrTechnique.msgMaintenance);
                else setMessageProbleme(dataApi);
            }
            else  setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
    }

    //utile car pas touver moyen avec setInterval de lancer une fct avec param :
    function lanceFctVerifMaintenance() {return verifMaintenance(setEnMaintenance, setMsgMaintenance)} 

    useEffect(() => {
        if(global.config.modeDebugApp == 1) console.log("raffraichissement useEffect Signin");

        document.title = (props.asAnOrg == true) ? "Brof Admin" : "Extranet Brof";

        verifMaintenance(setEnMaintenance, setMsgMaintenance); // bien laisser sinon le formulaire s'affiche brièvement
        if(logout != null) {
            setBearer('000000');
        }
        else if (utilisateurBearer !== '000000')  navigate(`/accueil`); // si le status n'est pas bon on redirige sur l'acceuil

        // Rafraîchir l'état toutes les minutes (60000) pour lancer cette fct
        const intervalId = setInterval(lanceFctVerifMaintenance, 60000);

        // Nettoyer l'intervalle lors de la suppression du composant
       return () => clearInterval(intervalId);

    }, []); // crochet vide : 1er rendu seulement

    return (
        <>
            <div className='logo-login'>
                <img src={`${process.env.PUBLIC_URL}/img/logo_CarifOref.png`} alt="Carif Oref Paca" className="feature-icon" />
            </div>
            <h1 className='sign-in-titre'>
                {props.asAnOrg == true ?
                 <>Back Office Brof</>
                 :
                 <>Extranet des organismes de formation</>
                }
            </h1>

            <section className="sign-in-content">
                <LoadingSpinner dataLoaded={dataLoaded}/>
                {/*process.env.NODE_ENV nok car a 'production' meme quand on build avec l'option dev */}
                {process.env.REACT_APP_MY_ENV != 'production' && <MessageErreurApi message={global.config.msgPrTechnique.msgSrvTest} />}
                {enMaintenance == true && <MessageErreurApi message={msgMaintenance} />}

                { dataLoaded == true && enMaintenance == false &&
                <form onSubmit={handleSubmit}>
                    <div className='bloc-centre'>
                        <h2>Connexion</h2>
                    </div>
                    <div className='input-group input-group-lg'>
                        <input className="form-control" placeholder='Identifiant' type="text" id="login" value={login} onChange={(event) => setLogin(event.target.value)} /> 
                    </div>
                    <div className='input-group input-group-lg'>
                        <input className="form-control" placeholder='Mot de passe' type="password" id="password" value={password} onChange={(event) => setPassword(event.target.value)} />
                    </div>
                    {props.asAnOrg == true &&
                    <div className='input-group input-group-lg'>
                        <input className="form-control" placeholder="Siret de l'organisme (optionnel)" type="text" id="OrgAsimulerSiret" value={orgAsimulerSiret} onChange={(event) => setOrgAsimulerSiret(event.target.value)} />
                    </div>
                    }
                    <div className="form-error">
                        {errorMsg ? errorMsg : null}
                    </div>
                    <div className='bloc-centre'>
                        <MessageErreurApi message={messageProbleme}  />
                    </div>
                    <ButtonDeBase typeSubmit={true } lib="Se connecter" disabled={(!login || !password)} />
                    {props.asAnOrg == false &&
                    <>
                        <div className='bloc-centre'>
                            <NavLink className="main-nav-item" to="" onClick={() => setShowModalMotPassOublie(true)}>Mot de passe oublié</NavLink>
                            <NavLink className="main-nav-item" to="" onClick={() => setShowModalInscription(true)}>Je ne possède pas de codes d'accès</NavLink>
                        </div>
                        <div className='bloc-centre' style={{textAlign: "left"}}>
                            Si vous êtes un organisme de formation déclaré en région Provence Alpes Côte d'Azur, auprès du Service Régional de Contrôle de la DREETS Provence-Alpes-Côte d'Azur, un espace réservé vous permet de mettre à jour les informations concernant votre organisme de formation et d'enregistrer l'ensemble des actions de formation que vous proposez.<br /><br /> 
                            Pour tous renseignements, vous pouvez nous contacter par téléphone au 04 42 82 43 23 ou par mail lcoccorese@cariforef.fr, du lundi au vendredi de 9h00 à 12h00 et de 14h00 à 16h00.<br /> <br />
                        </div>
                    </>
                    }
                </form>    
                }           
            </section>

            {showModalMotPassOublie &&
            <Modal size="lg" show={showModalMotPassOublie} onHide={handleCloseModalMotPassOublie} backdrop="static" keyboard={false} centered aria-labelledby="example-modal-sizes-title-lg">
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Mot de passe oublié
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <ProcedureMdp handleCloseModal={handleCloseModalMotPassOublie} />
              </Modal.Body>
            </Modal>
            }

            {showModalInscription &&
            <Modal size="lg" show={showModalInscription} onHide={handleCloseModalInscription} backdrop="static" keyboard={false} centered aria-labelledby="example-modal-sizes-title-lg">
              <Modal.Header closeButton>
                <Modal.Title id="modal-insciption">Demande de code d'accès</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <FormInscription handleCloseModal={handleCloseModalInscription} />
              </Modal.Body>
            </Modal>
            }

            {props.asAnOrg == true ?
            <div className='image-presentation-short-pro'>
                <img src={`${process.env.PUBLIC_URL}/img/image_presentation_pro.jpg`} alt="Carif Oref Paca"  className="feature-presentation-short" />
            </div>
        :
            <div className='image-presentation'>
                <img src={`${process.env.PUBLIC_URL}/img/image_presentation.png`} alt="Carif Oref Paca"  className="feature-presentation" />
            </div>
        }
        </>
    )
}
SignIn.propTypes = {
    asAnOrg: PropTypes.bool.isRequired,
}
export default SignIn;