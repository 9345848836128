import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

/**
 * Component for left navigation
 *
 * @component
 * @return { HTMLElement }
*/
function LiensPrecedentSuivant(props) { 
   const navigate = useNavigate();

   var numEtape = '';
   if(props.numEtape != null && props.numEtape != "") numEtape = props.numEtape; 
   else {
        var numEtape = parseInt(props.nomEtape.substring(5));
        if(props.nomEtape.substring(5) == '1b') numEtape = 1;
   }

   var numPrecedent = numEtape > 1  ? numEtape - 1 : '';
   // si on est a l'étape du choix des actions ou des sessions ou à la dernière étape, il ne faut pas proposer le bouton suivant
   var numSuivant = (numEtape == 4 || numEtape == 9 || numEtape == 14 || (numEtape == 13 && props.infosGenFormation.act_is_apprentissage == false)) ? '' :  numEtape + 1;

   // gestion des exceptions (cas ou l'étape n'a pas à être affichée)
   if(props.infosGenFormation.certifOuPro == 'pro' || (props.infosGenFormation.nbCertifications == 1 && props.infosGenFormation.nbCertifAyantDesBlocs == 0)) {
     if(numPrecedent == 11) numPrecedent = 10;
     if(numSuivant == 11) numSuivant = 12;
   }

   var lienPrecedent = ""; var lienSuivant = "";
   if(numEtape < 5) {
    lienPrecedent = `etape${numPrecedent}/${props.formationNumber}`;
    lienSuivant   = `etape${numSuivant}/${props.formationNumber}`;
   }
   else if(numEtape < 10) {
    lienPrecedent = `etape${numPrecedent}/${props.formationNumber}/action-${props.actionNumber}`;
    lienSuivant   = `etape${numSuivant}/${props.formationNumber}/action-${props.actionNumber}`;
   }
   else {
    lienPrecedent = `etape${numPrecedent}/${props.formationNumber}/action-${props.actionNumber}/session-${props.sessionNumber}`;
    lienSuivant   = `etape${numSuivant}/${props.formationNumber}/action-${props.actionNumber}/session-${props.sessionNumber}`;
   }

   var IsPrecedentValide = ''; // nok pour désactiver
   var IsSuivantValide = '';


   var notifSuivantFinal = (props.notifSuivant != null)  ? props.notifSuivant : ''; // notifSuivantFinal contient soit un texte passé en props soit un text précisé ci dessous

   // comme dans LeftMenu, on doit gérer la possibilité d'aller à l'étape suivante :
   var msgSuivanteImpossible = "Vous ne pouvez pas passer à l'étape suivante<br />tant que les précédentes ne sont pas complétées"
   var msgSuivanteImpossibleEtapeCouranteNok = "Vous ne pouvez pas passer à l'étape suivante<br />tant que cette étape n'est pas complétée"

   if(numEtape == 1 && (props.infosGenFormation.etape1 != 'ok')) {
    IsSuivantValide = 'nok';
    notifSuivantFinal = msgSuivanteImpossibleEtapeCouranteNok
   }
   else if(numEtape == 2 && (props.infosGenFormation.etape2 != 'ok')) {
    IsSuivantValide = 'nok';
    notifSuivantFinal = msgSuivanteImpossibleEtapeCouranteNok
   }
   else if(numEtape == 3 && (props.infosGenFormation.etape1 != 'ok' || props.infosGenFormation.etape2 != 'ok' || props.infosGenFormation.etape3 != 'ok')) {
    IsSuivantValide = 'nok';
    notifSuivantFinal = msgSuivanteImpossible
   }

   else if(numEtape == 5 && (props.infosGenFormation.etape5 != 'ok')) {
    IsSuivantValide = 'nok';
    notifSuivantFinal = msgSuivanteImpossibleEtapeCouranteNok
   }
   else if(numEtape == 6 && (props.infosGenFormation.etape6 != 'ok')) {
    IsSuivantValide = 'nok';
    notifSuivantFinal = msgSuivanteImpossibleEtapeCouranteNok
   }
   else if(numEtape == 7 && (props.infosGenFormation.etape7 != 'ok')) {
    IsSuivantValide = 'nok';
    notifSuivantFinal = msgSuivanteImpossibleEtapeCouranteNok
   }
   else if(numEtape == 8 && (props.infosGenFormation.etape5 != 'ok' || props.infosGenFormation.etape6 != 'ok' || props.infosGenFormation.etape7 != 'ok' || props.infosGenFormation.etape8 != 'ok')) {
    IsSuivantValide = 'nok';
    notifSuivantFinal = msgSuivanteImpossible
   }

   else if(numEtape == 10 && (props.infosGenFormation.etape10 != 'ok')) {
    IsSuivantValide = 'nok';
    notifSuivantFinal = msgSuivanteImpossibleEtapeCouranteNok
   }

   // en plus (pas dans leftMenu)
   else if(numEtape == 13 || numEtape == 14) {
    if(props.infosGenFormation.estValideLheoFormation != 'ok') {
        IsSuivantValide = 'nok';
        notifSuivantFinal = '<span style="color : red !important;">Au moins une étape n\'est pas encore valide.<br />Vous devez vous rendre sur les étapes grisées pour les compléter.</span>'
    }
    else {
        notifSuivantFinal = "Retour à la liste<br />de vos formations"
    }
   }
   //else notifSuivantFinal = ''; non!


   useEffect(() => {
    //console.log('useffect LiensPrecedentSuivant')
    //console.log(`props.infosGenFormation :`, props.infosGenFormation)
    //console.log(`props.notifBeCareful : ${props.notifBeCareful}` )
    //console.table(props.infosGenFormation)
    //console.log(`props.estValideLheoFormation : ${props.infosGenFormation.estValideLheoFormation}` )
   }, []);

   const MsgDemandeConfNoRecord = 'Vous n\'avez pas sauvegardé vos modifications (bouton Enregistrer).\r\n Etes vous sûr de vouloir changer d\'étape ?';

    //console.log(precedent +' :' + suivant);
    //pas eu besoin d'un bt : <button className="bottom-navig-item" onClick={() => navigate(`/formation/${lienSuivant}`)} disabled={!(numSuivant > 12)}><span className=" fas fa-circle-chevron-right"></span></button>
    // pointerEvents permet de rendre inopérent un lien
    return (<div className ="bottom-navig">
        <div>
            {numPrecedent != '' &&
                <a title={`Etape précédente`}
                style={{pointerEvents: IsPrecedentValide == 'nok' ? 'none' : ''}} 
                className={IsPrecedentValide == 'nok' ? 'bottom-navig-item disabled' : ' bottom-navig-item'} 

                onClick={function() {if(props.notifBeCareful==false || 
                    confirm(MsgDemandeConfNoRecord)) 
                    navigate(`/formation/${lienPrecedent}`)}}
                >
                    <span className="fas fa-circle-chevron-left"></span>
                </a>
            } 
            {/*import NavLink de dans react-router-dom si beosin
            <NavLink style={{pointerEvents: IsPrecedentValide == 'nok' ? 'none' : ''}} 
                className={IsPrecedentValide == 'nok' ? 'bottom-navig-item disabled' : ' bottom-navig-item'} 
                to={`/formation/`}><span className="fas fa-circle-chevron-left"></span></NavLink>*/}
        </div>
        <div>
            {/* attention bien remarquer que le lien de navigation est différent pour les dernières étapes */}
            {
            (numSuivant != ''|| (numEtape == 14 || (numEtape == 13 && props.infosGenFormation.act_is_apprentissage == false))) &&
                <a title={`Etape suivante`}
                    style={{pointerEvents: IsSuivantValide == 'nok' ? 'none' : ''}} 
                    className={IsSuivantValide == 'nok' ? 'bottom-navig-item disabled' : ' bottom-navig-item'} 

                    onClick={function() {if(props.notifBeCareful==false || 
                        confirm(MsgDemandeConfNoRecord)) 
                        navigate((numEtape == 14 || (numEtape == 13 && props.infosGenFormation.act_is_apprentissage == false)) ? `/formation` : `/formation/${lienSuivant}`)}}
                >
                    {notifSuivantFinal != ''  ?
                    <div style={{display: "inline-flex"}}>
                        <div style={{textAlign: "right", paddingTop: "10px"}} dangerouslySetInnerHTML={{  __html: notifSuivantFinal }}></div>
                        <span className="fas fa-circle-chevron-right"></span>
                    </div>
                    :
                    <span className="fas fa-circle-chevron-right"></span>
                    }
                </a>
            }
            {/*<NavLink style={{pointerEvents: IsSuivantValide == 'nok' ? 'none' : ''}}             
            className={IsSuivantValide == 'nok' ? 'bottom-navig-item disabled' : ' bottom-navig-item'} 
                    to={`/formation/${lienSuivant}`}><span className="fas fa-circle-chevron-right"></span></NavLink>*/}
        </div>
    </div>);
}

LiensPrecedentSuivant.propTypes = {

    nomEtape: PropTypes.string,
    numEtape: PropTypes.number.isRequired,

    formationNumber: PropTypes.number.isRequired,
    actionNumber: PropTypes.number,
    sessionNumber: PropTypes.number,

    infosGenFormation: PropTypes.object.isRequired,
    notifBeCareful: PropTypes.bool.isRequired, // si besoin d'afficher une notification avant le changement de page
    notifSuivant: PropTypes.string,
}
/* necessaire de commenter suite maj dependance
LiensPrecedentSuivant.defaultProps = {
    infosGenFormation: {
        "ID_ACTIONS_FORMATION": 1,

        "LIB_ACTIONS_FORMATION": "titre...",
        "certifOuPro": "pro",
        //"isCHangeCertifOuProPossible": true,

        "nbCertifications": 0,
        "nbProfessionnalisants": 0,
        "nbActions": 0,
        "nbFormateurs": 0,
        "nbSessions": 0,
        "nbCertifAyantDesBlocs": 0,
        "etape1": "ok",
        "etape2": "ok",
        "etape3": "ok",
        "etape4": "ok",
        "etape5": "nok",
        "etape6": "nok",
        "etape7": "nok",
        "etape8": "nok",
        "etape9": "nok",
        "etape10": "nok",
        "etape11": "nok",
        "etape12": "nok",
        "etape13": "nok",
        "etape14": "nok",

        "estValideLheoFormation": "nok",
        "estValideLheoAction": "nok",
        "estValideLheoSession": "nok",
    }
 }
*/
export default LiensPrecedentSuivant;