import { useEffect, useState } from 'react';

import MessageInfoBox, {MessageErreurApi} from './Messages.jsx';
import ButtonDeBase from '../Components/ButtonsCustom.jsx';
import FormRHF from "../Components/Form/Form";
import FormControl from "../Components/Form/FormControl";
import FormSelect from "../Components/Form/FormSelect";
import { apiGetProprietesFree, apiSoumissionFormulaireDemande } from '../Datas/datasAutre.js'
import LoadingSpinner from "../Components/LoadingSpinner";
import { appelRecupInfoGet, isValidSIRET } from '../fonctionsDiv.js';
import { apiCheckSiret, apiInformationsQuiforme } from '../Datas/dataLogin.js';

import '../config.js';


/**
 * Return Formulaire d'inscription
 *
 * @component
 * @summary page d'inscription
 * @param {  }
 * @return { HTMLElement }
 * model : http://extranet-formation.espace-competences.org/Inscription.aspx
*/
function FormInscription(props) {
    const [dataLoaded, setDataLoaded] = useState(true);
    const [spinnerLoad, setSpinnerLoad] = useState(false);
    const [infosProprietes, SetInfosProprietes] = useState(undefined); // contient les infos sur les champ (taille max, caractre obligatoire, éditable...)
    const [messageProbleme, setMessageProbleme] = useState();
    const [aumoinsUneModif, setAumoinsUneModif] = useState(false); // pour avertir l'utilisateur en cas de sortie sans  enregistrement

    //pour effectuer des actions en fonction de la saisie de l'utilisateur
    const [resteFormVisible, setResteFormVisible] = useState(false);
    
    const [dataApi, setdataApi] = useState();

    //pour effectuer des actions en fonction de la saisie de l'utilisateur
    async function gestionEvenements(value, name){
      setAumoinsUneModif(true);
      if(global.config.modeDebugApp == 1) console.log (`modif de ${name} : ${value[name]}`);

      if(name == "x_SIRET" && value[name].length == 14) { 
        var leSiret = value[name];

        if(!isValidSIRET(leSiret)) {  //.length != 14
          alert("Le siret que vous avez saisi ne semble pas valide, veuillez le corriger.");
          var ob = document.getElementById("x_SIRET");
          ob.focus();
  
          return false;
        }
        //recherche des informations Quiforme pour préremplissage
        setSpinnerLoad(true);
        var dataR = await apiInformationsQuiforme(leSiret);
        if(global.config.modeDebugApp == 1) console.log('retour apiInformationsQuiforme : ', dataR);
        setSpinnerLoad(false);
        
        setResteFormVisible(true);
        
        var dataApi = new Object();  
        dataApi["x_SHORT_LIB"] = dataApi["x_LIB_COMPANIES"] = dataApi["x_UAI"] = dataApi["x_NUM_DE"] = dataApi["x_DATE_DECLARATION"] = dataApi["x_ADDRESS1"] = dataApi["x_CODE_POSTAL"] = dataApi["x_LIB_VILLE"] = null;
        dataApi["x_etablissement_ferme_quiforme"] = dataApi["x_SIRET_quiforme"] = null
        dataApi["x_LIB_COMPANIES_quiforme"] = dataApi["x_UAI_quiforme"]  = dataApi["x_NUM_DE_quiforme"] = dataApi["x_DATE_DECLARATION_quiforme"] = dataApi["x_ADDRESS1_quiforme"] = dataApi["x_CODE_POSTAL_quiforme"] = dataApi["x_LIB_VILLE_quiforme"] = null;

        // siret avec tout : 42334912500082 ou 77566202600209
        //fermé : 81276701000026 (est dans la base 849060),  siret sans nda : 52435199600029 (est dans la base 644347)
        // tout a null : 78929750400019
        switch (dataR.statusCode) {
            case 401: 
              // on teste si c'est une chaine string qui est retourné par l'api
              if(typeof dataR.dataApi === 'string' || dataR.dataApi instanceof String) alert(dataR.dataApi); // un message personnalisé est retourné dans ce cas, ex : "Il y a plus de XXX résultats ({XXXX), veuillez affiner votre recherche"
              else alert(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
              //alert("Votre établissement est fermé d'après Quiforme. Si vous pensez que c'est une erreur, vous pouvez continuer à saisir vos informations et le Carif vous contactera.");
              dataApi["x_etablissement_ferme_quiforme"] = "SIRET " + leSiret + " fermé";
              break;

            case 200: 
              if(dataR.dataApi.siret_actif == null) 
                alert("Le siret " + leSiret + " n'a pas été trouvé dans Quiforme, veuillez le corriger si vous avez commis une erreur.\r\nDans le cas contraire, vous pouvez continuer votre saisie.")
              else alert("Certaines informations de ce formulaire vont être préremplies avec les données fournies par Quiforme, vous pourrez les modifier si nécessaire.");
              
              dataApi["x_DATE_DECLARATION"] = null; //pourquoi cela (decommenté le 040624) ????

              if(dataR.dataApi.siret_actif !== "undefined") {
                //document.getElementById("x_LIB_COMPANIES").value !!nein ca ne marche pas, quand on soumet le formulaire la valeur n'est pas détectée
                dataApi["x_SIRET_quiforme"] = dataR.dataApi.siret_actif;  // bonne façon d'affecter/associer/enregistrer une valeur dynamiquement
                                                                                        // c'est vérifié, on peut ajouter des éléments au tableau qu'on va envoyer ainsi, ca partira bien à lasoumission
              }

              if(dataR.dataApi.etablissement.denomination !== "undefined") {
                //document.getElementById("x_LIB_COMPANIES").value !!nein ca ne marche pas, quand on soumet le formulaire la vlauer n'est pas détectée
                dataApi["x_LIB_COMPANIES"] = dataR.dataApi.etablissement.denomination;
                dataApi["x_LIB_COMPANIES_quiforme"] = dataR.dataApi.etablissement.denomination;
              }
              if(dataR.dataApi.etablissement.uai !== "undefined") {
                dataApi["x_UAI"] = dataR.dataApi.etablissement.uai;
                dataApi["x_UAI_quiforme"] = dataR.dataApi.etablissement.uai;
              }

              if(dataR.dataApi.nda.length > 0) {
                dataApi["x_NUM_DE"] = dataR.dataApi.nda[0].nda;
                dataApi["x_NUM_DE_quiforme"] = dataR.dataApi.nda[0].nda;
                
                if(dataR.dataApi.nda[0].date_derniere_declaration !== null) {
                  const [year, month, day] = dataR.dataApi.nda[0].date_derniere_declaration.split('-');
                  dataApi["x_DATE_DECLARATION"] = `${day}/${month}/${year}`;// Recompose la date au format dd/mm/yyyy 
                  dataApi["x_DATE_DECLARATION_quiforme"] = `${day}/${month}/${year}`;
                }
              }
                
              if(dataR.dataApi.adresse_ban.length > 0) {
                dataApi["x_ADDRESS1"] = dataR.dataApi.adresse_ban[0].nom;
                dataApi["x_ADDRESS1_quiforme"] = dataR.dataApi.adresse_ban[0].nom;

                dataApi["x_CODE_POSTAL"] = dataR.dataApi.adresse_ban[0].codepostal;
                dataApi["x_CODE_POSTAL_quiforme"] = dataR.dataApi.adresse_ban[0].codepostal;

                dataApi["x_LIB_VILLE"] = dataR.dataApi.adresse_ban[0].commune;
                dataApi["x_LIB_VILLE_quiforme"] = dataR.dataApi.adresse_ban[0].commune;;
              }
              break;

            default:
              // silencieux si il y a le moindre problème....
              //if(global.config.modeDebugApp == 1) console.log('Le serveur a rencontré une erreur : ', leSiret);
              //setMessageProbleme(`Le serveur a rencontré une erreur, veuillez contacter le support "${global.config.admin.mail}".`);
              alert("Le service Quiforme qui permet de pré remplir le formulaire en fonction de votre Siret semble poser problème, veuillez s'il vous plait renseigner les champs manuellement.")       
              break;
        }

        setdataApi(dataApi)
        return false;
      }
    }


    const onSubmit = async (values) => {
      /*nok
      if(values["x_DATE_DECLARATION"] != null && dateModifCharFrToAmericSansSep(values["x_DATE_DECLARATION"]) > dateDuJourYYYYmmdd()) {
        alert("La date de déclaration ne peut être postérieure à la date du jour.");
        var ob = document.getElementById("x_DATE_DECLARATION");
        ob.focus();
        return false;
      }*/
      var leSiret = values["x_SIRET"];

      if(!isValidSIRET(leSiret)) {  //.length != 14
        alert("Le siret que vous avez saisi ne semble pas valide, veuillez le corriger.");
        var ob = document.getElementById("x_SIRET");
        ob.focus();

        return false;
      }
      
      setMessageProbleme();
      setDataLoaded(false);
      for(let i in values) if(values[i] === "") values[i] = null; // sinon génère une erreur : on met à null tout les champs vide (ract-hook-form semble le faire pour nous dans le cas ou on lui passe les valeurs par défaut en entré)

      var dataR = await apiCheckSiret(leSiret)
      if(global.config.modeDebugApp == 1) console.log('retour apiCheckSiret : ', dataR);

      switch (dataR.statusCode) {
          case 401: // retourne 401 si  resultat != 1
                if(global.config.modeDebugApp == 1) console.log('le Siret est effectivement inexistant : ', leSiret);
              break;
          case 400: 
            setDataLoaded(true);
            if(global.config.modeDebugApp == 1) console.log('problème au niveau de votre Siret : ', leSiret);
            setMessageProbleme(`Il y a un problème au niveau de votre Siret, veuillez vérifier votre saisie.`);
            return false;
          case 200: 
            setDataLoaded(true);
            if(global.config.modeDebugApp == 1) console.log('le Siret est existant.. pas d\'envoie de formulaire : ', leSiret);
            setMessageProbleme(`Votre siret ${leSiret} existe déjà dans notre base de données.<br />Si vous ne connaissez plus vos identifiants, veuillez cliquer sur le lien "<b>Mot De passe oublié</b>" présent sur la page d'identification.`);
            return false;
          default:
            setDataLoaded(true);
            if(global.config.modeDebugApp == 1) console.log('Le serveur a rencontré une erreur : ', leSiret);
            setMessageProbleme(`Le serveur a rencontré une erreur, veuillez contacter le support "${global.config.admin.mail}".`);
            return false;
      }

      if(values["x_RESPONSABLE_EMAIL"] != values["x_RESPONSABLE_EMAIL_CONFIRMATION"]) {
        alert("L\'e-mail de confirmation du responsable ne correpsond pas à l'e-mail saisi, veuillez vérifier votre saisie.");
        var ob = document.getElementById("x_RESPONSABLE_EMAIL");
        ob.focus();
        return false;
      }
      if(global.config.modeDebugApp == 1) console.log("Values:::", values);
      if(global.config.modeDebugApp == 1) console.log("Values:::", JSON.stringify(values));

      var dataR = await apiSoumissionFormulaireDemande("", values, "envoi-formulaire/inscription");
      if(global.config.modeDebugApp == 1) console.log('retour apiSoumissionFormulaireDemande : ', dataR);

      switch (dataR.statusCode) {
          case 200: 
               alert("Votre demande a bien été transmise, nous vérifions l'ensemble des éléments communiqués et revenons vers vous dans les plus brefs délais.");
               props.handleCloseModal();
              break;
          case 401:
              setMessageProbleme(global.config.msgPrTechnique.msgUnauthorized); // Problème technique Unauthorized
              break;
          default:
              setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      }
      setDataLoaded(true);
      //props.handleCloseModal();
    };

    
    const onError = (error) => {
      console.log("ERROR:::", error);
    };

    async function recupererProprietesFree() {
        const dataR = await apiGetProprietesFree();
        if(global.config.modeDebugApp == 1) console.log('retour apiGetProprietesFree : ', dataR);
        switch (dataR.statusCode) {
          case 200:
            // on duplique l'objet  x_RESPONSABLE_EMAIL pour pouvoir demander une confirmation
            var objPourConfirmation =  JSON.parse(JSON.stringify(dataR.dataApi["FomulaireInscription|x_RESPONSABLE_EMAIL"]));
            objPourConfirmation.lib_form = objPourConfirmation.lib_form + " (confirmation)"; // on clone (duplique) l'objet pour que  out fonctionne bien);
            objPourConfirmation.placeholder = objPourConfirmation.placeholder + " (confirmation)";
            dataR.dataApi["FomulaireInscription|x_RESPONSABLE_EMAIL_CONFIRMATION"] = objPourConfirmation
            
            SetInfosProprietes(dataR.dataApi);
             break;
          default:
            setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
        }
    }

    const [dataLEGALSTATUS, SetDataLEGALSTATUS] = useState(); // pour recup données du select
    async function chargementsConsecutifs() {
      setDataLoaded(false);
      await recupererProprietesFree();
      var pb = false;
      var ret = await appelRecupInfoGet("", "LEGALSTATUS", setMessageProbleme); 
      if(ret != null) SetDataLEGALSTATUS(ret); else pb = true;
      if(pb == true ) setMessageProbleme(global.config.msgPrTechnique.msgDefaut); // Problème technique par défaut
      setDataLoaded(true);
    }

    useEffect(() => {  
        setMessageProbleme(null);
        chargementsConsecutifs();
    }, []);

    const InputWithDiv = (props) => {
      return (<div className="alignement-horizontal-champs-form">
        <FormControl {...props} noCount="1" />
      </div>)
    };


    return (  
        <>
        <LoadingSpinner dataLoaded={dataLoaded}/>
        <LoadingSpinner dataLoaded={!spinnerLoad} message="Obtention des données propres à votre SIRET aurpès de Quiforme..."  className="spinner-container3"/>
        <MessageErreurApi message={messageProbleme} />

        {dataLoaded && infosProprietes && messageProbleme == null &&
            <FormRHF onSubmit={onSubmit} onError={onError} dataApi={dataApi}  gestionEvenements={gestionEvenements} >

              <div className="titre-inscription">Identité</div>

              <InputWithDiv id="x_SIRET" infosProp={infosProprietes["FomulaireInscription|x_SIRET"]} nolib="1" />
              {resteFormVisible && <FormControl id="x_LIB_COMPANIES" infosProp={infosProprietes["FomulaireInscription|x_LIB_COMPANIES"]} nolib="1" />}

              <InputWithDiv id="x_SHORT_LIB" infosProp={infosProprietes["FomulaireInscription|x_SHORT_LIB"]} nolib="1" />
              {resteFormVisible && <InputWithDiv id="x_NUM_DE" infosProp={infosProprietes["FomulaireInscription|x_NUM_DE"]} nolib="1" />}
              {resteFormVisible && <InputWithDiv id="x_DATE_DECLARATION" infosProp={infosProprietes["FomulaireInscription|x_DATE_DECLARATION"]} nolib="1" classSpecifique="input-date-inline" />}
              {resteFormVisible && <InputWithDiv id="x_UAI" infosProp={infosProprietes["FomulaireInscription|x_UAI"]} nolib="1" />}

              <FormSelect id="x_STATUT" infosProp={infosProprietes["FomulaireInscription|x_STATUT"]} options={dataLEGALSTATUS} nolib="1" />

              


              <br />
              
              <div className="titre-inscription-separation">Coordonnées de l'organisme</div>

              <InputWithDiv id="x_COMPANIES_EMAIL" infosProp={infosProprietes["FomulaireInscription|x_COMPANIES_EMAIL"]} nolib="1" />
              <InputWithDiv id="x_COMPANIES_PHONE_PORTABLE" infosProp={infosProprietes["FomulaireInscription|x_COMPANIES_PHONE_PORTABLE"]} nolib="1" />
              <InputWithDiv id="x_COMPANIES_PHONE_NUMBER_FIXE" infosProp={infosProprietes["FomulaireInscription|x_COMPANIES_PHONE_NUMBER_FIXE"]} nolib="1" />

              <FormControl id="x_URL" infosProp={infosProprietes["FomulaireInscription|x_URL"]} nolib="1" />

              {resteFormVisible && <FormControl id="x_ADDRESS1" infosProp={infosProprietes["FomulaireInscription|x_ADDRESS1"]} nolib="1" />}
              <FormControl id="x_ADDRESS2" infosProp={infosProprietes["FomulaireInscription|x_ADDRESS2"]} nolib="1" />

              {resteFormVisible && <InputWithDiv id="x_CODE_POSTAL" infosProp={infosProprietes["FomulaireInscription|x_CODE_POSTAL"]} nolib="1" />}
              {resteFormVisible && <InputWithDiv id="x_LIB_VILLE" infosProp={infosProprietes["FomulaireInscription|x_LIB_VILLE"]} nolib="1" />}
              <br />


              <div className="titre-inscription-separation">Responsable de l'organisme</div>
              <MessageInfoBox nomClasse="message-info-important" 
                            message="L'Email ci-dessous sera utilisé pour envoyer les identifiants de connexion de votre organisme et les demandes de renouvellement de mot de passe." />< br />

              <InputWithDiv id="x_RESPONSABLE_EMAIL" infosProp={infosProprietes["FomulaireInscription|x_RESPONSABLE_EMAIL"]} nolib="1" />
              <InputWithDiv id="x_RESPONSABLE_EMAIL_CONFIRMATION" infosProp={infosProprietes["FomulaireInscription|x_RESPONSABLE_EMAIL_CONFIRMATION"]} nolib="1" /><br />

              <InputWithDiv id="x_RESPONSABLE_FIRST_NAME" infosProp={infosProprietes["FomulaireInscription|x_RESPONSABLE_FIRST_NAME"]} nolib="1" />
              <InputWithDiv id="x_RESPONSABLE_LAST_NAME" infosProp={infosProprietes["FomulaireInscription|x_RESPONSABLE_LAST_NAME"]} nolib="1" /><br />

              <InputWithDiv id="x_RESPONSABLE_TEL_PORTABLE" infosProp={infosProprietes["FomulaireInscription|x_RESPONSABLE_TEL_PORTABLE"]} nolib="1" />
              <InputWithDiv id="x_RESPONSABLE_TEL_FIXE" infosProp={infosProprietes["FomulaireInscription|x_RESPONSABLE_TEL_FIXE"]} nolib="1" />

              


              <div className="titre-inscription-separation-important">Le siège de votre organisme est hors Paca ?</div>
              <ul>
                <li>&bull; Vous proposez uniquement des formations à distance : merci de vous adresser au Carif Oref de votre région où est déclaré votre organisme de formation</li>
                <li>&bull; Vous proposez des formations <b>en présentiel en région PACA</b> : dans ce cas, merci de compléter les informations ci-dessous en indiquant l’adresse d’un lieu de formation <b>sur la Région Paca</b> :</li>
              </ul>

              <InputWithDiv id="x_COMPANIES_EMAIL_EN_PACA" infosProp={infosProprietes["FomulaireInscription|x_COMPANIES_EMAIL_EN_PACA"]} nolib="1" />
              <InputWithDiv id="x_COMPANIES_PHONE_PORTABLE_EN_PACA" infosProp={infosProprietes["FomulaireInscription|x_COMPANIES_PHONE_PORTABLE_EN_PACA"]} nolib="1" />
              <InputWithDiv id="x_COMPANIES_PHONE_NUMBER_FIXE_EN_PACA" infosProp={infosProprietes["FomulaireInscription|x_COMPANIES_PHONE_NUMBER_FIXE_EN_PACA"]} nolib="1" />

              <FormControl id="x_ADDRESS1_EN_PACA" infosProp={infosProprietes["FomulaireInscription|x_ADDRESS1_EN_PACA"]} nolib="1" />
              <FormControl id="x_ADDRESS2_EN_PACA" infosProp={infosProprietes["FomulaireInscription|x_ADDRESS2_EN_PACA"]} nolib="1" />

              <InputWithDiv id="x_CODE_POSTAL_EN_PACA" infosProp={infosProprietes["FomulaireInscription|x_CODE_POSTAL_EN_PACA"]} nolib="1" />
              <InputWithDiv id="x_LIB_VILLE_EN_PACA" infosProp={infosProprietes["FomulaireInscription|x_LIB_VILLE_EN_PACA"]} nolib="1" /><br />





              <div className="partie-bouton-droite">
                <ButtonDeBase typeSubmit={true} lib="Envoyer" disabled={aumoinsUneModif == true ? false : true} nolib="1" />
              </div>
              <span className="text-obligatoire">Les champs signalés d'un astérisque rouge * sont obligatoires.</span>
          </FormRHF>
        }
        </>
    )

}


export default FormInscription;