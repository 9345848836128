function AdmMoteur() {
    return (     
        <main style={{fontSize: "1.1em"}}>
            <br/><b>Les outils de cette section servent à améliorer les réponses du moteur de recherche.</b><br/><br/>
            Vous pouvez :
            <ul>
                <li>ajouter des synonymes ou appellations aux diplomes de certif info</li>
                <li>ajouter des synonymes aux types de diplomes</li>
            </ul>
            <b>⚠️ Attention : l'indexation des formations est effectuée chaque nuit.<br />
            En conséquence, toute intervention sur les synonymes/appellations ne prendra effet que le lendemain.</b><br />
     </main>
     )
 }
 export default AdmMoteur;