import PropTypes from 'prop-types';

import { useNavigate } from "react-router-dom";

/**
 * Affiche un boutton classique
 * usage : 
 * import ButtonDeBase from '../Components/ButtonDeBase.jsx';
 * <ButtonDeBase onClick={handleClickCreateProfessionalisant} lib="Créer un non certifiant" />
 * <ButtonDeBase lib="Créer" typeSubmit={true} disabled={!dataLoaded} />
 * @component
 * @summary permet d'afficher un bouton
 * @param {function} props.onClick
 * @param {string} props.lib
 * @param {bool} props.typeSubmit
 * @param {string} props.title
 * @return { HTMLElement }
 * @example props.dataLoaded = false
*/
function ButtonDeBase(props) {
  var className = props.className != null ? props.className : "fas fa-circle-chevron-right";
  var className2 = props.className2 != null ? props.className2 : "myside";
  return (
    <button className={className2}
            type={props.typeSubmit == true ? 'submit' : 'button'}
            disabled={props.disabled}
            onClick={() => props.onClick && props.onClick()}
            title={props.title !== undefined && props.title != null ? props.title : ""}
            >
              {props.lib}<span className={className}></span>
    </button>
  );
}
{/*
<button className="myside" type="submit" 
                        disabled={(!champRechFormIntit && !champRechFormLieu && champRechFormTypeForm == "-" && champRechFormContrat == "-" && champRechFormMesure == "-" && !champRechFormNumSess) || !dataLoaded}>
                            Rechercher<span className=" fas fa-circle-chevron-right"></span></button>
ancienne façon : 
<a className="myside" href="./" rel="noopener">Créer une formation certifiante<span className=" fas fa-circle-chevron-right"></span></a>
                <a className="myside" href="./formation/" rel="noopener">Créer une formation non certifiante<span className=" fas fa-circle-chevron-right"></span></a>
                <button className="myside" onClick={() => handleClickCreateCerif('certif')}>Créer une formation certifiante<span className=" fas fa-circle-chevron-right"></span></button>
                <button className="myside" onClick={() => handleClickCreateCerif('pro')}>Créer une formation non certifiante<span className=" fas fa-circle-chevron-right"></span></button>
*/}

ButtonDeBase.propTypes = {
    onClick: PropTypes.func, //nok si on  lui donne ceci,c'est alors un object : onClick={function() {setChoixCrea("certif"); setShowModalIndicAvantCrea(true) }} 
    lib : PropTypes.string,
    typeSubmit : PropTypes.bool,
    disabled : PropTypes.bool,
    className : PropTypes.string,
    className2 : PropTypes.string
}

export default ButtonDeBase;

//fa-hand-fist
export function ButtonPourList(props) {
  var className = props.className != null ? props.className : "fas fa-plus";
   
    if(props.disabled == "disabled") return (<span className="button-list-ajout2"><span className="fas fa-question"></span></span>);
    else return (<span className="button-list-ajout"><span className={className}></span>{props.lib}</span>);
}
ButtonPourList.propTypes = {
    lib : PropTypes.string,
    className : PropTypes.string,
    disabled: PropTypes.string,
}

export function ButtonVoirFiche(props) {
  var className = props.className != null ? props.className : "fas fa-magnifying-glass"; // possible aussi fas fa-eye, fa-right-to-bracket
  if(props.disabled == "disabled") return (<span className="button-list-ajout2"><span className="fas fa-question"></span></span>);
  else                             return (<span className="enrobage-boutton"><span className={className}></span>{props.lib}</span>);
}
ButtonVoirFiche.propTypes = {
    lib : PropTypes.string,
    className : PropTypes.string,
    disabled : PropTypes.string,
}



import { NavLink } from "react-router-dom";

/**
 * Return NavLink
 *
 * @component
 * @summary utilise le composant ButtonVoirFiche dans un NavLink
 * @return { HTMLElement }
*/
export function ButtonVoirFiche2(props) {
  var className = props.className != null ? props.className : "fas fa-pen-to-square"; // possible aussi fas fa-eye, fa-right-to-bracket           fas fa-magnifying-glass
  var titre = props.title != null ? props.title : "Accéder à la fiche" ;
  
    return (
    <NavLink title={`${titre}`} to={`${props.lien}`}>
      <ButtonVoirFiche className={`${className}`}  disabled={props.disabled} />
    </NavLink>
    );

}
ButtonVoirFiche2.propTypes = {
    className : PropTypes.string,
    lien : PropTypes.string,
    title : PropTypes.string,
    disabled : PropTypes.string,
}

//idem ci dessus mais ne permet pas l'ouverture sur controle clique
export function ButtonVoirFicheNoOnglet(props) {
  const navigate = useNavigate(); // utile pour changer de page

  var className = props.className != null ? props.className : "fas fa-magnifying-glass"; // possible aussi fas fa-eye, fa-right-to-bracket
  var titre = props.title != null ? props.title : "Accéder à la fiche" ;
  
    return (
      <button
      style={{background : "transparent", border: "none"}}
              type= 'button'
              title={titre}
              disabled={props.disabled}
              onClick={function() {navigate(props.lien)}}
              >
        <span className="enrobage-boutton"><span className={className}></span></span>
      </button>
    );

}
ButtonVoirFicheNoOnglet.propTypes = {
    className : PropTypes.string,
    lien : PropTypes.string,
    title : PropTypes.string
}

export function ButtonSuppr(props) {
  var className = props.className != null ? props.className : "fas fa-trash";
  if(props.disabled == "disabled") className += ` disabled`
  return (<span className="enrobage-boutton"><span className={className}></span>{props.lib}</span>);
}
ButtonSuppr.propTypes = {
    lib : PropTypes.string,
    className : PropTypes.string,

    disabled : PropTypes.string,
}

export function ButtonCopy(props) {
  var className = props.className != null ? props.className : "fas fa-copy";
  if(props.disabled == "disabled") className += ` disabled`
  return (<span className="enrobage-boutton"><span className={className}></span>{props.lib}</span>);
}
ButtonCopy.propTypes = {
    lib : PropTypes.string,
    className : PropTypes.string,
    disabled : PropTypes.string,
}