
import { NavLink } from "react-router-dom";
import { useContext } from 'react';
import { DonneesUtilisateurContext } from '../contexte.js';

import * as ROUTES from "../routes_constants.js"
import SousMenu, { SousMenuAdminFctOrg, SousMenuAdminQuiforme, SousMenuAdminMoteur } from "./SousMenu";

/**
 * Component for header with navigation.
 *
 * @component
 * @return { HTMLElement }
 */
//        <img src={`${process.env.PUBLIC_URL}/pictures/logo.png`} alt="SportSee" />
  function Header() {

    const { setBearer, utilisateurRouteLogin, utilisateurBearer, utilisateurNomOrg, utilisateurUtilisateurId, utilisateurRole } = useContext(DonneesUtilisateurContext);

    //console.log(utilisateurBearer);
    const handleLogOut = () => {
        //localStorage.clear;
        setBearer('000000');
    }

    return (<>
        {typeof(utilisateurBearer) == undefined || utilisateurBearer !== '000000' ?
        <>
        <header className="header">
            <div className='logo-header-left'><img src={`${process.env.PUBLIC_URL}/img/logo_CarifOref.png`} alt="Carif Oref Paca" className="feature-icon" /></div>
            <div className='titre-header'><h1>Extranet - {utilisateurRole == "AdminExtranet" && <span className="text-red-600">Admin {utilisateurUtilisateurId} connecté comme </span>}{utilisateurNomOrg && utilisateurNomOrg}</h1></div>
            <div className="header-menu">
                <nav className="header-nav">
                    <ul className="flex text-white font-helvetica tracking-wide text-base xs:text-xs sm:text-sm md:text-sm justify-between xs:px-6">
                        {/*<li><NavLink className={(navData) => (navData.isActive ? "header-nav-itemOn" : 'header-nav-item')} to={ROUTES.ROUTE_PROFILE.link}> {ROUTES.ROUTE_PROFILE.name}</NavLink></li>
                        <li><NavLink className={(navData) => (navData.isActive ? "header-nav-itemOn" : 'header-nav-item')} to={ROUTES.ROUTE_TESTFORM.link}> {ROUTES.ROUTE_TESTFORM.name}</NavLink></li>
                        <li><NavLink className={(navData) => (navData.isActive ? "header-nav-itemOn" : "header-nav-item")} to={ROUTES.ROUTE_TYPDIP.link}> {ROUTES.ROUTE_TYPDIP.name}</NavLink></li>
                        */}
                        {/*
                        {utilisateurRole != "AdminExtranet" && <li><NavLink className={(navData) => (navData.isActive ? "header-nav-itemOn" : 'header-nav-item')} to={ROUTES.ROUTE_ACCUEIL.link}> {ROUTES.ROUTE_ACCUEIL.name}</NavLink></li>}

                        <li><NavLink className={(navData) => (navData.isActive ? "header-nav-itemOn" : 'header-nav-item')} to={ROUTES.ROUTE_ORGANISME.link}> 
                            {utilisateurRole == "AdminExtranet" ? ROUTES.ROUTE_ORGANISME.nameForPro : ROUTES.ROUTE_ORGANISME.name}</NavLink>
                        </li>
                        <li><NavLink className={(navData) => (navData.isActive ? "header-nav-itemOn" : "header-nav-item")} to={ROUTES.ROUTE_LIEUX_ET_CONTACTS.link}>
                            {utilisateurRole == "AdminExtranet" ? ROUTES.ROUTE_LIEUX_ET_CONTACTS.nameForPro : ROUTES.ROUTE_LIEUX_ET_CONTACTS.name}</NavLink>
                        </li>
                        */}

                        {/*<NavLink className={(navData) => (navData.isActive ? "header-nav-itemOn" : "header-nav-item")} to={ROUTES.ROUTE_FORMATION.link}>
{ROUTES.ROUTE_FORMATION.nameForPro}</NavLink>*/}
                        {utilisateurRole == "AdminExtranet" ?
                        <>
                            <li><SousMenuAdminQuiforme /></li>
                            <li><SousMenuAdminMoteur /></li>
                            {/*<li><b><NavLink className={(navData) => (navData.isActive ? "header-nav-itemOn" : 'header-nav-item')} to={ROUTES.ROUTE_ADMIN_CHANGE_ORG.link}> {ROUTES.ROUTE_ADMIN_CHANGE_ORG.name}</NavLink></b></li>*/}
                            
                            <li><SousMenuAdminFctOrg /></li>
                        </>
                        :
                        <>
                            <li><NavLink className={(navData) => (navData.isActive ? "header-nav-itemOn" : 'header-nav-item')} to={ROUTES.ROUTE_ACCUEIL.link}> {ROUTES.ROUTE_ACCUEIL.name}</NavLink></li>

                            <li><NavLink className={(navData) => (navData.isActive ? "header-nav-itemOn" : 'header-nav-item')} to={ROUTES.ROUTE_ORGANISME.link}> 
                                {ROUTES.ROUTE_ORGANISME.name}</NavLink>
                            </li>
                            <li><NavLink className={(navData) => (navData.isActive ? "header-nav-itemOn" : "header-nav-item")} to={ROUTES.ROUTE_LIEUX_ET_CONTACTS.link}>
                                {ROUTES.ROUTE_LIEUX_ET_CONTACTS.name}</NavLink>
                            </li>
                            <li><SousMenu /></li>
                            {/*<li><NavLink className={(navData) => (navData.isActive ? "header-nav-itemOn" : 'header-nav-item')} to={ROUTES.ROUTE_FAQ.link}> {ROUTES.ROUTE_FAQ.name}</NavLink></li>*/}
                        </>
                        }
                                                  
                        <li>
                        <NavLink className={(navData) => (navData.isActive ? "header-nav-itemOn" : "header-nav-item")} to={utilisateurRouteLogin} onClick={handleLogOut}>
                            Se déconnecter&nbsp;
                            <span className="fas fa-right-from-bracket"></span> 
                        </NavLink>
                        </li>
                    </ul>
                </nav> 
            </div>
        </header>

        {utilisateurRole == "AdminExtranet" ?
            <div className='image-presentation-short-pro'>
                <img src={`${process.env.PUBLIC_URL}/img/image_presentation_pro.jpg`} alt="Carif Oref Paca"  className="feature-presentation-short" />
            </div>
        :
            <div className='image-presentation-short'>
                <img src={`${process.env.PUBLIC_URL}/img/image_presentation.png`} alt="Carif Oref Paca"  className="feature-presentation-short" />
            </div>
        }

        </>
        : null} 
            </>
    )
}

export default Header