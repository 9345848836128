import React, { useState } from "react" // useContext, 
import { NavLink } from "react-router-dom"

import { DonneesUtilisateurContext } from '../../contexte.js';

import { ROUTE_FORMATION, ROUTE_ADMIN_MOTEUR } from "../../routes_constants.js"
import Menu from "./Menu"
import { items, itemsProFctOrg, itemsProFQuiforme, itemsProAdminMoteur } from "./Menu/definition_sous_menu.js"

// https://reactjsexample.com/a-beautiful-modern-and-responsive-navigation-component/
// https://github.com/MenaiAla/react-navigation
// demo : https://jiue8.csb.app/features/hosting
// https://codesandbox.io/s/jiue8?file=/src/components/features/Navigation/Links/Features/index.js

//le div principal avait aupparavant className="block", modif depuis que ::after a été ajouté au li dans Header.scss et le nav restructuré dans Header.jsx

const SousMenu = () => {
    //const { utilisateurRole } = useContext(DonneesUtilisateurContext);

    const [visible, setVisible] = useState(false)

    const displayMenu = () => {
        setVisible(true)
    }
    const hideMenu = () => {
        setVisible(false)
    }
    return <div style={{display:"inline-block"}}
        onMouseLeave={hideMenu}>
        <NavLink className={(navData) => (navData.isActive ? "header-nav-itemOn" : "header-nav-item")} to={ROUTE_FORMATION.link} onMouseOver={displayMenu}>
            {/*utilisateurRole == "AdminExtranet" ? ROUTE_FORMATION.nameForPro : ROUTE_FORMATION.name*/}
            {ROUTE_FORMATION.name}
        </NavLink>
        <Menu isVisible={visible} itemsFinaux={items} displayBadge={true} />
    </div>
}
export default SousMenu

export const SousMenuAdminFctOrg = () => {
    const [visible, setVisible] = useState(false)

    const displayMenu = () => {
        setVisible(true)
    }
    const hideMenu = () => {
        setVisible(false)
    }
    return <div style={{display:"inline-block"}}
        onMouseLeave={hideMenu}>
        <NavLink className={(navData) => (navData.isActive ? "header-nav-itemOn" : "header-nav-item")} to={ROUTE_FORMATION.link} onMouseOver={displayMenu}>
            Fonctionnalités organisme
        </NavLink>
        <Menu isVisible={visible} itemsFinaux={itemsProFctOrg} displayBadge={false} />
    </div>
}

export const SousMenuAdminMoteur = () => {
    const [visible, setVisible] = useState(false)

    const displayMenu = () => {
        setVisible(true)
    }
    const hideMenu = () => {
        setVisible(false)
    }
    return <div style={{display:"inline-block"}}
        onMouseLeave={hideMenu}>
        <NavLink className={(navData) => (navData.isActive ? "header-nav-itemOn" : "header-nav-item")} to={ROUTE_ADMIN_MOTEUR.link} onMouseOver={displayMenu}>
            Admin moteur recherche
        </NavLink>
        <Menu isVisible={visible} itemsFinaux={itemsProAdminMoteur} displayBadge={false} />
        </div>
}

export const SousMenuAdminQuiforme = () => {
    const [visible, setVisible] = useState(false)

    const displayMenu = () => {
        setVisible(true)
    }
    const hideMenu = () => {
        setVisible(false)
    }
    return <div style={{display:"inline-block"}}
        onMouseLeave={hideMenu}>
        <NavLink className={(navData) => (navData.isActive ? "header-nav-itemOn" : "header-nav-item")} to={"/admin/quiforme"} onMouseOver={displayMenu}>
            Outils Quiforme
        </NavLink>
        <Menu isVisible={visible} itemsFinaux={itemsProFQuiforme} displayBadge={false} />
    </div>
}
