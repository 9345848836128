import PropTypes from 'prop-types';
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";

/**
 * Composant affichant un champ bootstrap Form.Select
 *
 * @component
 * @return { HTMLElement }
 * <FormSelect id="ID_LHEO_PARCOURS_DE_FORMATION" infosProp={props.infosProprietes["ACTIONS_FORMATION|ID_LHEO_PARCOURS_DE_FORMATION"]} options={dataLHEO_PARCOURS_DE_FORMATION}/>

*/
function FormSelect({ register, errors, id, infosProp, options, nolib, classSpecifique, ...rest }) {
    var idElement = id;

    if(infosProp != null && infosProp.lib_form != null) {
        var placeholder  = infosProp.placeholder ;
        var editable = infosProp.editable;
        var libelle = infosProp.lib_form;
        var isRequired = infosProp.required > 0 ? true : false;
        var aide = infosProp.aide;
    }
    /*const popover = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">Titre</Popover.Header>
          <Popover.Body>
           {aide}
          </Popover.Body>
        </Popover>
    );*/
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            <div dangerouslySetInnerHTML={{ __html:  aide }}></div>
        </Tooltip>
      );

    var classInput = "";
    if(classSpecifique != null && classSpecifique != '') classInput = classSpecifique;;
    if(nolib == 1 && isRequired) classInput += " required-inline"; // utile pour mettre l'étoile à droit de l'input quand on ne souhaite pas afficher le libellé associé

    return (
        
        <>
        {idElement == null ?
            <>idElement indefini</>
        : 
            <Form.Group 
                className= { isRequired ?  "mb-3 required" : "mb-3" }
                controlId={idElement}> {/*controlId permet de mettre un for dans le label et un id identique dnas le controle*/ }

                {(libelle != "" && nolib != 1) && 
                <>
                    <Form.Label>{libelle}</Form.Label>

                    {aide != null && aide != "" && 
                    <OverlayTrigger  placement="right" overlay={renderTooltip} >   
                        <button className="simply" type='button'><span className="fas fa-circle-question"></span></button>
                    </OverlayTrigger>
                    }
                    {/* pas utilisé car contient un initulé a priori pas utile, en plus c'est sur click
                    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                        <Button variant="success">?</Button>
                    </OverlayTrigger>*/}
                </>
                }

                <Form.Select 
                aria-label="Default select example"
                {...register(idElement, { required: isRequired})}
                disabled= {editable == 0 && "disabled"}
                className={classInput}
                >
                    <option value="" key="-">{placeholder}</option>
                    {options && options.map((item) => {
                    return (
                        <option value={item.id} key={item.id}>
                            {item.lib}
                        </option>
                    );
                    })}
                </Form.Select>
                {nolib == 1 && isRequired && <><span className="required-inline-etoile">*</span><br /></>}
                { /* si le libellé n'est pas affiché alors l'éventuelle aide doit s'afficher ici (attention il faudra alors pecifier une class inline comme input-date-inline) */}
                {(libelle == "" || nolib == 1) && 
                    <>
                        {aide != null && aide != "" && 
                        <OverlayTrigger  placement="right" overlay={renderTooltip} > 
                            <button className="simply" type='button'><span className="fas fa-circle-question"></span></button>
                        </OverlayTrigger>
                        }
                    </>
                }

                {errors?.[idElement] && (
                <Form.Text className="text-danger">
                    {errors?.[idElement]?.type === "required" && <p>Cette information est requise</p>}
                </Form.Text>
                )}
            </Form.Group>
        }
        </>

    )
}

FormSelect.propTypes = {
    id: PropTypes.string.isRequired,
    infosProp: PropTypes.object,
    errors: PropTypes.object,
    options: PropTypes.array,
    classSpecifique: PropTypes.string,
}

export default FormSelect